import {
  Drawer,
  List,
  Stack,
  Toolbar,
  SvgIcon,
  Typography,
} from '@mui/material'
import colorConfigs from 'configs/colorConfigs'
import sizeConfigs from 'configs/sizeConfigs'
import appRoutes from 'routes/routes'
import SidebarItem from './SidebarItem'
import SidebarItemCollapse from './SidebarItemCollapse'
import { ReactComponent as Express42Logo } from 'assets/images/express42-logo.svg'
import { EdpPlugin } from 'pages/components/Environments/PluginsPanel/PluginsPanel'
import { useState, useEffect } from 'react'
import { RouteType } from 'routes/config'
import PluginsStore from 'pages/components/Environments/PluginsPanel/store'
import { observer } from 'mobx-react'
import { reaction } from 'mobx'

const VERSION = window.env.REACT_APP_VERSION || ''

const Sidebar = observer(() => {
  const [pluggableRoutes, setPluggableRoutes] = useState<RouteType[]>([])

  useEffect(() => {
    const reactionDisposer = reaction(
      () =>
        PluginsStore.$('plugins').value.map(
          (plugin: EdpPlugin) => plugin.enabled
        ),
      () => {
        loadPluggableRoutes()
      }
    )

    return reactionDisposer
  }, [])

  const loadPluggableRoutes = () => {
    let routes: RouteType[] = []
    let plugins: EdpPlugin[] = []

    plugins = PluginsStore.$('plugins').value as EdpPlugin[]

    if (plugins) {
      for (const plugin of plugins) {
        if (plugin.enabled && plugin.routes) {
          routes = [...routes, ...plugin.routes]
        }
      }
    }

    setPluggableRoutes(routes)
  }

  useEffect(() => {
    if (PluginsStore.$('plugins').value.length > 0) {
      loadPluggableRoutes()
    } else {
      console.log('empty plugins list')
    }
  }, [PluginsStore.$('plugins').value])

  const allRoutes = [...appRoutes, ...pluggableRoutes]

  return (
    <>
      <Toolbar
        sx={{
          position: 'fixed',
          top: '0px',
          display: 'flex',
          background: colorConfigs.sidebar.logobg,
          color: colorConfigs.sidebar.color,
          height: `calc(${sizeConfigs.topbar.height} + 30px)`,
          width: sizeConfigs.sidebar.width,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
          <SvgIcon
            component={Express42Logo}
            sx={{ height: '48px', width: '48px' }}
            viewBox="0 0 120 120"
          />
        </Stack>
      </Toolbar>
      <Drawer
        variant="permanent"
        sx={{
          width: sizeConfigs.sidebar.width,
          '& .MuiDrawer-paper': {
            position: 'fixed',
            top: sizeConfigs.sidebar.header.height,
            bottom: 0,
            height: `calc(100vh - ${sizeConfigs.sidebar.header.height} - ${sizeConfigs.sidebar.footer.height})`,
            width: sizeConfigs.sidebar.width,
            boxSizing: 'border-box',
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}
      >
        <List disablePadding>
          {allRoutes.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
      </Drawer>
      <Typography
        component="div"
        sx={{
          display: 'flex',
          position: 'fixed',
          background: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          bottom: '0px',
          height: sizeConfigs.sidebar.footer.height,
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
          borderRightColor: 'rgba(0, 0, 0, 0.12)',
          width: sizeConfigs.sidebar.width,
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '11px',
        }}
      >
        {`Версия: ${VERSION}`}
      </Typography>
    </>
  )
})

export default Sidebar
