import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import {
  MdCalendarToday,
  MdOutlineTimer,
} from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { GitlabPipelineObject } from '@edp/types'
import { Chip, Link, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { getDuration } from '../../utils'
import { PipelineStatus, PipelineStatusColors } from './types'

export const PipelinesTable = ({ pipelineObjects }: any) => {
  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ paddingBottom: '5px', paddingTop: '5px' }}>
            <div
              style={{
                height: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Chip
                label={params.row.status}
                size="small"
                variant="filled"
                icon={React.cloneElement(
                  PipelineStatusColors[params.row.status as PipelineStatus]
                    .icon,
                  {
                    style: {
                      color:
                        PipelineStatusColors[
                          params.row.status as PipelineStatus
                        ].color,
                    },
                  }
                )}
                sx={{
                  minWidth: '80px',
                  backgroundColor:
                    PipelineStatusColors[params.row.status as PipelineStatus]
                      .backgroundColor,
                  color:
                    PipelineStatusColors[params.row.status as PipelineStatus]
                      .color,
                  border: 'none',
                  justifyContent: 'left',
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdOutlineTimer />
              <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                {getDuration(
                  String(params.row.created_at),
                  String(params.row.updated_at)
                )}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdCalendarToday />
              <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                {moment(params.row.created_at).format('DD.MM.YYYY HH:mm:ss')}
              </Typography>
            </div>
          </div>
        )
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 60,
      renderCell: (params) => (
        <Link href={params.row.web_url} target="_blank" rel="noreferrer">
          {params.row.id}
        </Link>
      ),
    },
    { field: 'ref', headerName: 'Ref', minWidth: 250 },
  ]

  const rows = pipelineObjects.data.map(
    (pipelineObject: GitlabPipelineObject) => {
      return {
        id: pipelineObject.id,
        status: pipelineObject.status,
        ref: pipelineObject.ref,
        web_url: pipelineObject.web_url,
        created_at: pipelineObject.created_at,
        updated_at: pipelineObject.updated_at,
        duration: getDuration(
          pipelineObject.created_at,
          pipelineObject.updated_at
        ),
      }
    }
  )

  return (
    <DataGrid
      getRowId={(row) => row.id}
      columns={columns}
      rows={rows}
      pageSize={5}
      rowsPerPageOptions={[5]}
      autoPageSize
      autoHeight={true}
      onCellClick={() => {}}
      getRowHeight={() => 'auto'}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
    />
  )
}
