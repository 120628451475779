import { CustomCardHeader } from '@edp/core-common-frontend'
import {
  Cancel,
  CheckCircle,
  Refresh,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Card, CardContent, IconButton, Tooltip } from '@mui/material'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import { OperationSpec, OperationStatus } from 'types/Managers/Operations'

export interface RetryButtonProps {
  uuid: string
  retriable: boolean
}

export const AllOperationsPanel = () => {
  const [operations, setOperations] = useState<OperationSpec[]>([])
  const apiClient = new ApiClient()

  const RetryButton = (props: RetryButtonProps) => {
    const retry = async () => {
      await apiClient.retryOperation(props.uuid)
      getOperations()
    }

    return (
      <IconButton disabled={!props.retriable} onClick={retry}>
        <Refresh />
      </IconButton>
    )
  }

  const getOperations = async () => {
    const apiClient = new ApiClient()

    try {
      const data = await apiClient.getOperations()
      setOperations(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getOperations()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'retry',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        return (
          <RetryButton
            uuid={params.row.uuid}
            retriable={params.row.retriable}
          />
        )
      },
    },
    {
      field: 'status',
      headerName: '',
      width: 20,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === OperationStatus.Succeed ? (
              <CheckCircle style={{ color: 'green' }} />
            ) : (
              <Tooltip title={params.row.error} arrow placement="top">
                <Cancel style={{ color: 'red' }} />
              </Tooltip>
            )}
          </>
        )
      },
    },
    {
      field: 'user',
      headerName: 'Пользователь',
      minWidth: 150,
    },
    {
      field: 'manager',
      headerName: 'Компонент',
      minWidth: 150,
    },
    {
      field: 'name',
      headerName: 'Название компонента',
      minWidth: 200,
    },
    {
      field: 'entity',
      headerName: 'UUID компонента',
      minWidth: 300,
    },
    {
      field: 'controller',
      headerName: 'Инфраструктурный сервис',
      minWidth: 230,
    },
    { field: 'resource', headerName: 'Ресурс', minWidth: 180 },
    {
      field: 'action',
      headerName: 'Действие',
      minWidth: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Время создания',
      width: 200,
      renderCell: (params) =>
        params.row.createdAt.toLocaleString('ru-RU', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        }),
    },
    { field: 'uuid', headerName: 'UUID операции', minWidth: 300 },
  ]

  const rows = operations.map((operation: OperationSpec) => {
    return {
      uuid: operation.uuid,
      status: operation.status,
      user: operation.user,
      controller: operation.controller,
      resource: operation.resource,
      action: operation.action,
      error: operation.error,
      entity: operation.entity,
      name: operation.name,
      manager: operation.manager,
      retriable: operation.retriable,
      createdAt: operation.createdAt,
    }
  })

  return (
    <Card sx={{ boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Операции"
        description='Каждое взаимодействие EDP с инфраструктурными сервисами запускается внутри отдельной сущности-враппера — "операции". Некоторые из операций можно перезапустить'
        fullPage={true}
      />
      <CardContent>
        <DataGrid
          getRowId={(row) => row.uuid}
          columns={columns}
          rows={rows}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoPageSize
          autoHeight={true}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{ BaseButton: RetryButton }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
        />
      </CardContent>
    </Card>
  )
}
