import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  name: {
    value: null,
    rules: 'required|string|between:3,64',
  },
  description: null,
}

export default new MobxReactForm({ fields }, { plugins })
