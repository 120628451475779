import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import useFetchHelmChart from 'hooks/useFetchHelmChart'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const helmChart = useFetchHelmChart(uuid)

  return (
    <ComponentCard
      name={helmChart?.name}
      description={helmChart?.description}
      fullPage
      options={[
        {
          name: 'Владелец',
          value: helmChart?.ownerRef?.name,
          isUrl: true,
          externalUrl: false,
          url: `/teams/${helmChart?.ownerRef?.uuid}`,
        },
        {
          name: 'Хранилище артефактов',
          value: helmChart?.registryEngine,
        },
        {
          name: 'Registry URL',
          value: helmChart?.registryURL,
        },
      ]}
    />
  )
}

export default DescriptionPanel
