import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const variable = {
  key: null,
  value: null,
  description: null,
}

const fields = {
  variables: {
    value: [],
    options: {
      structure: variable,
    },
  },
}

export default new MobxReactForm({ fields }, { plugins })
