import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { ServiceInstanceInfoSpec } from 'types/service'
import { EnvironmentSpec } from 'types/Managers/Environments'
import { handleResponse } from '@edp/core-common-frontend'

const API_V2_URL = 'http://edp-local.edp.internal.express42.io:8080/api/v2'

export async function createEnvironment(
  this: ApiClient,
  environment: EnvironmentSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/environments`

  const body = JSON.stringify(environment)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })
  return await handleResponse(response)
}

export async function getEnvironments(
  this: ApiClient
): Promise<EnvironmentSpec[]> {
  const url = `${this.serverUrl}/environments`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getEnvironmentsBy(
  this: ApiClient,
  filterName: string,
  filterValue: string
): Promise<EnvironmentSpec[]> {
  const url = `${this.serverUrl}/environments?filterName=${filterName}&filterValue=${filterValue}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await handleResponse(response)
}

export async function getEnvironment(
  this: ApiClient,
  uuid: string
): Promise<EnvironmentSpec> {
  const url = `${this.serverUrl}/environments/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updateEnvironment(
  this: ApiClient,
  spec: EnvironmentSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/environments/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getEnvironmentInstances(
  this: ApiClient,
  uuid: string
): Promise<Array<ServiceInstanceInfoSpec>> {
  const url = `${this.serverUrl}/environments/${uuid}/instances/info`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function deleteEnvironment(
  this: ApiClient,
  uuid: string
): Promise<EnvironmentSpec> {
  const url = `${this.serverUrl}/environments/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
