import { KubernetesNamespaceSelect } from '@edp/core-common-frontend'
import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Tooltip, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import './styles.css'

export interface UserInteractionKubernetesNamespaceSelectProps {
  name: string
  description: string
  helperText: string
  error?: boolean
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
}

const UserInteractionKubernetesNamespaceSelect = forwardRef(
  // eslint-disable-next-line
  (props: UserInteractionKubernetesNamespaceSelectProps, _ref) => {
    return (
      <Grid className="UserInteraction-Grid">
        <Grid className="UserInteraction-Description">
          <Typography variant="body2" fontSize={'15px'}>
            {props.description}
          </Typography>
          <Tooltip arrow title={props.helperText} placement="top">
            <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
          </Tooltip>
        </Grid>
        <KubernetesNamespaceSelect
          error={props.error}
          selected={props.selected}
          setSelected={props.setSelected}
          disabled={props.disabled}
        />
      </Grid>
    )
  }
)

UserInteractionKubernetesNamespaceSelect.displayName =
  'UserInteractionKubernetesNamespaceSelect'

export default UserInteractionKubernetesNamespaceSelect
