import { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { IconButton, LinearProgress, Tooltip } from '@mui/material'
import { Delete } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Acl, AclFilter, DescribeAclResource } from 'kafkajs'
import {
  AclOperationTypes,
  AclPermissionTypes,
  AclResourceTypes,
  ResourcePatternTypes,
} from 'types/Controllers/Kafka'

export interface KafkaAclsTableProps {
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface DeleteButtonProps {
  acls: DescribeAclResource
  acl: Acl
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface UpdateButtonProps {
  name: string
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export const KafkaAclsTable = (props: KafkaAclsTableProps) => {
  const { uuid } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [acls, setAcls] = useState<DescribeAclResource[]>([])

  const apiClient = new ApiClient()

  const DeleteButton = (props: DeleteButtonProps) => {
    const apiClient = new ApiClient()

    const filter: AclFilter = {
      resourceName: props.acls.resourceName,
      resourcePatternType: props.acls.resourcePatternType,
      resourceType: props.acls.resourceType,
      operation: props.acl.operation,
      permissionType: props.acl.permissionType,
      host: props.acl.host,
      principal: props.acl.principal,
    }

    const deleteAcl = async () => {
      await apiClient.deleteKafkaAcl(uuid || '', filter)
      getAcls()
    }

    return (
      <IconButton onClick={deleteAcl}>
        <Tooltip arrow title={`Удалить ACL`} placement="top">
          <Delete />
        </Tooltip>
      </IconButton>
    )
  }

  const getAcls = async () => {
    try {
      setLoading(true)

      const aclFilter: AclFilter = {
        resourceType: AclResourceTypes.ANY,
        resourcePatternType: ResourcePatternTypes.ANY,
        operation: AclOperationTypes.ANY,
        permissionType: AclPermissionTypes.ANY,
      }

      const data = await apiClient.getKafkaAcls(uuid || '', aclFilter)
      setAcls(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAcls()
  }, [props.created, props.updated])

  const columns: GridColDef[] = [
    {
      field: 'delete',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return (
          <DeleteButton
            acls={params.row.acls}
            acl={params.row.acl}
            failed={props.failed}
            setFailed={props.setFailed}
            errorMessage={props.errorMessage}
            setErrorMessage={props.setErrorMessage}
          />
        )
      },
    },
    {
      field: 'resourceName',
      headerName: 'name',
      width: 250,
    },
    {
      field: 'resourceType',
      headerName: 'resourceType',
      width: 150,
    },
    {
      field: 'principal',
      headerName: 'principal',
      width: 250,
    },
    {
      field: 'operation',
      headerName: 'operation',
      width: 150,
    },
    {
      field: 'permissionType',
      headerName: 'permissionType',
      width: 150,
    },
    {
      field: 'patternType',
      headerName: 'patternType',
      width: 150,
    },
  ]

  const rows = acls.flatMap((acls: DescribeAclResource) => {
    return acls.acls.map((acl: Acl) => {
      return {
        acls: acls,
        acl: acl,
        resourceName: acls.resourceName,
        resourceType: AclResourceTypes[acls.resourceType],
        operation: AclOperationTypes[acl.operation],
        permissionType: AclPermissionTypes[acl.permissionType],
        patternType: ResourcePatternTypes[acls.resourcePatternType],
        principal: acl.principal,
      }
    })
  })

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && (
        <DataGrid
          getRowId={() => uuidv4()}
          columns={columns}
          rows={rows}
          pageSize={12}
          rowsPerPageOptions={[12]}
          autoPageSize
          autoHeight={true}
          getRowHeight={() => 'auto'}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      )}
    </>
  )
}

export default KafkaAclsTable
