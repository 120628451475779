import React, { useState } from 'react'
import { Typography } from '@mui/material'

import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import { CustomButton } from '@edp/core-common-frontend'
import { AiOutlineCloudUpload } from '@edp/core-common-frontend/dist/packages/react-icons/ai'
import { AiOutlineCheckCircle } from '@edp/core-common-frontend/dist/packages/react-icons/ai'
import { ClusterSpec } from '@edp/types'

export interface CertificatesProps {
  cluster: ClusterSpec
  setCluster: React.Dispatch<React.SetStateAction<ClusterSpec>>
  disabled: boolean
}

export const Certificates = (props: CertificatesProps) => {
  const [tlsCa, setTlsCa] = useState<File>()
  const [tlsCert, setTlsCert] = useState<File>()
  const [tlsKey, setTlsKey] = useState<File>()

  if (tlsCert) {
    const reader = new FileReader()
    reader.readAsText(tlsCert)

    reader.onload = () => {
      const data = reader.result
      if (data !== null) {
        props.setCluster({
          ...props.cluster,
          tlsConfig: {
            ...props.cluster.tlsConfig,
            certData: window.btoa(String(data)),
          },
        })
      }
    }
  }

  if (tlsKey) {
    const reader = new FileReader()
    reader.readAsText(tlsKey)

    reader.onload = () => {
      const data = reader.result
      if (data !== null) {
        props.setCluster({
          ...props.cluster,
          tlsConfig: {
            ...props.cluster.tlsConfig,
            keyData: window.btoa(String(data)),
          },
        })
      }
    }
  }

  if (tlsCa) {
    const reader = new FileReader()
    reader.readAsText(tlsCa)

    reader.onload = () => {
      const data = reader.result
      if (data !== null) {
        props.setCluster({
          ...props.cluster,
          tlsConfig: {
            ...props.cluster.tlsConfig,
            caData: window.btoa(String(data)),
          },
        })
      }
    }
  }

  const handleTlsCertChange = (e: any) => {
    if (e.target.files) {
      setTlsCert(e.target.files[0])
    }
  }

  const handleTlsKeyChange = (e: any) => {
    if (e.target.files) {
      setTlsKey(e.target.files[0])
    }
  }

  const handleTlsCaChange = (e: any) => {
    if (e.target.files) {
      setTlsCa(e.target.files[0])
    }
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Сертификаты
      </Typography>
      <UserInteractionSwitch
        name="insecureTLS"
        description="Отключить проверку TLS"
        helperText="Отключить проверку TLS при взаимодействии с Kubernetes кластером."
        disabled={props.disabled}
        checked={props.cluster.tlsConfig.insecureTLS}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setCluster({
            ...props.cluster,
            tlsConfig: {
              ...props.cluster.tlsConfig,
              insecureTLS: event.target.checked,
            },
          })
        }
      />
      <UserInteractionSwitch
        name="selfSigned"
        description="Самоподписанный сертификат"
        helperText="Использовать самоподписанный SSL сертификат для доступа к Kubernetes кластеру."
        disabled={props.disabled}
        checked={props.cluster.tlsConfig.selfSigned}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setCluster({
            ...props.cluster,
            tlsConfig: {
              ...props.cluster.tlsConfig,
              selfSigned: event.target.checked,
            },
          })
        }
      />
      {props.cluster.tlsConfig.selfSigned ? (
        <UserInteractionCustom
          name="upload"
          description="Загрузить сертификаты"
          helperText='Загрузить корневой сертификат, самоподписаный сертификат и ключ в случае выбора опции "Самоподписаный сертификат".'
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomButton
              disabled={props.disabled}
              sx={{ width: 115, height: 40 }}
              startIcon={
                tlsCa ? <AiOutlineCheckCircle /> : <AiOutlineCloudUpload />
              }
            >
              CA
              <input hidden type="file" onChange={handleTlsCaChange} />
            </CustomButton>
            <CustomButton
              disabled={props.disabled}
              sx={{ width: 115, height: 40 }}
              startIcon={
                tlsCert ? <AiOutlineCheckCircle /> : <AiOutlineCloudUpload />
              }
            >
              CRT
              <input hidden type="file" onChange={handleTlsCertChange} />
            </CustomButton>
            <CustomButton
              disabled={props.disabled}
              sx={{ width: 115, height: 40 }}
              startIcon={
                tlsKey ? <AiOutlineCheckCircle /> : <AiOutlineCloudUpload />
              }
            >
              KEY
              <input hidden type="file" onChange={handleTlsKeyChange} />
            </CustomButton>
          </div>
        </UserInteractionCustom>
      ) : (
        <></>
      )}
    </>
  )
}

export default Certificates
