export interface GitlabBranch {
  name: string
}
export interface GitlabPipeline {
  ref: string
  variables: GitlabVariable[]
}

export interface GitlabVariable {
  key: string
  value: string
  description?: string
  environment_scope?: string
}

export interface GitlabContributorsSummary {
  getContributorsData: GitlabContributorData[]
}

export interface GitlabLanguagesSummary {
  getLanguagesData: any
}

export interface GitlabIssuesSummary {
  getIssuesData: GitlabIssueObject[]
}

export type GitlabContributorData = {
  project_web_url: string
  project_default_branch: string
  name: string
  email: string
  avatar_url: string
}

export type GitlabIssueObject = {
  id: string
  project_name: string
  project_id: string
  title: string
  state: GitlabIssueState
  type: GitlabIssueType
  description: string
  created_at: string
  updated_at: string
  author: GitlabAuthor
  web_url: string
}

export interface GitlabUser {
  id?: number
  username: string
  name?: string
  state?: string
  avatar_url?: string
  web_url?: string
  access_level?: number
  password?: string
  email?: string
  provider?: string
  external_uid?: string
  skip_confirmation?: boolean
  created_at?: string
  expires_at?: string | null
}

type GitlabAuthor = {
  id: string
  username: string
  name: string
  avatar_url: string
  web_url: string
}

export enum GitlabAccessLevels {
  No = 0,
  Minimal = 5,
  Guest = 10,
  Reporter = 20,
  Developer = 30,
  Maintainer = 40,
  Owner = 50,
}

export type GitlabMergeRequestState = 'opened' | 'closed' | 'all'
export type GitlabIssueState = 'opened' | 'closed'
export type GitlabIssueType = 'issue' | 'incident' | 'test_case'

export interface GitlabGroup {
  id: number
  name: string
  path: string
  full_path: string
  description: string
  visibility: string
}

export interface GitlabCommit {
  id: string
  short_id: string
  title: string
  created_at: string
  parent_ids: string[]
  message: string | null
  author_name: string
  author_email: string
  authored_date: string
  committer_name: string
  committer_email: string
  committed_date: string
  web_url: string
}

export interface GitlabRelease {
  tag_name: string
  description: string
}

export interface GitlabTag {
  commit: GitlabCommit
  release: GitlabRelease
  name: string
  target: string
  message: string | null
  protected: boolean
}

export interface GitlabTagRequest {
  ref: string
  tag_name: string
  message?: string
}

export interface GitlabBranchRequest {
  branch: string
  ref: string
}
