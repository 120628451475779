import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from '@edp/core-common-frontend'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import { DeletionWindow } from 'components/DeletionWindow'
import { HelmChartSpec } from 'types/Managers/HelmCharts'
import useFetchHelmChart from 'hooks/useFetchHelmChart'
import { NothingFoundPage } from '@edp/core-common-frontend'

export const HelmChartsPage = () => {
  const [helmCharts, setHelmCharts] = useState<HelmChartSpec[]>()
  const [filteredHelmCharts, setFilteredHelmCharts] =
    useState<HelmChartSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [helmChartToDelete, setHelmChartToDelete] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getHelmCharts = async () => {
    setLoading(true)

    try {
      const data = await apiClient.getHelmCharts()
      setHelmCharts(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getHelmCharts()
  }, [])

  useEffect(() => {
    const hc: HelmChartSpec[] = []

    if (helmCharts && owner !== '') {
      for (const helmChart of helmCharts) {
        if (helmChart.owner === owner) {
          hc.push(helmChart)
        }
      }
      setFilteredHelmCharts(hc)
    }

    if (owner === '') {
      setFilteredHelmCharts(helmCharts)
    }
  }, [helmCharts, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setHelmChartToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteHelmChart(helmChartToDelete).then(() => {
        setDeleted(true)
        getHelmCharts()
        setDeletionWindowOpened(false)
      })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const helmChartToDeleteSpec = useFetchHelmChart(helmChartToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/components/helm-charts/register'}
          >
            Зарегистрировать
          </CustomButton>
        </Box>
      </Grid>
      {filteredHelmCharts?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте зарегистрировать новый Helm chart, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredHelmCharts?.map((helmChart, index) => (
          <ComponentCard
            key={index}
            name={`${helmChart.name}`}
            uuid={helmChart.uuid}
            description={helmChart.description}
            options={[
              {
                name: 'Владелец',
                value: helmChart.ownerRef?.name,
                isUrl: true,
                externalUrl: false,
                url: `/teams/${helmChart.ownerRef?.uuid}`,
              },
              {
                name: 'Хранилище артефактов',
                value: helmChart.registryEngine,
              },
              {
                name: 'Registry URL',
                value: helmChart.registryURL,
              },
            ]}
            linkOpen={`/components/helm-charts/${helmChart.uuid}`}
            linkUpdate={`/components/helm-charts/update/${helmChart.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление Helm Chart'}
          text={`Helm chart ${helmChartToDeleteSpec?.name} будет удален без возможности восстановления.`}
          spec={helmChartToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Helm chart ${helmChartToDeleteSpec?.name} удален`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении Helm chart ${helmChartToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default HelmChartsPage
