import { Divider, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionRepositorySelect from 'components/UserInteraction/UserInteractionRepositorySelect'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { GitlabBranch } from 'types/Controllers/Gitlab'
import { ActionType } from 'types/Managers/Actions'
import CustomWebhookAction from '../CustomWebhookAction/CustomWebhookAction'
import CustomWebhookActionStore from '../CustomWebhookAction/store'
import GitlabGroupLevelVariablesAction from '../GitlabGroupLevelVariablesAction/GitlabGroupLevelVariablesAction'
import GitlabGroupLevelVariablesActionStore from '../GitlabGroupLevelVariablesAction/store'
import KafkaTopicAction from '../KafkaTopicAction/KafkaTopicAction'
import KafkaTopicActionStore from '../KafkaTopicAction/store'
import { KrakendEndpointAction } from '../KrakendEndpointAction/KrakendEndpointAction'
import KrakendEndpointActionStore from '../KrakendEndpointAction/store'
import MattermostPostAction from '../MattermostPostAction/MattermostPostAction'
import MattermostPostActionStore from '../MattermostPostAction/store'
import MattermostWebhookAction from '../MattermostWebhookAction/MattermostWebhookAction'
import MattermostWebhookActionStore from '../MattermostWebhookAction/store'
import ActionStore from './store'
import './styles.css'

const BasicInfo = observer(() => {
  const [branches, setBranches] = useState<GitlabBranch[]>([])

  const apiClient = new ApiClient()

  const fetchBranches = async () => {
    try {
      if (ActionStore.$('targetRepository').value) {
        const data = await apiClient.getRepositoryBranches(
          ActionStore.$('targetRepository').value
        )
        setBranches(data)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchBranches()
  }, [ActionStore.$('targetRepository').value])

  const handleAddVaultSecretVariables = () => {
    let v: { key: string; value: string }[] = [
      ...ActionStore.$('vault.secret.data').value,
    ]

    runInAction(() => {
      v = [...v, { key: '', value: '' }]
      ActionStore.$('vault.secret.data').set(v)
    })
  }

  const handleRemoveVaultSecretVariables = (index: number) => {
    runInAction(() => {
      const v: { key: string; value: string }[] = [
        ...ActionStore.$('vault.secret.data').value,
      ]
      v.splice(index, 1)
      ActionStore.$('vault.secret.data').set(v)
    })
  }

  const handleChangeVaultSecretVariablesKey = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const v = [...ActionStore.$('vault.secret.data').value]
      v[index].key = event.target.value
      ActionStore.$('vault.secret.data').set(v)
    })
  }

  const handleChangeVaultSecretVariablesValue = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const v = [...ActionStore.$('vault.secret.data').value]
    runInAction(() => {
      v[index].value = event.target.value
      ActionStore.$('vault.secret.data').set(v)
    })
  }

  return (
    <>
      <Typography variant="body1" className="Typography-Body1">
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название action."
        error={!ActionStore.$('name').isValid}
        {...ActionStore.$('name').bind()}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор action. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        error={!ActionStore.$('slug').isValid}
        {...ActionStore.$('slug').bind()}
      />
      <UserInteractionCustomSelect
        name="type"
        description="Тип"
        helperText="Тип action."
        options={Object.values(ActionType) as string[]}
        error={!ActionStore.$('type').isValid}
        selected={ActionStore.$('type').value}
        setSelected={(value) => ActionStore.$('type').set(value)}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание action."
        multiline
        rows={6}
        height={'150px'}
        {...ActionStore.$('description').bind()}
      />
      <UserInteractionTeamSelect
        name="owner"
        key="owner"
        description="Владелец"
        helperText="Команда владеющая action."
        error={!ActionStore.$('owner').isValid}
        selected={ActionStore.$('owner').value}
        setSelected={(value) => ActionStore.$('owner').set(value)}
      />
      {[ActionType.GitlabPipeline, ActionType.GitlabMR].includes(
        ActionStore.$('type').value
      ) && (
        <UserInteractionRepositorySelect
          name="repository"
          key="repository"
          description="Репозиторий"
          helperText="Репозиторий action."
          error={!ActionStore.$('repository').isValid}
          selected={ActionStore.$('repository').value}
          setSelected={(value) => ActionStore.$('repository').set(value)}
        />
      )}
      {ActionStore.$('type').value === ActionType.GitlabMR && (
        <>
          <UserInteractionRepositorySelect
            name="targetRepository"
            key="targetRepository"
            description="Целевой репозиторий"
            helperText="Репозиторий в который будет сделан Merge Request."
            selected={ActionStore.$('targetRepository').value}
            setSelected={(value) =>
              ActionStore.$('targetRepository').set(value)
            }
          />
          <UserInteractionCustomSelect
            name="targetBranch"
            key="targetBranch"
            description="Целевая ветка"
            helperText="Ветка целевого репозитория в которую будет сделан Merge Request."
            options={branches.map((branch) => branch.name)}
            selected={ActionStore.$('targetBranch').value}
            setSelected={(value) => ActionStore.$('targetBranch').set(value)}
          />
        </>
      )}
      {ActionStore.$('type').value === ActionType.VaultSecret && (
        <div>
          <UserInteractionTextField
            name="vaultSecretPath"
            description="Путь к секрету в Vault"
            helperText="Путь к секрету в Vault."
            {...ActionStore.$('vault.secret.path').bind()}
          />
          <UserInteractionTextField
            name="vaultSecretName"
            description="Название секрета в Vault"
            helperText="Название секрета в Vault."
            {...ActionStore.$('vault.secret.name').bind()}
          />
          <Typography
            variant="subtitle2"
            paddingTop="30px"
            className="Typography-Subtitle2"
          >
            Переменные секрета
          </Typography>
          {ActionStore.$('vault.secret.data').value &&
            ActionStore.$('vault.secret.data').value.map(
              (variable: { key: string; value: string }, index: number) => (
                <UserInteractionTwoTextFields
                  key={`vault-secret-variable-${index}`}
                  helperText={
                    'Название переменной которая будет добавлена в создаваемый секрет и ее значение.'
                  }
                  index={index}
                  first={variable.key}
                  second={variable.value}
                  firstLabel={'Название'}
                  secondLabel={'Значение'}
                  removeButton
                  handleRemove={() => handleRemoveVaultSecretVariables(index)}
                  handleChangeFirst={(event) =>
                    handleChangeVaultSecretVariablesKey(index, event)
                  }
                  handleChangeSecond={(event) =>
                    handleChangeVaultSecretVariablesValue(index, event)
                  }
                />
              )
            )}
          <UserInteractionAddButton handleAdd={handleAddVaultSecretVariables} />
        </div>
      )}
      {ActionStore.$('type').value === ActionType.KrakendEndpoint && (
        <>
          <UserInteractionRepositorySelect
            name="targetRepository"
            key="targetRepository"
            description="Целевой репозиторий"
            helperText="Репозиторий в который будет сделан Merge Request."
            selected={ActionStore.$('targetRepository').value}
            setSelected={(value) =>
              ActionStore.$('targetRepository').set(value)
            }
          />
          <UserInteractionCustomSelect
            name="targetBranch"
            key="targetBranch"
            description="Целевая ветка"
            helperText="Ветка целевого репозитория в которую будет сделан Merge Request."
            options={branches.map((branch) => branch.name)}
            selected={ActionStore.$('targetBranch').value}
            setSelected={(value) => ActionStore.$('targetBranch').set(value)}
          />
          <UserInteractionTextField
            name="targetFile"
            description="Целевой файл"
            helperText="Файл целевого репозитория, который будет изменен."
            {...ActionStore.$('targetFile').bind()}
          />
          <Divider
            sx={{
              paddingTop: '30px',
              marginBottom: '15px',
            }}
          />
          <KrakendEndpointAction form={KrakendEndpointActionStore} />
        </>
      )}
      {ActionStore.$('type').value === ActionType.KafkaTopic && (
        <>
          <Divider
            sx={{
              paddingTop: '30px',
              marginBottom: '15px',
            }}
          />
          <KafkaTopicAction form={KafkaTopicActionStore} validate={false} />
        </>
      )}
      {ActionStore.$('type').value === ActionType.GitlabGroupLevelVariables && (
        <>
          <Divider
            sx={{
              paddingTop: '30px',
              marginBottom: '15px',
            }}
          />
          <GitlabGroupLevelVariablesAction
            form={GitlabGroupLevelVariablesActionStore}
            validate={false}
          />
        </>
      )}
      {ActionStore.$('type').value === ActionType.MattermostPost && (
        <>
          <Divider
            sx={{
              paddingTop: '30px',
              marginBottom: '15px',
            }}
          />
          <MattermostPostAction form={MattermostPostActionStore} />
        </>
      )}
      {ActionStore.$('type').value === ActionType.MattermostWebhook && (
        <>
          <Divider
            sx={{
              paddingTop: '30px',
              marginBottom: '15px',
            }}
          />
          <MattermostWebhookAction form={MattermostWebhookActionStore} />
        </>
      )}
      {ActionStore.$('type').value === ActionType.CustomWebhook && (
        <>
          <Divider
            sx={{
              paddingTop: '30px',
              marginBottom: '15px',
            }}
          />
          <CustomWebhookAction form={CustomWebhookActionStore} />
        </>
      )}
    </>
  )
})

export default BasicInfo
