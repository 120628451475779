import { CardMedia, LinearProgress } from '@mui/material'
import { useFetchKubernetesNamespace } from '@edp/core-common-frontend'
import { useParams } from 'react-router-dom'
import './styles.css'
import { useState } from 'react'
import { NothingFoundPage } from '@edp/core-common-frontend'

export const OpensearchPanel = () => {
  const { uuid } = useParams()
  const namespace = useFetchKubernetesNamespace(uuid)

  const [loading, setLoading] = useState<boolean>(true)

  const handleLoad = () => {
    setLoading(false)
  }

  const isValidUrl = (url: string) => {
    try {
      new URL(url)
      return true
    } catch (e) {
      return false
    }
  }

  if (!namespace?.integrations?.opensearch?.dashboard && !loading) {
    return (
      <NothingFoundPage text="Opensearch dashboard не найден. Попробуйте добавить его на странице редактирования namespace." />
    )
  }

  if (
    namespace?.integrations?.opensearch?.dashboard &&
    !isValidUrl(namespace?.integrations.opensearch?.dashboard)
  ) {
    return (
      <NothingFoundPage text="Некорректный URL Opensearch dashboard. Попробуйте добавить правильный URL на странице редактирования namespace." />
    )
  }

  return (
    <>
      {loading && <LinearProgress />}
      <CardMedia
        className="KubernetesNamespace-OpensearchPanel-CardMedia"
        component="iframe"
        height="100%"
        width="100%"
        src={namespace?.integrations?.opensearch?.dashboard}
        sx={{
          border: 'none',
          boxShadow: 'none',
          visibility: loading ? 'hidden' : 'visible',
        }}
        onLoad={handleLoad}
      />
    </>
  )
}
