import React, { useEffect, useState } from 'react'
import { ServiceSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export interface LifecycleStageTable {
  created: boolean
  lifecycleStageUuid: string
}

export const LifecycleStageTable = (props: LifecycleStageTable) => {
  const [services, setServices] = useState<ServiceSpec[]>([])
  const apiClient = new ApiClient()

  const navigate = useNavigate()

  const getServices = async () => {
    try {
      const data = await apiClient.getServicesByLifecycleStage(
        props.lifecycleStageUuid
      )
      setServices(data)
    } catch (e) {
      console.log(`Произошла ошибка при получении списка сервисов:`, e)
    }
  }

  useEffect(() => {
    getServices()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Название',
      minWidth: 390,
      renderCell: (params) => {
        return (
          <Link
            component="button"
            onClick={() => {
              navigate(`/services/${params.row.uuid}`)
            }}
          >
            {params.row.name}
          </Link>
        )
      },
    },
    {
      field: 'description',
      headerName: 'Описание',
      minWidth: 390,
    },
    {
      field: 'owner',
      headerName: 'Владелец',
      minWidth: 390,
      renderCell: (params) => {
        return (
          <Link
            component="button"
            onClick={() => {
              navigate(`/teams/${params.row.ownerUuid}`)
            }}
          >
            {params.row.owner}
          </Link>
        )
      },
    },
  ]

  const rows = services.map((service: ServiceSpec) => {
    return {
      uuid: service.uuid,
      name: service.name,
      description: service.description,
      ownerUuid: service.ownerRef?.uuid,
      owner: service.ownerRef?.name,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.uuid}
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 49}
    />
  )
}
