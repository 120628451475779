import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { React } from '@edp/core-common-frontend/dist/packages/react'
import {
  TerraformActionRecordSpec,
  TerraformActionRecordStage,
  TerraformActionRecordStatus,
} from '@edp/types'
import Anser from 'anser'
import { ApiClient } from 'api/ApiClient'
import { Socket, io } from 'socket.io-client'
import { StatusColors } from '../types'

type LogsDialogProps = {
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  terraformActionRecordUUID: string
  stage: TerraformActionRecordStage
}

const LogsDialog = (props: LogsDialogProps) => {
  const [logs, setLogs] = React.useState<string>('')
  const [status, setStatus] = React.useState<TerraformActionRecordStatus>()
  const [terraformActionRecord, setTerraformActionRecord] =
    React.useState<TerraformActionRecordSpec>()
  const apiClient = new ApiClient()
  let socket: Socket

  const handleClose = () => {
    props.setOpened(false)
    props.setUpdated(!props.updated)
  }

  const fetchTerraformActionRecord = async () => {
    try {
      const data = await apiClient.getTerraformActionRecord(
        props.terraformActionRecordUUID
      )
      setTerraformActionRecord(data)
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    fetchTerraformActionRecord()
  }, [props.opened])

  const fetchStatus = async () => {
    if (terraformActionRecord) {
      switch (props.stage) {
        case TerraformActionRecordStage.Validate:
          setStatus(terraformActionRecord!.stages.validate.status)
          break
        case TerraformActionRecordStage.Plan:
          setStatus(terraformActionRecord!.stages.plan.status)
          break
        case TerraformActionRecordStage.Apply:
          setStatus(terraformActionRecord!.stages.apply.status)
          break
        case TerraformActionRecordStage.Destroy:
          setStatus(terraformActionRecord!.stages.destroy.status)
          break
      }
    }
  }

  React.useEffect(() => {
    fetchStatus()
  }, [props.opened, props.stage, terraformActionRecord])

  const fetchLogs = async () => {
    if (terraformActionRecord && status) {
      if ([TerraformActionRecordStatus.Running].includes(status)) {
        socket = io(
          `http://edp-local.edp.internal.express42.io:3015/terraform-action-records/${
            terraformActionRecord.uuid
          }/${props.stage.toLowerCase()}/logs`,
          { transports: ['websocket'] }
        )

        socket.on('logs', (receivedLogs) => {
          setLogs((prevLogs) => prevLogs + receivedLogs)
        })
      } else {
        const data = await apiClient.getTerraformActionRecordLogs(
          terraformActionRecord.uuid,
          props.stage
        )
        setLogs(data)
      }
    }
  }

  React.useEffect(() => {
    if (props.opened == true) {
      fetchLogs()
    } else {
      setLogs('')
    }
  }, [props.opened, terraformActionRecord, status])

  const ansiToHtml = (text: string) => {
    const html = Anser.ansiToHtml(text, {})

    return { __html: html }
  }

  return (
    <Dialog
      open={props.opened}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: '1000px', height: '70%' } }}
    >
      <DialogTitle sx={{ paddingBottom: '10px', display: 'grid' }}>
        {`${terraformActionRecord?.terraformActionRef?.name}: ${props.stage}`}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
        {status && (
          <Chip
            label={status}
            size="small"
            variant="filled"
            icon={React.cloneElement(
              StatusColors[status as TerraformActionRecordStatus].icon,
              {
                style: {
                  color:
                    StatusColors[status as TerraformActionRecordStatus].color,
                },
              }
            )}
            sx={{
              backgroundColor:
                StatusColors[status as TerraformActionRecordStatus]
                  .backgroundColor,
              color: StatusColors[status as TerraformActionRecordStatus].color,
              border: 'none',
              justifyContent: 'left',
              width: '90px',
            }}
          />
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: 'black',
          color: 'white',
        }}
      >
        <div style={{ width: 900 }}>
          <pre
            style={{ fontSize: '12px', paddingLeft: '10px' }}
            dangerouslySetInnerHTML={ansiToHtml(`${logs}`)}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LogsDialog
