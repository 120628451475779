import { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import { Delete } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Permission } from 'types/rbac/permission'

export interface PermissionsTableProps {
  created: boolean
}

export interface DeleteButtonProps {
  uuid: string
  name: string
}

export const PermissionsTable = (props: PermissionsTableProps) => {
  const [permissions, setPermissions] = useState<Permission[]>([])
  const apiClient = new ApiClient()

  const DeleteButton = (props: DeleteButtonProps) => {
    const deletePermission = async () => {
      await apiClient.deletePermission(props.uuid)
      getPermissions()
    }

    return (
      <IconButton onClick={deletePermission}>
        <Tooltip
          arrow
          title={`Удалить политику "${props.name}"`}
          placement="top"
        >
          <Delete />
        </Tooltip>
      </IconButton>
    )
  }

  const getPermissions = async () => {
    try {
      const data = await apiClient.getPermissions()
      setPermissions(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPermissions()
  }, [props.created])

  const columns: GridColDef[] = [
    {
      field: 'delete',
      headerName: '',
      width: 30,
      renderCell: (params) => {
        return <DeleteButton uuid={params.row.uuid} name={params.row.name} />
      },
    },
    {
      field: 'name',
      headerName: 'Название',
      minWidth: 390,
    },
    {
      field: 'action',
      headerName: 'Действие',
      minWidth: 50,
    },
    {
      field: 'object',
      headerName: 'Ресурс',
      minWidth: 140,
    },
    {
      field: 'owner',
      headerName: 'Владелец',
      minWidth: 300,
    },
  ]

  const rows = permissions.map((permission: Permission) => {
    return {
      uuid: permission.uuid,
      name: permission.name,
      action: permission.action,
      object: permission.object,
      owner: permission.ownerRef?.name,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.uuid}
      columns={columns}
      rows={rows}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
      components={{ BaseButton: DeleteButton }}
    />
  )
}
