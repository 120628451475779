import { observable } from 'mobx'
import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const EdpPluginComponent = {
  component: null,
  extensionPoint: null,
}

const RouteSidebarProps = {
  displayText: null,
  icon: null,
}

const RouteTopbarProps = {
  nameFromParams: false,
  breadcrumbs: null,
  entity: null,
  accessible: true,
}

const RouteType = {
  element: null,
  state: null,
  index: false,
  path: null,
  child: [],
  sidebarProps: {
    value: null,
    options: {
      structure: RouteSidebarProps,
    },
  },
  topbarProps: {
    value: null,
    options: {
      structure: RouteTopbarProps,
    },
  },
}

const EdpPlugin = {
  name: null,
  id: null,
  enabled: false,
  version: null,
  components: {
    value: [],
    options: {
      structure: EdpPluginComponent,
    },
  },
  routes: {
    value: [],
    options: {
      structure: RouteType,
    },
  },
}

const fields = {
  plugins: {
    value: [],
    options: {
      structure: EdpPlugin,
    },
  },
}

export default new MobxReactForm({ fields }, { plugins })
