import { observer } from 'mobx-react-lite'

import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import BasicInfo from './BasicInfo'
import KafkaClusterStore from './store'
import { KafkaClusterSpec } from 'types/Managers/KafkaClusters'
import { EditComponentPage } from 'components/EditComponentPage'
import Grafana from './Grafana'
import { EntityAction } from 'types/entities'

export interface EditKafkaClusterPageProps {
  action: EntityAction
}

export const EditServicePage = observer((props: EditKafkaClusterPageProps) => {
  const { uuid } = useParams()
  const navigate = useNavigate()

  const [edited, setEdited] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  useEffect(() => {
    KafkaClusterStore.reset()
  }, [])

  const fetchData = async () => {
    try {
      if (uuid) {
        const data = await apiClient.getKafkaCluster(uuid)
        KafkaClusterStore.set(data)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [uuid])

  const handleEdit = async () => {
    setEdited(false)
    setFailed(false)
    setErrorMessage('')
    setLoading(true)

    try {
      await KafkaClusterStore.validate().then(({ isValid }) => {
        if (!isValid) {
          setFailed(true)
          const e = new Error('Все обязательные поля должны быть заполнены')
          return Promise.reject(e)
        }
      })

      const spec: KafkaClusterSpec = {
        name: KafkaClusterStore.$('name').value,
        description: KafkaClusterStore.$('description').value,
        owner: KafkaClusterStore.$('owner').value,
        username: KafkaClusterStore.$('username').value,
        password: KafkaClusterStore.$('password').value,
        brokers: KafkaClusterStore.$('brokers').value,
        allowTopicsDeletion: KafkaClusterStore.$('allowTopicsDeletion').value,
        integrations: {
          grafana: {
            dashboard: KafkaClusterStore.$('integrations.grafana.dashboard')
              .value,
          },
        },
      }

      if (props.action === EntityAction.Create) {
        await apiClient.createKafkaCluster(spec)
      }

      if (props.action === EntityAction.Update) {
        spec.uuid = uuid
        await apiClient.updateKafkaCluster(spec)
      }

      setEdited(true)
      navigate('/platform-services/kafka/clusters')
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
    } finally {
      setLoading(false)
    }
  }

  let editedMessage

  if (props.action === EntityAction.Create) {
    editedMessage = 'Кластер Kafka зарегистрирован'
  }

  if (props.action === EntityAction.Update) {
    editedMessage = 'Кластер Kafka обновлен'
  }

  return (
    <>
      <EditComponentPage
        action={props.action}
        handleEdit={handleEdit}
        loading={loading}
        edited={edited}
        setEdited={setEdited}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        editedMessage={editedMessage}
      >
        <>
          <BasicInfo
            failed={failed}
            setFailed={setFailed}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <Grafana />
        </>
      </EditComponentPage>
    </>
  )
})

export default EditServicePage
