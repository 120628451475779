import React from 'react'

import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Typography, Tooltip, Slider } from '@mui/material'
import './styles.css'

export interface UserInteractionSliderProps {
  name: string
  value: number
  min: number
  max: number
  step: number
  description: string
  helperText: string
  marks: {
    value: number
    label: string
  }[]
  valueLabelFormat: string | ((value: number, index: number) => React.ReactNode)
  onChange: (event: any) => void
  disabled?: boolean
}

const UserInteractionSlider = (props: UserInteractionSliderProps) => {
  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {props.description}
        </Typography>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <Slider
        className="UserInteraction-Slider"
        disabled={props.disabled}
        name={props.name}
        min={props.min}
        max={props.max}
        step={props.step}
        marks={props.marks}
        value={props.value}
        valueLabelFormat={props.valueLabelFormat}
        onChange={props.onChange}
        size="small"
        valueLabelDisplay="auto"
      />
    </Grid>
  )
}

export default UserInteractionSlider
