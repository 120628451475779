import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectProps,
  CustomSelectComponentUUIDOption,
} from '@edp/core-common-frontend'
import { ApiClient } from 'api/ApiClient'
import { RepositorySpec } from 'types/Managers/Repositories'

export interface RepositorySelectProps extends CustomSelectProps {
  template?: boolean
}

export const RepositorySelect = (props: RepositorySelectProps) => {
  const [repositories, setRepositories] = useState<RepositorySpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getRepositories = async () => {
      try {
        const data = await apiClient.getRepositories()
        setRepositories(data)
      } catch (e) {
        console.log(e)
      }
    }

    getRepositories()
  }, [])

  let options: CustomSelectComponentUUIDOption[]

  if (props.template) {
    const templates = repositories?.filter(
      (repository) => repository.isTemplate
    )
    options =
      templates?.map((t) => ({
        label: t.name,
        uuid: t.uuid,
      })) || []
  } else {
    options =
      repositories?.map((r) => ({
        label: r.name,
        uuid: r.uuid,
      })) || []
  }

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default RepositorySelect
