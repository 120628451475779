import { handleResponse } from '@edp/core-common-frontend'
import {
  DockerTagsResponse,
  GitlabMergeRequestsSummary,
  GitlabPipelinesSummary,
  GitlabTag,
  ServiceInfoValidateSpec,
  ServiceUpdateTemplateStatusSpec,
  StatusBoolean,
  ServiceExistUpdateTemplateBranchSpec,
  ServiceUpdateLockSpec,
  ServiceSpec,
  HelmNamespaceReleases,
  ServiceInfoSpec,
} from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import { GrafanaDashboardPanelResponse } from 'common/grafana'
import {
  GitlabBranch,
  GitlabContributorsSummary,
  GitlabIssuesSummary,
  GitlabLanguagesSummary,
  GitlabPipeline,
  GitlabTagRequest,
} from 'types/Controllers/Gitlab'
import { HarborArtifact } from 'types/Controllers/Harbor'
import { VaultEnvironmentSecret } from 'types/Controllers/Vault'
import { DockerRegistryUrl } from 'types/Managers/Artifacts'
import { SbomSpec } from 'types/Sbom'
import { ServiceInstanceInfoSpec } from 'types/service'
import {
  SonarqubeFindingSummary,
  SonarqubeFindingsWrapper,
  SonarqubeMetrics,
} from 'types/sonarqube'

export async function getArtifacts(
  this: ApiClient,
  uuid: string
): Promise<HarborArtifact[]> {
  const url = `${this.serverUrl}/services/${uuid}/artifacts`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getArtifactDockerRegistryUrl(
  this: ApiClient,
  uuid: string,
  registryID: number
): Promise<DockerRegistryUrl> {
  const url = `${this.serverUrl}/services/${uuid}/artifacts/docker-registry-url/${registryID}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceBranches(
  this: ApiClient,
  uuid: string
): Promise<GitlabBranch[]> {
  const url = `${this.serverUrl}/services/${uuid}/branches`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function startPipeline(
  this: ApiClient,
  uuid: string,
  spec: GitlabPipeline
): Promise<JSON> {
  const url = `${this.serverUrl}/services/${uuid}/pipelines`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}

export async function getPipelinesSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabPipelinesSummary> {
  const url = `${this.serverUrl}/services/${uuid}/pipelines`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getContributorsSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabContributorsSummary> {
  const url = `${this.serverUrl}/services/${uuid}/contributors`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getMergeRequestsSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabMergeRequestsSummary> {
  const url = `${this.serverUrl}/services/${uuid}/merge-requests`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getLanguagesSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabLanguagesSummary> {
  const url = `${this.serverUrl}/services/${uuid}/languages`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getIssuesSummary(
  this: ApiClient,
  uuid: string
): Promise<GitlabIssuesSummary> {
  const url = `${this.serverUrl}/services/${uuid}/issues`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceGrafanaPanels(
  this: ApiClient,
  uuid: string
): Promise<GrafanaDashboardPanelResponse> {
  const url = `${this.serverUrl}/services/${uuid}/grafana/panels`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceSecrets(
  this: ApiClient,
  uuid: string
): Promise<VaultEnvironmentSecret[]> {
  const url = `${this.serverUrl}/services/${uuid}/secrets`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceInstancesInfo(
  this: ApiClient,
  uuid: string
): Promise<ServiceInstanceInfoSpec[]> {
  const url = `${this.serverUrl}/services/${uuid}/instances/info`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceSonarqubeFindingsSummary(
  this: ApiClient,
  uuid: string
): Promise<SonarqubeFindingSummary> {
  const url = `${this.serverUrl}/services/${uuid}/sonarqube/findings`

  const metrics: SonarqubeMetrics = {
    alert_status: undefined,
    bugs: undefined,
    reliability_rating: undefined,
    vulnerabilities: undefined,
    security_rating: undefined,
    security_hotspots_reviewed: undefined,
    security_review_rating: undefined,
    code_smells: undefined,
    sqale_rating: undefined,
    coverage: undefined,
    duplicated_lines_density: undefined,
  }

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const findings = (await handleResponse(
    result
  )) as unknown as SonarqubeFindingsWrapper

  findings.measures.forEach((m) => {
    metrics[m.metric] = m.value
  })

  return {
    lastAnalysis: findings.analysisDate,
    metrics,
  }
}

export async function getServiceSbom(
  this: ApiClient,
  uuid: string,
  branch: string
): Promise<SbomSpec> {
  const url = `${this.serverUrl}/services/${uuid}/sbom/${branch}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceApiSpecificationByLink(
  this: ApiClient,
  uuid: string,
  environment: string
): Promise<any> {
  const url = `${this.serverUrl}/services/${uuid}/api-specification/spec/link/${environment}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceApiSpecificationByBranch(
  this: ApiClient,
  uuid: string,
  branch: string
): Promise<any> {
  const url = `${this.serverUrl}/services/${uuid}/api-specification/spec/branch/${branch}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceReadme(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/services/${uuid}/readme`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getServiceHelmReleases(
  this: ApiClient,
  uuid: string
): Promise<HelmNamespaceReleases[]> {
  const url = `${this.serverUrl}/services/${uuid}/helm-releases`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function deleteSbom(this: ApiClient, id: number) {
  const url = `${this.serverUrl}/sbom?id=${id}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}

export async function getServiceTags(
  this: ApiClient,
  uuid: string
): Promise<DockerTagsResponse> {
  const url = `${this.serverUrl}/services/${uuid}/artifacts/tags`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getServiceRepositoryTags(
  this: ApiClient,
  uuid: string
): Promise<GitlabTag[]> {
  const url = `${this.serverUrl}/services/${uuid}/repository/tags`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function createServiceRepositoryTag(
  this: ApiClient,
  uuid: string,
  spec: GitlabTagRequest
): Promise<JSON> {
  const url = `${this.serverUrl}/services/${uuid}/repository/tags`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await handleResponse(response)
}

export async function getServiceUpdateLock(
  this: ApiClient,
  uuid: string
): Promise<ServiceUpdateLockSpec> {
  const url = `${this.serverUrl}/services/${uuid}/update/lock`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function deleteServiceUpdateLock(
  this: ApiClient,
  uuid: string
): Promise<StatusBoolean> {
  const url = `${this.serverUrl}/services/${uuid}/update/lock`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function serviceUpdateTemplate(
  this: ApiClient,
  uuid: string
): Promise<ServiceUpdateTemplateStatusSpec> {
  const url = `${this.serverUrl}/services/${uuid}/template/update`

  const response = await fetch(url, {
    method: 'PATCH',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function serviceUpdateTemplateBranchExist(
  this: ApiClient,
  uuid: string
): Promise<ServiceExistUpdateTemplateBranchSpec> {
  const url = `${this.serverUrl}/services/${uuid}/template/update/branch/exist`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getServiceInfo(
  this: ApiClient,
  uuid: string
): Promise<ServiceInfoSpec> {
  const url = `${this.serverUrl}/services/${uuid}/info`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function serviceUpdateTemplateExist(
  this: ApiClient,
  uuid: string
): Promise<StatusBoolean> {
  const url = `${this.serverUrl}/services/${uuid}/template/update/exist`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function serviceConfigurationValidate(
  this: ApiClient,
  uuid: string
): Promise<ServiceInfoValidateSpec> {
  const url = `${this.serverUrl}/services/${uuid}/configuration/validate`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getServicesByLifecycleStage(
  this: ApiClient,
  uuid: string
): Promise<ServiceSpec[]> {
  const url = `${this.serverUrl}/services/lifecycle/stages/${uuid}`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}
