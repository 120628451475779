import { TeamSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BasicInfo from './EditTeamPage/BasicInfo'
import Integrations from './EditTeamPage/Integrations'
import Resources from './EditTeamPage/Resources'
import TeamApi from './EditTeamPage/TeamApi'

export const CreateTeamPage = () => {
  const navigate = useNavigate()

  const [team, setTeam] = useState<TeamSpec>({
    uuid: '',
    name: '',
    slug: '',
    responsible: '',
    parentTeam: '',
    description: '',
    focus: '',
    type: '',
    versioning: '',
    wiki: '',
    chatChannels: '',
    timeDailyMeeting: '',
    quotas: {
      kubernetes: {
        requestsCpu: 0,
        requestsMemory: 0,
        limitsCpu: 0,
        limitsMemory: 0,
      },
    },
    integrations: {
      keycloak: {
        enabled: false,
        group: '',
        realmRole: '',
      },
      argocd: {
        enabled: false,
        project: '',
      },
      gitlab: {
        enabled: false,
        group: '',
        syncMembers: {
          enabled: false,
          auto: false,
          accessLevel: undefined,
        },
      },
      harbor: {
        enabled: false,
        group: '',
        project: '',
      },
      nexus: {
        enabled: false,
        group: '',
        kubernetes: {
          enabled: false,
        },
        docker: {
          autoGeneratePort: false,
        },
      },
      grafana: {
        enabled: false,
        dashboard: '',
        org: '',
      },
      vault: {
        enabled: false,
        secret: '',
        policy: '',
        group: '',
        groupAlias: '',
      },
      defectdojo: {
        enabled: false,
        group: {
          id: undefined,
          name: '',
          description: '',
        },
      },
    },
  })

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)

  const apiClient = new ApiClient()

  const handleCreate = async () => {
    setCreated(false)
    if (!team.name) {
      setLoading(false)
      setErrorMessage('поле "название" должно быть заполнено')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)

    try {
      const teamSpec: TeamSpec = {
        uuid: team.uuid,
        name: team.name,
        slug: team.slug,
        responsible: team.responsible,
        parentTeam: team.parentTeam,
        description: team.description,
        focus: team.focus,
        chatChannels: team.chatChannels,
        timeDailyMeeting: team.timeDailyMeeting,
        type: team.type,
        versioning: team.versioning,
        wiki: team.wiki,
        quotas: {
          kubernetes: {
            requestsCpu: team.quotas.kubernetes.limitsCpu,
            requestsMemory: team.quotas.kubernetes.limitsMemory,
            limitsCpu: team.quotas.kubernetes.limitsCpu,
            limitsMemory: team.quotas.kubernetes.limitsMemory,
          },
        },
        integrations: {
          keycloak: {
            enabled: team.integrations.keycloak.enabled,
            group: team.name,
            realmRole: team.name,
          },
          argocd: {
            enabled: team.integrations.argocd?.enabled,
            project: team.integrations.argocd?.enabled ? team.name : undefined,
          },
          gitlab: {
            enabled: team.integrations.gitlab.enabled,
            group: team.integrations.gitlab.enabled ? team.name : undefined,
            syncMembers: {
              enabled: team.integrations.gitlab.syncMembers?.enabled,
              auto: team.integrations.gitlab.syncMembers?.auto,
              accessLevel: team.integrations.gitlab.syncMembers?.accessLevel,
            },
          },
          harbor: {
            enabled: team.integrations.harbor.enabled,
            group: team.integrations.harbor.enabled ? team.name : undefined,
            project: team.integrations.harbor.enabled ? team.name : undefined,
          },
          nexus: {
            enabled: team.integrations.nexus?.enabled,
            group: team.integrations.nexus?.enabled ? team.name : undefined,
            kubernetes: {
              enabled:
                team.integrations.nexus?.enabled &&
                team.integrations.nexus?.kubernetes?.enabled,
            },
            docker: {
              name: team.integrations.nexus?.enabled
                ? `${team.name}-docker`
                : undefined,
              autoGeneratePort:
                team.integrations.nexus.docker?.autoGeneratePort,
              httpPort: team.integrations.nexus.docker?.httpPort
                ? team.integrations.nexus.docker.httpPort
                : undefined,
            },
          },
          grafana: {
            enabled: team.integrations.grafana.enabled,
            org: team.integrations.grafana.enabled ? team.name : undefined,
            dashboard: team.integrations.grafana.dashboard,
          },
          vault: {
            enabled: team.integrations.vault.enabled,
            secret: team.integrations.vault.enabled ? team.name : undefined,
            policy: team.integrations.vault.enabled ? team.name : undefined,
            group: team.integrations.vault.enabled ? team.name : undefined,
            groupAlias: team.integrations.vault.enabled ? team.name : undefined,
          },
          defectdojo: {
            enabled: team.integrations.defectdojo.enabled,
            group: {
              name: team.integrations.defectdojo.enabled
                ? team.name
                : undefined,
            },
          },
        },
      }

      await apiClient.createTeam(teamSpec)
      setCreated(true)
      navigate('/teams')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createdMessage={`Команда ${team.name} создана`}
    >
      <>
        <BasicInfo team={team} setTeam={setTeam} disabled={false} />
        <TeamApi team={team} setTeam={setTeam} disabled={false} />
        <Integrations team={team} setTeam={setTeam} disabled={false} />
        <Resources team={team} setTeam={setTeam} disabled={false} />
      </>
    </CreateComponentPage>
  )
}

export default CreateTeamPage
