import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ApiClient } from 'api/ApiClient'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionRepositorySelect from 'components/UserInteraction/UserInteractionRepositorySelect'
import UserInteractionServiceTemplateSelect from 'components/UserInteraction/UserInteractionServiceTemplateSelect'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionThreeTextFields from 'components/UserInteraction/UserInteractionThreeTextFields'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { RepositoryVariable } from 'types/Managers/Repositories'
import { ServiceInfoValidateSpec, ServiceTemplate } from '@edp/types'
import { GitlabBranchRequest, GitlabVariable } from 'types/index'
import ServiceStore from './store'
import './styles.css'
import UserInteractionServiceTemplateVersionSelect from 'components/UserInteraction/UserInteractionServiceTemplateVersionSelect'
import { BsFillGearFill } from '@edp/core-common-frontend/dist/packages/react-icons/bs'
import { ServiceTemplateVersionInfo } from 'components/ServiceTemplateVersionInfo'
import UserInteractionTextFieldOnlyField from 'components/UserInteraction/UserInteractionTextFieldOnlyField'
import { ServiceTemplateVersionError } from 'components/ServiceTemplateVersionError'

export interface TemplateProps {
  disabled: boolean
  visible: boolean
}

export const IMMUTABLE_VARIABLES = [
  'EDP_TEAM_SLUG',
  'EDP_SERVICE_UUID',
  'EDP_SERVICE_SLUG',
  'EDP_TEMPLATE_VERSION',
  'EDP_TEMPLATE_HASH',
  'EDP_UPDATES_BRANCH',
]

const ImmutableVariablesIcon = () => {
  return (
    <Tooltip arrow title={'Служебные переменные'} placement="top">
      <div>
        <BsFillGearFill className="Accordion-HelpIcon" />
      </div>
    </Tooltip>
  )
}

export const Template = observer((props: TemplateProps) => {
  const [gitlabUrl, setGitlabUrl] = useState<string>('')
  const [gitlabBranchDefault, setGitlabBranchDefault] = useState<string>('')
  const [gitlabGroups, setGitlabGroups] = useState<string[]>([])
  const [updatesBranchOptions, setUpdatesBranchOptions] = useState<string[]>([])
  const [template, setTemplate] = useState<ServiceTemplate>()
  const [otherVersion, setOtherVersion] = useState<boolean>(false)
  const [selectedVersionTemplate, setSelectedVersionTemplate] =
    useState<string>('')
  const [templateRepositoryUrl, setTemplateRepositoryUrl] = useState<string>('')

  const [loadingRepositoryVariable, setLoadingRepositoryVariable] =
    useState<boolean>(false)
  const [loadingProjectLevelVariable, setLoadingProjectLevelVariable] =
    useState<boolean>(false)
  const [loadingBranches, setLoadingBranches] = useState<boolean>(false)
  const [validTemplate, setValidTemplate] = useState<boolean>(false)
  const [validTemplateStatus, setValidTemplateStatus] =
    useState<ServiceInfoValidateSpec>()

  const [listVersionsTemplate, setListVersionsTemplate] = useState<
    Array<string>
  >([])

  const apiClient = new ApiClient()

  const getGitlabGroups = async () => {
    try {
      let templateGroups: string[] = []
      ServiceStore.$('gitlabGroupFullPath').set('')

      if (template) {
        if (template.groups && template.groups.length > 0) {
          templateGroups = template.groups
        } else {
          const groups = await apiClient.getGitlabGroups()
          templateGroups = groups.map((group) => group.full_path)
        }
      }

      setGitlabGroups(templateGroups)
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    getGitlabGroups()
  }, [template])

  function transformRepositoryVariableValue(key: string, value: any): string {
    if (IMMUTABLE_VARIABLES.includes(key)) {
      return 'генерируется автоматически'
    } else {
      return value ? value : ''
    }
  }

  const handleAddGitlabProjectLevelVariables = () => {
    let t: GitlabVariable[] = [
      ...ServiceStore.$('gitlabProjectLevelVariables').value,
    ]

    runInAction(() => {
      t = [...t, { key: '', value: '' }]
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleRemoveGitlabProjectLevelVariables = (index: number) => {
    runInAction(() => {
      const t: GitlabVariable[] = [
        ...ServiceStore.$('gitlabProjectLevelVariables').value,
      ]
      t.splice(index, 1)
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeGitlabProjectLevelVariablesKey = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const t = [...ServiceStore.$('gitlabProjectLevelVariables').value]
      t[index].key = event.target.value
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeGitlabProjectLevelVariablesValue = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const t = [...ServiceStore.$('gitlabProjectLevelVariables').value]
    runInAction(() => {
      t[index].value = event.target.value
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeGitlabProjectLevelVariablesEnvironmentScope = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const t = [...ServiceStore.$('gitlabProjectLevelVariables').value]
    runInAction(() => {
      t[index].environment_scope = event.target.value
      ServiceStore.$('gitlabProjectLevelVariables').set(t)
    })
  }

  const handleChangeRepositoryVariables = (index: number, value: string) => {
    runInAction(() => {
      const v: RepositoryVariable[] = [
        ...ServiceStore.$('templateRepositoryVariables').value,
      ]
      v[index].value = value

      ServiceStore.$('templateRepositoryVariables').set(v)
    })
  }

  const handleAddBranch = () => {
    let b: GitlabBranchRequest[] = [...ServiceStore.$('branches').value]

    runInAction(() => {
      b = [...b, { branch: '', ref: '' }]
      ServiceStore.$('branches').set(b)
    })
  }

  const handleRemoveBranch = (index: number) => {
    runInAction(() => {
      const b: GitlabBranchRequest[] = [...ServiceStore.$('branches').value]
      b.splice(index, 1)
      ServiceStore.$('branches').set(b)
    })
  }

  const handleChangeBranch = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const b = [...ServiceStore.$('branches').value]
    runInAction(() => {
      b[index].branch = event.target.value
      b[index].ref = gitlabBranchDefault
      ServiceStore.$('branches').set(b)
    })
  }

  const getRepositoryVariables = async () => {
    try {
      if (ServiceStore.$('templateVersion').value === '') {
        return
      }

      setLoadingRepositoryVariable(true)
      ServiceStore.$('templateRepositoryVariables').reset()

      if (ServiceStore.$('template').value) {
        const template = await apiClient.getServiceTemplate(
          ServiceStore.$('template').value
        )
        const templateRepository = await apiClient.getRepository(
          template.templateRepository
        )
        const variables = await apiClient.getRepositoryVariablesByVersion(
          templateRepository.uuid || '',
          ServiceStore.$('templateVersion').value
        )

        const v: RepositoryVariable[] = []

        for (const variable of variables) {
          const transformedVariable: RepositoryVariable = {
            key: variable.key,
            value: transformRepositoryVariableValue(
              variable.key,
              variable.value
            ),
            description: variable.description,
          }

          v.push(transformedVariable)
        }

        ServiceStore.$('templateRepositoryVariables').set(v)
        setLoadingRepositoryVariable(false)
      }
    } catch (e) {
      setLoadingRepositoryVariable(false)
      console.error(e)
    }
  }

  const getRepositoryProjectLevelVariables = async () => {
    try {
      if (ServiceStore.$('templateVersion').value === '') {
        return
      }

      setLoadingProjectLevelVariable(true)
      ServiceStore.$('gitlabProjectLevelVariables').reset()

      if (ServiceStore.$('template').value) {
        const template = await apiClient.getServiceTemplate(
          ServiceStore.$('template').value
        )
        const templateRepository = await apiClient.getRepository(
          template.templateRepository
        )
        let variables =
          await apiClient.getRepositoryProjectLevelVariablesByVersion(
            templateRepository.uuid || '',
            ServiceStore.$('templateVersion').value
          )

        if (variables.length == 0) {
          variables = []
        }

        ServiceStore.$('gitlabProjectLevelVariables').set(variables)
        setLoadingProjectLevelVariable(false)
      }
    } catch (e) {
      setLoadingProjectLevelVariable(false)
      console.error(e)
    }
  }

  const getRepositoryVariablesBranches = async () => {
    try {
      if (ServiceStore.$('templateVersion').value === '') {
        return
      }

      setLoadingBranches(true)
      ServiceStore.$('branches').reset()

      if (ServiceStore.$('template').value) {
        const template = await apiClient.getServiceTemplate(
          ServiceStore.$('template').value
        )
        const templateRepository = await apiClient.getRepository(
          template.templateRepository
        )
        let branches = await apiClient.getRepositoryVariablesBranchesByVersion(
          templateRepository.uuid || '',
          ServiceStore.$('templateVersion').value
        )

        if (branches.length == 0) {
          branches = []
        }

        for (let i = 0; i < branches.length; i++) {
          if (typeof branches[i].ref === 'undefined') {
            branches[i].ref = gitlabBranchDefault
          }
        }

        ServiceStore.$('branches').set(branches)
        setLoadingBranches(false)
      }
    } catch (e) {
      setLoadingBranches(false)
      console.error(e)
    }
  }

  useEffect(() => {
    const getGitlabUrl = async () => {
      try {
        const data = await apiClient.getGitlabUrl()
        setGitlabUrl(data)
      } catch (e) {
        console.error(e)
      }
    }

    getGitlabUrl()
  }, [])

  useEffect(() => {
    const getGitlabBranchDefault = async () => {
      try {
        const data = await apiClient.getGitlabBranchDefault()
        setGitlabBranchDefault(data.name)
        ServiceStore.$('updatesBranch').set(data.name)

        setUpdatesBranchOptions([data.name])
      } catch (e) {
        console.error(e)
      }
    }
    getGitlabBranchDefault()
  }, [])

  useEffect(() => {
    const setRepositoryUrl = async () => {
      try {
        if (ServiceStore.$('slug').value) {
          ServiceStore.$('repositoryUrl').set(
            `${gitlabUrl}/${ServiceStore.$('gitlabGroupFullPath').value}/${
              ServiceStore.$('slug').value
            }.git`
          )
        } else {
          ServiceStore.$('repositoryUrl').set('')
        }
      } catch (e) {
        console.error(e)
      }
    }

    setRepositoryUrl()
  }, [
    ServiceStore.$('slug').value,
    gitlabUrl,
    ServiceStore.$('gitlabGroupFullPath').value,
  ])

  useEffect(() => {
    const getTemplate = async () => {
      try {
        if (
          ServiceStore.$('templated').value &&
          ServiceStore.$('template').value
        ) {
          const template = await apiClient.getServiceTemplate(
            ServiceStore.$('template').value
          )
          setTemplate(template)
          setSelectedVersionTemplate(template.currentVersion)
          ServiceStore.$('templateVersion').set(template.currentVersion)

          const repository = await apiClient.getRepository(
            template.templateRepository
          )
          setTemplateRepositoryUrl(repository.url)
        } else {
          setTemplate(undefined)
          setTemplateRepositoryUrl('')
        }
      } catch (e) {
        console.error(e)
      }
    }

    getTemplate()
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    getRepositoryVariables()
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    getRepositoryVariables()
  }, [ServiceStore.$('templateVersion').value])

  useEffect(() => {
    getRepositoryProjectLevelVariables()
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    getRepositoryProjectLevelVariables()
  }, [ServiceStore.$('templateVersion').value])

  useEffect(() => {
    getRepositoryVariablesBranches()
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    getRepositoryVariablesBranches()
  }, [ServiceStore.$('templateVersion').value])

  useEffect(() => {
    setOtherVersion(false)
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    if (ServiceStore.$('template').value === '') {
      setSelectedVersionTemplate('')
      ServiceStore.$('templateVersion').set('')
    }
  }, [ServiceStore.$('template').value])

  useEffect(() => {
    if (ServiceStore.$('templateVersion').value !== '') {
      setSelectedVersionTemplate(ServiceStore.$('templateVersion').value)
    }
  }, [ServiceStore.$('templateVersion').value])

  useEffect(() => {
    if (!otherVersion && template) {
      setSelectedVersionTemplate(template.currentVersion)
      ServiceStore.$('templateVersion').set(template.currentVersion)
      setValidTemplateStatus(undefined)
    }

    if (otherVersion) {
      setSelectedVersionTemplate(ServiceStore.$('templateVersion').value)
    }
  }, [otherVersion])

  useEffect(() => {
    if (
      ServiceStore.$('templated').value &&
      ServiceStore.$('template').value === ''
    ) {
      setSelectedVersionTemplate('')
      ServiceStore.$('templateVersion').set('')
    }
  }, [ServiceStore.$('templated').value])

  useEffect(() => {
    const options: string[] = [gitlabBranchDefault]
    let existCurrentSelectedBranch = false

    for (const branch of ServiceStore.$('branches').value) {
      if (branch.branch === ServiceStore.$('updatesBranch').value) {
        existCurrentSelectedBranch = true
      }

      if (branch.branch !== undefined && branch.branch !== '') {
        options.push(branch.branch)
      }
    }

    if (!existCurrentSelectedBranch) {
      ServiceStore.$('updatesBranch').set(gitlabBranchDefault)
    }

    setUpdatesBranchOptions(options)
  }, [ServiceStore.$('branches').value])

  useEffect(() => {
    const checkValidTemplate = async () => {
      try {
        if (ServiceStore.$('templateVersion').value === '') {
          ServiceStore.$('templateValid').set(false)
          setValidTemplate(false)
          return
        }

        setValidTemplate(true)

        if (
          ServiceStore.$('template').value &&
          ServiceStore.$('templateVersion').value
        ) {
          const status =
            await apiClient.serviceTemplateConfigurationValidateByVersion(
              ServiceStore.$('template').value,
              ServiceStore.$('templateVersion').value
            )

          if (status.status !== 'success') {
            setValidTemplateStatus(status)
            ServiceStore.$('templateValid').set(false)
            setValidTemplate(false)
          } else {
            ServiceStore.$('templateValid').set(true)
            setValidTemplate(true)
          }
        }
      } catch (e) {
        setValidTemplate(false)
        console.error(e)
      }
    }

    if (!props.disabled) {
      checkValidTemplate()
    }
  }, [ServiceStore.$('templateVersion').value])

  return (
    <>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Шаблон
      </Typography>
      <UserInteractionSwitch
        name="templated"
        description="Создать сервис из шаблона"
        disabled={props.disabled}
        helperText={`Включить создание сервиса из шаблона.`}
        checked={Boolean(ServiceStore.$('templated').value)}
        onChange={(e) => {
          ServiceStore.$('templated').set(e.target.checked)
          setTemplate(undefined)
          ServiceStore.$('template').set(undefined)
        }}
      />
      {ServiceStore.$('templated').value && (
        <>
          <UserInteractionServiceTemplateSelect
            name="template"
            description="Шаблон"
            disabled={props.disabled}
            helperText="Шаблон на основе которого будет создаваться репозиторий сервиса."
            error={!ServiceStore.$('template').isValid}
            selected={ServiceStore.$('template').value}
            setSelected={(value) => ServiceStore.$('template').set(value)}
          />
          {selectedVersionTemplate && props.visible && (
            <ServiceTemplateVersionInfo
              severity="info"
              text={`Выбрана версия шаблона: <b>${selectedVersionTemplate}</b>`}
            />
          )}
          {props.visible && (
            <UserInteractionSwitch
              name={`enabled-version-template`}
              helperText="Использовать другую версию шаблона"
              checked={otherVersion}
              description="Другая версия"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                ServiceStore.$('templateUpdate').set(!event.target.checked)
                setListVersionsTemplate([])
                setOtherVersion(event.target.checked)
              }}
            />
          )}
          <UserInteractionSwitch
            name={`enabled-version-template-autoupdate`}
            helperText="Включить автообновление версии шаблона"
            checked={ServiceStore.$('templateUpdate').value}
            description="Автообновление шаблона"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              ServiceStore.$('templateUpdate').set(event.target.checked)
              {
                otherVersion && setOtherVersion(!event.target.checked)
              }
            }}
          />
          {otherVersion && (
            <UserInteractionServiceTemplateVersionSelect
              templateUUID={ServiceStore.$('template').value}
              name="templateVersion"
              description="Версия шаблона"
              helperText="Версия шаблона"
              error={!ServiceStore.$('templateVersion').isValid}
              selected={ServiceStore.$('templateVersion').value}
              setSelected={(value) =>
                ServiceStore.$('templateVersion').set(value)
              }
              setOptions={(data) => setListVersionsTemplate(data)}
            />
          )}
          {props.visible && (
            <>
              <UserInteractionCustomSelect
                name={'updates-branch-selector'}
                description={`Ветка обновлений`}
                key={''}
                helperText={`Ветка, в которую будут отправляться МР с обновлениями`}
                options={updatesBranchOptions}
                error={false}
                selected={ServiceStore.$('updatesBranch').value}
                setSelected={(value) =>
                  ServiceStore.$('updatesBranch').set(value)
                }
              />
              <UserInteractionCustomSelect
                name="gitlabGroup"
                description="Группа репозиториев"
                helperText="Группа репозиториев в Gitlab в которой будет создаваться репозиторий сервиса."
                options={gitlabGroups}
                error={!ServiceStore.$('gitlabGroupFullPath').isValid}
                selected={ServiceStore.$('gitlabGroupFullPath').value}
                setSelected={(value) =>
                  ServiceStore.$('gitlabGroupFullPath').set(value)
                }
              />
              <UserInteractionTextField
                name="templateRepositoryUrl"
                disabled
                description="Адрес шаблонного репозитория"
                helperText="Адрес шаблонного репозитория."
                value={templateRepositoryUrl}
              />
              <UserInteractionTextField
                name="repositoryUrl"
                disabled
                description="Адрес репозитория сервиса"
                helperText="Адрес репозитория сервиса. Генерируется автоматически. Должна быть выбрана группа репозиториев и указан идентификатор."
                value={ServiceStore.$('repositoryUrl').value}
              />
            </>
          )}
          {validTemplate && !props.disabled ? (
            <>
              {!loadingRepositoryVariable &&
              !loadingProjectLevelVariable &&
              !loadingBranches ? (
                <>
                  {!props.disabled &&
                    ServiceStore.$('templateRepositoryVariables').value && (
                      <Typography
                        variant="subtitle2"
                        paddingTop="30px"
                        className="Typography-Subtitle2"
                      >
                        Переменные шаблонного репозитория
                      </Typography>
                    )}
                  {!props.disabled &&
                    ServiceStore.$('templateRepositoryVariables').value && (
                      <Accordion
                        elevation={0}
                        sx={{
                          '&::before': {
                            visibility: 'hidden',
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            width: '694px',
                            padding: '0px',
                          }}
                        >
                          <ImmutableVariablesIcon />
                          <Typography
                            variant="subtitle2"
                            sx={{
                              paddingLeft: '5px',
                            }}
                          >
                            Служебные переменные
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: '0px',
                            justifyContent: 'space-between',
                          }}
                        >
                          {ServiceStore.$(
                            'templateRepositoryVariables'
                          ).value.map(
                            (variable: RepositoryVariable, index: number) =>
                              IMMUTABLE_VARIABLES.includes(variable.key) ? (
                                <UserInteractionTwoTextFields
                                  key={`template-repository-variable-${index}`}
                                  helperText={variable.description}
                                  index={index}
                                  first={variable.key}
                                  second={transformRepositoryVariableValue(
                                    variable.key,
                                    variable.value
                                  )}
                                  firstLabel={'Название'}
                                  secondLabel={'Значение'}
                                  handleChangeSecond={(e) =>
                                    handleChangeRepositoryVariables(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  firstDisabled
                                  // Users can't change some variables
                                  secondDisabled={true}
                                />
                              ) : (
                                <></>
                              )
                          )}
                        </AccordionDetails>
                      </Accordion>
                    )}
                  {!props.disabled &&
                    ServiceStore.$('templateRepositoryVariables').value &&
                    ServiceStore.$('templateRepositoryVariables').value.map(
                      (variable: RepositoryVariable, index: number) =>
                        !IMMUTABLE_VARIABLES.includes(variable.key) ? (
                          <UserInteractionTwoTextFields
                            key={`template-repository-variable-${index}`}
                            helperText={variable.description}
                            index={index}
                            first={variable.key}
                            second={transformRepositoryVariableValue(
                              variable.key,
                              variable.value
                            )}
                            firstLabel={'Название'}
                            secondLabel={'Значение'}
                            handleChangeSecond={(e) =>
                              handleChangeRepositoryVariables(
                                index,
                                e.target.value
                              )
                            }
                            firstDisabled
                            // Users can't change some variables
                            secondDisabled={false}
                          />
                        ) : (
                          <></>
                        )
                    )}
                  {!props.disabled && (
                    <>
                      <Typography
                        variant="subtitle2"
                        paddingTop="30px"
                        className="Typography-Subtitle2"
                      >
                        Project-level CI/CD переменные в Gitlab
                      </Typography>
                      {ServiceStore.$('gitlabProjectLevelVariables').value &&
                        ServiceStore.$('gitlabProjectLevelVariables').value.map(
                          (variable: GitlabVariable, index: number) => (
                            <UserInteractionThreeTextFields
                              key={`gitlab-project-level-variable-${index}`}
                              index={index}
                              first={variable.key}
                              second={variable.value}
                              third={variable.environment_scope}
                              firstLabel={'Название'}
                              secondLabel={'Значение'}
                              thirdLabel={'Environment scope'}
                              helperText={variable.description}
                              removeButton
                              handleRemove={() =>
                                handleRemoveGitlabProjectLevelVariables(index)
                              }
                              handleChangeFirst={(event) =>
                                handleChangeGitlabProjectLevelVariablesKey(
                                  index,
                                  event
                                )
                              }
                              handleChangeSecond={(event) =>
                                handleChangeGitlabProjectLevelVariablesValue(
                                  index,
                                  event
                                )
                              }
                              handleChangeThird={(event) =>
                                handleChangeGitlabProjectLevelVariablesEnvironmentScope(
                                  index,
                                  event
                                )
                              }
                            />
                          )
                        )}
                      <UserInteractionAddButton
                        handleAdd={handleAddGitlabProjectLevelVariables}
                      />
                      <Typography
                        variant="subtitle2"
                        paddingTop="30px"
                        className="Typography-Subtitle2"
                      >
                        Ветки
                      </Typography>
                      {ServiceStore.$('branches').value && (
                        <UserInteractionTextFieldOnlyField
                          index="default"
                          name="default-branch"
                          helperText="Ветка по умолчанию"
                          label="Название ветки по умолчанию"
                          readonly={true}
                          disabled={true}
                          value={`${gitlabBranchDefault}`}
                        />
                      )}
                      {ServiceStore.$('branches').value &&
                        ServiceStore.$('branches').value.map(
                          (branch: GitlabBranchRequest, index: number) => (
                            <UserInteractionTextFieldOnlyField
                              index={index}
                              key={`branch-${index}`}
                              name={branch.branch}
                              label={'Название'}
                              removeButton
                              handleChange={(event) => {
                                handleChangeBranch(index, event)
                              }}
                              value={branch.branch}
                              handleRemove={() => handleRemoveBranch(index)}
                            />
                          )
                        )}
                      <UserInteractionAddButton handleAdd={handleAddBranch} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {ServiceStore.$('template').value && !props.disabled ? (
                    <CircularProgress />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {template?.currentVersion === '' && !props.disabled ? (
                <>
                  {listVersionsTemplate.length == 0 ? (
                    <>
                      {otherVersion ? (
                        <>
                          <ServiceTemplateVersionError
                            severity="error"
                            text="Не удалось получить список версий шаблона. Проверьте наличие версий у шаблона"
                          />
                        </>
                      ) : (
                        <ServiceTemplateVersionError
                          severity="error"
                          text="Невозможно создать сервис. Шаблон не имеет установленной версии. Пожалуйста, установите актуальную версию шаблона или выберите из списка доступных, нажав на переключатель Другая версия"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {validTemplateStatus?.status === 'error' ? (
                        <>
                          <ServiceTemplateVersionError
                            severity="error"
                            text="Невозможно создать сервис. Версия имеет проблемы"
                            status={validTemplateStatus}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {!loadingRepositoryVariable &&
                  !loadingProjectLevelVariable &&
                  !loadingBranches &&
                  template &&
                  !props.disabled ? (
                    <>
                      <ServiceTemplateVersionError
                        severity="error"
                        text="Невозможно создать сервис. Версия имеет проблемы"
                        status={validTemplateStatus}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {!ServiceStore.$('templated').value && (
        <>
          <UserInteractionRepositorySelect
            name="repository"
            description="Существующий репозиторий"
            disabled={props.disabled}
            helperText="Выберите существующий репозиторий сервиса."
            selected={ServiceStore.$('repository').value}
            setSelected={(value) => ServiceStore.$('repository').set(value)}
          />
        </>
      )}
    </>
  )
})

export default Template
