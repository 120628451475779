import {
  AiFillEnvironment,
  AiOutlineBranches,
  AiOutlineCheckCircle,
  AiOutlineCluster,
  AiOutlineCode,
} from '@edp/core-common-frontend/dist/packages/react-icons/ai'
import { CgProfile } from '@edp/core-common-frontend/dist/packages/react-icons/cg'
import { CiCircleList } from '@edp/core-common-frontend/dist/packages/react-icons/ci'
import {
  FaInfinity,
  FaRegBuilding,
} from '@edp/core-common-frontend/dist/packages/react-icons/fa'
import { GiPlatform } from '@edp/core-common-frontend/dist/packages/react-icons/gi'
import {
  ImMap2,
  ImUsers,
  ImClock,
  ImList,
} from '@edp/core-common-frontend/dist/packages/react-icons/im'
import { IoLogoWebComponent } from '@edp/core-common-frontend/dist/packages/react-icons/io5'
import {
  MdMonitor,
  MdOutlineDashboardCustomize,
  MdOutlineSecurity,
  MdOutlineSettings,
} from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { RiTeamFill } from '@edp/core-common-frontend/dist/packages/react-icons/ri'
import {
  SiApachekafka,
  SiHelm,
  SiKubernetes,
  SiSwagger,
  SiTerraform,
} from '@edp/core-common-frontend/dist/packages/react-icons/si'
import {
  SlDocs,
  SlOrganization,
} from '@edp/core-common-frontend/dist/packages/react-icons/sl'
import {
  TbComponents,
  TbHeartRateMonitor,
  TbRadar,
  TbTemplate,
} from '@edp/core-common-frontend/dist/packages/react-icons/tb'
import {
  VscDebugStart,
  VscSymbolNamespace,
} from '@edp/core-common-frontend/dist/packages/react-icons/vsc'
import ComponentsLayout from 'components/Layouts/ComponentsLayout'
import KubernetesLayout from 'components/Layouts/KubernetesLayout'
import OrganizationLayout from 'components/Layouts/OrganizationLayout'
import PlatformServicesLayout from 'components/Layouts/PlatformServicesLayout'
import SettingsLayout from 'components/Layouts/SettingsLayout'
import { DocsPage } from 'pages/DocsPage'
import ActionsPage from 'pages/components/Actions/ActionsPage'
import CreateActionPage from 'pages/components/Actions/CreateActionPage'
import UpdateActionPage from 'pages/components/Actions/UpdateActionPage'
import CreateCustomEntityPage from 'pages/components/CustomEntities/CreateCustomEntityPage'
import CustomEntitiesPage from 'pages/components/CustomEntities/CustomEntitiesPage'
import CustomEntityPage from 'pages/components/CustomEntities/CustomEntityPage'
import UpdateCustomEntityPage from 'pages/components/CustomEntities/UpdateCustomEntityPage'
import UpdateEnvironmentPage from 'pages/components/Environments/UpdateEnvironmentPage'
import HelmChartPage from 'pages/components/HelmCharts/HelmChartPage'
import UpdateHelmChartPage from 'pages/components/HelmCharts/UpdateHelmChartPage'
import UpdateRepositoryPage from 'pages/components/Repositories/UpdateRepositoryPage'
import CreateServiceTemplatePage from 'pages/components/ServiceTemplates/CreateServiceTemplatePage'
import UpdateServiceTemplatePage from 'pages/components/ServiceTemplates/UpdateServiceTemplatePage'
import UpdateServicePage from 'pages/Services/UpdateServicePage'
import CreateTerraformActionPage from 'pages/components/TerraformActions/CreateTerraformActionPage'
import TerraformActionPage from 'pages/components/TerraformActions/TerraformActionPage'
import TerraformActionsPage from 'pages/components/TerraformActions/TerraformActionsPage'
import UpdateTerraformActionPage from 'pages/components/TerraformActions/UpdateTerraformActionPage'
import UpdateClusterPage from 'pages/kubernetes/Clusters/UpdateClusterPage'
import MonitoringPage from 'pages/organization/MonitoringPage/MonitoringPage'
import { StatusPage } from 'pages/organization/StatusPage'
import Structure from 'pages/organization/Structure/Structure'
import UpdateTeamPage from 'pages/Teams/UpdateTeamPage'
import { TechRadarPage } from 'pages/organization/TechRadarPage/TechRadarPage'
import CreateKafkaClusterPage from 'pages/platformServices/Kafka/CreateKafkaClusterPage'
import KafkaClusterPage from 'pages/platformServices/Kafka/KafkaClusterPage'
import KafkaClustersPage from 'pages/platformServices/Kafka/KafkaClustersPage'
import UpdateKafkaClusterPage from 'pages/platformServices/Kafka/UpdateKafkaClusterPage'
import UpdatePlatformServiceConfigurationPage from 'pages/platformServices/PlatformServiceConfigurations/UpdatePlatformServiceConfigurationPage'
import CreatePlatformServiceInstancePage from 'pages/platformServices/PlatformServiceInstances/CreatePlatformServiceInstancePage'
import UpdatePlatformServiceInstancePage from 'pages/platformServices/PlatformServiceInstances/UpdatePlatformServiceInstancePage'
import UpdatePlatformServiceTemplatePage from 'pages/platformServices/PlatformServiceTemplates/UpdatePlatformServiceTemplatePage'
import CredentialsPage from 'pages/settings/CredentialsPage/CredentialsPage'
import InfrastructureServicesPage from 'pages/settings/InfrastructureServices/InfrastructureServicesPage'
import OperationsPage from 'pages/settings/OperationsPage/OperationsPage'
import ProfilePage from 'pages/settings/ProfilePage/ProfilePage'
import { RBACPage } from 'pages/settings/rbac/RBACPage'
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage'
import ActionPage from '../pages/components/Actions/ActionPage'
import CreateEnvironmentPage from '../pages/components/Environments/CreateEnvironmentPage'
import EnvironmentPage from '../pages/components/Environments/EnvironmentPage'
import EnvironmentsPage from '../pages/components/Environments/EnvironmentsPage'
import CreateHelmChartPage from '../pages/components/HelmCharts/CreateHelmChartPage'
import HelmChartsPage from '../pages/components/HelmCharts/HelmChartsPage'
import CreateRepositoryPage from '../pages/components/Repositories/CreateRepositoryPage'
import RepositoriesPage from '../pages/components/Repositories/RepositoriesPage'
import RepositoryPage from '../pages/components/Repositories/RepositoryPage'
import ServiceTemplatePage from '../pages/components/ServiceTemplates/ServiceTemplatePage'
import ServiceTemplatesPage from '../pages/components/ServiceTemplates/ServiceTemplatesPage'
import CreateServicePage from '../pages/Services/CreateServicePage'
import ServicePage from '../pages/Services/ServicePage'
import ServicesPage from '../pages/Services/ServicesPage'
import ExplorePage from '../pages/explore/ExplorePage'
import ClusterPage from '../pages/kubernetes/Clusters/ClusterPage'
import ClustersPage from '../pages/kubernetes/Clusters/ClustersPage'
import CreateClusterPage from '../pages/kubernetes/Clusters/CreateClusterPage'
import CreateKubernetesNamespacePage from '../pages/kubernetes/KubernetesNamespaces/CreateKubernetesNamespacePage'
import KubernetesNamespacePage from '../pages/kubernetes/KubernetesNamespaces/KubernetesNamespacePage'
import KubernetesNamespacesPage from '../pages/kubernetes/KubernetesNamespaces/KubernetesNamespacesPage'
import UpdateKubernetesNamespacePage from '../pages/kubernetes/KubernetesNamespaces/UpdateKubernetesNamespacePage'
import CreateTeamPage from '../pages/Teams/CreateTeamPage'
import TeamPage from '../pages/Teams/TeamPage'
import TeamsPage from '../pages/Teams/TeamsPage'
import CreatePlatformServiceConfigurationPage from '../pages/platformServices/PlatformServiceConfigurations/CreatePlatformServiceConfigurationPage'
import PlatformServiceConfigurationPage from '../pages/platformServices/PlatformServiceConfigurations/PlatformServiceConfigurationPage'
import PlatformServiceConfigurationsPage from '../pages/platformServices/PlatformServiceConfigurations/PlatformServiceConfigurationsPage'
import PlatformServiceInstancePage from '../pages/platformServices/PlatformServiceInstances/PlatformServiceInstancePage'
import PlatformServiceInstancesPage from '../pages/platformServices/PlatformServiceInstances/PlatformServiceInstancesPage'
import CreatePlatformServiceTemplatePage from '../pages/platformServices/PlatformServiceTemplates/CreatePlatformServiceTemplatePage'
import PlatformServiceTemplatePage from '../pages/platformServices/PlatformServiceTemplates/PlatformServiceTemplatePage'
import PlatformServiceTemplatesPage from '../pages/platformServices/PlatformServiceTemplates/PlatformServiceTemplatesPage'
import { SwaggerPage } from '../pages/settings/SwaggerPage'
import { RouteType } from './config'
import LifecyclePage from 'pages/settings/lifecycle/LifecyclePage'
import UpdateLifecycleStagePage from 'pages/settings/lifecycle/UpdateLifecycleStagePage'
import CreateLifecycleStagePage from 'pages/settings/lifecycle/CreateLifecycleStagePage'
import LifecycleStagePage from 'pages/settings/lifecycle/LifecycleStagePage'
import ServiceSummaryTablePage from 'pages/settings/ServiceSummaryTable/ServiceSummaryTablePage'

const routes: RouteType[] = [
  {
    index: true,
    element: <ExplorePage />,
    state: 'home',
    path: '/',
  },
  {
    path: '/teams',
    element: <TeamsPage />,
    state: 'teams',
    sidebarProps: {
      displayText: 'Команды',
      icon: <RiTeamFill />,
    },
    topbarProps: {
      breadcrumbs: 'Команды',
    },
  },
  {
    path: '/teams/:uuid',
    element: <TeamPage />,
    state: 'teams',
    topbarProps: {
      nameFromParams: true,
      entity: 'Team',
    },
  },
  {
    path: '/teams/create',
    element: <CreateTeamPage />,
    state: 'teams',
    topbarProps: {
      breadcrumbs: 'Создать',
    },
  },
  {
    path: '/teams/update',
    element: <NotFoundPage />,
    state: 'teams',
    topbarProps: {
      breadcrumbs: 'Обновить',
      accessible: false,
    },
  },
  {
    path: '/teams/update/:uuid',
    element: <UpdateTeamPage />,
    state: 'teams',
    topbarProps: {
      nameFromParams: true,
      entity: 'Team',
    },
  },
  {
    path: '/services',
    element: <ServicesPage />,
    state: 'services',
    sidebarProps: {
      displayText: 'Сервисы',
      icon: <AiOutlineCode />,
    },
    topbarProps: {
      breadcrumbs: 'Сервисы',
    },
  },
  {
    path: '/services/create',
    element: <CreateServicePage />,
    state: 'services',
    topbarProps: {
      breadcrumbs: 'Создать',
    },
  },
  {
    path: '/services/update',
    element: <NotFoundPage />,
    state: 'services',
    topbarProps: {
      breadcrumbs: 'Обновить',
      accessible: false,
    },
  },
  {
    path: '/services/update/:uuid',
    element: <UpdateServicePage />,
    state: 'services',
    topbarProps: {
      breadcrumbs: 'Обновить',
      nameFromParams: true,
      entity: 'Service',
    },
  },
  {
    path: '/services/:uuid',
    element: <ServicePage />,
    state: 'services',
    topbarProps: {
      nameFromParams: true,
      entity: 'Service',
    },
  },
  {
    path: '/components',
    element: <ComponentsLayout />,
    state: 'components',
    sidebarProps: {
      displayText: 'Компоненты',
      icon: <IoLogoWebComponent />,
    },
    topbarProps: {
      breadcrumbs: 'Компоненты',
      accessible: false,
    },
    child: [
      {
        path: '/components/environments',
        element: <EnvironmentsPage />,
        state: 'components.environments',
        sidebarProps: {
          displayText: 'Окружения',
          icon: <AiFillEnvironment />,
        },
        topbarProps: {
          breadcrumbs: 'Окружения',
        },
      },
      {
        path: '/components/environments/create',
        element: <CreateEnvironmentPage />,
        state: 'components.environments',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/environments/update',
        element: <NotFoundPage />,
        state: 'components.environments',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/environments/update/:uuid',
        element: <UpdateEnvironmentPage />,
        state: 'components.environments',
        topbarProps: {
          nameFromParams: true,
          entity: 'Environment',
        },
      },
      {
        path: '/components/environments/:uuid',
        element: <EnvironmentPage />,
        state: 'components.environments',
        topbarProps: {
          nameFromParams: true,
          entity: 'Environment',
        },
      },
      {
        path: '/components/repositories',
        element: <RepositoriesPage />,
        state: 'components.repositories',
        sidebarProps: {
          displayText: 'Репозитории',
          icon: <AiOutlineBranches />,
        },
        topbarProps: {
          breadcrumbs: 'Репозитории',
        },
      },
      {
        path: '/components/repositories/register',
        element: <CreateRepositoryPage />,
        state: 'components.repositories',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/components/repositories/update',
        element: <NotFoundPage />,
        state: 'components.repositories',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/repositories/update/:uuid',
        element: <UpdateRepositoryPage />,
        state: 'components.repositories',
        topbarProps: {
          breadcrumbs: 'Обновить',
          nameFromParams: true,
          entity: 'Repository',
        },
      },
      {
        path: '/components/repositories/:uuid',
        element: <RepositoryPage />,
        state: 'components.repositories',
        topbarProps: {
          nameFromParams: true,
          entity: 'Repository',
        },
      },
      {
        path: '/components/helm-charts',
        element: <HelmChartsPage />,
        state: 'components.helm-charts',
        sidebarProps: {
          displayText: 'Helm charts',
          icon: <SiHelm />,
        },
        topbarProps: {
          breadcrumbs: 'Helm charts',
        },
      },
      {
        path: '/components/helm-charts/register',
        element: <CreateHelmChartPage />,
        state: 'components.helm-charts',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/components/helm-charts/update',
        element: <NotFoundPage />,
        state: 'components.helm-charts',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/helm-charts/update/:uuid',
        element: <UpdateHelmChartPage />,
        state: 'components.helm-charts',
        topbarProps: {
          breadcrumbs: 'Обновить',
          nameFromParams: true,
          entity: 'HelmChart',
        },
      },
      {
        path: '/components/helm-charts/:uuid',
        element: <HelmChartPage />,
        state: 'components.helm-charts',
        topbarProps: {
          nameFromParams: true,
          entity: 'HelmChart',
        },
      },
      {
        path: '/components/custom-entities',
        element: <CustomEntitiesPage />,
        state: 'components.custom-entities',
        sidebarProps: {
          displayText: 'Custom компоненты',
          icon: <MdOutlineDashboardCustomize />,
        },
        topbarProps: {
          breadcrumbs: 'Custom компоненты',
        },
      },
      {
        path: '/components/custom-entities/register',
        element: <CreateCustomEntityPage />,
        state: 'components.custom-entities',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/components/custom-entities/update',
        element: <NotFoundPage />,
        state: 'components.custom-entities',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/custom-entities/update/:uuid',
        element: <UpdateCustomEntityPage />,
        state: 'components.custom-entities',
        topbarProps: {
          nameFromParams: true,
          entity: 'CustomEntity',
        },
      },
      {
        path: '/components/custom-entities/:uuid',
        element: <CustomEntityPage />,
        state: 'components.custom-entities',
        topbarProps: {
          nameFromParams: true,
          entity: 'CustomEntity',
        },
      },
      {
        path: '/components/actions',
        element: <ActionsPage />,
        state: 'components.actions',
        sidebarProps: {
          displayText: 'Actions',
          icon: <VscDebugStart />,
        },
        topbarProps: {
          breadcrumbs: 'Actions',
        },
      },
      {
        path: '/components/actions/create',
        element: <CreateActionPage />,
        state: 'components.actions',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/actions/update',
        element: <NotFoundPage />,
        state: 'components.actions',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/actions/update/:uuid',
        element: <UpdateActionPage />,
        state: 'components.actions',
        topbarProps: {
          nameFromParams: true,
          entity: 'Action',
        },
      },
      {
        path: '/components/actions/:uuid',
        element: <ActionPage />,
        state: 'components.actions',
        topbarProps: {
          nameFromParams: true,
          entity: 'Action',
        },
      },
      {
        path: '/components/service-templates',
        element: <ServiceTemplatesPage />,
        state: 'components.service-templates',
        sidebarProps: {
          displayText: 'Шаблоны сервисов',
          icon: <TbTemplate />,
        },
        topbarProps: {
          breadcrumbs: 'Шаблоны сервисов',
        },
      },
      {
        path: '/components/service-templates/create',
        element: <CreateServiceTemplatePage />,
        state: 'components.service-templates',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/service-templates/update',
        element: <NotFoundPage />,
        state: 'components.service-templates',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/service-templates/update/:uuid',
        element: <UpdateServiceTemplatePage />,
        state: 'components.service-templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'ServiceTemplate',
        },
      },
      {
        path: '/components/service-templates/:uuid',
        element: <ServiceTemplatePage />,
        state: 'components.service-templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'ServiceTemplate',
        },
      },
      /*
      {
        path: '/components/terraform-actions',
        element: <TerraformActionsPage />,
        state: 'components.terraform-actions',
        sidebarProps: {
          displayText: 'Terraform actions',
          icon: <SiTerraform />,
        },
        topbarProps: {
          breadcrumbs: 'Terraform actions',
        },
      },
      {
        path: '/components/terraform-actions/create',
        element: <CreateTerraformActionPage />,
        state: 'components.terraform-actions',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/components/terraform-actions/update',
        element: <NotFoundPage />,
        state: 'components.terraform-actions',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/components/terraform-actions/update/:uuid',
        element: <UpdateTerraformActionPage />,
        state: 'components.terraform-actions',
        topbarProps: {
          nameFromParams: true,
          entity: 'TerraformAction',
        },
      },
      {
        path: '/components/terraform-actions/:uuid',
        element: <TerraformActionPage />,
        state: 'components.terraform-actions',
        topbarProps: {
          nameFromParams: true,
          entity: 'TerraformAction',
        },
      },
      */
    ],
  },
  {
    path: '/kubernetes',
    element: <KubernetesLayout />,
    state: 'kubernetes',
    sidebarProps: {
      displayText: 'Kubernetes',
      icon: <SiKubernetes />,
    },
    topbarProps: {
      breadcrumbs: 'Kubernetes',
      accessible: false,
    },
    child: [
      {
        path: '/kubernetes/clusters',
        element: <ClustersPage />,
        state: 'kubernetes.clusters',
        sidebarProps: {
          displayText: 'Кластеры',
          icon: <AiOutlineCluster />,
        },
        topbarProps: {
          breadcrumbs: 'Кластеры',
        },
      },
      {
        path: '/kubernetes/clusters/register',
        element: <CreateClusterPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/kubernetes/clusters/update',
        element: <NotFoundPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/kubernetes/clusters/update/:uuid',
        element: <UpdateClusterPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          nameFromParams: true,
          entity: 'Cluster',
        },
      },
      {
        path: '/kubernetes/clusters/:uuid',
        element: <ClusterPage />,
        state: 'kubernetes.clusters',
        topbarProps: {
          nameFromParams: true,
          entity: 'Cluster',
        },
      },
      {
        path: '/kubernetes/namespaces',
        element: <KubernetesNamespacesPage />,
        state: 'kubernetes.namespaces',
        sidebarProps: {
          displayText: 'Namespaces',
          icon: <VscSymbolNamespace />,
        },
        topbarProps: {
          breadcrumbs: 'Namespaces',
        },
      },
      {
        path: '/kubernetes/namespaces/register',
        element: <CreateKubernetesNamespacePage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/kubernetes/namespaces/update',
        element: <NotFoundPage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/kubernetes/namespaces/update/:uuid',
        element: <UpdateKubernetesNamespacePage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          nameFromParams: true,
          entity: 'KubernetesNamespace',
        },
      },
      {
        path: '/kubernetes/namespaces/:uuid',
        element: <KubernetesNamespacePage />,
        state: 'kubernetes.namespaces',
        topbarProps: {
          nameFromParams: true,
          entity: 'KubernetesNamespace',
        },
      },
    ],
  },
  {
    path: '/platform-services',
    element: <PlatformServicesLayout />,
    state: 'platform-services',
    sidebarProps: {
      displayText: 'Платформенные сервисы',
      icon: <GiPlatform />,
    },
    topbarProps: {
      breadcrumbs: 'Платформенные сервисы',
      accessible: false,
    },
    child: [
      {
        path: '/platform-services/templates',
        element: <PlatformServiceTemplatesPage />,
        state: 'platform-services.templates',
        sidebarProps: {
          displayText: 'Шаблоны',
          icon: <TbTemplate />,
        },
        topbarProps: {
          breadcrumbs: 'Шаблоны',
        },
      },
      {
        path: '/platform-services/templates/create',
        element: <CreatePlatformServiceTemplatePage />,
        state: 'platform-services.templates',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/platform-services/templates/update',
        element: <NotFoundPage />,
        state: 'platform-services.template',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/platform-services/templates/update/:uuid',
        element: <UpdatePlatformServiceTemplatePage />,
        state: 'platform-services.templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceTemplate',
        },
      },
      {
        path: '/platform-services/templates/:uuid',
        element: <PlatformServiceTemplatePage />,
        state: 'platform-services.templates',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceTemplate',
        },
      },
      {
        path: '/platform-services/configurations',
        element: <PlatformServiceConfigurationsPage />,
        state: 'platform-services.configurations',
        sidebarProps: {
          displayText: 'Конфигурации',
          icon: <CiCircleList />,
        },
        topbarProps: {
          breadcrumbs: 'Конфигурации',
        },
      },
      {
        path: '/platform-services/configurations/create',
        element: <CreatePlatformServiceConfigurationPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/platform-services/configurations/update',
        element: <NotFoundPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/platform-services/configurations/update/:uuid',
        element: <UpdatePlatformServiceConfigurationPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceConfiguration',
        },
      },
      {
        path: '/platform-services/configurations/:uuid',
        element: <PlatformServiceConfigurationPage />,
        state: 'platform-services.configurations',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceConfiguration',
        },
      },
      {
        path: '/platform-services/instances',
        element: <PlatformServiceInstancesPage />,
        state: 'platform-services.instances',
        sidebarProps: {
          displayText: 'Инстансы',
          icon: <TbComponents />,
        },
        topbarProps: {
          breadcrumbs: 'Инстансы',
        },
      },
      {
        path: '/platform-services/instances/create',
        element: <CreatePlatformServiceInstancePage />,
        state: 'platform-services.instances',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/platform-services/instances/update',
        element: <NotFoundPage />,
        state: 'platform-services.instances',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/platform-services/instances/update/:uuid',
        element: <UpdatePlatformServiceInstancePage />,
        state: 'platform-services.instances',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceInstance',
        },
      },
      {
        path: '/platform-services/instances/:uuid',
        element: <PlatformServiceInstancePage />,
        state: 'platform-services.instances',
        topbarProps: {
          nameFromParams: true,
          entity: 'PlatformServiceInstance',
        },
      },
      {
        path: '/platform-services/kafka',
        element: <NotFoundPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          breadcrumbs: 'Kafka',
          accessible: false,
        },
      },
      {
        path: '/platform-services/kafka/clusters',
        element: <KafkaClustersPage />,
        state: 'platform-services.kafka',
        sidebarProps: {
          displayText: 'Kafka',
          icon: <SiApachekafka />,
        },
        topbarProps: {
          breadcrumbs: 'Кластеры',
        },
      },
      {
        path: '/platform-services/kafka/clusters/:uuid',
        element: <KafkaClusterPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          entity: 'KafkaCluster',
          nameFromParams: true,
        },
      },
      {
        path: '/platform-services/kafka/clusters/create',
        element: <CreateKafkaClusterPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          breadcrumbs: 'Зарегистрировать',
        },
      },
      {
        path: '/platform-services/kafka/clusters/update/:uuid',
        element: <UpdateKafkaClusterPage />,
        state: 'platform-services.kafka',
        topbarProps: {
          breadcrumbs: 'Обновить',
          nameFromParams: true,
        },
      },
    ],
  },
  {
    path: '/organization',
    element: <OrganizationLayout />,
    state: 'organization',
    sidebarProps: {
      displayText: 'Организация',
      icon: <FaRegBuilding />,
    },
    topbarProps: {
      breadcrumbs: 'Организация',
      accessible: false,
    },
    child: [
      {
        path: '/organization/structure',
        element: <Structure />,
        state: 'organization.structure',
        sidebarProps: {
          displayText: 'Оргструктура',
          icon: <SlOrganization />,
        },
        topbarProps: {
          breadcrumbs: 'Оргструктура',
        },
      },
      {
        path: '/organization/monitoring',
        element: <MonitoringPage />,
        state: 'organization.monitoring',
        sidebarProps: {
          displayText: 'Мониторинг',
          icon: <TbHeartRateMonitor />,
        },
        topbarProps: {
          breadcrumbs: 'Мониторинг',
        },
      },
      {
        path: '/organization/tech-radar',
        element: <TechRadarPage />,
        state: 'organization.tech-radar',
        sidebarProps: {
          displayText: 'Техрадар',
          icon: <TbRadar />,
        },
        topbarProps: {
          breadcrumbs: 'Технологический радар',
        },
      },
      {
        path: '/organization/status-page',
        element: <StatusPage />,
        state: 'organization.status-page',
        sidebarProps: {
          displayText: 'Status page',
          icon: <AiOutlineCheckCircle />,
        },
        topbarProps: {
          breadcrumbs: 'Status page',
        },
      },
    ],
  },
  {
    path: '/settings',
    element: <SettingsLayout />,
    state: 'settings',
    sidebarProps: {
      displayText: 'Настройки',
      icon: <MdOutlineSettings />,
    },
    topbarProps: {
      breadcrumbs: 'Настройки',
      accessible: false,
    },
    child: [
      {
        path: '/settings/rbac',
        element: <RBACPage />,
        state: 'settings.rbac',
        sidebarProps: {
          displayText: 'Управление доступом',
          icon: <ImUsers />,
        },
        topbarProps: {
          breadcrumbs: 'Управление доступом',
        },
      },
      {
        path: '/settings/lifecycle',
        element: <LifecyclePage />,
        state: 'settings.lifecycle',
        sidebarProps: {
          displayText: 'Управление жизненным циклом',
          icon: <ImClock />,
        },
        topbarProps: {
          breadcrumbs: 'Управление жизненным циклом',
        },
      },
      {
        path: '/settings/lifecycle/stages/:uuid',
        element: <LifecycleStagePage />,
        state: 'settings.lifecycle',
        topbarProps: {
          nameFromParams: true,
          entity: 'LifecycleStage',
        },
      },
      {
        path: '/settings/lifecycle/stages/create',
        element: <CreateLifecycleStagePage />,
        state: 'settings.lifecycle',
        topbarProps: {
          breadcrumbs: 'Создать',
        },
      },
      {
        path: '/settings/lifecycle/stages/update',
        element: <NotFoundPage />,
        state: 'settings.lifecycle',
        topbarProps: {
          breadcrumbs: 'Обновить',
          accessible: false,
        },
      },
      {
        path: '/settings/lifecycle/stages/update/:uuid',
        element: <UpdateLifecycleStagePage />,
        state: 'settings.lifecycle',
        topbarProps: {
          nameFromParams: true,
          entity: 'LifecycleStage',
        },
      },
      {
        path: '/settings/operations',
        element: <OperationsPage />,
        state: 'settings.operations',
        sidebarProps: {
          displayText: 'Операции',
          icon: <FaInfinity />,
        },
        topbarProps: {
          breadcrumbs: 'Операции',
        },
      },
      {
        path: '/settings/servicessummarytable',
        element: <ServiceSummaryTablePage />,
        state: 'settings.servicesummarytable',
        sidebarProps: {
          displayText: 'Сводная таблица сервисов',
          icon: <ImList />,
        },
        topbarProps: {
          breadcrumbs: 'Сводная таблица сервисов',
        },
      },
      {
        path: '/settings/credentials',
        element: <CredentialsPage />,
        state: 'settings.credentials',
        sidebarProps: {
          displayText: 'Учетные данные',
          icon: <MdOutlineSecurity />,
        },
        topbarProps: {
          breadcrumbs: 'Учетные данные',
        },
      },
      {
        path: '/settings/infra-services',
        element: <InfrastructureServicesPage />,
        state: 'settings.infra-services',
        sidebarProps: {
          displayText: 'Инфраструктурные сервисы',
          icon: <MdMonitor />,
        },
        topbarProps: {
          breadcrumbs: 'Инфраструктурные сервисы',
        },
      },
      {
        path: '/settings/api',
        element: <SwaggerPage />,
        state: 'setting.api',
        sidebarProps: {
          displayText: 'API',
          icon: <SiSwagger />,
        },
        topbarProps: {
          breadcrumbs: 'API',
        },
      },
      {
        path: '/settings/profile',
        element: <ProfilePage />,
        state: 'settings.profile',
        sidebarProps: {
          displayText: 'Профиль',
          icon: <CgProfile />,
        },
        topbarProps: {
          breadcrumbs: 'Профиль',
        },
      },
    ],
  },
  {
    path: '/explore',
    element: <ExplorePage />,
    state: 'explore',
    sidebarProps: {
      displayText: 'Экосистема',
      icon: <ImMap2 />,
    },
    topbarProps: {
      breadcrumbs: 'Экосистема',
    },
  },
  {
    path: '/docs',
    element: <DocsPage />,
    state: 'docs',
    sidebarProps: {
      displayText: 'Документация',
      icon: <SlDocs />,
    },
    topbarProps: {
      breadcrumbs: 'Документация',
    },
  },
  {
    path: '/*',
    element: <NotFoundPage />,
    state: '404',
  },
]

export default routes
