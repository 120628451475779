import { Alert, Card, CardContent, LinearProgress } from '@mui/material'
import { CustomCardHeader } from '@edp/core-common-frontend'
import { TagsTable } from './TagsTable'
import { GitlabTag } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import React from 'react'

export interface TagsCardProps {
  uuid: string
}

export const TagsCard = (props: TagsCardProps) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [failed, setFailed] = React.useState<boolean>(false)
  const [tags, setTags] = React.useState<GitlabTag[]>([])
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const apiClient = new ApiClient()

  const getTags = async () => {
    try {
      setLoading(true)
      setErrorMessage('')
      setFailed(false)

      const data = await apiClient.getServiceRepositoryTags(props.uuid)
      setTags(data)
    } catch (e) {
      console.error(e)
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getTags()
  }, [])

  return (
    <Card sx={{ minHeight: '400px', boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Tags"
        description="Информация о тегах в репозитории сервиса"
        fullPage={false}
      />
      {loading && <LinearProgress />}
      {!loading && failed && <Alert severity="error">{errorMessage}</Alert>}
      {!loading && !failed && (
        <CardContent>
          <TagsTable tags={tags} />
        </CardContent>
      )}
    </Card>
  )
}
