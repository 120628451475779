import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import { Card, CardContent } from '@mui/material'
import { CustomButton } from '@edp/core-common-frontend'
import React from 'react'

type CreateComponentPageProps = {
  handleCreate: () => Promise<void>
  loading: boolean
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  createdMessage?: string
  errorMessage?: string
  createButtonText?: string
}

export const CreateComponentPage = (props: CreateComponentPageProps) => {
  let createButtonText = ''
  if (props.createButtonText) {
    createButtonText = props.createButtonText
  } else {
    createButtonText = 'Создать'
  }

  return (
    <Card
      sx={{
        border: 'none',
        boxShadow: 'none',
        minHeight: '100%',
        paddingTop: '0px',
      }}
    >
      <CardContent>
        {props.children}
        <CustomButton
          sx={{
            height: '40px',
            width: '220px',
            marginTop: '50px',
            marginBottom: '10px',
          }}
          onClick={props.handleCreate}
          loading={props.loading}
        >
          {createButtonText}
        </CustomButton>
      </CardContent>
      <CustomSnackbarComponent
        opened={props.created}
        setOpened={props.setCreated}
        message={props.createdMessage}
        severity="success"
      />
      <CustomSnackbarComponent
        opened={props.failed}
        setOpened={props.setFailed}
        message={props.errorMessage}
        severity="error"
      />
    </Card>
  )
}

export default CreateComponentPage
