import React, { forwardRef } from 'react'

import { Grid, TextField, Tooltip } from '@mui/material'
import './styles.css'
import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { CHANGE_ME_PLACEHOLDER } from 'types/other'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'

export interface UserInteractionTextFieldOnlyFieldProps {
  index: string | number
  name: string
  label?: string
  value: string
  type?: string
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  height?: string
  helperText?: string
  removeButton?: boolean
  handleRemove?: () => void
}

const UserInteractionTextFieldOnlyField = forwardRef(
  (props: UserInteractionTextFieldOnlyFieldProps, _ref) => {
    return (
      <Grid className="UserInteraction-Grid-Remove">
        <Grid className="UserInteraction-Grid">
          <TextField
            key={`${props.index}-key`}
            name={props.name}
            className="UserInteraction-TextFieldOnlyField"
            label={props.label}
            value={props.value}
            error={props.value === CHANGE_ME_PLACEHOLDER}
            type={props.type}
            onChange={props.handleChange}
            required={props.required || false}
            disabled={props.disabled}
            InputProps={{
              readOnly: props.readonly,
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                fontSize: '10px',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(14px, -5px) scale(0.75)',
                },
              },
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: `${props.height || '40px'}`,
              },
              '& .MuiOutlinedInput-root': {
                fontSize: '10px',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '15px',
              },
            }}
          />
        </Grid>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-TextFieldOnlyField-HelpIcon"></HelpOutline>
        </Tooltip>
        {props.removeButton && (
          <UserInteractionRemoveButton handleRemove={props.handleRemove} />
        )}
      </Grid>
    )
  }
)

UserInteractionTextFieldOnlyField.displayName =
  'UserInteractionTextFieldOnlyField'

export default UserInteractionTextFieldOnlyField
