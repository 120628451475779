import { CustomButton } from '@edp/core-common-frontend'
import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { React } from '@edp/core-common-frontend/dist/packages/react'
import {
  Managers,
  TerraformActionRecordSpec,
  TerraformActionRecordStatus,
  TerraformActionSpec,
  TerraformVariable,
} from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import 'prismjs/components/prism-log'
import 'prismjs/themes/prism.css'
import TerraformActionStore from '../store'

type RunDialogProps = {
  opened: boolean
  uuid: string
  terraformAction: TerraformActionSpec
  terraformActionRecord?: TerraformActionRecordSpec
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

const RunDialog = observer((props: RunDialogProps) => {
  const [failed, setFailed] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [enableValidate, setEnableValidate] = React.useState<boolean>(true)
  const [enablePlan, setEnablePlan] = React.useState<boolean>(true)
  const [enableApply, setEnableApply] = React.useState<boolean>(true)
  const [enableDestroy, setEnableDestroy] = React.useState<boolean>(false)

  const apiClient = new ApiClient()

  const handleChangeValidate = (event: any) => {
    setEnableValidate(event.target.checked)
  }

  const handleChangePlan = (event: any) => {
    setEnablePlan(event.target.checked)
  }

  const handleChangeApply = (event: any) => {
    setEnableApply(event.target.checked)
  }

  const handleChangeDestroy = (event: any) => {
    setEnableDestroy(event.target.checked)
  }

  const getTerraformActionVariables = async () => {
    try {
      TerraformActionStore.$('variables').set([])

      if (props.terraformActionRecord) {
        TerraformActionStore.$('variables').set(
          props.terraformActionRecord.variables
        )
      } else {
        const data = await apiClient.getTerraformActionVariables(
          props.terraformAction.uuid!
        )
        TerraformActionStore.$('variables').set(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    getTerraformActionVariables()
  }, [props.opened])

  const handleRun = async () => {
    try {
      setFailed(false)
      setErrorMessage('')

      const terraformActionRecord: TerraformActionRecordSpec = {
        uuid: '',
        terraformAction: props.terraformAction.uuid!,
        status: TerraformActionRecordStatus.Created,
        entityKind: Managers.Service,
        entityUUID: props.uuid || '',
        state: '',
        stages: {
          validate: {
            status: enableValidate
              ? TerraformActionRecordStatus.Created
              : TerraformActionRecordStatus.Skipped,
            logs: '',
          },
          plan: {
            status: enablePlan
              ? TerraformActionRecordStatus.Created
              : TerraformActionRecordStatus.Skipped,
            logs: '',
          },
          apply: {
            status: enableApply
              ? TerraformActionRecordStatus.Created
              : TerraformActionRecordStatus.Skipped,
            logs: '',
          },
          destroy: {
            status: enableDestroy
              ? TerraformActionRecordStatus.Created
              : TerraformActionRecordStatus.Skipped,
            logs: '',
          },
        },
        variables: TerraformActionStore.$('variables').value,
      }

      await apiClient.terraformActionRun(
        props.terraformAction.uuid!,
        terraformActionRecord
      )

      props.setOpened(false)
    } catch (e) {
      setErrorMessage(String(e))
      setFailed(true)
      console.error(e)
    } finally {
      props.setUpdated(!props.updated)
    }
  }

  const handleClose = () => {
    props.setOpened(false)
  }

  const handleChangeTerraformVariablesValue = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const v: TerraformVariable[] = [
        ...TerraformActionStore.$('variables').value,
      ]
      v[index].value = event.target.value
      TerraformActionStore.$('variables').set(v)
    })
  }

  return (
    <Dialog
      open={props.opened}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: '1000px' } }}
    >
      <DialogTitle sx={{ display: 'grid' }}>
        {`Terraform action "${props.terraformAction.name}"`}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" className="Typography-Subtitle2">
          Этапы
        </Typography>
        <UserInteractionSwitch
          name="validate"
          description="validate"
          helperText="validate"
          key={'validate'}
          checked={enableValidate}
          onChange={(event) => handleChangeValidate(event)}
        />
        <UserInteractionSwitch
          name="plan"
          description="plan"
          helperText="plan"
          key={'plan'}
          checked={enablePlan}
          onChange={(event) => handleChangePlan(event)}
        />
        <UserInteractionSwitch
          name="apply"
          description="apply"
          helperText="apply"
          key={'apply'}
          checked={enableApply}
          onChange={(event) => handleChangeApply(event)}
        />
        <UserInteractionSwitch
          name="destroy"
          description="destroy"
          helperText="destroy"
          key={'destroy'}
          checked={enableDestroy}
          onChange={(event) => handleChangeDestroy(event)}
        />
        {TerraformActionStore.$('variables').value.length > 0 && (
          <>
            <Typography
              variant="subtitle2"
              className="Typography-Subtitle2"
              paddingTop="30px"
            >
              Переменные Terraform action
            </Typography>
            {TerraformActionStore.$('variables').value.map(
              (terraformActionVariable: TerraformVariable, index: number) => (
                <UserInteractionTwoTextFields
                  key={terraformActionVariable.key}
                  first={terraformActionVariable.key}
                  firstReadOnly
                  second={terraformActionVariable.value}
                  helperText={terraformActionVariable.description}
                  handleChangeSecond={(event) =>
                    handleChangeTerraformVariablesValue(index, event)
                  }
                />
              )
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          sx={{
            width: '100px',
          }}
          onClick={handleRun}
        >
          Запустить
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
})

export default RunDialog
