import { useParams } from 'react-router-dom'
import {
  transformHelmReleases,
  HelmReleasesCard,
} from '@edp/plugin-kubernetes-panel-frontend'
import { useEffect, useState } from 'react'
import { HelmNamespaceAndNameReleases, HelmNamespaceReleases } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import {
  CustomSnackbarComponent,
  NothingFoundPage,
  useFetchKubernetesNamespace,
} from '@edp/core-common-frontend'
import { Grid, LinearProgress } from '@mui/material'

export const HelmReleasesPanel = () => {
  const { uuid } = useParams()
  const namespace = useFetchKubernetesNamespace(uuid)

  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const [helmReleases, setHelmReleases] = useState<
    HelmNamespaceAndNameReleases[]
  >([])

  useEffect(() => {
    const getHelmReleases = async () => {
      try {
        setLoading(true)
        if (uuid) {
          const releases = await apiClient.getKubernetesNamespaceHelmReleases(
            uuid
          )

          const tmp: HelmNamespaceReleases[] = [
            {
              namespace: '',
              releases: releases,
            },
          ]

          const result = await transformHelmReleases(tmp)

          setHelmReleases(result)
          console.log(result)
        }
      } catch (e) {
        console.error(e)
        setFailed(true)
        if (e instanceof Error) {
          setErrorMessage(e.message)
        } else {
          setErrorMessage('unknown')
        }
      } finally {
        setLoading(false)
      }
    }

    getHelmReleases()
  }, [uuid])

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {helmReleases && helmReleases.length > 0 ? (
            <Grid item xs={12}>
              <HelmReleasesCard helmReleases={helmReleases} />
            </Grid>
          ) : (
            <>
              <NothingFoundPage
                text={`Helm релизы для namespace ${namespace?.name} не найдены`}
              />
            </>
          )}
        </>
      )}

      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={`При получении данных произошла ошибка: ${errorMessage}`}
        severity="error"
      />
    </>
  )
}
