import { SiVault } from '@edp/core-common-frontend/dist/packages/react-icons/si'
import { Tooltip, Typography } from '@mui/material'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ActionVariables, VaultSecretVariables } from 'types/Managers/Actions'
import ServiceStore from '../store'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import './styles.css'

export interface VaultSecretActionProps {
  actionVariables: ActionVariables
  index: number
}

export const VaultSecretActionLogo = () => {
  return (
    <Tooltip
      arrow
      title={
        'Vault secret action создает секрет в HashiCorp Vault по указанному пути и заполняет его переменными, значение которых вы можете задать ниже.'
      }
      placement="top"
    >
      <div>
        <SiVault className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const VaultSecretAction = observer((props: VaultSecretActionProps) => {
  const handleChangeActionsVariables = (
    actionsVariablesIndex: number,
    dataIndex: number,
    key: string,
    value: string
  ) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      ;(
        actionsVariables[actionsVariablesIndex]
          .variables as VaultSecretVariables
      ).data[dataIndex] = {
        key,
        value,
      }
      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  const handleChangeSecretPath = (index: number, value: string) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const v = actionsVariables[index].variables as VaultSecretVariables
      v.path = value
      actionsVariables[index].variables = v

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  const handleChangeSecretName = (index: number, value: string) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const v = actionsVariables[index].variables as VaultSecretVariables
      v.name = value
      actionsVariables[index].variables = v

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  return (
    <>
      <UserInteractionTextField
        name="vaultSecretPath"
        description="Путь к секрету в Vault"
        helperText="Путь к секрету в Vault."
        value={
          (props.actionVariables.variables as VaultSecretVariables).path || ''
        }
        onChange={(e) => handleChangeSecretPath(props.index, e.target.value)}
      />
      <UserInteractionTextField
        name="vaultSecretName"
        description="Название секрета в Vault"
        helperText="Название секрета в Vault."
        value={
          (props.actionVariables.variables as VaultSecretVariables).name || ''
        }
        onChange={(e) => handleChangeSecretName(props.index, e.target.value)}
      />
      {(props.actionVariables.variables as VaultSecretVariables).data && (
        <>
          <Typography
            variant="subtitle2"
            paddingTop="15px"
            className="Typography-Subtitle2"
          >
            Переменные
          </Typography>
          {(props.actionVariables.variables as VaultSecretVariables).data.map(
            (item: { key: string; value: string }, index: number) => (
              <UserInteractionTwoTextFields
                key={`action-${
                  (props.actionVariables.variables as VaultSecretVariables).name
                }-variable-${index}`}
                index={index}
                first={item.key}
                second={item.value}
                helperText={
                  'Название переменной которая будет добавлена в создаваемый секрет и ее значение.'
                }
                firstLabel={'Название'}
                secondLabel={'Значение'}
                firstDisabled
                handleChangeSecond={(e) =>
                  handleChangeActionsVariables(
                    props.index,
                    index,
                    item.key,
                    e.target.value
                  )
                }
              />
            )
          )}
        </>
      )}
    </>
  )
})
