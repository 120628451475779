import React from 'react'
import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Typography, Tooltip } from '@mui/material'
import './styles.css'

export interface UserInteractionCustomProps {
  name: string
  description: string
  helperText: string
  children: React.ReactNode
}

const UserInteractionCustom = (props: UserInteractionCustomProps) => {
  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {props.description}
        </Typography>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <Grid className="UserInteraction-Grid-Custom">{props.children}</Grid>
    </Grid>
  )
}

export default UserInteractionCustom
