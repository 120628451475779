import {
  CustomCardHeader,
  CustomSnackbarComponent,
  useFetchKubernetesNamespace,
} from '@edp/core-common-frontend'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { NothingFoundPage } from '@edp/core-common-frontend'
import {
  getKubernetesNamespaceResourceQuotas,
  ResourceQuotasTable,
} from '@edp/plugin-kubernetes-panel-frontend'
import { V1ResourceQuotaList } from '@kubernetes/client-node'
import { React } from '@edp/core-common-frontend/dist/packages/react'
import { Card, Grid } from '@mui/material'

export const ResourceQuotaPanel = () => {
  const { uuid } = useParams()
  const namespace = useFetchKubernetesNamespace(uuid)

  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [resourceQuotas, setResourceQuotas] = useState<V1ResourceQuotaList>()

  useEffect(() => {
    const getResourceQuotas = async () => {
      try {
        setLoading(true)
        if (uuid) {
          const data = await getKubernetesNamespaceResourceQuotas(uuid)
          setResourceQuotas(data)
        }
      } catch (e) {
        console.error(e)
        setFailed(true)
        if (e instanceof Error) {
          setErrorMessage(e.message)
        } else {
          setErrorMessage('unknown')
        }
      } finally {
        setLoading(false)
      }
    }

    getResourceQuotas()
  }, [uuid])

  return (
    <>
      {!loading && resourceQuotas && resourceQuotas.items.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Card>
              <CustomCardHeader
                height="80px"
                title="ResourceQuotas"
                description={`Текущие ResourceQuota для namespace ${namespace?.name}`}
                fullPage={false}
              />
              <ResourceQuotasTable resourceQuotas={resourceQuotas.items} />
            </Card>
          </Grid>
        </>
      ) : (
        <>
          <NothingFoundPage
            text={`ResourceQuotas для namespace ${namespace?.name} не найдены`}
          />
        </>
      )}
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={`При получении данных произошла ошибка: ${errorMessage}`}
        severity="error"
      />
    </>
  )
}
