import { CustomCardHeader } from '@edp/core-common-frontend'
import {
  Cancel,
  CheckCircle,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Card,
  CardContent,
  LinearProgress,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValidRowModel,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { ServiceSpec } from '@edp/types'
import React, { useEffect, useState } from 'react'
import {
  StatusFillingServiceFields,
  StatusFillingServiceSpec,
} from 'utils/countingFillingServiceFields'
import { ServiceSummaryFiltered } from './utils'
import { Link as RouterLink } from 'react-router-dom'
import './style.css'

interface ServiceSummaryTableCardProps {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  services: ServiceSpec[]
  selectedTeams: string[]
  selectedLifecycleStages: string[]
  statusService: Map<string, StatusFillingServiceSpec>
}

export const ServiceSummaryTableCard = (
  props: ServiceSummaryTableCardProps
) => {
  const [rows, setRows] = useState<GridRowsProp<GridValidRowModel>>([])

  const generateRows = async () => {
    const tmpRows = []

    if (props.services) {
      for (const service of props.services) {
        props.setLoading(true)
        if (
          !ServiceSummaryFiltered(
            props.selectedTeams,
            props.selectedLifecycleStages,
            service
          )
        ) {
          continue
        }

        if (service.uuid) {
          const status = props.statusService.get(service.uuid)
          if (status) {
            tmpRows.push({
              uuid: service.uuid,
              name: service.name,
              ownerUUID: service.ownerRef?.uuid,
              owner: service.ownerRef?.name,
              [StatusFillingServiceFields.Namespaces]:
                status.filledFields.includes(
                  StatusFillingServiceFields.Namespaces
                ),
              [StatusFillingServiceFields.HelmReleases]:
                status.filledFields.includes(
                  StatusFillingServiceFields.HelmReleases
                ),
              [StatusFillingServiceFields.Swagger]:
                status.filledFields.includes(
                  StatusFillingServiceFields.Swagger
                ),
              [StatusFillingServiceFields.Lifecycle]:
                status.filledFields.includes(
                  StatusFillingServiceFields.Lifecycle
                ),
              [StatusFillingServiceFields.Grafana]:
                status.filledFields.includes(
                  StatusFillingServiceFields.Grafana
                ),
              [StatusFillingServiceFields.Registry]:
                status.filledFields.includes(
                  StatusFillingServiceFields.Grafana
                ),
            })
          }
        }
      }
      setRows(tmpRows)
      props.setLoading(false)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'uuid',
      headerName: 'uuid',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Сервис',
      width: 300,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <RouterLink
              to={`/services/${params.row.uuid}`}
              className="ServiceSummaryTable-Link"
              rel="noreferrer"
            >
              {params.row.name}
            </RouterLink>
          </>
        )
      },
    },
    {
      field: 'ownerUUID',
      headerName: 'ownerUUID',
      hide: true,
    },
    {
      field: 'owner',
      headerName: 'Команда',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <RouterLink
              to={`/teams/${params.row.ownerUUID}`}
              className="ServiceSummaryTable-Link"
              rel="noreferrer"
            >
              {params.row.owner}
            </RouterLink>
          </>
        )
      },
    },
    {
      field: StatusFillingServiceFields.Namespaces,
      headerName: 'Namespaces',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row[StatusFillingServiceFields.Namespaces] ? (
              <CheckCircle style={{ color: 'green' }} />
            ) : (
              <Cancel style={{ color: 'red' }} />
            )}
          </>
        )
      },
    },
    {
      field: StatusFillingServiceFields.HelmReleases,
      headerName: 'Helm релизы',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row[StatusFillingServiceFields.HelmReleases] ? (
              <CheckCircle style={{ color: 'green' }} />
            ) : (
              <Cancel style={{ color: 'red' }} />
            )}
          </>
        )
      },
    },
    {
      field: StatusFillingServiceFields.Swagger,
      headerName: 'Swagger',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row[StatusFillingServiceFields.Swagger] ? (
              <CheckCircle style={{ color: 'green' }} />
            ) : (
              <Cancel style={{ color: 'red' }} />
            )}
          </>
        )
      },
    },
    {
      field: StatusFillingServiceFields.Lifecycle,
      headerName: 'Стадия',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row[StatusFillingServiceFields.Lifecycle] ? (
              <CheckCircle style={{ color: 'green' }} />
            ) : (
              <Cancel style={{ color: 'red' }} />
            )}
          </>
        )
      },
    },
    {
      field: StatusFillingServiceFields.Grafana,
      headerName: 'Grafana',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row[StatusFillingServiceFields.Grafana] ? (
              <CheckCircle style={{ color: 'green' }} />
            ) : (
              <Cancel style={{ color: 'red' }} />
            )}
          </>
        )
      },
    },
    {
      field: StatusFillingServiceFields.Registry,
      headerName: 'Registry',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row[StatusFillingServiceFields.Registry] ? (
              <CheckCircle style={{ color: 'green' }} />
            ) : (
              <Cancel style={{ color: 'red' }} />
            )}
          </>
        )
      },
    },
  ]

  useEffect(() => {
    if (!props.loading) {
      generateRows()
    }
  }, [props.statusService, props.selectedTeams, props.selectedLifecycleStages])

  return (
    <Card sx={{ boxShadow: '1', marginTop: '10px' }}>
      <CustomCardHeader
        height="80px"
        title="Сводная таблица заполненности сервисов"
        description="Сводная таблица заполненности сервисов"
        fullPage={true}
      />
      <CardContent>
        {!props.loading ? (
          <DataGrid
            getRowId={(row) => row.uuid}
            columns={columns}
            rows={rows ? rows : []}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoPageSize
            autoHeight={true}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        ) : (
          <LinearProgress />
        )}
      </CardContent>
    </Card>
  )
}

export default ServiceSummaryTableCard
