import React from 'react'

import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Typography, Tooltip } from '@mui/material'
import './styles.css'
import { TeamSelect } from '../TeamSelect'

export interface UserInteractionTeamSelectProps {
  name: string
  description: string
  helperText: string
  error?: boolean
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
}

const UserInteractionTeamSelect = (props: UserInteractionTeamSelectProps) => {
  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {props.description}
        </Typography>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <TeamSelect
        selected={props.selected}
        setSelected={props.setSelected}
        error={props.error}
        disabled={props.disabled}
      />
    </Grid>
  )
}

export default UserInteractionTeamSelect
