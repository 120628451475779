import { ComponentPage } from 'components/ComponentPage'
import DescriptionPanel from './DescriptionsPanel/DescriptionPanel'
import { OpensearchPanel } from './OpensearchPanel/OpensearchPanel'
import { ResourceQuotaPanel } from './ResourceQuotaPanel/ResourceQuotaPanel'
import { HelmReleasesPanel } from './HelmReleasesPanel/HelmReleasesPanel'

export const KubernetesNamespacePage = () => {
  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Opensearch',
      index: 1,
      children: <OpensearchPanel />,
    },
    {
      name: 'Квоты ресурсов',
      index: 2,
      children: <ResourceQuotaPanel />,
    },
    {
      name: 'Helm релизы',
      index: 3,
      children: <HelmReleasesPanel />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default KubernetesNamespacePage
