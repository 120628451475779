import { observer } from 'mobx-react-lite'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import { CircularProgress, Typography } from '@mui/material'
import ServiceTemplateStore from './store'
import UserInteractionRepositorySelect from 'components/UserInteraction/UserInteractionRepositorySelect'
import './styles.css'
import { ServiceTemplateVersionInfo } from 'components/ServiceTemplateVersionInfo'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'

const BasicInfo = observer(() => {
  const [currentVersion, setCurrentVersion] = useState<string>('')
  const [updatedCurrentVersion, setUpdatedCurrentVersion] =
    useState<boolean>(true)
  const [currentVersionNotFound, setCurrentVersionNotFound] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  useEffect(() => {
    async function getLastTagName() {
      try {
        setFailed(false)
        setCurrentVersionNotFound(false)
        setLoading(true)
        const lastTag = await apiClient.getRepositoryLastTag(
          ServiceTemplateStore.$('templateRepository').value
        )
        if (lastTag.name === '') {
          setCurrentVersion('')
          setCurrentVersionNotFound(true)
        } else {
          setCurrentVersion(lastTag.name)
          ServiceTemplateStore.$('currentVersion').set(lastTag.name)
        }
        setLoading(false)
        setUpdatedCurrentVersion(true)
      } catch (e) {
        setLoading(false)
        setFailed(true)
        setUpdatedCurrentVersion(true)
        console.error('Ошибка при получении данных:', e)
      }
    }

    if (!updatedCurrentVersion) {
      getLastTagName()
    }
  }, [ServiceTemplateStore.$('templateRepository').value])

  return (
    <>
      <Typography variant="body1" className="Typography-Body1">
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название шаблона сервиса."
        error={!ServiceTemplateStore.$('name').isValid}
        {...ServiceTemplateStore.$('name').bind()}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор шаблона сервиса. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        error={!ServiceTemplateStore.$('slug').isValid}
        {...ServiceTemplateStore.$('slug').bind()}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание шаблона сервиса."
        multiline
        rows={6}
        height={'150px'}
        {...ServiceTemplateStore.$('description').bind()}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая шаблоном сервиса."
        error={!ServiceTemplateStore.$('owner').isValid}
        selected={ServiceTemplateStore.$('owner').value}
        setSelected={(value) => ServiceTemplateStore.$('owner').set(value)}
      />
      <UserInteractionRepositorySelect
        name="templateRepository"
        description="Шаблонный репозиторий"
        helperText="Репозиторий с шаблоном сервиса."
        template
        error={!ServiceTemplateStore.$('templateRepository').isValid}
        selected={ServiceTemplateStore.$('templateRepository').value}
        setSelected={(value) => {
          setUpdatedCurrentVersion(false)
          ServiceTemplateStore.$('templateRepository').set(value)
        }}
      />
      {loading && <CircularProgress size="25px" color="inherit" />}
      {currentVersion !== '' && !loading && (
        <ServiceTemplateVersionInfo
          severity="info"
          text={`Текущая версия шаблона: <b>${
            ServiceTemplateStore.$('currentVersion').value
          }</b>`}
        />
      )}
      {currentVersionNotFound && !loading && (
        <ServiceTemplateVersionInfo
          severity="error"
          text="<b>Нельзя создать шаблон сервиса из данного репозитория, в репозитории отсутствуют теги</b>"
        />
      )}
      {failed && !loading && (
        <ServiceTemplateVersionInfo
          severity="error"
          text="<b>Произошла ошибка при получении текущей версии шаблона</b>"
        />
      )}
    </>
  )
})

export default BasicInfo
