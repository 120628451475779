import { Typography } from '@mui/material'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import React from 'react'
import { EnvironmentSpec } from 'types/Managers/Environments'

export interface IntegrationsProps {
  disabled: boolean
  environment: EnvironmentSpec
  setEnvironment: React.Dispatch<React.SetStateAction<EnvironmentSpec>>
}

export const Integrations = (props: IntegrationsProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setEnvironment({
      ...props.environment,
      integrations: {
        ...props.environment.integrations,
        opensearch: {
          ...props.environment.integrations.opensearch,
          datastream: {
            ...props.environment.integrations.opensearch.datastream,
            [name]: value,
          },
        },
      },
    })
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Интеграции
      </Typography>
      <UserInteractionSwitch
        name="sigstore"
        description="Sigstore"
        disabled={props.disabled}
        helperText={`Включить проверку цифровой подписи Docker образов для окружения.`}
        checked={props.environment.integrations.sigstore.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setEnvironment({
            ...props.environment,
            integrations: {
              ...props.environment.integrations,
              sigstore: {
                ...props.environment.integrations.sigstore,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="kubernetes"
        description="Kubernetes"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов Kubernetes для окружения.`}
        checked={props.environment.integrations.kubernetes.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setEnvironment({
            ...props.environment,
            integrations: {
              ...props.environment.integrations,
              kubernetes: {
                ...props.environment.integrations.kubernetes,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="vault"
        description="HashiCorp Vault"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов HashiCorp Vault для окружения.`}
        checked={props.environment.integrations.vault.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setEnvironment({
            ...props.environment,
            integrations: {
              ...props.environment.integrations,
              vault: {
                ...props.environment.integrations.vault,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      <UserInteractionSwitch
        name="opensearch"
        description="OpenSearch"
        disabled={props.disabled}
        helperText={`Включить создание ресурсов OpenSearch для окружения.`}
        checked={props.environment.integrations.opensearch.enabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setEnvironment({
            ...props.environment,
            integrations: {
              ...props.environment.integrations,
              opensearch: {
                ...props.environment.integrations.opensearch,
                enabled: event.target.checked,
              },
            },
          })
        }
      />
      {props.environment.integrations.opensearch.enabled ? (
        <>
          <UserInteractionTextField
            name="number_of_shards"
            description="Number of shards"
            disabled={props.disabled}
            helperText=""
            value={String(
              props.environment.integrations.opensearch.datastream
                ?.number_of_shards || '0'
            )}
            onChange={handleChange}
          />
          <UserInteractionTextField
            name="refresh_interval"
            description="Refresh interval"
            disabled={props.disabled}
            helperText=""
            value={
              props.environment.integrations.opensearch.datastream
                ?.refresh_interval || ''
            }
            onChange={handleChange}
          />
          <UserInteractionTextField
            name="rollover_min_size"
            description="Rollover min size"
            disabled={props.disabled}
            helperText=""
            value={
              props.environment.integrations.opensearch.datastream
                ?.rollover_min_size || ''
            }
            onChange={handleChange}
          />
          <UserInteractionTextField
            name="rollover_min_index_age"
            description="Rollover min index age"
            disabled={props.disabled}
            helperText=""
            value={
              props.environment.integrations.opensearch.datastream
                ?.rollover_min_index_age || ''
            }
            onChange={handleChange}
          />
          <UserInteractionTextField
            name="rollover_min_primary_shard_size"
            description="Rollover min primary shard size"
            disabled={props.disabled}
            helperText=""
            value={
              props.environment.integrations.opensearch.datastream
                ?.rollover_min_primary_shard_size || ''
            }
            onChange={handleChange}
          />
          <UserInteractionTextField
            name="delete_min_index_age"
            description="Delete min index age"
            disabled={props.disabled}
            helperText=""
            value={
              props.environment.integrations.opensearch.datastream
                ?.delete_min_index_age || ''
            }
            onChange={handleChange}
          />{' '}
        </>
      ) : (
        <></>
      )}
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Grafana
      </Typography>
      <UserInteractionTextField
        name="grafana-dashboard"
        description="Ссылка на dashboard"
        helperText="Ссылка на Grafana dashboard окружения"
        value={props.environment.integrations.grafana?.dashboard || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setEnvironment({
            ...props.environment,
            integrations: {
              ...props.environment.integrations,
              grafana: {
                ...props.environment.integrations.grafana,
                dashboard: event.target.value,
              },
            },
          })
        }}
      />
    </>
  )
}
