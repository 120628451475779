import { getAuthType, getJWT } from 'api/utils'
import { RepositorySpec, RepositoryVariable } from 'types/Managers/Repositories'
import { ApiClient } from 'api/ApiClient'
import {
  GitlabBranch,
  GitlabBranchRequest,
  GitlabTag,
  GitlabVariable,
} from 'types/Controllers/Gitlab'
import { handleResponse } from '@edp/core-common-frontend'

export async function createRepository(
  this: ApiClient,
  spec: RepositorySpec
): Promise<JSON> {
  const url = `${this.serverUrl}/repositories`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getRepositories(
  this: ApiClient
): Promise<RepositorySpec[]> {
  const url = `${this.serverUrl}/repositories`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepository(
  this: ApiClient,
  uuid: string
): Promise<RepositorySpec> {
  const url = `${this.serverUrl}/repositories/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryVariables(
  this: ApiClient,
  uuid: string
): Promise<RepositoryVariable[]> {
  const url = `${this.serverUrl}/repositories/${uuid}/variables/repository`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryVariablesByVersion(
  this: ApiClient,
  uuid: string,
  version: string
): Promise<RepositoryVariable[]> {
  const url = `${this.serverUrl}/repositories/${uuid}/variables/repository/${version}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryProjectLevelVariables(
  this: ApiClient,
  uuid: string
): Promise<GitlabVariable[]> {
  const url = `${this.serverUrl}/repositories/${uuid}/variables/project-level`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryProjectLevelVariablesByVersion(
  this: ApiClient,
  uuid: string,
  version: string
): Promise<GitlabVariable[]> {
  const url = `${this.serverUrl}/repositories/${uuid}/variables/project-level/${version}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryVariablesBranches(
  this: ApiClient,
  uuid: string
): Promise<GitlabBranchRequest[]> {
  const url = `${this.serverUrl}/repositories/${uuid}/variables/branches`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryVariablesBranchesByVersion(
  this: ApiClient,
  uuid: string,
  version: string
): Promise<GitlabBranchRequest[]> {
  const url = `${this.serverUrl}/repositories/${uuid}/variables/branches/${version}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updateRepository(
  this: ApiClient,
  spec: RepositorySpec
): Promise<JSON> {
  const url = `${this.serverUrl}/repositories/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })
  return await handleResponse(response)
}

export async function runRepositorySync(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/repositories/${uuid}/mirroring/sync`
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function deleteRepository(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/repositories?uuid=${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryBranches(
  this: ApiClient,
  uuid: string
): Promise<GitlabBranch[]> {
  const url = `${this.serverUrl}/repositories/${uuid}/branches`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getRepositoryLastTag(
  this: ApiClient,
  uuid: string
): Promise<GitlabTag> {
  const url = `${this.serverUrl}/repositories/${uuid}/tags/last`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
