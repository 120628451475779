import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { highlight, languages } from 'prismjs'
import 'prismjs/components/prism-yaml'
import 'prismjs/themes/prism.css'
import React from 'react'
import Editor from 'react-simple-code-editor'
import YAML from 'yaml'
import { CustomButton } from '@edp/core-common-frontend'
import './styles.css'

export type DeletionWindowProps = {
  title?: string
  text?: string
  additionalText?: string
  spec?: object
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  handleDelete: any
  loading?: boolean
}

export const DeletionWindow = (props: DeletionWindowProps) => {
  const handleClose = () => props.setOpened(false)

  const spec = new YAML.Document()
  spec.contents = props.spec

  return (
    <Dialog open={props.opened} onClose={handleClose}>
      <DialogTitle
        style={{
          marginRight: '1.1em',
        }}
      >
        {props.title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <Typography component="div" variant="body1">
          {props.text}
        </Typography>
        <Typography
          component="div"
          variant="caption"
          sx={{ paddingTop: '15px' }}
        >
          {props.additionalText}
        </Typography>
        {props.spec ? (
          <>
            <Typography
              component="div"
              variant="caption"
              sx={{ paddingTop: '15px', paddingBottom: '5px' }}
            >
              {'Спецификация удаляемого компонента:'}
            </Typography>
            <Editor
              disabled
              name={'spec'}
              className="DeletionWindow-HighlightedInput-Editor"
              value={spec.toString()}
              onValueChange={() => {}}
              highlight={(value) =>
                highlight(value, languages.yaml, 'language-yaml')
              }
              padding={10}
            />
          </>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={props.handleDelete}
          sx={{
            color: 'white',
            backgroundColor: '#DC3545',
            ':hover': {
              bgcolor: '#C22131',
            },
          }}
          loading={props.loading}
        >
          Удалить
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeletionWindow
