import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { ApiClient } from 'api/ApiClient'
import { Permission } from 'types/rbac/permission'

export interface RolesTransferListProps {
  rolePermissions: Permission[]
  setRolePermissions: React.Dispatch<React.SetStateAction<Permission[]>>
}

function not(a: Permission[], b: Permission[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a: Permission[], b: Permission[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a: Permission[], b: Permission[]) {
  return [...a, ...not(b, a)]
}

export default function RolesTransferList(props: RolesTransferListProps) {
  const [checked, setChecked] = useState<Permission[]>([])
  const [left, setLeft] = useState<Permission[]>([])

  const leftChecked = intersection(checked, left)
  const rolePermissionsChecked = intersection(checked, props.rolePermissions)

  const apiClient = new ApiClient()

  const getPermissions = async () => {
    try {
      let data = await apiClient.getPermissions()
      data = data.filter(
        (item: Permission) =>
          !props.rolePermissions.some(
            (permission: Permission) => permission.uuid === item.uuid
          )
      )
      setLeft(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPermissions()
  }, [props.rolePermissions])

  const handleToggle = (permission: Permission) => () => {
    const currentIndex = checked.indexOf(permission)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(permission)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (permissions: Permission[]) =>
    intersection(checked, permissions).length

  const handleToggleAll = (permissions: Permission[]) => () => {
    if (numberOfChecked(permissions) === permissions.length) {
      setChecked(not(checked, permissions))
    } else {
      setChecked(union(checked, permissions))
    }
  }

  const handleCheckedRolePermissions = () => {
    props.setRolePermissions(props.rolePermissions.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rolePermissionsChecked))
    props.setRolePermissions(not(props.rolePermissions, rolePermissionsChecked))
    setChecked(not(checked, rolePermissionsChecked))
  }

  const permissionsList = (
    title: React.ReactNode,
    permissions: Permission[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(permissions)}
            checked={
              numberOfChecked(permissions) === permissions.length &&
              permissions.length !== 0
            }
            indeterminate={
              numberOfChecked(permissions) !== permissions.length &&
              numberOfChecked(permissions) !== 0
            }
            disabled={permissions.length === 0}
            inputProps={{
              'aria-label': 'all permissions selected',
            }}
          />
        }
        title={title}
        subheader={`Выбрано ${numberOfChecked(permissions)}/${
          permissions.length
        }`}
      />
      <Divider />
      <List
        sx={{
          width: 370,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {permissions.map((permission) => {
          const labelId = `transfer-list-all-item-${permission.name}-label`

          return (
            <ListItem
              key={permissions.indexOf(permission)}
              role="listitem"
              button
              onClick={handleToggle(permission)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(permission) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={permission.name} />
            </ListItem>
          )
        })}
      </List>
    </Card>
  )

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>{permissionsList('Все политики', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRolePermissions}
            disabled={leftChecked.length === 0}
            aria-label="move selected rolePermissions"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rolePermissionsChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {permissionsList('Политики роли', props.rolePermissions)}
      </Grid>
    </Grid>
  )
}
