import { observer } from 'mobx-react-lite'
import { Typography } from '@mui/material'
import KubernetesNamespaceStore from './store'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'

const Integrations = observer(() => {
  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Opensearch
      </Typography>
      <UserInteractionTextField
        name="opensearch-dashboard"
        description="Ссылка на dashboard"
        helperText="Ссылка на Opensearch dashboard kubernetes namespace"
        {...KubernetesNamespaceStore.$(
          'integrations.opensearch.dashboard'
        ).bind()}
      />
    </>
  )
})

export default Integrations
