import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { CircularProgress, Grid, Typography } from '@mui/material'
import ServiceStore from './store'
import { getKubernetesNamespaces } from '@edp/core-common-frontend'
import {
  KubernetesNamespaceSpec,
  ServiceKubernetesConfigObjectsSpec,
  ServiceKubernetesConfigObjectsType,
  ServiceKubernetesConfigSpec,
} from '@edp/types'
import UserInteractionCustomMultipleSelect from 'components/UserInteraction/UserInteractionCustomMultipleSelect'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'

type KubernetesProps = {
  disabled: boolean
}

const Kubernetes = observer((props: KubernetesProps) => {
  const [selectedNamespaces, setSelectedNamespaces] = useState<Array<string>>(
    []
  )
  const [kubernetesNamespaces, setKubernetesNamespaces] = useState<
    Array<KubernetesNamespaceSpec>
  >([])
  const [formattedKubernetesNamespaces, setFormattedKubernetesNamespaces] =
    useState<Array<string>>([])
  const [loadingNamespaces, setLoadingNamespaces] = useState<boolean>(true)

  const [mapKubernetesObjects, setMapKubernetesObjects] = useState<
    Map<string, ServiceKubernetesConfigObjectsSpec>
  >(new Map())
  const [
    stateKubernetesObjectsAllowDeletion,
    setStateKubernetesObjectsAllowDeletion,
  ] = useState<Record<string, any>>({})

  const getNamespaceByName = (
    name: string
  ): KubernetesNamespaceSpec | undefined => {
    try {
      for (const namespace of kubernetesNamespaces) {
        if (namespace.name === name) {
          if (namespace.uuid) {
            return namespace
          } else {
            return undefined
          }
        }
      }
    } catch (e) {
      console.log(`При определении UUID namespace произошла ошибка: ${e}`)
    }

    return undefined
  }

  const handleChangeCheckedAllowDelete = (
    objName: string,
    checked: boolean
  ) => {
    const objConfig = mapKubernetesObjects.get(objName)
    if (objConfig) {
      objConfig.allowDeletion = checked
      mapKubernetesObjects.set(objName, { ...objConfig })
      setStateKubernetesObjectsAllowDeletion({
        ...stateKubernetesObjectsAllowDeletion,
        [`allowDelete-${objConfig.object}`]: objConfig.allowDeletion,
      })
    }
  }

  useEffect(() => {
    const getNamespaces = async () => {
      setLoadingNamespaces(true)
      try {
        const data = await getKubernetesNamespaces()
        setKubernetesNamespaces(data)

        // Получаем только имена
        const kubernetesNamespaceNames: Array<string> = []
        for (const namespace of data) {
          kubernetesNamespaceNames.push(namespace.name)
        }

        setFormattedKubernetesNamespaces(kubernetesNamespaceNames)
      } catch (e) {
        console.error('Ошибка при получении данных: ', e)
      } finally {
        setLoadingNamespaces(false)
      }
    }

    getNamespaces()
  }, [])

  useEffect(() => {
    const objectsConfigMap: Map<string, ServiceKubernetesConfigObjectsSpec> =
      new Map()
    const config: ServiceKubernetesConfigSpec = {
      ...ServiceStore.$('kubernetesConfigRef').value,
    }

    // Преобразуем
    if (ServiceStore.$('kubernetesConfigRef.objects').value) {
      for (const obj of config.objects) {
        objectsConfigMap.set(obj.object, obj)
      }
    }

    // Проверяем и добавляем недостающие объекты
    for (const objName of Object.values(ServiceKubernetesConfigObjectsType)) {
      if (!objectsConfigMap.has(objName)) {
        const defaultConfigObj: ServiceKubernetesConfigObjectsSpec = {
          service: '',
          object: objName,
          allowDeletion: false,
        }
        objectsConfigMap.set(objName, { ...defaultConfigObj })
      }
    }

    // Устанавливаем стейт на удаление
    for (const [key, value] of objectsConfigMap.entries()) {
      setStateKubernetesObjectsAllowDeletion({
        ...stateKubernetesObjectsAllowDeletion,
        [`allowDelete-${key}`]: value.allowDeletion,
      })
    }

    // Обновляем Store
    config.objects = Array.from(mapKubernetesObjects, ([_, value]) => value)

    ServiceStore.$('kubernetesConfigRef').reset()
    ServiceStore.$('kubernetesConfigRef').set({ ...config })

    setMapKubernetesObjects(objectsConfigMap)
  }, [ServiceStore.$('name').value])

  useEffect(() => {
    if (
      kubernetesNamespaces.length !== 0 &&
      ServiceStore.$('name').value !== ''
    ) {
      // Получаем выбранные имена
      const selectedKubernetesNamespaceNames: Array<string> = []
      for (const namespace of ServiceStore.$('namespaces').value) {
        selectedKubernetesNamespaceNames.push(namespace.name)
      }

      setSelectedNamespaces(selectedKubernetesNamespaceNames)
    }
  }, [
    kubernetesNamespaces,
    formattedKubernetesNamespaces,
    ServiceStore.$('name').value,
  ])

  useEffect(() => {
    const tmp: Array<KubernetesNamespaceSpec> = []

    for (const namespace of selectedNamespaces) {
      const result = getNamespaceByName(namespace)

      if (result !== undefined) {
        tmp.push(result)
      }
    }

    ServiceStore.$('namespaces').set(tmp)
  }, [selectedNamespaces])

  useEffect(() => {
    const config: ServiceKubernetesConfigSpec = ServiceStore.$(
      'kubernetesConfigRef'
    ).value
    config.objects = Array.from(mapKubernetesObjects, ([_, value]) => value)

    ServiceStore.$('kubernetesConfigRef.objects').reset()
    ServiceStore.$('kubernetesConfigRef').set({ ...config })
  }, [stateKubernetesObjectsAllowDeletion])

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Kubernetes
      </Typography>
      <Grid>
        {loadingNamespaces ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <UserInteractionCustomMultipleSelect
              name="namespaces"
              description="Namespaces"
              helperText="Выберите namespace, в которых может быть развернут сервис"
              options={formattedKubernetesNamespaces}
              selected={selectedNamespaces}
              setSelected={setSelectedNamespaces}
            />
            <UserInteractionSwitch
              name={`allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`}
              helperText={`Включить возможность удаления подов`}
              checked={
                stateKubernetesObjectsAllowDeletion[
                  `allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`
                ] || false
              }
              description="Возможность удаления подов"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeCheckedAllowDelete(
                  ServiceKubernetesConfigObjectsType.Pod,
                  event.target.checked
                )
              }}
            />
          </>
        )}
      </Grid>
    </>
  )
})

export default Kubernetes
