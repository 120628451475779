import { TeamSpec } from '@edp/types'
export interface Permission {
  uuid?: string
  name: string
  action: string
  object: string
  owner?: string
  ownerRef?: TeamSpec
}

export enum Actions {
  Create = 'create',
  Get = 'get',
  Update = 'update',
  Delete = 'delete',
}

export enum Objects {
  Environment = 'environment',
  Repository = 'repository',
  Service = 'service',
  Team = 'team',
  Instance = 'instance',
  Kafka = 'kafka',
  Cluster = 'cluster',
  HelmChart = 'helmChart',
  PlatformServiceConfiguration = 'platformServiceConfiguration',
  PlatformServiceInstance = 'platformServiceInstance',
  PlatformServiceTemplate = 'platformServiceTemplate',
  CustomEntity = 'customEntity',
  Action = 'action',
  ServiceTemplate = 'serviceTemplate',
  KubernetesNamespace = 'kubernetesNamespace',
  Role = 'role',
  LifecycleStage = 'lifecycleStage',
  RoleBinding = 'roleBinding',
  Permission = 'permission',
  Operation = 'operation',
}
