import { CustomCardHeader } from '@edp/core-common-frontend'
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  linearProgressClasses,
  ListItem,
  styled,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { ServiceSpec } from '@edp/types'
import { useEffect, useState } from 'react'
import {
  StatusFillingServiceFields,
  StatusFillingServiceSpec,
} from 'utils/countingFillingServiceFields'
import { ServiceSummaryFiltered } from './utils'

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: Number(value) < 50 ? '#f44336' : '#4caf50',
  },
}))

interface ServiceSummaryStatsRowProps {
  name: string
  filledFields: number
  allFields: number
}

export interface ServiceSummaryStatsCardProps {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  services: ServiceSpec[]
  selectedTeams: string[]
  selectedLifecycleStages: string[]
  statusService: Map<string, StatusFillingServiceSpec>
}

const ServiceSummaryStatsRow = (props: ServiceSummaryStatsRowProps) => {
  const [fixedName, setFixedName] = useState<string>('')

  useEffect(() => {
    if (props.name === StatusFillingServiceFields.HelmReleases) {
      setFixedName('Helm релизы')
    } else if (props.name === StatusFillingServiceFields.Lifecycle) {
      setFixedName('Стадия')
    } else {
      setFixedName(props.name)
    }
  }, [])

  return (
    <>
      <Grid item xs={1}>
        <b>{fixedName}</b>
      </Grid>
      <Grid item xs={10}>
        <BorderLinearProgress
          sx={{ with: '100%' }}
          variant="determinate"
          value={
            props.filledFields === 0 && props.allFields === 0
              ? 100
              : (props.filledFields / props.allFields) * 100
          }
        />
      </Grid>
      <Grid item xs={1}>
        <p style={{ paddingLeft: '10px' }}>
          {props.filledFields}/{props.allFields}
        </p>
      </Grid>
    </>
  )
}

export const ServiceSummaryStatsCard = (
  props: ServiceSummaryStatsCardProps
) => {
  const [stats, setStats] = useState<Map<string, number>>(
    new Map<string, number>()
  )
  const [numberServices, setNumberServices] = useState<number>(0)

  const generateStats = async () => {
    const tmpStats = new Map<string, number>()
    let tmpNumberServices = 0

    for (const key of Object.keys(StatusFillingServiceFields)) {
      tmpStats.set(key, 0)
    }

    setStats(tmpStats)

    for (const service of props.services) {
      if (
        !ServiceSummaryFiltered(
          props.selectedTeams,
          props.selectedLifecycleStages,
          service
        )
      ) {
        continue
      }

      if (service.uuid) {
        const status = props.statusService.get(service.uuid)
        tmpNumberServices += 1
        if (status) {
          for (const key of Object.keys(StatusFillingServiceFields)) {
            if (status.filledFields.includes(key)) {
              const value = tmpStats.get(key)
              if (value !== undefined) {
                tmpStats.set(key, value + 1)
              }
            }
          }
        }
      }
    }
    setStats(tmpStats)
    setNumberServices(tmpNumberServices)
  }

  useEffect(() => {
    if (!props.loading) {
      generateStats()
    }
  }, [props.statusService, props.selectedTeams, props.selectedLifecycleStages])

  return (
    <Card sx={{ boxShadow: '1', marginTop: '10px' }}>
      <CustomCardHeader
        height="80px"
        title="Статистика"
        description="Статистика заполненности полей"
        fullPage={true}
      />
      <CardContent>
        {!props.loading ? (
          <ListItem>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              spacing={1}
            >
              {Array.from(stats).map((value) => {
                return (
                  <ServiceSummaryStatsRow
                    name={value[0]}
                    filledFields={value[1]}
                    allFields={numberServices}
                  />
                )
              })}
            </Grid>
          </ListItem>
        ) : (
          <LinearProgress />
        )}
      </CardContent>
    </Card>
  )
}

export default ServiceSummaryStatsCard
