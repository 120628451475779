import {
  CustomButton,
  CustomSnackbarComponent,
  NothingFoundPage,
} from '@edp/core-common-frontend'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { AiOutlineDownload } from '@edp/core-common-frontend/dist/packages/react-icons/ai'
import { MdDeleteOutline } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { Box, Grid, LinearProgress, Link, ListItemText } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { ServiceBranchesSelect } from 'components/ServiceBranchesSelect'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import { CycloneDXSbom, CycloneDXSbomLicense, SbomSpec } from 'types/Sbom'
import { v4 as uuidv4 } from 'uuid'
import './styles.css'

interface SbomPanelProps {
  sbomSpec: SbomSpec
}

interface RenderedLicense {
  name: string
  url: string
}

interface LicenseCount {
  name: string
  count: number
}

function DataGridTitle() {
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10px',
        marginBottom: '10px',
      }}
    ></Box>
  )
}

const renderLicenses = (
  licenses: CycloneDXSbomLicense[]
): RenderedLicense[] => {
  const result: RenderedLicense[] = []

  try {
    for (const license of licenses) {
      if (license.license.id) {
        const l: RenderedLicense = {
          name: license.license.id,
          url: `https://spdx.org/licenses/${license.license.id}.html`,
        }
        result.push(l)
      }
      if (license.license.name) {
        const l: RenderedLicense = {
          name: license.license.name,
          url: `https://spdx.org/licenses/${license.license.name}.html`,
        }
        result.push(l)
      }
    }
    return result
  } catch (e) {
    return []
  }
}

export const DenseTable = (props: SbomPanelProps) => {
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Компонент', minWidth: 350 },
    { field: 'type', headerName: 'Тип', minWidth: 150 },
    { field: 'version', headerName: 'Версия', minWidth: 150 },
    {
      field: 'licenses',
      headerName: 'Лицензии',
      minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            {renderLicenses(params.row.licenses).map((license) => (
              <ListItemText>
                <Link target="_blank" href={license.url}>
                  {license.name}
                </Link>
              </ListItemText>
            ))}
          </>
        )
      },
    },
  ]

  const rows = props.sbomSpec.sbom.components.map((component) => {
    return {
      id: uuidv4(),
      name: component.name,
      type: component.type,
      version: component.version,
      licenses: component.licenses,
    }
  })

  return (
    <div style={{ display: 'flex', height: '425px' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(row) => row.id}
          columns={columns}
          rows={rows}
          pageSize={5}
          rowsPerPageOptions={[5]}
          autoPageSize
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{ Toolbar: DataGridTitle }}
        />
      </div>
    </div>
  )
}

export const SbomPanel = () => {
  const { uuid } = useParams()
  const [sbom, setSbom] = useState<CycloneDXSbom>()
  const [sbomSpec, setSbomSpec] = useState<SbomSpec>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleted, setDeleted] = useState<boolean>(false)
  const [branch, setBranch] = useState('')
  const [licensesCount, setLicensesCount] = useState<LicenseCount[]>([])

  useEffect(() => {
    setSbom(undefined)
    setSbomSpec(undefined)
    setDeleted(false)

    const apiClient = new ApiClient()

    const getSbom = async () => {
      if (uuid && branch) {
        try {
          setLoading(true)
          const data = (await apiClient.getServiceSbom(
            uuid,
            branch
          )) as SbomSpec
          setSbom(data.sbom)
          setSbomSpec(data)
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }
    }

    getSbom()
  }, [uuid, branch])

  useEffect(() => {
    const getLicensesCount = async () => {
      try {
        if (sbom) {
          const licenses: string[] = []

          for (const component of sbom.components) {
            if (component.licenses) {
              for (const license of component.licenses) {
                if (license.license.id) {
                  licenses.push(license.license.id)
                }
                if (license.license.name) {
                  licenses.push(license.license.name)
                }
              }
            }
          }

          const occurrences: LicenseCount[] = licenses.reduce(
            (acc: LicenseCount[], item) => {
              const index = acc.findIndex((obj) => obj.name === item)

              if (index === -1) {
                acc.push({ name: item, count: 1 })
              } else {
                acc[index].count++
              }
              return acc
            },
            []
          )

          const sorted = occurrences.sort((a, b) => b.count - a.count)

          setLicensesCount(sorted)
        } else {
          setLicensesCount([])
        }
      } catch (e) {
        console.log(e)
      }
    }

    getLicensesCount()
  }, [sbom, branch])

  function handleDownload() {
    if (sbom) {
      const jsonData = JSON.stringify(sbom)
      const blob = new Blob([jsonData], { type: 'application/json' })
      saveAs(blob, `${sbomSpec?.service}-${branch}-sbom.json`)
    }
  }

  async function handleDelete() {
    try {
      const apiClient = new ApiClient()

      if (sbomSpec) {
        const id = sbomSpec.id

        if (id) {
          await apiClient.deleteSbom(id)
        }
      }

      setSbom(undefined)
      setSbomSpec(undefined)
      setDeleted(true)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Grid className="Sbom-Grid-Panel">
        <ServiceBranchesSelect
          title={'Ветка'}
          selected={branch}
          setSelected={setBranch}
        />
        <CustomButton
          startIcon={<MdDeleteOutline />}
          disabled={sbom === undefined}
          onClick={handleDelete}
        >
          Удалить
        </CustomButton>
        <CustomButton
          startIcon={<AiOutlineDownload />}
          disabled={sbom === undefined}
          onClick={handleDownload}
        >
          Скачать
        </CustomButton>
      </Grid>
      <CustomSnackbarComponent
        opened={deleted}
        setOpened={setDeleted}
        message={`SBOM для ветки ${branch} удален`}
        severity="success"
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {licensesCount.length > 0 && (
            <BarChart
              width={1100}
              height={400}
              data={licensesCount}
              margin={{
                top: 20,
                right: 30,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3" />
              <XAxis dataKey="name" tick={{ fontSize: 12 }} />
              <YAxis
                yAxisId="left"
                orientation="left"
                stroke="#031333"
                tick={{ fontSize: 12 }}
              />
              <Tooltip />
              <Bar yAxisId="left" dataKey="count" fill="#031333" />
            </BarChart>
          )}
          {licensesCount.length === 0 && <></>}
          {sbomSpec && <DenseTable sbomSpec={sbomSpec} />}
          {!sbomSpec && (
            <NothingFoundPage text="Попробуйте выбрать другую ветку или загрузить через API SBOM файл в JSON формате, соответствующий спецификации CycloneDX." />
          )}
        </>
      )}
    </>
  )
}

export default SbomPanel
