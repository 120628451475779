import { ApiClient } from 'api/ApiClient'
import { LifecycleStageSpec } from '@edp/types'
import { useEffect, useState } from 'react'

const useFetchLifecycleStage = (uuid?: string) => {
  const [lifecycleStage, setLifecycleStage] = useState<LifecycleStageSpec>()
  const apiClient = new ApiClient()

  useEffect(() => {
    const fetchLifecycleStage = async () => {
      if (uuid) {
        try {
          const data = await apiClient.getLifecycleStage(uuid)
          setLifecycleStage(data)
        } catch (e) {
          console.error('Ошибка при получении стадии жизненного цикла:', e)
        }
      }
    }

    fetchLifecycleStage()
  }, [uuid])

  return lifecycleStage
}

export default useFetchLifecycleStage
