import { ExploreTool } from './types'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  Button,
} from '@mui/material'
import { IoMdOpen } from '@edp/core-common-frontend/dist/packages/react-icons/io'

type Props = {
  card: ExploreTool
}

export const ToolCard = ({ card }: Props) => {
  const { title, description, url, image, tags } = card

  return (
    <>
      {url ? (
        <Card
          key={title}
          sx={{
            minWidth: 275,
            display: 'grid',
          }}
        >
          <CardMedia
            image={image}
            title={title}
            sx={{ height: 128, backgroundSize: '75%' }}
          />
          <CardContent>
            <Typography paragraph variant="h6">
              {title}{' '}
            </Typography>
            <Typography component="div" variant="subtitle2">
              {description || 'Описание отсутствует'}
            </Typography>
            {tags && (
              <Box marginTop={2}>
                {tags.map((item, idx) => (
                  <Chip size="small" key={idx} label={item} />
                ))}
              </Box>
            )}
          </CardContent>
          <CardActions
            sx={{
              justifyContent: 'right',
              alignSelf: 'end',
              height: 50,
            }}
          >
            <Button
              color="primary"
              disabled={!url}
              href={url}
              target="_blank"
              startIcon={<IoMdOpen />}
            >
              Открыть
            </Button>
          </CardActions>
        </Card>
      ) : (
        <></>
      )}
    </>
  )
}
