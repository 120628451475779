import { Box, Grid } from '@mui/material'
import { Action, ActionType } from 'types/Managers/Actions'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from '@edp/core-common-frontend'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import useFetchAction from 'hooks/useFetchAction'
import { NothingFoundPage } from '@edp/core-common-frontend'

export const ActionsPage = () => {
  const [actions, setActions] = useState<Action[]>()
  const [filteredActions, setFilteredActions] = useState<Action[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [actionToDelete, setActionToDelete] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getActions = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getActions()
      setActions(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getActions()
  }, [])

  useEffect(() => {
    const s: Action[] = []

    if (actions && owner !== '') {
      for (const action of actions) {
        if (action.owner === owner) {
          s.push(action)
        }
      }
      setFilteredActions(s)
    }

    if (owner === '') {
      setFilteredActions(actions)
    }
  }, [actions, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setActionToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteAction(actionToDelete).then(() => {
        setDeleted(true)
        getActions()
        setDeletionWindowOpened(false)
      })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const actionToDeleteSpec = useFetchAction(actionToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton component={Link} to={'/components/actions/create'}>
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {filteredActions?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новый action, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredActions?.map((action, index) => (
          <ComponentCard
            key={index}
            name={action.name}
            uuid={action.uuid}
            description={action.description}
            options={[
              {
                name: 'Идентификатор',
                value: action?.slug,
              },
              {
                name: 'Владелец',
                value: action.ownerRef?.name,
                isUrl: true,
                externalUrl: false,
                url: `/teams/${action.ownerRef?.uuid}`,
              },
              {
                name: 'Тип',
                value: action.type,
              },
              ...([ActionType.GitlabPipeline, ActionType.GitlabMR].includes(
                action.type
              )
                ? [
                    {
                      name: 'Репозиторий',
                      isUrl: true,
                      value: action.repositoryRef?.name,
                      url: `/components/repositories/${action.repositoryRef?.uuid}`,
                      externalUrl: false,
                    },
                    {
                      name: 'Репозиторий в Gitlab',
                      isUrl: true,
                      value: action.repositoryRef?.slug,
                      url: action.repositoryRef?.url,
                      externalUrl: true,
                    },
                  ]
                : []),
              ...([ActionType.GitlabMR, ActionType.KrakendEndpoint].includes(
                action.type
              )
                ? [
                    {
                      name: 'Целевой репозиторий',
                      isUrl: true,
                      value: action.targetRepositoryRef?.name,
                      url: `/components/repositories/${action.targetRepositoryRef?.uuid}`,
                      externalUrl: false,
                    },
                    {
                      name: 'Целевой репозиторий в Gitlab',
                      isUrl: true,
                      value: action.targetRepositoryRef?.slug,
                      url: action.targetRepositoryRef?.url,
                      externalUrl: true,
                    },
                    {
                      name: 'Ветка целевого репозитория',
                      value: action.targetBranch,
                    },
                  ]
                : []),
              ...([ActionType.GitlabGroupLevelVariables].includes(action.type)
                ? [
                    {
                      name: 'Целевая группа в Gitlab',
                      isUrl: false,
                      value: action.gitlab?.groupLevelVariables?.group,
                    },
                  ]
                : []),
            ]}
            linkOpen={`/components/actions/${action.uuid}`}
            linkUpdate={`/components/actions/update/${action.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление action'}
          text={`Action ${actionToDeleteSpec?.name} будет удален.`}
          spec={actionToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Action ${actionToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении action ${actionToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default ActionsPage
