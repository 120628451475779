import {
  Add,
  Remove,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Divider, IconButton, Typography } from '@mui/material'
import UserInteractionEnvironmentSelect from 'components/UserInteraction/UserInteractionEnvironmentSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import ServiceStore from './store'
import './styles.css'

export interface SecretsProps {
  disabled: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Secrets = observer((props: SecretsProps) => {
  const handleAddVaultSecretField = () => {
    let s =
      ServiceStore.$('vault.secrets').value.length > 0
        ? [...ServiceStore.$('vault.secrets').value]
        : []

    runInAction(() => {
      s = [...s, { environment: '', path: '' }]
      ServiceStore.$('vault.secrets').set(s)
    })
  }

  const handleRemoveVaultSecretField = () => {
    if (ServiceStore.$('vault.secrets').value.length > 0) {
      runInAction(() => {
        const s = [...ServiceStore.$('vault.secrets').value]
        s.pop()
        ServiceStore.$('vault.secrets').set(s)
      })
    }
  }

  const handleVaultEnvironmentChange = (index: number, event: any) => {
    runInAction(() => {
      const s = [...ServiceStore.$('vault.secrets').value]
      s[index].environment = event
      ServiceStore.$('vault.secrets').set(s)
    })
  }

  const handleVaultSecretPathChange = (index: number, path: string) => {
    runInAction(() => {
      const s = [...ServiceStore.$('vault.secrets').value]
      s[index].path = path
      ServiceStore.$('vault.secrets').set(s)
    })
  }

  return (
    <>
      <Divider
        sx={{
          paddingTop: '30px',
        }}
      />
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Секреты
      </Typography>
      {ServiceStore.$('vault.secrets').value.length > 0 &&
        ServiceStore.$('vault.secrets').value.map(
          (vault: { environment: string; path: string }, index: number) => (
            <div key={index}>
              <UserInteractionEnvironmentSelect
                name={`environment`}
                key={`environment-${index}`}
                description={`Окружение`}
                helperText={`Окружение`}
                selected={vault.environment}
                setSelected={(event) =>
                  handleVaultEnvironmentChange(index, event)
                }
              />
              <UserInteractionTextField
                name="path"
                key={`path-${index}`}
                description="Путь к секрету"
                helperText="Путь к секрету для сервиса на окружении в HashiCorp Vault и его название. Например 'secrets/data/<secret-name>'"
                value={vault.path}
                onChange={(event) =>
                  handleVaultSecretPathChange(index, event.target.value)
                }
              />
            </div>
          )
        )}
      <IconButton aria-label="add" onClick={handleAddVaultSecretField}>
        <Add />
      </IconButton>
      <IconButton aria-label="remove" onClick={handleRemoveVaultSecretField}>
        <Remove />
      </IconButton>
    </>
  )
})

export default Secrets
