import { CustomCardHeader } from '@edp/core-common-frontend'
import { Card, CardContent } from '@mui/material'
import useFetchLifecycleStage from 'hooks/useFetchLifecycleStage'
import { useParams } from 'react-router-dom'
import { LifecycleStageTable } from './LifecycleStageTable'

export const LifecycleStagePage = () => {
  const { uuid } = useParams()
  const lifecycleStage = useFetchLifecycleStage(uuid)

  return (
    <>
      {lifecycleStage?.uuid ? (
        <Card sx={{ boxShadow: '1' }}>
          <CustomCardHeader
            height="80px"
            title={`Сервисы на стадии "${lifecycleStage.name.toLowerCase()}"`}
          />
          <CardContent>
            <LifecycleStageTable
              created={true}
              lifecycleStageUuid={lifecycleStage.uuid}
            />
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </>
  )
}

export default LifecycleStagePage
