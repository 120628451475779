import { Grid } from '@mui/material'
import { CustomButton } from '@edp/core-common-frontend'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import { useState } from 'react'
import EditKafkaAcl, { KafkaAclActions } from './EditKafkaAcl'
import { KafkaAclsTable } from './KafkaAclsTable'

export const KafkaAclsPanel = () => {
  const [opened, setOpened] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleOpen = () => setOpened(true)

  return (
    <>
      <Grid className="KafkaAclsPanel-CreateButton">
        <CustomButton onClick={handleOpen}>Создать</CustomButton>
      </Grid>
      <EditKafkaAcl
        name={''}
        opened={opened}
        setOpened={setOpened}
        created={created}
        setCreated={setCreated}
        updated={updated}
        setUpdated={setUpdated}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        action={KafkaAclActions.Create}
      />
      <KafkaAclsTable
        updated={updated}
        setUpdated={setUpdated}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        created={created}
        setCreated={setCreated}
      />
      <CustomSnackbarComponent
        opened={created}
        setOpened={setCreated}
        message="ACL создан"
        severity="success"
      />
      <CustomSnackbarComponent
        opened={updated}
        setOpened={setUpdated}
        message="ACL обновлен"
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
}

export default KafkaAclsPanel
