import { ServiceTemplate } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { EditComponentPage } from 'components/EditComponentPage'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { EntityAction } from 'types/entities'
import Actions from './Actions'
import BasicInfo from './BasicInfo'
import Groups from './Groups'
import TerraformActions from './TerraformActions'
import ServiceTemplateStore from './store'

export interface EditServiceTemplatePageProps {
  action: EntityAction
}

export const EditServiceTemplatePage = observer(
  (props: EditServiceTemplatePageProps) => {
    const { uuid } = useParams()
    const navigate = useNavigate()

    const [edited, setEdited] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [failed, setFailed] = useState<boolean>(false)
    const apiClient = new ApiClient()

    useEffect(() => {
      ServiceTemplateStore.reset()
    }, [])

    const fetchData = async () => {
      try {
        if (uuid) {
          const data = await apiClient.getServiceTemplate(uuid)
          ServiceTemplateStore.set(data)

          ServiceTemplateStore.$('actions').set(data.actions)
        }
      } catch (e) {
        console.error('Ошибка при получении данных:', e)
      }
    }

    useEffect(() => {
      fetchData()
    }, [uuid])

    const handleEdit = async () => {
      setEdited(false)
      setFailed(false)
      setErrorMessage('')
      setLoading(true)

      try {
        await ServiceTemplateStore.validate().then(({ isValid }) => {
          if (!isValid) {
            setFailed(true)
            const e = new Error(
              'Все обязательные поля должны быть заполнены. Проверьте наличие тегов в шаблонном репозитории'
            )
            return Promise.reject(e)
          }
        })

        const actions = ServiceTemplateStore.$('actions').value
        const terraformActions =
          ServiceTemplateStore.$('terraformActions').value
        const groups = ServiceTemplateStore.$('groups').value

        const serviceTemplate: ServiceTemplate = {
          name: ServiceTemplateStore.$('name').value,
          slug: ServiceTemplateStore.$('slug').value,
          description: ServiceTemplateStore.$('description').value,
          owner: ServiceTemplateStore.$('owner').value,
          groups:
            groups.length > 0
              ? groups.filter((item: string) => item !== '')
              : [],
          templateRepository:
            ServiceTemplateStore.$('templateRepository').value,
          currentVersion: ServiceTemplateStore.$('currentVersion').value,
          actions:
            actions.length > 0
              ? actions.filter((item: string) => item !== '')
              : [],
          terraformActions:
            terraformActions.length > 0
              ? terraformActions.filter((item: string) => item !== '')
              : [],
        }

        if (props.action === EntityAction.Create) {
          await apiClient.createServiceTemplate(serviceTemplate)
        }

        if (props.action === EntityAction.Update) {
          serviceTemplate.uuid = uuid
          await apiClient.updateServiceTemplate(serviceTemplate)
        }

        setEdited(true)
        navigate('/components/service-templates')
      } catch (e) {
        setFailed(true)
        if (e instanceof Error) {
          setErrorMessage(e.message)
        } else {
          setErrorMessage('Неизвестная ошибка')
        }
      } finally {
        setLoading(false)
      }
    }

    let editedMessage

    if (props.action === EntityAction.Create) {
      editedMessage = 'Шаблон сервиса создан'
    }

    if (props.action === EntityAction.Update) {
      editedMessage = 'Шаблон сервиса обновлен'
    }

    return (
      <>
        <EditComponentPage
          action={props.action}
          handleEdit={handleEdit}
          loading={loading}
          edited={edited}
          setEdited={setEdited}
          failed={failed}
          setFailed={setFailed}
          errorMessage={errorMessage}
          editedMessage={editedMessage}
        >
          <>
            <BasicInfo />
            <Groups />
            <Actions />
            {/*<TerraformActions />*/}
          </>
        </EditComponentPage>
      </>
    )
  }
)

export default EditServiceTemplatePage
