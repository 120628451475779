import { getAuthType, getJWT } from 'api/utils'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'
import { ApiClient } from 'api/ApiClient'
import { handleResponse } from '@edp/core-common-frontend'

export async function createPlatformServiceTemplate(
  this: ApiClient,
  spec: PlatformServiceTemplateSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/platform-services/templates`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await handleResponse(response)
}

export async function getPlatformServiceTemplates(
  this: ApiClient
): Promise<PlatformServiceTemplateSpec[]> {
  const url = `${this.serverUrl}/platform-services/templates`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getPlatformServiceTemplate(
  this: ApiClient,
  name: string
): Promise<PlatformServiceTemplateSpec> {
  const url = `${this.serverUrl}/platform-services/templates/${name}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updatePlatformServiceTemplate(
  this: ApiClient,
  spec: PlatformServiceTemplateSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/platform-services/templates/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function deletePlatformServiceTemplate(
  this: ApiClient,
  uuid: string
) {
  const url = `${this.serverUrl}/platform-services/templates/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
