import {
  Add,
  Remove,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { TbPlugConnected } from '@edp/core-common-frontend/dist/packages/react-icons/tb'
import { IconButton, Typography } from '@mui/material'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import UserInteractionPasswordTextField from 'components/UserInteraction/UserInteractionPasswordTextField'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import KafkaClusterStore from './store'

export interface BasicInfoProps {
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export const BasicInfo = observer((props: BasicInfoProps) => {
  const handleChangeBrokers = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    runInAction(() => {
      const b = [...KafkaClusterStore.$('brokers').value]
      b[index] = event.target.value
      KafkaClusterStore.$('brokers').set(b)
    })
  }

  const handleAddBrokersField = () => {
    let b: string[] = [...KafkaClusterStore.$('brokers').value]

    runInAction(() => {
      b = [...b, '']
      KafkaClusterStore.$('brokers').set(b)
    })
  }

  const handleRemoveBrokersField = () => {
    if (KafkaClusterStore.$('brokers').value.length > 0) {
      runInAction(() => {
        const b: string[] = [...KafkaClusterStore.$('brokers').value]
        b.pop()
        KafkaClusterStore.$('brokers').set(b)
      })
    }
  }

  // ToDo: implement
  const checkConnect = async () => {
    await KafkaClusterStore.validate().then(({ isValid }) => {
      if (!isValid) {
        props.setFailed(true)
        props.setErrorMessage('Все обязательные поля должны быть заполнены')
      }
    })
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название кластера Kafka."
        error={!KafkaClusterStore.$('name').isValid}
        {...KafkaClusterStore.$('name').bind()}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание кластера Kafka."
        multiline
        rows={6}
        height={'150px'}
        {...KafkaClusterStore.$('description').bind()}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая кластером Kafka."
        error={!KafkaClusterStore.$('owner').isValid}
        selected={KafkaClusterStore.$('owner').value}
        setSelected={(value) => KafkaClusterStore.$('owner').set(value)}
      />
      <UserInteractionTextField
        required
        name="username"
        description="Пользователь"
        helperText="Пользователь Kafka"
        error={!KafkaClusterStore.$('username').isValid}
        {...KafkaClusterStore.$('username').bind()}
      />
      <UserInteractionPasswordTextField
        required
        name="password"
        description="Пароль"
        helperText="Пароль пользователя Kafka."
        error={!KafkaClusterStore.$('password').isValid}
        {...KafkaClusterStore.$('password').bind()}
      />
      <UserInteractionSwitch
        name="allow-topics-deletion"
        description="Разрешить удаление топиков"
        helperText={'Разрешить удаление топиков из кластера Kafka через EDP'}
        checked={KafkaClusterStore.$('allowTopicsDeletion').value}
        onChange={(e) => {
          KafkaClusterStore.$('allowTopicsDeletion').set(e.target.checked)
        }}
      />
      <Typography
        variant="body2"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Брокеры
      </Typography>
      <div>
        {KafkaClusterStore.$('brokers').value &&
          KafkaClusterStore.$('brokers').value.map(
            (broker: string, index: number) => (
              <UserInteractionTextField
                key={index}
                name={`broker_${index}`}
                description={`Адрес брокера ${index + 1}`}
                helperText={`Адрес брокера ${index + 1}`}
                value={broker}
                onChange={(event) => handleChangeBrokers(index, event)}
              />
            )
          )}
        <IconButton aria-label="add" onClick={handleAddBrokersField}>
          <Add />
        </IconButton>
        <IconButton aria-label="remove" onClick={handleRemoveBrokersField}>
          <Remove />
        </IconButton>
      </div>
      <UserInteractionCustom
        name="check"
        description="Проверить подключение"
        helperText="Проверить подключение к Kafka кластеру."
      >
        <IconButton onClick={checkConnect} sx={{ marginLeft: '-6px' }}>
          <TbPlugConnected style={{ color: '#808080' }} />
        </IconButton>
      </UserInteractionCustom>
    </>
  )
})

export default BasicInfo
