import { CustomCardHeader } from '@edp/core-common-frontend'
import { Card, CardContent, LinearProgress } from '@mui/material'
import Alert from '@mui/material/Alert'
import { ApiClient } from 'api/ApiClient'
import { useAsync } from 'react-use'
import { GitlabContributorData } from 'types/Controllers/Gitlab'
import { ContributorsList } from './components/ContributorsList'

export interface ContributorsCardProps {
  uuid: string
}

export const ContributorsCard = (props: ContributorsCardProps) => {
  const apiClient = new ApiClient()

  const { value, loading, error } =
    useAsync(async (): Promise<GitlabContributorData> => {
      const gitlabObj = await apiClient.getContributorsSummary(props.uuid)
      const data = gitlabObj?.getContributorsData
      const renderData: any = { data }

      return renderData
    }, [])

  return (
    <Card style={{ minHeight: '250px', boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Контрибьюторы"
        description="Пользователи, которые внесли вклад в кодовую базу сервиса"
        fullPage={false}
      />
      {loading && <LinearProgress />}
      {!loading && error && <Alert severity="error">{error.message}</Alert>}
      {!loading && !error && (
        <CardContent>
          <ContributorsList contributorsObj={value || { data: [] }} />
        </CardContent>
      )}
    </Card>
  )
}
