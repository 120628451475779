import { Grid } from '@mui/material'
import { Contributor } from '../Contributor'
import { GitlabContributorData } from '@edp/types'

export const ContributorsList = ({ contributorsObj }: any) => {
  const data = contributorsObj.data.map(
    (contributor: GitlabContributorData) => {
      return {
        name: contributor.name,
        email: contributor.email,
        avatar_url: contributor.avatar_url,
      }
    }
  )
  return (
    <Grid container spacing={1} justifyContent="flex-start">
      {data.map((contributor: GitlabContributorData) => (
        <Grid key={contributor.name} item>
          <Contributor contributor={contributor} />
        </Grid>
      ))}
    </Grid>
  )
}
