import {
  ServiceKubernetesConfigObjectsSpec,
  ServiceSwaggerConfigLinkSpec,
} from '@edp/types'
import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const plugins = {
  dvr: dvr(validatorjs),
}

const branch = {
  name: null,
  ref: null,
}

const namespace = {
  uuid: null,
  name: null,
}

const helmRelease = {
  service: null,
  name: null,
}

const linksEmpty: ServiceSwaggerConfigLinkSpec[] = []

const swaggerConfigRef = {
  service: null,
  type: null,
  links: linksEmpty,
}

const objectsEmpty: ServiceKubernetesConfigObjectsSpec[] = []

const kubernetesConfigRef = {
  service: null,
  objects: objectsEmpty,
}

const fields = {
  name: {
    value: null,
    rules: 'required|string|between:3,64',
    options: {
      validateOnChange: true,
    },
  },
  slug: {
    value: null,
    rules: 'required|string|between:3,64|regex:/^[a-z][a-z0-9-]*$/',
    options: {
      validateOnChange: true,
    },
  },
  description: null,
  owner: {
    value: null,
    rules: 'required',
    options: {
      validateOnChange: true,
    },
  },
  repository: null,
  repositoryUrl: null,
  templated: false,
  template: {
    value: null,
    rules: 'required_with:templated',
    options: {
      validateOnChange: true,
    },
  },
  templateVersion: null,
  templateUpdate: true,
  templateValid: true,
  terraformActions: [],
  templateRepositoryVariables: [],
  updatesBranch: null,
  additionalActionsVariables: [],
  templateActionsVariables: [],
  actionsVariables: [],
  gitlabProjectLevelVariables: [],
  gitlabGroupFullPath: {
    value: null,
    options: {
      validateOnChange: true,
    },
  },
  branches: {
    value: [],
    options: {
      structure: branch,
    },
  },
  vault: {
    secrets: [],
  },
  helmReleases: {
    value: [],
    options: {
      structure: helmRelease,
    },
  },
  namespaces: {
    value: [],
    options: {
      structure: namespace,
    },
  },
  lifecycleStage: null,
  swaggerConfig: null,
  swaggerConfigRef: {
    value: [],
    fields: swaggerConfigRef,
  },
  kubernetesConfig: null,
  kubernetesConfigRef: {
    value: [],
    fields: kubernetesConfigRef,
  },
}

export default new MobxReactForm({ fields }, { plugins })
