import {
  CustomButton,
  CustomSnackbarComponent,
  getKubernetesNamespace,
  useFetchService,
} from '@edp/core-common-frontend'
import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { MdSettingsInputComponent } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { SiKubernetes } from '@edp/core-common-frontend/dist/packages/react-icons/si'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionEnvironmentSelect from 'components/UserInteraction/UserInteractionEnvironmentSelect'
import UserInteractionHelmChartSelect from 'components/UserInteraction/UserInteractionHelmChartSelect'
import UserInteractionKubernetesNamespaceSelect from 'components/UserInteraction/UserInteractionKubernetesNamespaceSelect'
import UserInteractionServiceBranchesSelect from 'components/UserInteraction/UserInteractionServiceBranchesSelect'
import UserInteractionServiceVersionSelect from 'components/UserInteraction/UserInteractionServiceVersionSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import useFetchHelmChart from 'hooks/useFetchHelmChart'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GitlabPipeline } from 'types/Controllers/Gitlab'
import PipelineVariables from './PipelineVariables'
import DeployStore from './store'
import { ServiceMetadata } from '@edp/types'

export const Deploy = observer(() => {
  const { uuid } = useParams()
  const [helmChartVersions, setHelmChartVersions] = useState<string[]>()
  const [variablesOpened, setVariablesOpened] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [helmChartVersionsLoading, setHelmChartVersionsLoading] =
    useState<boolean>(false)
  const [serviceMetadata, setServiceMetadata] =
    React.useState<ServiceMetadata>()
  const [environment, setEnvironment] = useState<string>('')
  const [namespace, setNamespace] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    DeployStore.reset()
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const apiClient = new ApiClient()

  const helmChartSpec = useFetchHelmChart(DeployStore.$('helmChart').value)
  const serviceSpec = useFetchService(uuid)

  const getTags = async () => {
    try {
      setLoading(true)
      setErrorMessage('')
      setFailed(false)

      const data = await apiClient.getServiceTags(uuid!)

      const metadata: ServiceMetadata = {
        dockerRegistryUrl: data.dockerRegistryUrl,
        dockerRegistryName: data.dockerRegistryName,
        dockerRegistryType: data.dockerRegistryType,
        dockerImageName: data.dockerImageName,
      }

      setServiceMetadata(metadata)
    } catch (e) {
      console.error(e)
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getTags()
  }, [])

  const getEnvironment = async () => {
    if (DeployStore.$('environment').value) {
      const data = await apiClient.getEnvironment(
        DeployStore.$('environment').value
      )
      setEnvironment(data.slug)
    } else {
      setEnvironment('')
    }
  }

  const getNamespace = async () => {
    if (DeployStore.$('namespace').value) {
      const data = await getKubernetesNamespace(
        DeployStore.$('namespace').value
      )
      setNamespace(data.slug)
    } else {
      setNamespace('')
    }
  }

  const getHelmChartVersions = async () => {
    setHelmChartVersionsLoading(true)

    try {
      if (DeployStore.$('helmChart').value) {
        const versions = await apiClient.getHelmChartVersions(
          DeployStore.$('helmChart').value
        )
        setHelmChartVersions(versions)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setHelmChartVersionsLoading(false)
    }
  }

  useEffect(() => {
    getEnvironment()
  }, [DeployStore.$('environment').value])

  useEffect(() => {
    getNamespace()
  }, [DeployStore.$('namespace').value])

  useEffect(() => {
    getHelmChartVersions()
  }, [DeployStore.$('helmChart').value])

  useEffect(() => {
    const setVariables = async () => {
      try {
        DeployStore.$('variables').set([
          { key: 'ACTION', value: 'deploy' },
          { key: 'HELM_CHART', value: helmChartSpec?.name || '' },
          {
            key: 'HELM_CHART_VERSION',
            value: DeployStore.$('helmChartVersion').value,
          },
          { key: 'HELM_REPO', value: helmChartSpec?.registryURL },
          {
            key: 'HELM_RELEASE',
            value: DeployStore.$('helmRelease').value,
          },
          {
            key: 'K8S_NAMESPACE',
            value: namespace,
          },
          {
            key: 'ENVIRONMENT',
            value: environment,
          },
          {
            key: 'DOCKER_IMAGE',
            value: `${serviceMetadata?.dockerRegistryUrl}/${serviceMetadata?.dockerImageName}`,
          },
          {
            key: 'DOCKER_IMAGE_TAG',
            value: DeployStore.$('version').value,
          },
        ])
      } catch (e) {
        console.log(e)
      }
    }

    setVariables()
  }, [
    helmChartSpec,
    serviceSpec,
    DeployStore.$('version').value,
    DeployStore.$('helmChartVersion').value,
    DeployStore.$('helmRelease').value,
    DeployStore.$('namespace').value,
    DeployStore.$('environment').value,
    namespace,
    environment,
  ])

  const deploy = async () => {
    setLoading(true)
    setErrorMessage('')

    if (uuid) {
      try {
        await DeployStore.validate().then(({ isValid }) => {
          if (!isValid) {
            setFailed(true)
            const e = new Error('Все обязательные поля должны быть заполнены')
            return Promise.reject(e)
          }
        })
        const spec: GitlabPipeline = {
          ref: DeployStore.$('ref').value,
          variables: DeployStore.$('variables').value,
        }

        await apiClient.startPipeline(uuid, spec)

        setCreated(true)
        setOpen(false)
      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.message)
          setFailed(true)
        } else {
          setErrorMessage('unknown')
          setFailed(true)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <CustomButton
        startIcon={<SiKubernetes />}
        onClick={handleOpen}
        sx={{
          width: '145px',
        }}
      >
        Развернуть
      </CustomButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: '750px' } }}
        aria-labelledby="deploy-dialog"
        aria-describedby="deploy-dialog-description"
      >
        <DialogTitle>
          Развернуть артефакт в Kubernetes
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Grid
            sx={{
              display: 'grid',
              paddingTop: '10px',
            }}
          >
            <UserInteractionServiceVersionSelect
              uuid={uuid || ''}
              name="version"
              description="Версия"
              helperText="Версия артефакта, которая будет развернута."
              error={!DeployStore.$('version').isValid}
              selected={DeployStore.$('version').value}
              setSelected={(value) => DeployStore.$('version').set(value)}
            />
            <UserInteractionServiceBranchesSelect
              name="ref"
              description="Ветка репозитория"
              helperText="Ветка репозитория из которой будет производиться развертывание"
              error={!DeployStore.$('ref').isValid}
              selected={DeployStore.$('ref').value}
              setSelected={(value) => DeployStore.$('ref').set(value)}
            />
            <UserInteractionEnvironmentSelect
              name="environment"
              description="Окружение"
              helperText="Окружение на которое будет производиться развертывание."
              error={!DeployStore.$('environment').isValid}
              selected={DeployStore.$('environment').value}
              setSelected={(value) => DeployStore.$('environment').set(value)}
            />
            <UserInteractionKubernetesNamespaceSelect
              name="namespace"
              description="Namespace"
              helperText="Namespace в котором будет развернут инстанс."
              error={!DeployStore.$('namespace').isValid}
              selected={DeployStore.$('namespace').value}
              setSelected={(value) => DeployStore.$('namespace').set(value)}
            />
            <UserInteractionHelmChartSelect
              name="helmChart"
              description="Helm chart"
              helperText="Helm chart, который будет использоваться для развертывания сервиса."
              error={!DeployStore.$('helmChart').isValid}
              selected={DeployStore.$('helmChart').value}
              setSelected={(value) => DeployStore.$('helmChart').set(value)}
            />
            <UserInteractionCustomSelect
              name="helmVersion"
              description="Версия Helm chart"
              helperText="Версия Helm chart, которая будет использоваться для развертывания инстанса."
              options={helmChartVersions || ['']}
              error={!DeployStore.$('helmChartVersion').isValid}
              selected={DeployStore.$('helmChartVersion').value}
              setSelected={(value) =>
                DeployStore.$('helmChartVersion').set(value)
              }
              loading={helmChartVersionsLoading}
            />
            <UserInteractionTextField
              name="helmRelease"
              description="Helm release"
              helperText="Название Helm release."
              error={!DeployStore.$('helmRelease').isValid}
              {...DeployStore.$('helmRelease').bind()}
            />
            <UserInteractionCustom
              name="check"
              description="Переменные pipeline"
              helperText="Переменные окружения, которые будут переданы в pipeline развертывания."
            >
              <IconButton
                onClick={() => setVariablesOpened(true)}
                sx={{ marginLeft: '-6px' }}
              >
                <MdSettingsInputComponent style={{ color: '#757575' }} />
              </IconButton>
              <PipelineVariables
                variables={DeployStore.$('variables').value}
                opened={variablesOpened}
                setOpened={setVariablesOpened}
              />
            </UserInteractionCustom>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton loading={loading} onClick={deploy}>
            Развернуть
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomSnackbarComponent
        opened={created}
        setOpened={setCreated}
        message={'Развертывание запущено'}
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
})

export default Deploy
