import React from 'react'

import { observer } from 'mobx-react-lite'
import ServiceStore from './store'
import { Grid, Typography } from '@mui/material'
import UserInteractionLifecycleStageSelect from 'components/UserInteraction/UserInteractionLifecycleStageSelect'

type LifecycleProps = {
  disabled: boolean
}

const Lifecycle = observer((props: LifecycleProps) => {
  return (
    <>
      {props.disabled ? (
        <></>
      ) : (
        <>
          <Typography
            variant="body1"
            sx={{
              paddingTop: '30px',
              paddingBottom: '15px',
              fontWeight: '600',
            }}
          >
            Жизненный цикл
          </Typography>
          <Grid className="UserInteraction-Grid">
            <UserInteractionLifecycleStageSelect
              name="lifecycleStage"
              description="Стадия жизненного цикла"
              helperText="Выберите стадию жизненного цикла сервиса"
              error={!ServiceStore.$('lifecycleStage').isValid}
              selected={ServiceStore.$('lifecycleStage').value}
              setSelected={(value) =>
                ServiceStore.$('lifecycleStage').set(value)
              }
            />
          </Grid>
        </>
      )}
    </>
  )
})

export default Lifecycle
