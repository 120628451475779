import { CustomCardHeader } from '@edp/core-common-frontend'
import { GitlabPipelineObject } from '@edp/types'
import { Alert, Card, CardContent, LinearProgress } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { useAsync } from 'react-use'
import { PipelinesTable } from './PipelinesTable'

export interface PipelineCardProps {
  uuid: string
}

export const PipelinesCard = (props: PipelineCardProps) => {
  const apiClient = new ApiClient()

  const { value, loading, error } = useAsync(async (): Promise<
    GitlabPipelineObject[]
  > => {
    const gitlabObj = await apiClient.getServicePipelinesSummary(props.uuid)
    const data = gitlabObj?.getPipelinesData
    const renderData: any = { data }

    return renderData
  }, [])

  return (
    <Card sx={{ minHeight: '400px', boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Pipelines"
        description="Информация о пайплайнах сервиса"
        fullPage={false}
      />
      {loading && <LinearProgress />}
      {!loading && error && <Alert severity="error">{error.message}</Alert>}
      {!loading && !error && (
        <CardContent>
          <PipelinesTable pipelineObjects={value || []} />
        </CardContent>
      )}
    </Card>
  )
}
