import { Grid } from '@mui/material'
import Build from '../Build/Build'
import CreateTag from '../CreateTag/CreateTag'
import Deploy from '../Deploy/Deploy'
import { ContributorsCard, LanguagesCard } from './components'
import ArtifactsCard from './components/ArtifactsCard/ArtifactsCard'
import { MergeRequestsCard } from './components/MergeRequestsCard/MergeRequestsCard'
import { PipelinesCard } from './components/PipelinesCard/PipelinesCard'
import { TagsCard } from './components/TagsCard/TagsCard'

export interface CiCdPanelProps {
  uuid: string
}

export const CiCdPanel = (props: CiCdPanelProps) => (
  <>
    <Grid container className="Grid-Panel">
      <Grid item>
        <CreateTag />
      </Grid>
      <Grid item>
        <Build />
      </Grid>
      <Grid item>
        <Deploy />
      </Grid>
    </Grid>
    <Grid container spacing={6} direction="row" alignItems="stretch">
      <Grid item sm={12} md={6}>
        <ContributorsCard uuid={props.uuid} />
      </Grid>
      <Grid item sm={12} md={6}>
        <LanguagesCard uuid={props.uuid} />
      </Grid>
      <Grid item sm={12} md={6}>
        <PipelinesCard uuid={props.uuid} />
      </Grid>
      <Grid item sm={12} md={6}>
        <ArtifactsCard uuid={props.uuid} />
      </Grid>
      <Grid item sm={12} md={6}>
        <MergeRequestsCard uuid={props.uuid} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TagsCard uuid={props.uuid} />
      </Grid>
    </Grid>
  </>
)
