import { Add } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

export interface UserInteractionAddButtonProps {
  handleAdd?: () => void
}

export const UserInteractionAddButton = (
  props: UserInteractionAddButtonProps
) => {
  return (
    <Tooltip arrow title={'Добавить'} placement="top">
      <IconButton aria-label="add" onClick={props.handleAdd}>
        <Add />
      </IconButton>
    </Tooltip>
  )
}
