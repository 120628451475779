import { observer } from 'mobx-react'
import { EdpPlugin } from './PluginsPanel'
import PluginsStore from './store'
import { runInAction } from 'mobx'

export const LoadedPluginsList = observer(() => {
  const handleEnablePlugin = (pluginId: number, checked: boolean) => {
    const plugin = PluginsStore.$('plugins').value.find(
      (plugin: EdpPlugin) => plugin.id === pluginId
    )
    if (plugin) {
      runInAction(() => {
        plugin.enabled = checked
      })
    }
  }

  return (
    <div>
      {PluginsStore.$('plugins').value.map((plugin: EdpPlugin) => (
        <div key={plugin.id}>
          name: {plugin.name} <br />
          id: {plugin.id} <br />
          enabled: {String(plugin.enabled)}
          <input
            type="checkbox"
            checked={plugin.enabled}
            onChange={(e) => handleEnablePlugin(plugin.id, e.target.checked)}
          />
          <br />
          version: {plugin.version}
          <br />
          <br />
        </div>
      ))}
    </div>
  )
})
