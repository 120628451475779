import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import {
  HarborHelmChart,
  HarborHelmChartDetails,
} from 'types/Controllers/Harbor'
import { HelmChartRegistry, HelmChartSpec } from 'types/Managers/HelmCharts'
import { HelmChartArtifact } from 'types/Managers/Artifacts'
import { handleResponse } from '@edp/core-common-frontend'

export async function createHelmChart(
  this: ApiClient,
  spec: HelmChartSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/helm-charts`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getHelmCharts(this: ApiClient): Promise<HelmChartSpec[]> {
  const url = `${this.serverUrl}/helm-charts`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updateHelmChart(
  this: ApiClient,
  spec: HelmChartSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/helm-charts/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getHelmChartsFromRepository(
  this: ApiClient,
  teamUUID: string
): Promise<HelmChartArtifact[]> {
  const url = `${this.serverUrl}/helm-charts/all/${teamUUID}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}

export async function getHelmChartRegistryUrl(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/helm-charts/${uuid}/registry/url`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}

export async function getHarborHelmCharts(
  this: ApiClient,
  project: string
): Promise<HarborHelmChart[]> {
  const url = `${this.serverUrl}/helm-charts/harbor/${project}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getHelmChart(
  this: ApiClient,
  uuid: string
): Promise<HelmChartSpec> {
  const url = `${this.serverUrl}/helm-charts/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getHelmChartDetails(
  this: ApiClient,
  uuid: string
): Promise<HarborHelmChartDetails[]> {
  const url = `${this.serverUrl}/helm-charts/${uuid}/details`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function deleteHelmChart(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/helm-charts?uuid=${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getHelmChartsFromRegistry(
  this: ApiClient,
  spec: HelmChartRegistry
): Promise<string[]> {
  const url = `${this.serverUrl}/helm-charts/registry`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getHelmChartVersions(
  this: ApiClient,
  uuid: string
): Promise<string[]> {
  const url = `${this.serverUrl}/helm-charts/${uuid}/versions`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getHarborUrl(this: ApiClient): Promise<string> {
  const url = `${this.serverUrl}/harbor/url`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
