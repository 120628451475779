import { ReactNode, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import PageWrapper from 'components/Layouts/PageWrapper'
import appRoutes from './routes'
import { RouteType } from './config'
import PluginsStore from 'pages/components/Environments/PluginsPanel/store'
import { EdpPlugin } from 'pages/components/Environments/PluginsPanel/PluginsPanel'
import { reaction } from 'mobx'

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && generateRoute(route.child)}
      </Route>
    )
  )
}

export const StaticRoutes = () => {
  return generateRoute(appRoutes)
}

export const PluggableRoutes = () => {
  const [routes, setRoutes] = useState<RouteType[]>([])

  useEffect(() => {
    const reactionDisposer = reaction(
      () =>
        PluginsStore.$('plugins').value.map(
          (plugin: EdpPlugin) => plugin.enabled
        ),
      () => {
        loadPluggableRoutes()
      }
    )

    return reactionDisposer
  }, [])

  const loadPluggableRoutes = () => {
    let routes: RouteType[] = []
    let plugins: EdpPlugin[] = []

    plugins = PluginsStore.$('plugins').value as EdpPlugin[]

    if (plugins) {
      for (const plugin of plugins) {
        if (plugin.enabled && plugin.routes) {
          routes = [...routes, ...plugin.routes]
        }
      }
    }

    setRoutes(routes)
  }

  useEffect(() => {
    if (PluginsStore.$('plugins').value.length > 0) {
      loadPluggableRoutes()
    } else {
      console.log('empty plugins list')
    }
  }, [PluginsStore.$('plugins').value])

  return generateRoute(routes)
}
