import React, { forwardRef } from 'react'

import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Typography, Tooltip } from '@mui/material'
import './styles.css'
import { RepositorySelect } from '../RepositorySelect'

export interface UserInteractionRepositorySelectProps {
  name: string
  description: string
  helperText: string
  error?: boolean
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  template?: boolean
  disabled?: boolean
}

const UserInteractionRepositorySelect = forwardRef(
  // eslint-disable-next-line
  (props: UserInteractionRepositorySelectProps, _ref) => {
    return (
      <Grid className="UserInteraction-Grid">
        <Grid className="UserInteraction-Description">
          <Typography variant="body2" fontSize={'15px'}>
            {props.description}
          </Typography>
          <Tooltip arrow title={props.helperText} placement="top">
            <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
          </Tooltip>
        </Grid>
        <RepositorySelect
          selected={props.selected}
          setSelected={props.setSelected}
          template={props.template}
          error={props.error}
          disabled={props.disabled}
        />
      </Grid>
    )
  }
)

UserInteractionRepositorySelect.displayName = 'UserInteractionRepositorySelect'

export default UserInteractionRepositorySelect
