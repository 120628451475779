import React, { forwardRef } from 'react'

import {
  HelpOutline,
  Visibility,
  VisibilityOff,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Grid,
  Typography,
  Tooltip,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material'

import './styles.css'

export interface UserInteractionPasswordTextFieldProps {
  name: string
  description: string
  helperText: string
  value: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  multiline?: boolean
  rows?: number
  height?: string
  disabled?: boolean
  type?: string
  error?: boolean
}

const UserInteractionPasswordTextField = forwardRef(
  // eslint-disable-next-line
  (props: UserInteractionPasswordTextFieldProps, _ref) => {
    const [showPassword, setShowPassword] = React.useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault()
    }

    return (
      <Grid className="UserInteraction-Grid">
        <Grid className="UserInteraction-Description">
          <Typography variant="body2" fontSize={'15px'}>
            {props.description}
          </Typography>
          <Tooltip arrow title={props.helperText} placement="top">
            <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
          </Tooltip>
        </Grid>
        <TextField
          name={props.name}
          className="UserInteraction-TextField"
          value={props.value}
          onChange={props.onChange}
          required={props.required || false}
          multiline={props.multiline || false}
          rows={props.rows || 1}
          disabled={props.disabled}
          type={showPassword ? 'text' : 'password'}
          error={props.error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiInputBase-root': {
              height: `${props.height || '40px'}`,
            },
          }}
        />
      </Grid>
    )
  }
)

UserInteractionPasswordTextField.displayName =
  'UserInteractionPasswordTextField'

export default UserInteractionPasswordTextField
