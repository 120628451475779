import {
  Cancel,
  CheckCircle,
  NotInterested,
  Pending,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'

export enum PipelineStatus {
  Pending = 'pending',
  Running = 'running',
  Success = 'success',
  Failed = 'failed',
  Canceled = 'canceled',
  Skipped = 'skipped',
  Manual = 'manual',
  Scheduled = 'scheduled',
}

export const PipelineStatusColors = {
  [PipelineStatus.Pending]: {
    backgroundColor: '#ADD8E6',
    color: '#0000FF',
    icon: <Pending />,
  },
  [PipelineStatus.Running]: {
    backgroundColor: '#ADD8E6',
    color: '#0000FF',
    icon: <Pending />,
  },
  [PipelineStatus.Success]: {
    backgroundColor: '#CAE5CF',
    color: '#2B5834',
    icon: <CheckCircle />,
  },
  [PipelineStatus.Failed]: {
    backgroundColor: '#FFB6C1',
    color: '#D40A0A',
    icon: <Cancel />,
  },
  [PipelineStatus.Canceled]: {
    backgroundColor: '#D3D3D3',
    color: '#696969',
    icon: <NotInterested />,
  },
  [PipelineStatus.Skipped]: {
    backgroundColor: '#D3D3D3',
    color: '#696969',
    icon: <NotInterested />,
  },
  [PipelineStatus.Manual]: {
    backgroundColor: '#D3D3D3',
    color: '#696969',
    icon: <NotInterested />,
  },
  [PipelineStatus.Scheduled]: {
    backgroundColor: '#D3D3D3',
    color: '#696969',
    icon: <NotInterested />,
  },
}
