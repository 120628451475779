import { ComponentCard } from 'components/ComponentCard'
import { Action } from 'types/Managers/Actions'
import InfoIcon from '@mui/icons-material/Info'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import {
  ServiceInfoValidateSpec,
  ServiceTemplate,
  ServiceTemplateLastVersionResponseSpec,
  ServiceTemplateUpdateVersionResponseSpec,
} from '@edp/types'
import { Button, Chip, CircularProgress, Tooltip } from '@mui/material'
import { GrUpdate } from '@edp/core-common-frontend/dist/packages/react-icons/gr'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import './style.css'

interface DescriptionPanelProps {
  serviceTemplate?: ServiceTemplate
}

export const DescriptionPanel = (props: DescriptionPanelProps) => {
  const [serviceTemplateActions, setServiceTemplateActions] = useState<
    Action[]
  >([])
  const apiClient = new ApiClient()

  const [updated, setUpdated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingValidateChip, setLoadingValidateChip] = useState<boolean>(false)
  const [loadingValidateLastVersionChip, setLoadingValidateLastVersionChip] =
    useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [statusUpdate, setStatusUpdate] =
    useState<ServiceTemplateUpdateVersionResponseSpec>()
  const [failed, setFailed] = useState<boolean>(false)
  const [existNewVersion, setExistNewVersion] = useState<boolean>(false)

  const [validateStatus, setValidateStatus] = useState<ServiceInfoValidateSpec>(
    { status: 'unknown', branch: '' }
  )
  const [validateStatusLastVersion, setValidateStatusLastVersion] =
    useState<ServiceInfoValidateSpec>({ status: 'unknown', branch: '' })

  const [lastVersion, setLastVersion] = useState<string>('')

  const fetchActions = async () => {
    try {
      if (props.serviceTemplate && props.serviceTemplate.actions) {
        let filteredActions: Action[] = []

        const actions = await apiClient.getActions()

        for (const serviceTemplateAction of props.serviceTemplate.actions) {
          const filteredAction = actions.find(
            (action) => action.uuid === serviceTemplateAction
          )
          if (filteredAction) {
            filteredActions = [...filteredActions, filteredAction]
          }

          setServiceTemplateActions(filteredActions)
        }
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  const getCurrentVersionTemplate = (spec?: ServiceTemplate) => {
    if (spec?.currentVersion) {
      if (spec.currentVersion === '') {
        return 'Не определена'
      } else {
        return spec.currentVersion
      }
    } else {
      return 'Не определена'
    }
  }

  const getCurrentVersionTemplateURL = (url: string | undefined) => {
    if (url) {
      let formatted = ''
      if (url.endsWith('.git')) {
        formatted = url.replace(/\.git$/, '')
      } else {
        formatted = url
      }

      return `${formatted}/-/tree/${props.serviceTemplate?.currentVersion}`
    } else {
      return ''
    }
  }

  const handleInfoStatus = () => {}

  const generateValidateChipIcon = (status: ServiceInfoValidateSpec) => {
    let text = `Шаблон сервиса имеет проблемы с конфигурацией`

    if (status.errors) {
      // Структура

      let messageSpec = ''

      for (const error of status.errors) {
        if (error.path) {
          messageSpec = `${messageSpec}\nФайл: ${error.filename}\nПуть: ${error.path}\nОшибка: ${error.message}`
        }
      }

      if (messageSpec !== '') {
        text = `${text}\n\nПроблемы со структурой конфигурационного файла: \n${messageSpec}`
      }

      // Другие ошибки:

      let messageOtherErrors = ''
      for (const error of status.errors) {
        if (
          typeof error.variable === 'undefined' &&
          typeof error.path === 'undefined'
        ) {
          messageOtherErrors = `${messageOtherErrors}\n${error.message}`
        }
      }

      if (messageOtherErrors !== '') {
        text = `${text}\n\nОшибки:\n${messageOtherErrors}`
      }
    }

    return (
      <Tooltip
        title={text}
        placement="bottom"
        classes={{
          popper: 'tooltipPopperServiceTemplateClass',
          tooltipPlacementBottom: 'tooltipPopperServiceTemplateClassInfo',
        }}
      >
        <InfoIcon />
      </Tooltip>
    )
  }

  const validateChip = () => {
    return (
      <>
        {loadingValidateChip ? (
          <CircularProgress size="25px" color="inherit" />
        ) : (
          <>
            {validateStatus.status === 'success' && (
              <Chip label="Конфигурация корректна" color="success" />
            )}
            {validateStatus.status === 'error' && (
              <Chip
                label="Есть проблемы с конфигурацией"
                color="warning"
                deleteIcon={generateValidateChipIcon(validateStatus)}
                onDelete={handleInfoStatus} // Пустая функция, но она обязательна
              />
            )}
            {validateStatus.status === 'unknown' && (
              <Chip label="Не удалось проверить конфигурацию" color="warning" />
            )}
          </>
        )}
        {loadingValidateLastVersionChip ? (
          <></>
        ) : (
          <>
            {validateStatusLastVersion.status === 'error' && (
              <Chip
                label="Есть проблемы с конфигурацией последней версии"
                color="warning"
                style={{ marginLeft: '5px' }}
                deleteIcon={generateValidateChipIcon(validateStatusLastVersion)}
                onDelete={handleInfoStatus} // Пустая функция, но она обязательна
              />
            )}
          </>
        )}
      </>
    )
  }

  const UpdateCurrentVersionButton = (): JSX.Element => {
    const handleUpdateCurrentVersion = async () => {
      try {
        setUpdated(false)
        setErrorMessage('')
        setLoading(true)

        if (props.serviceTemplate) {
          apiClient
            .updateServiceTemplateLastVersion(props.serviceTemplate)
            .then((res: ServiceTemplateUpdateVersionResponseSpec) => {
              if (props.serviceTemplate && res.currentVersion) {
                props.serviceTemplate.currentVersion = res.currentVersion
              }
              setUpdated(true)
              setLoading(false)
              setStatusUpdate(res)
            })
            .catch((e) => {
              setLoading(false)
              setErrorMessage(e.message)
              setFailed(true)
            })
        }
      } catch (e) {
        setLoading(false)
        if (e instanceof Error) {
          setErrorMessage(e.message)
          setFailed(true)
        } else {
          setErrorMessage('unknown')
          setFailed(true)
        }
      }
    }

    return (
      <>
        <Button
          size="small"
          color="inherit"
          variant="text"
          onClick={handleUpdateCurrentVersion}
          startIcon={<GrUpdate />}
        >
          Обновить текущую версию
        </Button>
      </>
    )
  }

  useEffect(() => {
    fetchActions()
  }, [props.serviceTemplate])

  useEffect(() => {
    const validateServiceTemplate = async () => {
      const apiClient = new ApiClient()

      try {
        setLoadingValidateChip(true)
        if (props.serviceTemplate?.uuid) {
          const data: ServiceInfoValidateSpec =
            await apiClient.serviceTemplateConfigurationValidateByVersion(
              props.serviceTemplate?.uuid,
              props.serviceTemplate?.currentVersion
            )
          setValidateStatus(data)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingValidateChip(false)
      }
    }

    validateServiceTemplate()
  }, [props.serviceTemplate])

  useEffect(() => {
    const getServiceTemplateLastVersion = async () => {
      const apiClient = new ApiClient()

      try {
        if (props.serviceTemplate?.uuid) {
          const data: ServiceTemplateLastVersionResponseSpec =
            await apiClient.getServiceTemplateLastVersion(
              props.serviceTemplate?.uuid
            )
          setLastVersion(data.version)
        }
      } catch (e) {
        console.log(e)
      }
    }

    getServiceTemplateLastVersion()
  }, [props.serviceTemplate])

  useEffect(() => {
    const validateServiceTemplateLastVersion = async () => {
      const apiClient = new ApiClient()

      try {
        setLoadingValidateLastVersionChip(true)
        if (props.serviceTemplate?.uuid && lastVersion !== '') {
          const data: ServiceInfoValidateSpec =
            await apiClient.serviceTemplateConfigurationValidateByVersion(
              props.serviceTemplate?.uuid,
              lastVersion
            )
          setValidateStatusLastVersion(data)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingValidateLastVersionChip(false)
      }
    }

    validateServiceTemplateLastVersion()
  }, [lastVersion])

  useEffect(() => {
    const existNewVersion = () => {
      if (props.serviceTemplate) {
        if (lastVersion !== props.serviceTemplate.currentVersion) {
          setExistNewVersion(true)
        }
      }
      setExistNewVersion(false)
    }

    existNewVersion()
  }, [lastVersion])

  return (
    <>
      <ComponentCard
        name={props.serviceTemplate?.name}
        description={props.serviceTemplate?.description}
        headerAction={validateChip()}
        headerClassName="headerServiceTemplateClass"
        headerClasses={{
          content: 'headerContentServiceTemplateClass',
          action: 'headerActionServiceTemplateClass',
        }}
        fullPage
        options={[
          {
            name: 'Идентификатор',
            value: props.serviceTemplate?.slug,
          },
          {
            name: 'Владелец',
            value: props.serviceTemplate?.ownerRef?.name,
            isUrl: true,
            externalUrl: false,
            url: `/teams/${props.serviceTemplate?.ownerRef?.uuid}`,
          },
          {
            name: `Текущая версия`,
            value: getCurrentVersionTemplate(props.serviceTemplate),
            isUrl: true,
            externalUrl: true,
            url: getCurrentVersionTemplateURL(
              props.serviceTemplate?.templateRepositoryRef?.url
            ),
          },
          {
            name: `Доступна новая версия`,
            value: lastVersion,
            isUrl: true,
            externalUrl: true,
            url: `${props.serviceTemplate?.templateRepositoryRef?.url}/-/tree/${lastVersion}`,
            disabled: !existNewVersion,
          },
          {
            name: 'Шаблонный репозиторий в EDP',
            isUrl: true,
            value: props.serviceTemplate?.templateRepositoryRef?.name,
            url: `/components/repositories/${props.serviceTemplate?.templateRepositoryRef?.uuid}`,
            externalUrl: false,
          },
          {
            name: 'Шаблонный репозиторий в Gitlab',
            isUrl: true,
            value: props.serviceTemplate?.templateRepositoryRef?.name,
            url: `${props.serviceTemplate?.templateRepositoryRef?.url}`,
            externalUrl: true,
          },
          ...serviceTemplateActions.map((action, index) => ({
            name: `Action ${index + 1}`,
            value: action.name,
            isUrl: true,
            externalUrl: false,
            url: `/components/actions/${action.uuid}`,
          })),
        ]}
        customCardActions={UpdateCurrentVersionButton()}
      />

      {statusUpdate?.error && statusUpdate.message ? (
        <CustomSnackbarComponent
          opened={updated}
          setOpened={setUpdated}
          message={`${statusUpdate.message}`}
          severity="success"
        />
      ) : (
        <CustomSnackbarComponent
          opened={updated}
          setOpened={setUpdated}
          message={'Версия успешно обновлена'}
          severity="success"
        />
      )}
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={`При запуске обновления версии произошла ошибка: ${errorMessage}`}
        severity="error"
      />
    </>
  )
}

export default DescriptionPanel
