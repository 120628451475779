import {
  createKubernetesNamespace,
  getKubernetesNamespace,
  updateKubernetesNamespace,
} from '@edp/core-common-frontend'
import { KubernetesNamespaceSpec } from '@edp/types'
import { EditComponentPage } from 'components/EditComponentPage'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { EntityAction } from 'types/entities'
import BasicInfo from './BasicInfo'
import Integrations from './Integrations'
import KubernetesNamespaceStore from './store'

export interface EditKubernetesNamespacePageProps {
  action: EntityAction
}

export const EditKubernetesNamespacePage = observer(
  (props: EditKubernetesNamespacePageProps) => {
    const { uuid } = useParams()
    const navigate = useNavigate()

    const [edited, setEdited] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [failed, setFailed] = useState<boolean>(false)

    useEffect(() => {
      KubernetesNamespaceStore.reset()
    }, [])

    const fetchData = async () => {
      try {
        if (uuid) {
          const data = await getKubernetesNamespace(uuid)
          KubernetesNamespaceStore.set(data)
        }
      } catch (e) {
        console.error('Ошибка при получении данных:', e)
      }
    }

    useEffect(() => {
      fetchData()
    }, [uuid])

    const handleEdit = async () => {
      setEdited(false)
      setFailed(false)
      setErrorMessage('')
      setLoading(true)

      try {
        await KubernetesNamespaceStore.validate().then(({ isValid }) => {
          if (!isValid) {
            setFailed(true)
            const e = new Error('Все обязательные поля должны быть заполнены')
            return Promise.reject(e)
          }
        })

        const spec: KubernetesNamespaceSpec = {
          name: KubernetesNamespaceStore.$('name').value,
          slug: KubernetesNamespaceStore.$('slug').value,
          description: KubernetesNamespaceStore.$('description').value,
          owner: KubernetesNamespaceStore.$('owner').value,
          environment: KubernetesNamespaceStore.$('environment').value,
          integrations: KubernetesNamespaceStore.$('integrations').value,
        }

        if (props.action === EntityAction.Create) {
          await createKubernetesNamespace(spec)
        }

        if (props.action === EntityAction.Update) {
          spec.uuid = uuid
          await updateKubernetesNamespace(spec)
        }

        setEdited(true)
        navigate('/kubernetes/namespaces')
      } catch (e) {
        setFailed(true)
        if (e instanceof Error) {
          setErrorMessage(e.message)
        } else {
          setErrorMessage('Неизвестная ошибка')
        }
      } finally {
        setLoading(false)
      }
    }

    let editedMessage

    if (props.action === EntityAction.Create) {
      editedMessage = 'Kubernetes namespace зарегистрирован'
    }

    if (props.action === EntityAction.Update) {
      editedMessage = 'Kubernetes namespace обновлен'
    }

    return (
      <>
        <EditComponentPage
          action={props.action}
          handleEdit={handleEdit}
          loading={loading}
          edited={edited}
          setEdited={setEdited}
          failed={failed}
          setFailed={setFailed}
          errorMessage={errorMessage}
          editedMessage={editedMessage}
        >
          <>
            <BasicInfo disabled={props.action === EntityAction.Update} />
            <Integrations />
          </>
        </EditComponentPage>
      </>
    )
  }
)

export default EditKubernetesNamespacePage
