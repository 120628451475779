import { Alert, AlertColor, Grid } from '@mui/material'

type ServiceTemplateVersionInfoProps = {
  severity: AlertColor
  text: string
}

export const ServiceTemplateVersionInfo = (
  props: ServiceTemplateVersionInfoProps
) => {
  return (
    <Grid className="VersionAlertGrid">
      <Alert className="VersionAlert" severity={props.severity}>
        <span dangerouslySetInnerHTML={{ __html: props.text }} />
      </Alert>
    </Grid>
  )
}
