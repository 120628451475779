import { getServices, getTeams } from '@edp/core-common-frontend'
import { LifecycleStageSpec, ServiceSpec, TeamSpec } from '@edp/types'
import { Grid } from '@mui/material'
import { CustomMultipleSelectComponent } from 'components/CustomMultipleSelectComponent'
import { useEffect, useState } from 'react'
import {
  countingFillingServiceFieldsStatus,
  StatusFillingServiceSpec,
} from 'utils/countingFillingServiceFields'
import ServiceSummaryStatsCard from './ServiceSummaryStatsCard'
import ServiceSummaryTableCard from './ServiceSummaryTableCard'
import { ApiClient } from 'api/ApiClient'
import ServiceSummaryDownloadButton from './ServiceSummaryDownloadButton'

export const ServiceSummaryTablePage = () => {
  const [services, setServices] = useState<Array<ServiceSpec>>([])

  const [teams, setTeams] = useState<Array<TeamSpec>>()
  const [formattedTeams, setFormattedTeams] = useState<Array<string>>([])
  const [selectedTeams, setSelectedTeams] = useState<Array<string>>([])

  const [lifecycleStages, setLifecycleStages] = useState<
    Array<LifecycleStageSpec>
  >([])
  const [formatetedLifecycleStage, setFormattedLifecycleStage] = useState<
    Array<string>
  >([])
  const [selectedLifecycleStages, setSelectedLifecycleStages] = useState<
    Array<string>
  >([])

  const [statusService, setStatusService] = useState<
    Map<string, StatusFillingServiceSpec>
  >(new Map())

  const [loading, setLoading] = useState<boolean>(true)

  const apiClient = new ApiClient()

  const fetchServices = async () => {
    setLoading(true)

    try {
      const data = await getServices()
      setServices(data)

      const tmpStatusService = new Map<string, StatusFillingServiceSpec>()
      for (const service of data) {
        const status = await countingFillingServiceFieldsStatus(service)
        if (service.uuid) {
          tmpStatusService.set(service.uuid, status)
        }
      }
      setStatusService(tmpStatusService)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchTeams = async () => {
    try {
      const data = await getTeams()
      setTeams(data)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchLifecycleStages = async () => {
    try {
      const data = await apiClient.getLifecycleStages()
      setLifecycleStages(data)
    } catch (e) {
      console.log(e)
    }
  }

  const formatTeams = () => {
    const teamNames: Array<string> = []

    if (teams) {
      for (const team of teams) {
        teamNames.push(team.name)
      }

      setFormattedTeams(teamNames)
    }
  }

  const formatLifecycleStages = async () => {
    const lifecycleStageNames: Array<string> = []

    try {
      for (const lifecycleStage of lifecycleStages) {
        lifecycleStageNames.push(lifecycleStage.name)
      }

      setFormattedLifecycleStage(lifecycleStageNames)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchServices()
    fetchTeams()
    fetchLifecycleStages()
  }, [])

  useEffect(() => {
    formatTeams()
  }, [teams])

  useEffect(() => {
    formatLifecycleStages()
  }, [lifecycleStages])

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={7}
          sx={{
            display: 'flex',
          }}
        >
          <CustomMultipleSelectComponent
            title="Выберите команды"
            options={formattedTeams}
            selected={selectedTeams}
            setSelected={setSelectedTeams}
          />
          <div
            style={{
              marginLeft: '5px',
            }}
          >
            <CustomMultipleSelectComponent
              title="Выберите стадии"
              options={formatetedLifecycleStage}
              selected={selectedLifecycleStages}
              setSelected={setSelectedLifecycleStages}
            />
          </div>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              marginLeft: 'auto',
            }}
          >
            <ServiceSummaryDownloadButton
              services={services}
              selectedTeams={selectedTeams}
              selectedLifecycleStages={selectedLifecycleStages}
              statusService={statusService}
            />
          </div>
        </Grid>
      </Grid>
      <ServiceSummaryStatsCard
        loading={loading}
        setLoading={setLoading}
        services={services}
        selectedTeams={selectedTeams}
        selectedLifecycleStages={selectedLifecycleStages}
        statusService={statusService}
      />
      <ServiceSummaryTableCard
        loading={loading}
        setLoading={setLoading}
        services={services}
        selectedTeams={selectedTeams}
        selectedLifecycleStages={selectedLifecycleStages}
        statusService={statusService}
      />
    </>
  )
}

export default ServiceSummaryTablePage
