import { ApiClient } from 'api/ApiClient'
import { LifecycleStageSpec } from '@edp/types'
import { getAuthType, getJWT } from 'api/utils'
import { handleResponse } from '@edp/core-common-frontend'

export async function createLifecycleStage(
  this: ApiClient,
  spec: LifecycleStageSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/lifecycle/stages`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getLifecycleStages(
  this: ApiClient
): Promise<LifecycleStageSpec[]> {
  const url = `${this.serverUrl}/lifecycle/stages`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getLifecycleStage(
  this: ApiClient,
  uuid: string
): Promise<LifecycleStageSpec> {
  const url = `${this.serverUrl}/lifecycle/stages/${uuid}`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function updateLifecycleStage(
  this: ApiClient,
  spec: LifecycleStageSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/lifecycle/stages/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function deleteLifecycleStage(
  this: ApiClient,
  uuid: string
): Promise<JSON> {
  const url = `${this.serverUrl}/lifecycle/stages/${uuid}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}
