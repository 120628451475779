import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectProps,
  CustomSelectComponentUUIDOption,
} from '@edp/core-common-frontend'
import { ApiClient } from 'api/ApiClient'
import { TeamSpec } from '@edp/types'

export interface TeamSelectProps extends CustomSelectProps {}

export const TeamSelect = (props: TeamSelectProps) => {
  const [teams, setTeams] = useState<TeamSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getTeams = async () => {
      try {
        const data = await apiClient.getTeams()
        setTeams(data)
      } catch (e) {
        console.log(e)
      }
    }

    getTeams()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    teams?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default TeamSelect
