import { handleResponse } from '@edp/core-common-frontend'
import { HelmRelease } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'

export async function getKubernetesNamespaceHelmReleases(
  this: ApiClient,
  uuid: string
): Promise<HelmRelease[]> {
  const url = `${this.serverUrl}/kubernetes/namespaces/${uuid}/helm-releases`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}
