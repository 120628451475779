import SwaggerUI from 'swagger-ui-react'
import { useState, useEffect } from 'react'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'
import 'swagger-ui-react/swagger-ui.css'
import { NothingFoundPage, useFetchService } from '@edp/core-common-frontend'
import { Grid, LinearProgress, Tab, Tabs } from '@mui/material'
import Branches from 'components/ServiceBranchesSelect/ServiceBranchesSelect'
import './styles.css'
import { ServiceSwaggerConfigType } from '@edp/types'

export const ApiPanel = () => {
  const { uuid } = useParams()
  const [apiSpec, setApiSpec] = useState<string>('')
  const [branch, setBranch] = useState<string>('main')
  const [apiSpecLinkEnvironment, setApiSpecLinkEnvironment] =
    useState<number>(0)
  const [apiSpecEnvironment, setApiSpecEnvironment] = useState<string>('')

  const service = useFetchService(uuid)

  const [loading, setLoading] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const isUnknownServiceSwaggerConfigType = (): boolean => {
    if (
      service?.swaggerConfigRef?.type === ServiceSwaggerConfigType.Repository
    ) {
      return false
    } else if (
      service?.swaggerConfigRef?.type === ServiceSwaggerConfigType.Link
    ) {
      return false
    } else {
      return true
    }
  }

  const handleChangeApiSpecLinkEnvironment = (
    event: React.SyntheticEvent,
    environment: number
  ) => {
    setApiSpecLinkEnvironment(environment)
  }

  const fetchApiSpecFromRepository = async () => {
    setApiSpec('')
    setLoading(true)

    try {
      if (uuid && branch) {
        const body = await apiClient.getServiceApiSpecificationByBranch(
          uuid,
          branch
        )
        setApiSpec(body)
      }
    } catch (e) {
      console.log('Ошибка при получении спецификации:', e)
    } finally {
      setLoading(false)
    }
  }

  const fetchApiSpecFromUrl = async () => {
    setApiSpec('')
    setLoading(true)

    try {
      if (
        service?.swaggerConfigRef?.type === ServiceSwaggerConfigType.Link &&
        uuid
      ) {
        const body = await apiClient.getServiceApiSpecificationByLink(
          uuid,
          apiSpecEnvironment
        )
        setApiSpec(body)
      }
    } catch (e) {
      console.log('Ошибка при получении данных: ', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (
      service?.swaggerConfigRef?.type === ServiceSwaggerConfigType.Repository
    ) {
      fetchApiSpecFromRepository()
    }

    if (service?.swaggerConfigRef?.type === ServiceSwaggerConfigType.Link) {
      if (service?.swaggerConfigRef?.links.length > 0) {
        setApiSpecEnvironment(service.swaggerConfigRef.links[0].environment)
      }
    }
  }, [uuid, branch, service])

  useEffect(() => {
    fetchApiSpecFromUrl()
  }, [apiSpecEnvironment])

  return (
    <>
      {service?.swaggerConfigRef?.type ===
        ServiceSwaggerConfigType.Repository && (
        <>
          <Grid className="ApiPanel-Grid-Button">
            <Branches selected={branch} setSelected={setBranch} />
          </Grid>
          {loading && <LinearProgress />}
          {apiSpec && !loading && <SwaggerUI spec={apiSpec} />}
          {!apiSpec && !loading && (
            <NothingFoundPage text="Попробуйте выбрать другую ветку или добавить в репозиторий сервиса спецификацию API в формате, поддерживаемом Swagger (Swagger/OpenAPI). Файл должен называться openapi-schema.yml и находиться в корне репозитория." />
          )}
        </>
      )}
      {service?.swaggerConfigRef?.type === ServiceSwaggerConfigType.Link && (
        <>
          <Tabs
            value={apiSpecLinkEnvironment}
            onChange={handleChangeApiSpecLinkEnvironment}
            variant="scrollable"
            scrollButtons="auto"
          >
            {service?.swaggerConfigRef?.links.map((spec) => (
              <Tab
                label={spec.environment}
                onClick={() => {
                  if (spec.environment) {
                    setApiSpecEnvironment(spec.environment)
                  } else {
                    setApiSpecEnvironment('')
                  }
                }}
              />
            ))}
          </Tabs>
          {loading && <LinearProgress />}
          {apiSpec && !loading && <SwaggerUI spec={apiSpec} />}
          {!apiSpec && !loading && (
            <>
              <NothingFoundPage text="Проверьте корректность ссылки для окружения в настройках сервиса. Ссылка должна вести на спецификацию API в формате json, поддерживаемом Swagger (Swagger/OpenAPI)." />
            </>
          )}
        </>
      )}
      {isUnknownServiceSwaggerConfigType() && (
        <>
          <NothingFoundPage text="Настройте источник конфигурации Swagger" />
        </>
      )}
    </>
  )
}

export default ApiPanel
