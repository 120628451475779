import Link from '@mui/material/Link'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from '@edp/core-common-frontend'
import {
  GitSecretSpec,
  GitSecretStatus,
  GitSecretStatusUpdateReqSpec,
} from 'types/Security'
import {
  FcOk,
  FcHighPriority,
  FcServices,
} from '@edp/core-common-frontend/dist/packages/react-icons/fc'
import { Grid } from '@mui/material'

export interface GitSecretsTableProps {
  secrets: Array<GitSecretSpec>
  setNeedUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

export const GitSecretsTable = (props: GitSecretsTableProps) => {
  const apiClient = new ApiClient()

  const handleChangeStatusSecret = async (id: number, status: string) => {
    try {
      const spec: GitSecretStatusUpdateReqSpec = {
        id: id,
        status: status,
      }

      await apiClient.updateStatusGitSecret(spec).then(() => {
        props.setNeedUpdate(true)
      })
    } catch (err) {
      console.log(err)
      if (err instanceof Error) {
        console.log(err)
      } else {
        console.log(err)
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Статус',
      minWidth: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Grid container>
            <Grid item xs={12} style={{ display: 'inline-flex' }}>
              {params.row.status === GitSecretStatus.Fixed && (
                <FcOk size={20} />
              )}
              {params.row.status === GitSecretStatus.Unfixed && (
                <FcHighPriority size={20} />
              )}
              {params.row.status === GitSecretStatus.FalsePositive && (
                <FcServices size={20} />
              )}
              <div style={{ marginLeft: '0.5em' }}>{params.row.status}</div>
            </Grid>
          </Grid>
        )
      },
    },
    {
      field: 'commit',
      headerName: 'Коммит',
      minWidth: 50,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Дата',
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: 'file',
      headerName: 'Файл',
      minWidth: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        let formattedRepositoryUrl = ''

        if (params.row.repositoryUrl.endsWith('.git')) {
          formattedRepositoryUrl = params.row.repositoryUrl.replace(
            /\.git$/,
            ''
          )
        } else {
          formattedRepositoryUrl = params.row.repositoryUrl
        }

        return (
          <Link
            href={`${formattedRepositoryUrl}/-/blob/${params.row.commit}/${params.row.file}#L${params.row.startLine}`}
          >
            {params.row.file}
          </Link>
        )
      },
    },
    {
      field: 'ruleId',
      headerName: 'Правило',
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: 'description',
      headerName: 'Опиcание',
      minWidth: 350,
      disableColumnMenu: true,
      sortable: false,
      resizable: true,
    },
    {
      field: 'falsePositive',
      headerName: '',
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.status !== GitSecretStatus.Fixed && (
              <>
                {params.row.status !== GitSecretStatus.FalsePositive && (
                  <CustomButton
                    size="small"
                    sx={{
                      width: '120px',
                    }}
                    onClick={() =>
                      handleChangeStatusSecret(
                        Number(params.row.id),
                        GitSecretStatus.FalsePositive
                      )
                    }
                  >
                    Ложное
                  </CustomButton>
                )}
                {params.row.status === GitSecretStatus.FalsePositive && (
                  <CustomButton
                    size="small"
                    sx={{
                      width: '120px',
                    }}
                    onClick={() =>
                      handleChangeStatusSecret(
                        Number(params.row.id),
                        GitSecretStatus.Unfixed
                      )
                    }
                  >
                    Корректное
                  </CustomButton>
                )}
              </>
            )}
          </>
        )
      },
    },
    {
      field: 'solved',
      headerName: '',
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.status !== GitSecretStatus.FalsePositive && (
              <>
                {params.row.status === GitSecretStatus.Unfixed && (
                  <CustomButton
                    size="small"
                    sx={{
                      width: '150px',
                    }}
                    onClick={() =>
                      handleChangeStatusSecret(
                        Number(params.row.id),
                        GitSecretStatus.Fixed
                      )
                    }
                  >
                    Исправлено
                  </CustomButton>
                )}
                {params.row.status === GitSecretStatus.Fixed && (
                  <CustomButton
                    size="small"
                    sx={{
                      width: '150px',
                    }}
                    onClick={() =>
                      handleChangeStatusSecret(
                        Number(params.row.id),
                        GitSecretStatus.Unfixed
                      )
                    }
                  >
                    Не исправлено
                  </CustomButton>
                )}
              </>
            )}
          </>
        )
      },
    },
  ]

  const rows = props.secrets.map((data) => {
    return {
      id: data.id,
      status: data.status,
      repositoryUrl: data.repositoryUrl,
      description: data.secret.Description,
      startLine: data.secret.StartLine,
      file: data.secret.File,
      commit: data.secret.Commit,
      date: data.secret.Date,
      ruleId: data.secret.RuleID,
    }
  })

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSize={5}
        rowsPerPageOptions={[5]}
        autoPageSize={true}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'date',
                sort: 'desc',
              },
            ],
          },
        }}
      />
    </div>
  )
}

export default GitSecretsTable
