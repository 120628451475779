import { ApiClient } from 'api/ApiClient'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { EntityAction } from 'types/entities'
import { useEffect, useState } from 'react'
import LifecycleStageStore from './store'
import { EditComponentPage } from 'components/EditComponentPage'
import { LifecycleStageSpec } from '@edp/types'
import BasicInfo from './BasicInfo'

export interface EditLifecycleStagePageProps {
  action: EntityAction
}

export const EditLifecycleStagePage = observer(
  (props: EditLifecycleStagePageProps) => {
    const { uuid } = useParams()
    const navigate = useNavigate()

    const [edited, setEdited] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [failed, setFailed] = useState<boolean>(false)
    const apiClient = new ApiClient()

    useEffect(() => {
      LifecycleStageStore.reset()
    }, [])

    const fetchData = async () => {
      try {
        if (uuid) {
          const data = await apiClient.getLifecycleStage(uuid)
          LifecycleStageStore.set(data)
        }
      } catch (e) {
        console.error('Ошибка при получении данных:', e)
      }
    }

    useEffect(() => {
      fetchData()
    }, [uuid])

    const handleEdit = async () => {
      setEdited(false)
      setFailed(false)
      setErrorMessage('')
      setLoading(true)

      try {
        await LifecycleStageStore.validate().then(({ isValid }) => {
          if (!isValid) {
            setFailed(true)
          }
        })

        const lifecycleStage: LifecycleStageSpec = {
          name: LifecycleStageStore.$('name').value,
          description: LifecycleStageStore.$('description').value,
        }

        if (props.action === EntityAction.Create) {
          await apiClient.createLifecycleStage(lifecycleStage)
        }

        if (props.action === EntityAction.Update) {
          lifecycleStage.uuid = uuid
          await apiClient.updateLifecycleStage(lifecycleStage)
        }

        setEdited(true)
        navigate('/settings/lifecycle')
      } catch (e) {
        setFailed(true)
        if (e instanceof Error) {
          setErrorMessage(e.message)
        } else {
          setErrorMessage('Неизвестная ошибка')
        }
      } finally {
        setLoading(false)
      }
    }

    let editedMessage

    if (props.action === EntityAction.Create) {
      editedMessage = 'Стадия жизненного цикла создана'
    }

    if (props.action === EntityAction.Update) {
      editedMessage = 'Стадия жизненного цикла обновлена'
    }

    return (
      <>
        <EditComponentPage
          action={props.action}
          handleEdit={handleEdit}
          loading={loading}
          edited={edited}
          setEdited={setEdited}
          failed={failed}
          setFailed={setFailed}
          errorMessage={errorMessage}
          editedMessage={editedMessage}
        >
          <BasicInfo />
        </EditComponentPage>
      </>
    )
  }
)

export default EditLifecycleStagePage
