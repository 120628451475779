import React, { useEffect, useState } from 'react'

import { Box, LinearProgress, Typography } from '@mui/material'
import {
  GridColDef,
  DataGrid,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { KeycloakUser } from 'types/Keycloak'
import { ApiClient } from 'api/ApiClient'
import { useParams } from 'react-router-dom'

export const DataGridTitle = () => {
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10px',
        marginBottom: '10px',
      }}
    >
      <Typography
        variant="body1"
        component="div"
        sx={{
          fontWeight: '600',
          textAlign: 'center',
        }}
      >
        Пользователи в Keycloak
      </Typography>
    </Box>
  )
}

export const KeycloakGroupMembersTable = () => {
  const { uuid } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [members, setMembers] = useState<KeycloakUser[]>([])

  useEffect(() => {
    const apiClient = new ApiClient()

    const getMembers = async () => {
      try {
        setLoading(true)
        const data = await apiClient.getKeycloakGroupMembers(uuid || '')
        setMembers(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getMembers()
  }, [uuid])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', minWidth: 50 },
    { field: 'username', headerName: 'Username', minWidth: 150 },
    {
      field: 'firstName',
      headerName: 'Имя',
      minWidth: 150,
    },
    {
      field: 'lastName',
      headerName: 'Фамилия',
      minWidth: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
    },
  ]

  const rows = members.map((member) => {
    return {
      id: member.id,
      username: member.username,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
    }
  })

  return (
    <div
      style={{
        paddingTop: '20px',
        marginBottom: '10px',
      }}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <DataGrid
          getRowId={(row) => row.id}
          columns={columns}
          rows={rows}
          pageSize={5}
          rowsPerPageOptions={[5]}
          autoPageSize
          autoHeight={true}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{ Toolbar: DataGridTitle }}
        />
      )}
    </div>
  )
}

export default KeycloakGroupMembersTable
