import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import { React } from '@edp/core-common-frontend/dist/packages/react'
import { Managers, TerraformActionRecordSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { observer } from 'mobx-react'
import './styles.css'
import TerraformActionsTable from './tables/TerraformActionsTable'

export interface TerraformPanelProps {
  uuid?: string
}

export const TerraformPanel = observer((props: TerraformPanelProps) => {
  const [terraformActionRecords, setTerraformActionRecords] = React.useState<
    TerraformActionRecordSpec[]
  >([])
  const [failed, setFailed] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [updated, setUpdated] = React.useState<boolean>(false)
  const [opened, setOpened] = React.useState<boolean>(false)

  const apiClient = new ApiClient()

  React.useEffect(() => {
    const getTerraformActionRecords = async () => {
      try {
        setTerraformActionRecords([])

        const data = await apiClient.getTerraformActionRecords(
          '6b67d375-7d96-4059-9ff6-dea5a04b4f9f',
          Managers.Service,
          props.uuid || ''
        )
        setTerraformActionRecords(data)
      } catch (e) {
        console.error(e)
      }
    }

    getTerraformActionRecords()
  }, [updated])

  const handleOpen = async () => {
    setOpened(true)
  }

  /** 
  const handleConverge = async () => {
    try {
      setFailed(false)
      setErrorMessage('')

      for (const serviceTerraformAction of serviceTerraformActions) {
        const terraformActionRecord: TerraformActionRecordSpec = {
          uuid: '',
          terraformAction: serviceTerraformAction,
          status: '',
          entityKind: Managers.Service,
          entityUUID: props.uuid || '',
          state: '',
          logs: '',
          variables: TerraformActionStore.$('variables').value,
        }

        await apiClient.terraformActionRun(
          serviceTerraformAction,
          terraformActionRecord
        )
      }
    } catch (e) {
      setErrorMessage(String(e))
      setFailed(true)
      console.error(e)
    }
  }
  */

  return (
    <>
      {/** 
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'flex-start',
          marginTop: '30px',
          marginBottom: '10px',
          gap: '10px',
        }}
      >

        <CustomButton
          sx={{
            width: '100px',
          }}
          onClick={handleConverge}
        >
          converge
        </CustomButton>

      </Grid>
        */}
      {/** 
      <TerraformActionRecordsTable
        terraformActionRecords={terraformActionRecords}
      />
      */}
      <TerraformActionsTable uuid={props.uuid || ''} />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
})

export default TerraformPanel
