import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Tooltip, Typography } from '@mui/material'
import { ServiceVersionSelect } from '../ServiceVersionSelect'

export interface UserInteractionServiceVersionSelectProps {
  uuid: string
  name: string
  description: string
  helperText: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  error?: boolean
}

const UserInteractionServiceVersionSelect = (
  props: UserInteractionServiceVersionSelectProps
) => {
  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {props.description}
        </Typography>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <ServiceVersionSelect
        uuid={props.uuid}
        selected={props.selected}
        setSelected={props.setSelected}
        error={props.error}
      />
    </Grid>
  )
}

export default UserInteractionServiceVersionSelect
