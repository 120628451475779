import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import { AclEntry, AclFilter, DescribeAclResource, ITopicConfig } from 'kafkajs'
import { KafkaTopic } from 'types/Controllers/Kafka'
import { KafkaClusterSpec } from 'types/Managers/KafkaClusters'
import { handleResponse } from '@edp/core-common-frontend'

export async function createKafkaCluster(
  this: ApiClient,
  spec: KafkaClusterSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function updateKafkaCluster(
  this: ApiClient,
  spec: KafkaClusterSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getKafkaClusters(
  this: ApiClient
): Promise<KafkaClusterSpec[]> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await handleResponse(response)
}

export async function getKafkaClustersBy(
  this: ApiClient,
  filterName: string,
  filterValue: string
): Promise<KafkaClusterSpec[]> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters?filterName=${filterName}&filterValue=${filterValue}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await handleResponse(response)
}

export async function getKafkaCluster(
  this: ApiClient,
  uuid: string
): Promise<KafkaClusterSpec> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await handleResponse(response)
}

export async function deleteKafkaCluster(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await handleResponse(response)
}

export async function createKafkaTopic(
  this: ApiClient,
  cluster: string,
  spec: ITopicConfig
) {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/topics`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}

export async function createKafkaAcl(
  this: ApiClient,
  cluster: string,
  spec: AclEntry
) {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/acls`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}

export async function getKafkaAcls(
  this: ApiClient,
  cluster: string,
  spec: AclFilter
): Promise<DescribeAclResource[]> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/acls/describe`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}

export async function deleteKafkaAcl(
  this: ApiClient,
  cluster: string,
  spec: AclFilter
): Promise<any> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/acls`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}

export async function getKafkaTopics(
  this: ApiClient,
  cluster: string
): Promise<KafkaTopic[]> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/topics`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}

export async function getKafkaTopic(
  this: ApiClient,
  cluster: string,
  name: string
): Promise<KafkaTopic> {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/topics/${name}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}

export async function updateKafkaTopic(
  this: ApiClient,
  cluster: string,
  spec: ITopicConfig
) {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/topics/${spec.topic}`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}

export async function deleteKafkaTopic(
  this: ApiClient,
  cluster: string,
  name: string
) {
  const url = `${this.serverUrl}/platform-services/kafka/clusters/${cluster}/topics/${name}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}
