import { Box, Grid } from '@mui/material'
import { PlatformServiceTemplateSpec } from 'types/Managers/PlatformServiceTemplates'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from '@edp/core-common-frontend'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import useFetchPlatformServiceTemplate from 'hooks/useFetchPlatformServiceTemplate'
import { NothingFoundPage } from '@edp/core-common-frontend'

export const PlatformServiceTemplatesPage = () => {
  const [platformServiceTemplates, setPlatformServiceTemplates] =
    useState<PlatformServiceTemplateSpec[]>()
  const [
    filteredPlatformServiceTemplates,
    setFilteredPlatformServiceTemplates,
  ] = useState<PlatformServiceTemplateSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [platformServiceTemplateToDelete, setPlatformServiceTemplateToDelete] =
    useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()
  const getPlatformServiceTemplates = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getPlatformServiceTemplates()
      setPlatformServiceTemplates(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPlatformServiceTemplates()
  }, [])

  useEffect(() => {
    const e: PlatformServiceTemplateSpec[] = []

    if (platformServiceTemplates && owner !== '') {
      for (const platformServiceTemplate of platformServiceTemplates) {
        if (platformServiceTemplate.owner === owner) {
          e.push(platformServiceTemplate)
        }
      }
      setFilteredPlatformServiceTemplates(e)
    }

    if (owner === '') {
      setFilteredPlatformServiceTemplates(platformServiceTemplates)
    }
  }, [platformServiceTemplates, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setPlatformServiceTemplateToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient
        .deletePlatformServiceTemplate(platformServiceTemplateToDelete)
        .then(() => {
          setDeleted(true)
          getPlatformServiceTemplates()
          setDeletionWindowOpened(false)
        })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const platformServiceTemplateToDeleteSpec = useFetchPlatformServiceTemplate(
    platformServiceTemplateToDelete
  )

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/platform-services/templates/create'}
          >
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {filteredPlatformServiceTemplates?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новый шаблон платформенного сервиса, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredPlatformServiceTemplates?.map(
          (platformServiceTemplate, index) => (
            <ComponentCard
              key={index}
              name={platformServiceTemplate.name}
              uuid={platformServiceTemplate.uuid}
              description={platformServiceTemplate.description}
              options={[
                {
                  name: 'Владелец',
                  value: `${platformServiceTemplate.ownerRef?.name}`,
                },
                {
                  name: 'Helm chart',
                  value: `${platformServiceTemplate.helmChart}`,
                },
                {
                  name: 'Версия Helm chart',
                  value: `${platformServiceTemplate.helmVersion}`,
                },
              ]}
              linkOpen={`/platform-services/templates/${platformServiceTemplate.uuid}`}
              linkUpdate={`/platform-services/templates/update/${platformServiceTemplate.uuid}`}
              handleDelete={handleOpenDeletionWindow}
            />
          )
        )}
        <DeletionWindow
          title={'Удаление шаблона'}
          text={`Шаблон платформенного сервиса ${platformServiceTemplateToDeleteSpec?.name} будет удален без возможности восстановления.`}
          spec={platformServiceTemplateToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Шаблон платформенного сервиса ${platformServiceTemplateToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении шаблона платформенного сервиса ${platformServiceTemplateToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default PlatformServiceTemplatesPage
