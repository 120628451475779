import { useFetchService } from '@edp/core-common-frontend'
import {
  History,
  TrendingFlat,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Chip,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import {
  MdCalendarToday,
  MdLink,
  MdOutlineLock,
  MdOutlineLockOpen,
  MdOutlineTimer,
} from '@edp/core-common-frontend/dist/packages/react-icons/md'
import {
  TerraformActionRecordSpec,
  TerraformActionRecordStage,
  TerraformActionRecordStatus,
  TerraformActionSpec,
} from '@edp/types'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import moment from 'moment'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { RunButton } from '../buttons/RunButton'
import LogsDialog from '../dialogs/LogsDialog'
import { StatusColors } from '../types'
import { getDuration } from '../utils'
import './styles.css'

export interface TerraformActionsTableProps {
  uuid: string
}

export interface TerraformActionRecords {
  terraformAction: TerraformActionSpec
  terraformActionRecords: TerraformActionRecordSpec[]
  lastTerraformActionRecord?: TerraformActionRecordSpec
}

const getLastTerraformActionRecord = (
  terraformActionRecords: TerraformActionRecordSpec[]
): TerraformActionRecordSpec | undefined => {
  try {
    if (terraformActionRecords.length === 0) {
      return undefined
    }

    const sortedRecords = terraformActionRecords.sort((a, b) => {
      const dateA = Date.parse(String(a.createdAt!))
      const dateB = Date.parse(String(b.createdAt!))

      return dateB - dateA
    })

    const last = sortedRecords[0]

    return last
  } catch (e) {
    return undefined
  }
}

export const TerraformActionsTable = (props: TerraformActionsTableProps) => {
  const [logsOpened, setLogsOpened] = React.useState<boolean>(false)
  const [updated, setUpdated] = React.useState<boolean>(false)
  const [terraformActionRecordUUID, setTerraformActionRecordUUID] =
    React.useState<string>('')
  const [terraformActionRecords, setTerraformActionRecords] = React.useState<
    TerraformActionRecords[]
  >([])
  const [stage, setStage] = React.useState<TerraformActionRecordStage>()

  const apiClient = new ApiClient()
  const service = useFetchService(props.uuid)

  const handleLogsOpen = (
    terraformActionRecordUUID: string,
    stage: TerraformActionRecordStage
  ) => {
    setTerraformActionRecordUUID(terraformActionRecordUUID)
    setStage(stage)
    setLogsOpened(true)
  }

  const fetchTerraformActions = async () => {
    setTerraformActionRecords([])
    const tar: TerraformActionRecords[] = []

    try {
      const serviceTerraformActions = service?.terraformActions || []

      for (const serviceTerraformAction of serviceTerraformActions) {
        const action = await apiClient.getTerraformAction(
          serviceTerraformAction
        )
        const records = await apiClient.getTerraformActionRecords(
          serviceTerraformAction,
          'Service',
          props.uuid
        )

        const actionRecords: TerraformActionRecords = {
          terraformAction: action,
          terraformActionRecords: records,
          lastTerraformActionRecord: getLastTerraformActionRecord(records),
        }
        tar.push(actionRecords)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setTerraformActionRecords(tar)
    }
  }

  React.useEffect(() => {
    fetchTerraformActions()
  }, [service, updated])

  const columns: GridColDef[] = [
    {
      field: 'controls',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        return (
          <RunButton
            uuid={props.uuid}
            terraformAction={params.row.terraformAction}
            terraformActionRecord={params.row.lastTerraformActionRecord}
            updated={updated}
            setUpdated={setUpdated}
          />
        )
      },
    },
    {
      field: 'status',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        return (
          params.row.lastTerraformActionRecord && (
            <div style={{ paddingBottom: '5px', paddingTop: '5px' }}>
              <div
                style={{
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Chip
                  label={params.row.lastTerraformActionRecord.status}
                  size="small"
                  variant="filled"
                  icon={React.cloneElement(
                    StatusColors[
                      params.row.lastTerraformActionRecord
                        .status as TerraformActionRecordStatus
                    ].icon,
                    {
                      style: {
                        color:
                          StatusColors[
                            params.row.lastTerraformActionRecord
                              .status as TerraformActionRecordStatus
                          ].color,
                      },
                    }
                  )}
                  sx={{
                    minWidth: '90px',
                    backgroundColor:
                      StatusColors[
                        params.row.lastTerraformActionRecord
                          .status as TerraformActionRecordStatus
                      ].backgroundColor,
                    color:
                      StatusColors[
                        params.row.lastTerraformActionRecord
                          .status as TerraformActionRecordStatus
                      ].color,
                    border: 'none',
                    justifyContent: 'left',
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdOutlineTimer />
                <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                  {getDuration(
                    String(params.row.lastTerraformActionRecord.createdAt),
                    String(params.row.lastTerraformActionRecord.updatedAt)
                  )}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdCalendarToday />
                <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                  {moment(
                    params.row.lastTerraformActionRecord.createdAt
                  ).format('DD.MM.YYYY HH:mm:ss')}
                </Typography>
              </div>
            </div>
          )
        )
      },
    },
    {
      field: 'terraformAction',
      headerName: 'Terraform action',
      minWidth: 200,
      renderCell: (params) => (
        <RouterLink
          className="Table-Link"
          to={`/components/terraform-actions/${params.row.terraformAction.uuid}`}
        >
          {params.row.terraformAction.name}
        </RouterLink>
      ),
    },
    {
      field: 'logs',
      headerName: '',
      minWidth: 450,
      renderCell: (params) => {
        return (
          params.row.lastTerraformActionRecord && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Chip
                clickable
                size="small"
                label="validate"
                onClick={() =>
                  handleLogsOpen(
                    params.row.lastTerraformActionRecord.uuid,
                    TerraformActionRecordStage.Validate
                  )
                }
                icon={React.cloneElement(
                  StatusColors[
                    params.row.lastTerraformActionRecord.stages.validate
                      .status as TerraformActionRecordStatus
                  ].icon,
                  {
                    style: {
                      color:
                        StatusColors[
                          params.row.lastTerraformActionRecord.stages.validate
                            .status as TerraformActionRecordStatus
                        ].color,
                    },
                  }
                )}
                sx={{
                  minWidth: '80px',
                  backgroundColor:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.validate
                        .status as TerraformActionRecordStatus
                    ].backgroundColor,
                  color:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.validate
                        .status as TerraformActionRecordStatus
                    ].color,
                  border: 'none',
                  justifyContent: 'left',
                }}
              />
              <TrendingFlat sx={{ paddingRight: '0px' }} fontSize="medium" />
              <Chip
                clickable
                size="small"
                label="plan"
                onClick={() =>
                  handleLogsOpen(
                    params.row.lastTerraformActionRecord.uuid,
                    TerraformActionRecordStage.Plan
                  )
                }
                icon={React.cloneElement(
                  StatusColors[
                    params.row.lastTerraformActionRecord.stages.plan
                      .status as TerraformActionRecordStatus
                  ].icon,
                  {
                    style: {
                      color:
                        StatusColors[
                          params.row.lastTerraformActionRecord.stages.plan
                            .status as TerraformActionRecordStatus
                        ].color,
                    },
                  }
                )}
                sx={{
                  minWidth: '80px',
                  backgroundColor:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.plan
                        .status as TerraformActionRecordStatus
                    ].backgroundColor,
                  color:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.plan
                        .status as TerraformActionRecordStatus
                    ].color,
                  border: 'none',
                  justifyContent: 'left',
                }}
              />
              <TrendingFlat sx={{ paddingRight: '0px' }} fontSize="medium" />
              <Chip
                clickable
                size="small"
                icon={React.cloneElement(
                  StatusColors[
                    params.row.lastTerraformActionRecord.stages.apply
                      .status as TerraformActionRecordStatus
                  ].icon,
                  {
                    style: {
                      color:
                        StatusColors[
                          params.row.lastTerraformActionRecord.stages.apply
                            .status as TerraformActionRecordStatus
                        ].color,
                    },
                  }
                )}
                sx={{
                  minWidth: '80px',
                  backgroundColor:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.apply
                        .status as TerraformActionRecordStatus
                    ].backgroundColor,
                  color:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.apply
                        .status as TerraformActionRecordStatus
                    ].color,
                  border: 'none',
                  justifyContent: 'left',
                }}
                label="apply"
                onClick={() =>
                  handleLogsOpen(
                    params.row.lastTerraformActionRecord.uuid,
                    TerraformActionRecordStage.Apply
                  )
                }
              />
              <TrendingFlat sx={{ paddingRight: '0px' }} fontSize="medium" />
              <Chip
                clickable
                size="small"
                label="destroy"
                onClick={() =>
                  handleLogsOpen(
                    params.row.lastTerraformActionRecord.uuid,
                    TerraformActionRecordStage.Destroy
                  )
                }
                icon={React.cloneElement(
                  StatusColors[
                    params.row.lastTerraformActionRecord.stages.destroy
                      .status as TerraformActionRecordStatus
                  ].icon,
                  {
                    style: {
                      color:
                        StatusColors[
                          params.row.lastTerraformActionRecord.stages.destroy
                            .status as TerraformActionRecordStatus
                        ].color,
                    },
                  }
                )}
                sx={{
                  minWidth: '80px',
                  backgroundColor:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.destroy
                        .status as TerraformActionRecordStatus
                    ].backgroundColor,
                  color:
                    StatusColors[
                      params.row.lastTerraformActionRecord.stages.destroy
                        .status as TerraformActionRecordStatus
                    ].color,
                  border: 'none',
                  justifyContent: 'left',
                }}
              />
            </div>
          )
        )
      },
    },
    {
      field: 'description',
      headerName: 'Описание',
      width: 500,
      renderCell: (params) => {
        return (
          <Typography
            variant="caption"
            sx={{ paddingBottom: '5px', paddingTop: '5px' }}
          >
            {params.row.terraformAction.description}
          </Typography>
        )
      },
    },
    {
      field: 'state',
      headerName: 'Terraform state',
      minWidth: 150,
      renderCell: (params) => {
        return (
          params.row.lastTerraformActionRecord && (
            <div style={{ paddingBottom: '5px', paddingTop: '5px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdLink />

                <Link
                  href={params.row.lastTerraformActionRecord.state}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                    url
                  </Typography>
                </Link>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdOutlineLock />

                <Link
                  href={params.row.lastTerraformActionRecord.state}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                    lock
                  </Typography>
                </Link>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdOutlineLockOpen />
                <Link
                  href={params.row.lastTerraformActionRecord.state}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                    unlock
                  </Typography>
                </Link>
              </div>
            </div>
          )
        )
      },
    },
    {
      field: 'history',
      headerName: 'История',
      width: 80,
      renderCell: (params) => {
        return (
          <IconButton>
            <Tooltip
              arrow
              title={`История запусков Terraform action`}
              placement="top"
            >
              <div>
                <History />
              </div>
            </Tooltip>
          </IconButton>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: 'createdAt',
    },
  ]

  const rows = terraformActionRecords.map(
    (terraformActionRecords: TerraformActionRecords) => {
      return {
        terraformAction: terraformActionRecords.terraformAction,
        lastTerraformActionRecord:
          terraformActionRecords.lastTerraformActionRecord,
      }
    }
  )

  return (
    <>
      <DataGrid
        getRowId={(row) => row.terraformAction.uuid}
        columns={columns}
        rows={rows}
        pageSize={8}
        rowsPerPageOptions={[8]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        autoPageSize
        autoHeight={true}
        onCellClick={() => {}}
        getRowHeight={() => 'auto'}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              createdAt: false,
            },
          },
        }}
        sx={{
          minWidth: '100%',
        }}
      />
      <LogsDialog
        opened={logsOpened}
        setOpened={setLogsOpened}
        updated={updated}
        setUpdated={setUpdated}
        terraformActionRecordUUID={terraformActionRecordUUID}
        stage={stage!}
      />
    </>
  )
}

export default TerraformActionsTable
