import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import { ResponseStatusText } from 'common/responseStatus'
import { CredentialsBaseSpec, CredentialsReqSpec } from 'types/Credentials'
import { handleResponse } from '@edp/core-common-frontend'

export async function createCredentials(
  this: ApiClient,
  spec: CredentialsReqSpec
): Promise<ResponseStatusText> {
  const url = `${this.serverUrl}/credentials`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  const status: Promise<ResponseStatusText> = await handleResponse(response)

  return status
}

export async function getCredentials(
  this: ApiClient,
  uuid: string
): Promise<CredentialsBaseSpec> {
  const url = `${this.serverUrl}/credentials/${uuid}`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getCredentialsArray(
  this: ApiClient
): Promise<Array<CredentialsBaseSpec>> {
  const url = `${this.serverUrl}/credentials`

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function deleteCredentials(
  this: ApiClient,
  uuid: string
): Promise<JSON> {
  const url = `${this.serverUrl}/credentials/${uuid}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}
