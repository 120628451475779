import { useEffect, useState } from 'react'

import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
  CustomSelectProps,
} from '@edp/core-common-frontend'
import { Action } from 'types/Managers/Actions'
import { ApiClient } from 'api/ApiClient'

export interface ActionSelectProps extends CustomSelectProps {}

export const ActionSelect = (props: ActionSelectProps) => {
  const [actions, setActions] = useState<Action[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getActions = async () => {
      try {
        const data = await apiClient.getActions()
        setActions(data)
      } catch (e) {
        console.log(e)
      }
    }

    getActions()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    actions?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default ActionSelect
