import { Box, Grid } from '@mui/material'
import { KafkaClusterSpec } from 'types/Managers/KafkaClusters'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from '@edp/core-common-frontend'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import useFetchKafkaCluster from 'hooks/useFetchKafkaCluster'
import { NothingFoundPage } from '@edp/core-common-frontend'

export const KafkaClustersPage = () => {
  const [kafkaClusters, setKafkaClusters] = useState<KafkaClusterSpec[]>([])
  const [filteredKafkaClusters, setFilteredKafkaClusters] =
    useState<KafkaClusterSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [kafkaClusterToDelete, setKafkaClusterToDelete] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getKafkaClusters = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getKafkaClusters()
      setKafkaClusters(data)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getKafkaClusters()
  }, [])

  useEffect(() => {
    const s: KafkaClusterSpec[] = []

    if (kafkaClusters && owner !== '') {
      for (const kafkaCluster of kafkaClusters) {
        if (kafkaCluster.owner === owner) {
          s.push(kafkaCluster)
        }
      }
      setFilteredKafkaClusters(s)
    }

    if (owner === '') {
      setFilteredKafkaClusters(kafkaClusters)
    }
  }, [kafkaClusters, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setKafkaClusterToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteKafkaCluster(kafkaClusterToDelete).then(() => {
        setDeleted(true)
        getKafkaClusters()
        setDeletionWindowOpened(false)
      })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const kafkaClusterToDeleteSpec = useFetchKafkaCluster(kafkaClusterToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            gap: '5px',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/platform-services/kafka/clusters/create'}
          >
            Зарегистрировать
          </CustomButton>
        </Box>
      </Grid>
      {filteredKafkaClusters?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте зарегистрировать новый кластер Kafka, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredKafkaClusters?.map((kafkaCluster, index) => (
          <ComponentCard
            key={index}
            name={kafkaCluster.name}
            uuid={kafkaCluster.uuid}
            description={kafkaCluster.description}
            options={[
              {
                name: 'Владелец',
                value: kafkaCluster.ownerRef?.name,
              },
              ...kafkaCluster.brokers.map((broker, index) => ({
                name: `Брокер ${index + 1}`,
                value: broker,
              })),
            ]}
            linkOpen={`/platform-services/kafka/clusters/${kafkaCluster.uuid}`}
            linkUpdate={`/platform-services/kafka/clusters/update/${kafkaCluster.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление кластера Kafka'}
          text={`Кластер Kafka ${kafkaClusterToDeleteSpec?.name} будет удален из EDP.`}
          additionalText={`Кластер Kafka будет удален только из EDP. Удаление из EDP не повлияет на конфигурацию кластера.`}
          spec={kafkaClusterToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Кластер Kafka ${kafkaClusterToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении кластера Kafka ${kafkaClusterToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default KafkaClustersPage
