import { ComponentCard } from 'components/ComponentCard'
import { Action, ActionType } from 'types/Managers/Actions'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { Box, LinearProgress } from '@mui/material'
import { NothingFoundPage } from '@edp/core-common-frontend'
import { ServiceTemplate } from '@edp/types'

interface ActionsPanelProps {
  serviceTemplate?: ServiceTemplate
}

export const ActionsPanel = (props: ActionsPanelProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const [serviceTemplateActions, setServiceTemplateActions] = useState<
    Action[]
  >([])
  const apiClient = new ApiClient()

  const fetchActions = async () => {
    try {
      setLoading(true)

      if (props.serviceTemplate && props.serviceTemplate.actions) {
        let filteredActions: Action[] = []

        const actions = await apiClient.getActions()

        for (const serviceTemplateAction of props.serviceTemplate.actions) {
          const filteredAction = actions.find(
            (action) => action.uuid === serviceTemplateAction
          )
          if (filteredAction) {
            filteredActions = [...filteredActions, filteredAction]
          }

          setServiceTemplateActions(filteredActions)
        }
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchActions()
  }, [props.serviceTemplate])

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && serviceTemplateActions.length == 0 && (
        <NothingFoundPage text="Попробуйте добавить Action к шаблону сервиса." />
      )}
      {!loading && serviceTemplateActions.length > 0 && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
            gridAutoRows: '1fr',
            gridGap: 10,
          }}
        >
          {serviceTemplateActions?.map((action, index) => (
            <ComponentCard
              key={index}
              name={action.name}
              uuid={action.uuid}
              description={action.description}
              options={[
                {
                  name: 'Идентификатор',
                  value: action?.slug,
                },
                {
                  name: 'Владелец',
                  value: action.ownerRef?.name,
                  isUrl: true,
                  externalUrl: false,
                  url: `/teams/${action.ownerRef?.uuid}`,
                },
                {
                  name: 'Тип',
                  value: action.type,
                },
                ...([ActionType.GitlabPipeline, ActionType.GitlabMR].includes(
                  action.type
                )
                  ? [
                      {
                        name: 'Репозиторий',
                        isUrl: true,
                        value: action.repositoryRef?.name,
                        url: `/components/repositories/${action.repositoryRef?.uuid}`,
                        externalUrl: false,
                      },
                      {
                        name: 'Репозиторий в Gitlab',
                        isUrl: true,
                        value: action.repositoryRef?.slug,
                        url: action.repositoryRef?.url,
                        externalUrl: true,
                      },
                    ]
                  : []),
                ...([ActionType.GitlabMR, ActionType.KrakendEndpoint].includes(
                  action.type
                )
                  ? [
                      {
                        name: 'Целевой репозиторий',
                        isUrl: true,
                        value: action.targetRepositoryRef?.name,
                        url: `/components/repositories/${action.targetRepositoryRef?.uuid}`,
                        externalUrl: false,
                      },
                      {
                        name: 'Целевой репозиторий в Gitlab',
                        isUrl: true,
                        value: action.targetRepositoryRef?.slug,
                        url: action.targetRepositoryRef?.url,
                        externalUrl: true,
                      },
                      {
                        name: 'Ветка целевого репозитория',
                        value: action.targetBranch,
                      },
                    ]
                  : []),
              ]}
              linkOpen={`/components/actions/${action.uuid}`}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default ActionsPanel
