import { observer } from 'mobx-react-lite'
import { Typography } from '@mui/material'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import LifecycleStageStore from './store'

const BasicInfo = observer(() => {
  return (
    <>
      <Typography variant="body1" className="Typography-Body1">
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название стадии жизненного цикла."
        error={!LifecycleStageStore.$('name').isValid}
        {...LifecycleStageStore.$('name').bind()}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание стадии жизненного цикла."
        multiline
        rows={6}
        height={'150px'}
        {...LifecycleStageStore.$('description').bind()}
      />
    </>
  )
})

export default BasicInfo
