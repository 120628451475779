import {
  AiOutlineAudit,
  AiOutlineInfoCircle,
} from '@edp/core-common-frontend/dist/packages/react-icons/ai'
import { GrVulnerability } from '@edp/core-common-frontend/dist/packages/react-icons/gr'
import { MdOutlineDescription } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import {
  SiGitlab,
  SiGrafana,
  SiKubernetes,
  SiSwagger,
  SiTerraform,
  SiVault,
} from '@edp/core-common-frontend/dist/packages/react-icons/si'
import {
  TbLicense,
  TbReportSearch,
} from '@edp/core-common-frontend/dist/packages/react-icons/tb'
import { AllurePanel } from '@edp/plugin-allure-panel-frontend'
import { DefectDojoPanel } from '@edp/plugin-defectdojo-panel-frontend'
import { GrafanaPanel } from '@edp/plugin-grafana-panel-frontend'
import { KubernetesPanel } from '@edp/plugin-kubernetes-panel-frontend'
import { ComponentPage } from 'components/ComponentPage'
import { OperationsPanel } from 'components/OperationsPanel/OperationsPanel'
import { useParams } from 'react-router-dom'
import ApiPanel from './ApiPanel/ApiPanel'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'
import { CiCdPanel } from './CiCdPanel/CiCdPanel'
import ReadmePanel from './ReadmePanel/ReadmePanel'
import SbomPanel from './SbomPanel/SbomPanel'
import { SecretsPanel } from './SecretsPanel/SecretsPanel'
import TerraformPanel from './TerraformPanel/TerraformPanel'

export const ServicePage = () => {
  const { uuid } = useParams()

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
      icon: <AiOutlineInfoCircle />,
    },
    {
      name: 'README',
      index: 1,
      children: <ReadmePanel uuid={uuid ?? ''} />,
      icon: <MdOutlineDescription />,
    },
    {
      name: 'CI/CD',
      index: 2,
      children: <CiCdPanel uuid={uuid ?? ''} />,
      icon: <SiGitlab />,
    },
    {
      name: 'Kubernetes',
      index: 3,
      children: <KubernetesPanel uuid={uuid ?? ''} />,
      icon: <SiKubernetes />,
    },
    {
      name: 'Секреты',
      index: 4,
      children: <SecretsPanel />,
      icon: <SiVault />,
    },
    {
      name: 'Мониторинг',
      index: 5,
      children: <GrafanaPanel uuid={uuid ?? ''} />,
      hidden: false,
      icon: <SiGrafana />,
    },
    {
      name: 'DefectDojo',
      index: 6,
      children: <DefectDojoPanel uuid={uuid ?? ''} />,
      icon: <GrVulnerability />,
    },
    {
      name: 'Allure',
      index: 7,
      children: <AllurePanel uuid={uuid ?? ''} />,
      icon: <TbReportSearch />,
    },
    {
      name: 'SBOM',
      index: 8,
      children: <SbomPanel />,
      icon: <TbLicense />,
    },
    {
      name: 'API',
      index: 9,
      children: <ApiPanel />,
      icon: <SiSwagger />,
    },
    {
      name: 'Операции',
      index: 10,
      children: <OperationsPanel entity={uuid ?? ''} />,
      icon: <AiOutlineAudit />,
    },
    {
      name: 'Terraform',
      index: 11,
      children: <TerraformPanel uuid={uuid ?? ''} />,
      hidden: true,
      icon: <SiTerraform />,
    },
  ]

  return <ComponentPage panels={panels} />
}

export default ServicePage
