import React, { useEffect, useState } from 'react'

import ServiceStore from './store'
import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { runInAction } from 'mobx'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import {
  ServiceSwaggerConfigLinkSpec,
  ServiceSwaggerConfigSpec,
  ServiceSwaggerConfigType,
} from '@edp/types'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionCustomSelectUUID from 'components/UserInteraction/UserInteractionCustomSelectUUID'
import { CustomSelectComponentUUIDOption } from '@edp/core-common-frontend'
import { EntityAction } from 'types/entities'

type SwaggerProps = {
  disabled: boolean
  action: EntityAction
}

const Swagger = observer((props: SwaggerProps) => {
  const [swaggerConfigTypes, setSwaggerConfigTypes] = useState<
    Array<CustomSelectComponentUUIDOption>
  >([])
  const [selectedSwaggerConfigType, setSelectedSwaggerConfigTypes] =
    useState<string>('')

  const handleSelectSwaggerConfigType = (
    value: React.SetStateAction<string>
  ) => {
    const config: ServiceSwaggerConfigSpec = {
      ...ServiceStore.$('swaggerConfigRef').value,
    }
    config.type = value.toString()
    ServiceStore.$(`swaggerConfigRef`).reset()
    ServiceStore.$(`swaggerConfigRef`).set({ ...config })

    setSelectedSwaggerConfigTypes(value.toString())
  }

  const handleAddLink = () => {
    const links: ServiceSwaggerConfigLinkSpec[] = ServiceStore.$(
      'swaggerConfigRef.links'
    ).value

    if (links.length != 0) {
      if (
        links[links.length - 1].environment !== '' ||
        links[links.length - 1].url !== ''
      ) {
        const config: ServiceSwaggerConfigSpec = {
          ...ServiceStore.$('swaggerConfigRef').value,
        }
        config.links.push({ service: '', environment: '', url: '' })
        ServiceStore.$(`swaggerConfigRef`).reset()
        ServiceStore.$(`swaggerConfigRef`).set({ ...config })
      }
    } else {
      const config: ServiceSwaggerConfigSpec = {
        ...ServiceStore.$('swaggerConfigRef').value,
      }
      config.links = [{ service: '', environment: '', url: '' }]
      ServiceStore.$(`swaggerConfigRef`).reset()
      ServiceStore.$(`swaggerConfigRef`).set({ ...config })
    }
  }

  const handleRemoveLink = (index: number) => {
    runInAction(() => {
      const config: ServiceSwaggerConfigSpec = {
        ...ServiceStore.$('swaggerConfigRef').value,
      }
      config.links.splice(index, 1)
      ServiceStore.$(`swaggerConfigRef`).reset()
      ServiceStore.$(`swaggerConfigRef`).set({ ...config })
    })
  }

  const handleChangeEnvironment = (index: number, value: string) => {
    runInAction(() => {
      const config: ServiceSwaggerConfigSpec = {
        ...ServiceStore.$('swaggerConfigRef').value,
      }
      config.links[index].environment = value
      ServiceStore.$(`swaggerConfigRef`).set({ ...config })
    })
  }

  const handleChangeURL = (index: number, value: string) => {
    runInAction(() => {
      const config: ServiceSwaggerConfigSpec = {
        ...ServiceStore.$('swaggerConfigRef').value,
      }
      config.links[index].url = value

      ServiceStore.$('swaggerConfigRef').set({ ...config })
    })
  }

  useEffect(() => {
    const formattedSwaggerConfigTypes = () => {
      const configTypes: Array<CustomSelectComponentUUIDOption> =
        Object.entries(ServiceSwaggerConfigType).map(([_, value]) => {
          let label = 'Unknown'

          if (value === ServiceSwaggerConfigType.Link) {
            label = 'Ссылка'
          }

          if (value === ServiceSwaggerConfigType.Repository) {
            label = 'Репозиторий'
          }

          const result: CustomSelectComponentUUIDOption = {
            label: label,
            uuid: value,
          }

          return result
        })

      setSwaggerConfigTypes(configTypes)
    }

    formattedSwaggerConfigTypes()
  }, [])

  useEffect(() => {
    const initSelectedSwaggerConfigType = () => {
      if (
        ServiceStore.$('swaggerConfigRef.type').value !== '' &&
        props.action === EntityAction.Update
      ) {
        setSelectedSwaggerConfigTypes(
          ServiceStore.$('swaggerConfigRef.type').value
        )
      }
    }

    initSelectedSwaggerConfigType()
  }, [ServiceStore.$('swaggerConfigRef.type').value])

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Swagger
      </Typography>
      <>
        <UserInteractionCustomSelectUUID
          name="swagger-config-type"
          description="Источник конфигурации Swagger"
          helperText="Способ получения конфигурации Swagger: из репозитория с кодом или из стороннего источника"
          options={swaggerConfigTypes}
          selected={selectedSwaggerConfigType}
          setSelected={handleSelectSwaggerConfigType}
        />
        {selectedSwaggerConfigType === ServiceSwaggerConfigType.Link && (
          <>
            {ServiceStore.$('swaggerConfigRef.links').value && (
              <>
                {ServiceStore.$('swaggerConfigRef.links').value.length > 0 &&
                  ServiceStore.$('swaggerConfigRef.links').value.map(
                    (
                      link: ServiceSwaggerConfigLinkSpec,
                      index: number,
                      test: any
                    ) => {
                      return (
                        <UserInteractionTwoTextFields
                          key={`swagger-environment-config-${index}`}
                          helperText={`${test}`}
                          index={index}
                          first={link.environment}
                          second={link.url}
                          firstLabel="Окружение"
                          secondLabel="Ссылка"
                          handleChangeFirst={(e) =>
                            handleChangeEnvironment(index, e.target.value)
                          }
                          handleChangeSecond={(e) =>
                            handleChangeURL(index, e.target.value)
                          }
                          removeButton
                          handleRemove={() => {
                            handleRemoveLink(index)
                          }}
                        />
                      )
                    }
                  )}
              </>
            )}
            <UserInteractionAddButton handleAdd={handleAddLink} />
          </>
        )}
      </>
    </>
  )
})

export default Swagger
