import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import {
  GitSecretScanStatus,
  GitSecretSpec,
  GitSecretStatusUpdateReqSpec,
  GitSecretsReqSpec,
} from 'types/Security'
import { handleResponse } from '@edp/core-common-frontend'

export async function startScanGitSecrets(
  this: ApiClient,
  spec: GitSecretsReqSpec
): Promise<any> {
  const url = `${this.serverUrl}/security/gitsecrets`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}

export async function getSecretsRepository(
  this: ApiClient,
  uuid: string
): Promise<Array<GitSecretSpec>> {
  const url = `${this.serverUrl}/security/gitsecrets/${uuid}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}

export async function getStatusScanGitSecrets(
  this: ApiClient,
  uuid: string
): Promise<GitSecretScanStatus> {
  const url = `${this.serverUrl}/security/gitsecrets/${uuid}/status`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  return await handleResponse(response)
}

export async function updateStatusGitSecret(
  this: ApiClient,
  spec: GitSecretStatusUpdateReqSpec
): Promise<any> {
  const url = `${this.serverUrl}/security/gitsecrets`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })

  return await handleResponse(response)
}
