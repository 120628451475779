import { ComponentPage } from 'components/ComponentPage'
import { OperationsPanel } from 'components/OperationsPanel/OperationsPanel'
import { useParams } from 'react-router-dom'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'
import PluginsPanel from './PluginsPanel/PluginsPanel'
import PluginsPanelExt1 from './PluginsPanelExt1/PluginsPanel'
import PluginsPanelExt2 from './PluginsPanelExt2/PluginsPanel'
import { GrafanaPanel } from './GrafanaPanel/GrafanaPanel'

export const EnvironmentPage = () => {
  const { uuid } = useParams()

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Grafana',
      index: 1,
      children: <GrafanaPanel />,
    },
    {
      name: 'Операции',
      index: 2,
      children: <OperationsPanel entity={uuid || ''} />,
    },
    {
      name: 'Плагины',
      index: 3,
      children: <PluginsPanel />,
      hidden: true,
    },
    {
      name: 'PluginsPanelExt1',
      index: 4,
      children: <PluginsPanelExt1 />,
      hidden: true,
    },
    {
      name: 'PluginsPanelExt2',
      index: 5,
      children: <PluginsPanelExt2 />,
      hidden: true,
    },
  ]

  return <ComponentPage panels={panels} />
}
export default EnvironmentPage
