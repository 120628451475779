import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
  CustomSelectProps,
} from '@edp/core-common-frontend'
import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import { PlatformServiceConfigurationSpec } from 'types/Managers/PlatformServiceConfigurations'

export interface PlatformServiceConfigurationSelectProps
  extends CustomSelectProps {
  platformServiceTemplate: string
}

export const PlatformServiceConfigurationSelect = (
  props: PlatformServiceConfigurationSelectProps
) => {
  const [platformServiceConfigurations, setPlatformServiceConfigurations] =
    useState<PlatformServiceConfigurationSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getPlatformServiceConfigurations = async () => {
      try {
        const data = await apiClient.getPlatformServiceConfigurationsBy(
          'platformServiceTemplate',
          props.platformServiceTemplate
        )
        setPlatformServiceConfigurations(data)
      } catch (e) {
        console.log(e)
      }
    }

    getPlatformServiceConfigurations()
  }, [props.platformServiceTemplate])

  const options: CustomSelectComponentUUIDOption[] =
    platformServiceConfigurations?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default PlatformServiceConfigurationSelect
