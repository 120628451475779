import {
  CustomSnackbarComponent,
  NothingFoundPage,
  deleteService,
  getServices,
  useFetchService,
} from '@edp/core-common-frontend'
import { ServiceSpec } from '@edp/types'
import { Box, Grid, LinearProgress } from '@mui/material'
import { ComponentCard } from 'components/ComponentCard'
import { CustomButton } from '@edp/core-common-frontend'
import { DeletionWindow } from 'components/DeletionWindow'
import { TeamSelect } from 'components/TeamSelect'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import FillingStatusComponent, {
  FillingStatusComponentType,
} from './FillingStatus/FillingStatusComponent'

export const ServicesPage = () => {
  const [services, setServices] = useState<ServiceSpec[]>()
  const [filteredServices, setFilteredServices] = useState<ServiceSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [serviceToDelete, setServiceToDelete] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const fetchServices = async () => {
    setLoading(true)
    try {
      const data = await getServices()
      setServices(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchServices()
  }, [])

  useEffect(() => {
    const s: ServiceSpec[] = []

    if (services && owner !== '') {
      for (const service of services) {
        if (service.owner === owner) {
          s.push(service)
        }
      }
      setFilteredServices(s)
    }

    if (owner === '') {
      setFilteredServices(services)
    }
  }, [services, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setServiceToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await deleteService(serviceToDelete)
      setDeleted(true)
      fetchServices()
      setDeletionWindowOpened(false)
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const serviceToDeleteSpec = useFetchService(serviceToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton component={Link} to={'/services/create'}>
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {loading && <LinearProgress />}
      {filteredServices?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новый сервис, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredServices?.map((service, index) => (
          <ComponentCard
            key={index}
            name={service.name}
            uuid={service.uuid}
            description={service.description}
            headerAction={
              <FillingStatusComponent
                service={service}
                type={FillingStatusComponentType.Circular}
                enabledLoading={true}
                styleContainer={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              />
            }
            headerClasses={{
              action: 'headerActionServiceCardClass',
            }}
            options={[
              {
                name: 'Идентификатор',
                value: `${service.slug}`,
              },
              {
                name: 'Владелец',
                value: service.ownerRef?.name,
                isUrl: true,
                externalUrl: false,
                url: `/teams/${service.ownerRef?.uuid}`,
              },
              {
                name: 'Репозиторий',
                isUrl: true,
                value: service.repositoryRef?.name,
                url: `/components/repositories/${service?.repositoryRef?.uuid}`,
                externalUrl: false,
              },
              {
                name: 'Репозиторий в Gitlab',
                isUrl: true,
                value: service.repositoryRef?.slug,
                url: service.repositoryRef?.url,
                externalUrl: true,
              },
              {
                name: 'Стадия',
                isUrl: false,
                value: service?.lifecycleStageRef?.name
                  ? `${service.lifecycleStageRef.name}`
                  : 'неизвестно',
              },
              {
                name: 'Создан из шаблона',
                value: service.templated ? 'да' : 'нет',
              },
              {
                name: 'Автообновление шаблона',
                value: service.templateUpdate ? 'да' : 'нет',
                disabled: !service.templated,
              },
              ...(service.templated
                ? [
                    {
                      name: 'Шаблон',
                      isUrl: true,
                      value: service.templateRef?.name,
                      url: `/components/service-templates/${service?.templateRef?.uuid}`,
                      externalUrl: false,
                    },
                  ]
                : []),
            ]}
            linkOpen={`/services/${service.uuid}`}
            linkUpdate={`/services/update/${service.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление сервиса'}
          text={`Сервис ${serviceToDeleteSpec?.name} будет удален без возможности восстановления.`}
          additionalText={`Репозиторий сервиса в Gitlab и EDP удален не будет, при необходимости удалите его вручную.`}
          spec={serviceToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Сервис ${serviceToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении сервиса ${serviceToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default ServicesPage
