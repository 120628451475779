import { ServiceSpec } from '@edp/types'

export const ServiceSummaryFiltered = (
  selectedTeams: Array<string>,
  selectedLifecycleStages: Array<string>,
  service: ServiceSpec
): boolean => {
  if (selectedTeams.length !== 0) {
    if (
      service.ownerRef?.name &&
      !selectedTeams.includes(service.ownerRef.name)
    ) {
      return false
    }
  }

  if (selectedLifecycleStages.length !== 0) {
    if (!service.lifecycleStage) {
      return false
    }

    if (
      service.lifecycleStageRef?.name &&
      !selectedLifecycleStages.includes(service.lifecycleStageRef.name)
    ) {
      return false
    }
  }

  return true
}
