import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from '@edp/core-common-frontend'
import { AclEntry } from 'kafkajs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  AclOperationTypes,
  AclPermissionTypes,
  AclResourceTypes,
  KafkaTopic,
  ResourcePatternTypes,
} from 'types/Controllers/Kafka'
import './styles.css'
import UserInteractionCustomSelect from 'components/UserInteraction/UserInteractionCustomSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'

export enum KafkaAclActions {
  Create,
  Update,
}

export interface EditKafkaAclProps {
  name: string
  action: KafkaAclActions
  opened: boolean
  disabled?: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export type Option = {
  key: string
  value?: string
}

export const EditKafkaAcl = (props: EditKafkaAclProps) => {
  const { uuid } = useParams()
  const [loading, setLoading] = useState<boolean>(false)

  const [resourcePatternType, setResourcePatternType] =
    useState<string>('LITERAL')
  const [aclOperationType, setAclOperationType] = useState<string>('ALL')
  const [aclPermissionType, setAclPermissionType] = useState<string>('DENY')
  const [principal, setPrincipal] = useState<string>('')
  const [host, setHost] = useState<string>('*')

  const [topics, setTopics] = useState<KafkaTopic[]>([])
  const [topic, setTopic] = useState<string>('')

  const handleClose = () => props.setOpened(false)

  const apiClient = new ApiClient()

  const getTopics = async () => {
    try {
      setLoading(true)
      const data = await apiClient.getKafkaTopics(uuid || '')
      setTopics(data)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('Неизвестная ошибка')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTopics()
  }, [])

  const handleCreate = async () => {
    props.setCreated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    try {
      setLoading(true)

      const spec: AclEntry = {
        resourceType: AclResourceTypes.TOPIC,
        resourceName: topic,
        resourcePatternType:
          ResourcePatternTypes[
            resourcePatternType as keyof typeof ResourcePatternTypes
          ],
        principal: principal,
        host: '*',
        operation:
          AclOperationTypes[aclOperationType as keyof typeof AclOperationTypes],
        permissionType:
          AclPermissionTypes[
            aclPermissionType as keyof typeof AclPermissionTypes
          ],
      }

      await apiClient.createKafkaAcl(uuid || '', spec)
      props.setCreated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog
        PaperProps={{ sx: { minWidth: '750px' } }}
        open={props.opened}
        onClose={handleClose}
      >
        <DialogTitle>
          {props.action === KafkaAclActions.Create && 'Создание ACL'}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <div style={{ paddingTop: '15px' }}>
            <UserInteractionCustomSelect
              name="resourcePattern"
              description="resourcePattern"
              helperText="Resource pattern."
              options={Object.keys(ResourcePatternTypes).filter(
                (key) =>
                  isNaN(Number(key)) &&
                  key !== 'UNKNOWN' &&
                  key !== 'ANY' &&
                  key !== 'MATCH'
              )}
              selected={resourcePatternType}
              setSelected={setResourcePatternType}
            />
            <UserInteractionCustomSelect
              name="operation"
              description="operation"
              helperText="Operation."
              options={Object.keys(AclOperationTypes).filter(
                (key) =>
                  isNaN(Number(key)) && key !== 'UNKNOWN' && key !== 'ANY'
              )}
              selected={aclOperationType}
              setSelected={setAclOperationType}
            />
            <UserInteractionCustomSelect
              name="topic"
              description="topic"
              helperText="Topic."
              options={topics.map((topic) => topic.name)}
              selected={topic}
              setSelected={setTopic}
            />
            <UserInteractionCustomSelect
              name="permissionType"
              description="permissionType"
              helperText="Permission type."
              options={Object.keys(AclPermissionTypes).filter(
                (key) =>
                  isNaN(Number(key)) && key !== 'UNKNOWN' && key !== 'ANY'
              )}
              selected={aclPermissionType}
              setSelected={setAclPermissionType}
            />
            <UserInteractionTextField
              name="principal"
              description="principal"
              helperText="Principal."
              value={principal}
              onChange={(event) => setPrincipal(event.target.value)}
            />
            <UserInteractionTextField
              name="host"
              description="host"
              helperText="Host."
              value={host}
              onChange={(event) => setHost(event.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right', alignSelf: 'end' }}>
          {props.action === KafkaAclActions.Create && (
            <CustomButton onClick={handleCreate} loading={loading}>
              Создать
            </CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditKafkaAcl
