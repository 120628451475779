import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Tooltip, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import { ActionSelect } from '../ActionSelect/ActionSelect'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'
import './styles.css'

export interface UserInteractionActionSelectProps {
  name: string
  description: string
  helperText: string
  error?: boolean
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
  removeButton?: boolean
  handleRemove?: () => void
}

const UserInteractionActionSelect = forwardRef(
  // eslint-disable-next-line
  (props: UserInteractionActionSelectProps, _ref) => {
    return (
      <Grid className="UserInteraction-Grid-Remove">
        <Grid className="UserInteraction-Grid">
          <Grid className="UserInteraction-Description">
            <Typography variant="body2" fontSize={'15px'}>
              {props.description}
            </Typography>
            <Tooltip arrow title={props.helperText} placement="top">
              <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
            </Tooltip>
          </Grid>
          <ActionSelect
            selected={props.selected}
            setSelected={props.setSelected}
            disabled={props.disabled}
            error={props.error}
          />
        </Grid>
        {props.removeButton && (
          <UserInteractionRemoveButton handleRemove={props.handleRemove} />
        )}
      </Grid>
    )
  }
)

UserInteractionActionSelect.displayName = 'UserInteractionActionSelect'

export default UserInteractionActionSelect
