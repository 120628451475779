import { Box, Grid, LinearProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from '@edp/core-common-frontend'
import { Link } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import { TeamSpec } from '@edp/types'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import useFetchTeam from 'hooks/useFetchTeam'
import { NothingFoundPage } from '@edp/core-common-frontend'

export const TeamsPage = () => {
  const [teams, setTeams] = useState<TeamSpec[]>([])

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [teamToDelete, setTeamToDelete] = useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getTeams = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getTeams()
      setTeams(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTeams()
  }, [])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setTeamToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteTeam(teamToDelete).then(() => {
        setDeleted(true)
        getTeams()
        setDeletionWindowOpened(false)
      })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const teamToDeleteSpec = useFetchTeam(teamToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            gap: '5px',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton component={Link} to={'/teams/create'}>
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {loading && <LinearProgress />}
      {teams?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте создать новую команду или проверить политики доступа." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {teams?.map((team, index) => (
          <ComponentCard
            key={index}
            name={team.name}
            uuid={team.uuid}
            description={team.description}
            options={[
              {
                name: 'Идентификатор',
                value: `${team.slug}`,
              },
              {
                name: 'Входит в',
                value: `${team.parentTeamRef?.name}`,
                disabled: !team.parentTeam,
              },
              {
                name: 'Ответственный',
                value: team.responsible,
                disabled: !team.responsible,
              },
              {
                name: 'Фокус',
                value: `${team.focus}`,
                disabled: !team.focus,
              },
              {
                name: 'Тип',
                value: `${team.type}`,
                disabled: !team.type,
              },
              {
                name: 'Версионирование',
                value: `${team.versioning}`,
                disabled: !team.versioning,
              },
              {
                name: 'Документация',
                value: `${team.wiki}`,
                disabled: !team.wiki,
              },
              {
                name: 'Чат',
                value: `${team.chatChannels}`,
                disabled: !team.chatChannels,
              },
              {
                name: 'Митинги',
                value: `${team.timeDailyMeeting}`,
                disabled: !team.timeDailyMeeting,
              },
            ]}
            linkOpen={`/teams/${team.uuid}`}
            linkUpdate={`/teams/update/${team.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление команды'}
          text={`Команда ${teamToDeleteSpec?.name} будет удалена без возможности восстановления.`}
          additionalText={`Будут удалены ресурсы команды в инфраструктурных сервисах, отмеченные при выборе интеграций во время создания команды. Ресурсы в Gitlab и Keycloak удалены не будут, при необходимости удалите их вручную в соответствующих системах.`}
          spec={teamToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Команда ${teamToDeleteSpec?.name} удалена.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении команды ${teamToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}.`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default TeamsPage
