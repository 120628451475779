import {
  PlayCircle,
  PlayCircleFilledOutlined,
  PlayCircleOutline,
  PlayCircleOutlined,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  IconButton,
  Tooltip,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { React } from '@edp/core-common-frontend/dist/packages/react'
import RunDialog from '../dialogs/RunDialog'
import { TerraformActionRecordSpec, TerraformActionSpec } from '@edp/types'

export interface RunButtonProps {
  uuid: string
  terraformAction: TerraformActionSpec
  terraformActionRecord?: TerraformActionRecordSpec
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

export const RunButton = (props: RunButtonProps) => {
  const [opened, setOpened] = React.useState<boolean>(false)
  const handleOpen = () => setOpened(true)

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ':hover': {
            bgcolor: 'transparent',
          },
        }}
      >
        <Tooltip arrow title={`Запустить Terraform action`} placement="top">
          <div>
            <PlayCircleOutlined fontSize="large" />
          </div>
        </Tooltip>
      </IconButton>
      <RunDialog
        uuid={props.uuid}
        terraformAction={props.terraformAction}
        terraformActionRecord={props.terraformActionRecord}
        opened={opened}
        setOpened={setOpened}
        updated={props.updated}
        setUpdated={props.setUpdated}
      />
    </>
  )
}
