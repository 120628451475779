import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { MdCalendarToday } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { GitlabMergeRequest } from '@edp/types'
import { Chip, Link, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Contributor } from '../ContributorsCard/components/Contributor'
import { MRState, MRStateColors } from './types'

export const MergeRequestsTable = ({ mergeRequests }: any) => {
  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ paddingBottom: '5px', paddingTop: '5px' }}>
            <div
              style={{
                height: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Chip
                label={params.row.state}
                size="small"
                variant="filled"
                icon={React.cloneElement(
                  MRStateColors[params.row.state as MRState].icon,
                  {
                    style: {
                      color: MRStateColors[params.row.state as MRState].color,
                    },
                  }
                )}
                sx={{
                  minWidth: '80px',
                  backgroundColor:
                    MRStateColors[params.row.state as MRState].backgroundColor,
                  color: MRStateColors[params.row.state as MRState].color,
                  border: 'none',
                  justifyContent: 'left',
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdCalendarToday />
              <Typography sx={{ paddingLeft: '5px' }} variant="caption">
                {moment(params.row.created_at).format('DD.MM.YYYY HH:mm:ss')}
              </Typography>
            </div>
          </div>
        )
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 60,
      renderCell: (params) => (
        <Link href={params.row.web_url} target="_blank" rel="noreferrer">
          {params.row.id}
        </Link>
      ),
    },
    {
      field: 'author',
      headerName: 'Автор',
      renderCell: (params) => (
        <Contributor
          contributor={{
            name: params.row.author.name,
            email: '',
            avatar_url: params.row.author.avatar_url,
            project_web_url: '',
            project_default_branch: '',
          }}
        />
      ),
    },
    { field: 'title', headerName: 'Название', minWidth: 200 },
  ]

  const rows = mergeRequests?.data?.map((mergeRequest: GitlabMergeRequest) => {
    return {
      id: mergeRequest.id,
      state: mergeRequest.state,
      author: mergeRequest.author,
      title: mergeRequest.title,
      web_url: mergeRequest.web_url,
      created_at: mergeRequest.created_at,
    }
  })

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      pageSize={5}
      rowsPerPageOptions={[5]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
    />
  )
}
