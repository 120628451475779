import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { LifecycleStageSpec } from '@edp/types'
import { Box, Grid } from '@mui/material'
import {
  CustomButton,
  CustomSnackbarComponent,
  NothingFoundPage,
} from '@edp/core-common-frontend'
import { Link } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import { DeletionWindow } from 'components/DeletionWindow'
import useFetchLifecycleStage from 'hooks/useFetchLifecycleStage'

export const AllStagesLifecyclePanel = () => {
  const [lifecycleStages, setLifecycleStages] = useState<LifecycleStageSpec[]>(
    []
  )
  const apiClient = new ApiClient()

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [lifecycleStageToDelete, setLifecycleStageToDelete] =
    useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const getLifecycleStages = async () => {
    try {
      const data = await apiClient.getLifecycleStages()
      setLifecycleStages(data)
    } catch (e) {
      console.log(`Ошибка при получении данных: ${e}`)
    }
  }

  useEffect(() => {
    getLifecycleStages()
  }, [])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setLifecycleStageToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteLifecycleStage(lifecycleStageToDelete)
      setDeleted(true)
      getLifecycleStages()
      setDeletionWindowOpened(false)
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const lifecycleStageToDeleteSpec = useFetchLifecycleStage(
    lifecycleStageToDelete
  )

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton
            component={Link}
            to={'/settings/lifecycle/stages/create'}
          >
            Создать
          </CustomButton>
        </Box>
      </Grid>
      {lifecycleStages.length === 0 && !loading && (
        <NothingFoundPage text="Попрубуйте добавить новую стадию жизненного цикла." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {lifecycleStages?.map((lifecycleStage, index) => (
          <ComponentCard
            key={index}
            name={lifecycleStage.name}
            uuid={lifecycleStage.uuid}
            description={lifecycleStage.description}
            linkOpen={`/settings/lifecycle/stages/${lifecycleStage.uuid}`}
            linkUpdate={`/settings/lifecycle/stages/update/${lifecycleStage.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление стадии жизненного цикла'}
          text={`Стадия жизненного цикла ${lifecycleStageToDeleteSpec?.name}`}
          spec={lifecycleStageToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Стадия жизненного цикла "${lifecycleStageToDeleteSpec?.name}" удалена.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении стадии жизненного цикла "${lifecycleStageToDeleteSpec?.name}" произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
