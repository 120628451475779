import { IconButton } from '@mui/material'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { ApiClient } from 'api/ApiClient'
import { DeletionWindow } from 'components/DeletionWindow'
import React, { useState } from 'react'
import { CredentialsBaseSpec } from 'types/Credentials'
import { Delete } from '@edp/core-common-frontend/dist/packages/mui/icons-material'

export interface ListCredentialsTableProps {
  credentials: Array<CredentialsBaseSpec>
  setDeleted: React.Dispatch<React.SetStateAction<boolean>>
  setDeletionFailed: React.Dispatch<React.SetStateAction<boolean>>
  setDeletionErrorMessage: React.Dispatch<React.SetStateAction<string>>
  updateTable: React.Dispatch<React.SetStateAction<boolean>>
  deletionWindowOpened: boolean
  setDeletionWindowOpened: React.Dispatch<React.SetStateAction<boolean>>
  currentCredentialsUUID: string
  setCurrentCredentialsUUID: React.Dispatch<React.SetStateAction<string>>
  currentCredentialsName: string
  setCurrentCredentialsName: React.Dispatch<React.SetStateAction<string>>
}

export const ListCredentialsTable = (props: ListCredentialsTableProps) => {
  const apiClient = new ApiClient()

  const [loading, setLoading] = useState<boolean>(false)

  const handleDeleteButton = async () => {
    props.setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    props.setDeletionWindowOpened(false)

    try {
      await apiClient
        .deleteCredentials(props.currentCredentialsUUID)
        .then(() => {
          props.setDeleted(true)
          props.updateTable(true)
        })
    } catch (e) {
      props.setDeletionFailed(true)
      if (e instanceof Error) {
        props.setDeletionErrorMessage(e.message)
      } else {
        props.setDeletionErrorMessage('unknown')
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'uuid',
      headerName: 'uuid',
      minWidth: 150,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Имя',
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Тип',
      minWidth: 150,
      disableColumnMenu: true,
    },
    {
      field: 'owner',
      headerName: 'Владелец',
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: 'description',
      headerName: 'Описание',
      minWidth: 500,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Действие',
      minWidth: 50,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        props.setCurrentCredentialsName(params.row.name)
        props.setCurrentCredentialsUUID(params.row.uuid)
        return (
          <>
            <IconButton
              onClick={handleDeleteButton}
              aria-label="delete"
              size="small"
            >
              <Delete />
            </IconButton>
          </>
        )
      },
    },
  ]

  const rows = props.credentials.map((data) => {
    return {
      id: data.uuid,
      uuid: data.uuid,
      name: data.name,
      owner: data.owner,
      description: data.description,
      type: data.type,
    }
  })

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSize={5}
        rowsPerPageOptions={[5]}
        autoPageSize={true}
        disableSelectionOnClick
      />
      <DeletionWindow
        text={`Вы действительно хотите удалить учетные данные ${props.currentCredentialsName}?`}
        opened={props.deletionWindowOpened}
        setOpened={props.setDeletionWindowOpened}
        handleDelete={handleDelete}
        loading={loading}
      />
    </div>
  )
}

export default ListCredentialsTable
