import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import { useFetchKubernetesNamespace } from '@edp/core-common-frontend'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const namespace = useFetchKubernetesNamespace(uuid)

  return (
    <ComponentCard
      name={namespace?.name}
      description={namespace?.description}
      fullPage
      options={[
        {
          name: 'Идентификатор',
          value: `${namespace?.slug}`,
        },
        {
          name: 'Владелец',
          value: namespace?.ownerRef?.name,
        },
        {
          name: 'Окружение',
          value: namespace?.environmentRef?.name,
        },
      ]}
    />
  )
}

export default DescriptionPanel
