import { useEffect, useRef, useState } from 'react'
import { LinearProgress } from '@mui/material'
import { TechRadarUrl } from 'types/TechRadar'
import { ApiClient } from 'api/ApiClient'
import { NothingFoundPage } from '@edp/core-common-frontend'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import TechRadarIframe from './TechRadarIframe/TechRadarIframe'

export function TechRadarPage() {
  const [techRadarUrl, setTechRadarUrl] = useState<TechRadarUrl>()
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const ref = useRef<HTMLDivElement | null>(null)

  const apiClient = new ApiClient()

  const getTechRadarUrl = async () => {
    try {
      setLoading(true)
      const data = await apiClient.getTechRadarUrl()
      setTechRadarUrl(data)
    } catch (e) {
      setFailed(true)

      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTechRadarUrl()
  }, [])

  return (
    <div ref={ref} style={{ height: '90%', width: '100%' }}>
      {loading && <LinearProgress />}
      {!loading && !failed && techRadarUrl !== undefined && (
        <TechRadarIframe url={techRadarUrl.url} />
      )}
      {!loading && !failed && techRadarUrl === undefined && (
        <NothingFoundPage text="Попробуйте добавить ссылку на технологический радар в конфигурацию edp-backend: 'techradar.url'." />
      )}
      {!loading && failed && techRadarUrl === undefined && (
        <CustomSnackbarComponent
          opened={failed}
          setOpened={setFailed}
          message={errorMessage}
          severity="error"
        />
      )}
    </div>
  )
}

export default TechRadarPage
