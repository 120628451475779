import moment from 'moment'
import 'moment/locale/ru'

export const getElapsedTime = (start: string) => {
  moment.locale('ru')
  return moment(start).fromNow()
}

export const getDuration = (start: string, end: string) => {
  if (!end || !start) {
    return 'NA'
  }

  const end_time = moment(end)
  const start_time = moment(start)

  const duration = moment.utc(end_time.diff(start_time))

  return duration.format('HH:mm:ss')
}
