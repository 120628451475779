import React from 'react'

import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Alert,
  Box,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { DeletionWindow } from 'components/DeletionWindow'
import UserInteractionInfraServiceTypeSelect from 'components/UserInteraction/UserInteractionInfraServiceTypeSelect'
import { OpensearchDashboardsInfoCard } from 'pages/settings/InfrastructureServices/cards/OpensearchDashboardInfoCard'
import { useEffect, useState } from 'react'
import {
  ArgoCDInfo,
  DefectDojoInfo,
  GitlabInfo,
  GrafanaInfo,
  HarborInfo,
  INFRA_SERVICE_ARGOCD,
  INFRA_SERVICE_DEFECTDOJO,
  INFRA_SERVICE_GITLAB,
  INFRA_SERVICE_GRAFANA,
  INFRA_SERVICE_HARBOR,
  INFRA_SERVICE_NEXUS,
  INFRA_SERVICE_OPENSEARCH,
  INFRA_SERVICE_OPENSEARCH_DASHBOARDS,
  INFRA_SERVICE_PROMETHEUS,
  INFRA_SERVICE_SONARQUBE,
  INFRA_SERVICE_VAULT,
  InfraServiceSpec,
  NexusStatus,
  OpensearchDashboardsInfo,
  OpensearchInfo,
  PrometheusInfo,
  SonarqubeInfo,
  VaultInfo,
} from 'types/Managers/InfraServices'
import { ArgocdInfoCard } from '../cards/ArgocdInfoCard'
import { DefectDojoInfoCard } from '../cards/DefectDojoInfoCard'
import { GitlabInfoCard } from '../cards/GitlabInfoCard'
import { GrafanaInfoCard } from '../cards/GrafanaInfoCard'
import { HarborInfoCard } from '../cards/HarborInfoCard'
import { NexusInfoCard } from '../cards/NexusInfoCard'
import { OpensearchInfoCard } from '../cards/OpensearchInfoCard'
import { PrometheusInfoCard } from '../cards/PrometheusInfoCard'
import { SonarqubeInfoCard } from '../cards/SonarqubeInfoCard'
import { VaultInfoCard } from '../cards/VaultInfoCard'

interface RowProps {
  type: string
  name: string
  description?: string
  url: string
  deleteStatus: string
  setDeleteStatus: React.Dispatch<React.SetStateAction<string>>
  deletionErrorMessage: string
  setDeletionErrorMessage: React.Dispatch<React.SetStateAction<string>>
  updateTable: React.Dispatch<React.SetStateAction<string>>
}

interface InfoCardProps {
  type: string
  name: string
}

const InfoCard = (props: InfoCardProps) => {
  const [infoCardData, setInfoCardData] = useState<any>()
  const [infoCardStatus, setInfoCardStatus] = useState<string>('loading')
  const [infoCardError, setInfoCardError] = useState<string>('')

  useEffect(() => {
    const apiClient = new ApiClient()

    const getData = async () => {
      try {
        setInfoCardStatus('loading')
        await apiClient
          .getInfraServiceInfo(props.type, props.name)
          .then((data) => {
            setInfoCardData(data)

            // Handler status

            switch (props.type) {
              case INFRA_SERVICE_HARBOR:
                if (data.external_url !== undefined) {
                  setInfoCardStatus('success')
                } else {
                  setInfoCardStatus('warning')
                }
                break

              case INFRA_SERVICE_GITLAB:
                if (data.message !== undefined) {
                  setInfoCardStatus('error')
                  setInfoCardError(data.message)
                } else {
                  setInfoCardStatus('success')
                }
                break

              case INFRA_SERVICE_ARGOCD:
                if (data.Platform !== undefined) {
                  setInfoCardStatus('success')
                } else {
                  setInfoCardStatus('warning')
                }
                break

              case INFRA_SERVICE_GRAFANA:
                if (data.buildInfo !== undefined) {
                  setInfoCardStatus('success')
                } else {
                  setInfoCardStatus('warning')
                }
                break

              case INFRA_SERVICE_VAULT:
                setInfoCardStatus('success')
                break

              case INFRA_SERVICE_DEFECTDOJO:
                if (data.detail !== undefined) {
                  setInfoCardStatus('warning')
                } else {
                  setInfoCardStatus('success')
                }
                break

              case INFRA_SERVICE_SONARQUBE:
                setInfoCardStatus('success')
                break

              case INFRA_SERVICE_PROMETHEUS:
                setInfoCardStatus('success')
                break

              case INFRA_SERVICE_OPENSEARCH:
                if (data.cluster_name !== undefined) {
                  setInfoCardStatus('success')
                } else {
                  setInfoCardStatus('warning')
                }
                break

              default:
                setInfoCardStatus('success')
            }
          })
      } catch (e) {
        setInfoCardStatus('error')
        if (e instanceof Error) {
          setInfoCardError(e.message)
        } else {
          setInfoCardError('unknown')
        }
      }
    }

    getData()
  }, [infoCardError, props.name, props.type])

  switch (props.type) {
    case INFRA_SERVICE_HARBOR:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <HarborInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as HarborInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_GITLAB:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <GitlabInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as GitlabInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_ARGOCD:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <ArgocdInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as ArgoCDInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_GRAFANA:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <GrafanaInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as GrafanaInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_VAULT:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <VaultInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as VaultInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_DEFECTDOJO:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <DefectDojoInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as DefectDojoInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_SONARQUBE:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <SonarqubeInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as SonarqubeInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_PROMETHEUS:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <PrometheusInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as PrometheusInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_OPENSEARCH:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <OpensearchInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as OpensearchInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_OPENSEARCH_DASHBOARDS:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <OpensearchDashboardsInfoCard
              status={infoCardStatus}
              error={infoCardStatus}
              info={infoCardData as OpensearchDashboardsInfo}
            />
          )}
        </>
      )

    case INFRA_SERVICE_NEXUS:
      return (
        <>
          {infoCardStatus === 'loading' && <LinearProgress />}
          {infoCardStatus !== 'loading' && (
            <NexusInfoCard
              status={infoCardStatus}
              error={infoCardError}
              info={infoCardData as NexusStatus}
            />
          )}
        </>
      )

    default:
      return <></>
  }
}

const Row = (props: RowProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [openDeletionWindow, setOpenDeletionWindow] = useState<boolean>(false)

  const handleDeleteButton = async () => {
    setOpenDeletionWindow(true)
  }

  const handleDelete = async () => {
    const apiClient = new ApiClient()
    try {
      await apiClient.deleteInfraService(props.type, props.name).then(() => {
        props.setDeleteStatus('success')
        props.updateTable(props.type)
      })
      setOpenDeletionWindow(false)
    } catch (e) {
      props.setDeleteStatus('error')
      if (e instanceof Error) {
        props.setDeletionErrorMessage(e.message)
      } else {
        props.setDeletionErrorMessage('unknown')
      }
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {props.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {props.description}
        </TableCell>
        <TableCell component="th" scope="row">
          {String(props.url)}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={handleDeleteButton}
            aria-label="delete"
            size="small"
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <InfoCard type={props.type} name={props.name} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DeletionWindow
        title={'Удаление инфраструктурного сервиса'}
        text={`Инфраструктурный сервис ${props.type} с названием ${props.name} будет удален из EDP.`}
        opened={openDeletionWindow}
        setOpened={setOpenDeletionWindow}
        handleDelete={handleDelete}
      />
    </>
  )
}

export const ListInfraServicePanel = () => {
  const [service, setService] = useState<string>('')
  const [infraServices, setInfraServices] = useState<Array<InfraServiceSpec>>(
    []
  )

  const [deleteStatus, setDeleteStatus] = useState<string>('')
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const updateTable = async (param: React.SetStateAction<string>) => {
    setService(param)

    const infraServices: Array<InfraServiceSpec> =
      await apiClient.getInfraServicesByType(String(param))
    setInfraServices(infraServices)
  }

  const updateDeleteStatus = () => {
    setDeleteStatus('')
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <UserInteractionInfraServiceTypeSelect
          name="service"
          description="Инфраструктурный сервис"
          helperText="Тип инфраструктурного сервиса."
          selected={service}
          setSelected={updateTable}
        />
      </Grid>
      <Grid item xs={12}>
        {infraServices.length === 0 && (
          <>
            {service === '' && (
              <Alert severity="info" sx={{ marginTop: '2em' }}>
                Выберите инфраструктурный сервис
              </Alert>
            )}
            {service !== '' && (
              <Alert severity="info" sx={{ marginTop: '2em' }}>
                В данный момент не добавлено инфраструктурных сервисов {service}
              </Alert>
            )}
          </>
        )}
        {infraServices.length !== 0 && (
          <TableContainer component={Paper} sx={{ marginTop: '2em' }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Название</TableCell>
                  <TableCell>Описание</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {infraServices.map((row) => (
                  <Row
                    type={service}
                    name={row.name}
                    description={row.description}
                    url={row.url}
                    deleteStatus={deleteStatus}
                    setDeleteStatus={setDeleteStatus}
                    deletionErrorMessage={deletionErrorMessage}
                    setDeletionErrorMessage={setDeletionErrorMessage}
                    updateTable={updateTable}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={12}>
        <CustomSnackbarComponent
          opened={deleteStatus === 'success'}
          setOpened={updateDeleteStatus}
          message="Инфраструктурный сервис удален."
          severity="success"
        />
        <CustomSnackbarComponent
          opened={deleteStatus === 'error'}
          setOpened={updateDeleteStatus}
          message={`При удалении произошла ошибка. ${deletionErrorMessage}`}
          severity="error"
        />
      </Grid>
    </Grid>
  )
}
