import {
  CustomSnackbarComponent,
  NothingFoundPage,
  deleteKubernetesNamespace,
  getKubernetesNamespaces,
  useFetchKubernetesNamespace,
} from '@edp/core-common-frontend'
import { KubernetesNamespaceSpec } from '@edp/types'
import { Box, Grid } from '@mui/material'
import { ComponentCard } from 'components/ComponentCard'
import { CustomButton } from '@edp/core-common-frontend'
import { DeletionWindow } from 'components/DeletionWindow'
import { TeamSelect } from 'components/TeamSelect'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const KubernetesNamespacesPage = () => {
  const [kubernetesNamespaces, setKubernetesNamespaces] =
    useState<KubernetesNamespaceSpec[]>()
  const [filteredKubernetesNamespaces, setFilteredKubernetesNamespaces] =
    useState<KubernetesNamespaceSpec[]>()
  const [owner, setOwner] = useState('')
  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [kubernetesNamespaceToDelete, setKubernetesNamespaceToDelete] =
    useState<string>('')
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const fetchKubernetesNamespaces = async () => {
    setLoading(true)
    try {
      const data = await getKubernetesNamespaces()
      setKubernetesNamespaces(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchKubernetesNamespaces()
  }, [])

  useEffect(() => {
    const s: KubernetesNamespaceSpec[] = []

    if (kubernetesNamespaces && owner !== '') {
      for (const kubernetesNamespace of kubernetesNamespaces) {
        if (kubernetesNamespace.owner === owner) {
          s.push(kubernetesNamespace)
        }
      }
      setFilteredKubernetesNamespaces(s)
    }

    if (owner === '') {
      setFilteredKubernetesNamespaces(kubernetesNamespaces)
    }
  }, [kubernetesNamespaces, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setKubernetesNamespaceToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await deleteKubernetesNamespace(kubernetesNamespaceToDelete)
      setDeleted(true)
      fetchKubernetesNamespaces()
      setDeletionWindowOpened(false)
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const kubernetesNamespaceToDeleteSpec = useFetchKubernetesNamespace(
    kubernetesNamespaceToDelete
  )

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton component={Link} to={'/kubernetes/namespaces/register'}>
            Зарегистрировать
          </CustomButton>
        </Box>
      </Grid>
      {filteredKubernetesNamespaces?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте зарегистрировать новый namespace, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredKubernetesNamespaces?.map((kubernetesNamespace, index) => (
          <ComponentCard
            key={index}
            name={kubernetesNamespace.name}
            uuid={kubernetesNamespace.uuid}
            description={kubernetesNamespace.description}
            options={[
              {
                name: 'Идентификатор',
                value: `${kubernetesNamespace.slug}`,
              },
              {
                name: 'Владелец',
                value: kubernetesNamespace.ownerRef?.name,
              },
              {
                name: 'Окружение',
                value: kubernetesNamespace.environmentRef?.name,
              },
            ]}
            linkOpen={`/kubernetes/namespaces/${kubernetesNamespace.uuid}`}
            linkUpdate={`/kubernetes/namespaces/update/${kubernetesNamespace.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление namespace'}
          text={`Namespace ${kubernetesNamespaceToDeleteSpec?.name} будет удален без возможности восстановления.`}
          spec={kubernetesNamespaceToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Namespace ${kubernetesNamespaceToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении namespace ${kubernetesNamespaceToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default KubernetesNamespacesPage
