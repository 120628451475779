import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { highlight, languages } from 'prismjs'
import React from 'react'
import Editor from 'react-simple-code-editor'

import 'prismjs/components/prism-json'
import 'prismjs/themes/prism.css'
import './styles.css'
import { KrakendEndpointSpec } from 'types/Controllers/Krakend'

export type KrakendJsonProps = {
  endpoint: KrakendEndpointSpec
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
}

export const KrakendJson = (props: KrakendJsonProps) => {
  const handleClose = () => props.setOpened(false)

  return (
    <Dialog
      open={props.opened}
      onClose={handleClose}
      PaperProps={{ sx: { maxWidth: '900px' } }}
    >
      <DialogTitle>
        Конфигурация KrakenD endpoint
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <div style={{ width: 750 }}>
          <Typography
            component="div"
            variant="caption"
            sx={{ paddingBottom: '5px' }}
          >
            {'Итоговая конфигурация добавляемого endpoint в JSON формате.'}
          </Typography>
          {props.endpoint ? (
            <>
              <Editor
                disabled
                name={'variables'}
                className="KrakenJson-HighlightedInput-Editor"
                value={JSON.stringify(props.endpoint, null, 2)}
                onValueChange={() => {}}
                highlight={(value) =>
                  highlight(value, languages.json, 'language-json')
                }
                padding={10}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default KrakendJson
