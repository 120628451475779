import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { ServiceSpec } from '@edp/types'
import { Alert, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  countingFillingServiceFieldsStatus,
  StatusFillingServiceSpec,
} from 'utils/countingFillingServiceFields'

export interface FillingStatusAlertProps {
  service: ServiceSpec
  showAlert: boolean
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
  status?: StatusFillingServiceSpec
}

export const FillingStatusAlert = (props: FillingStatusAlertProps) => {
  const [status, setStatus] = useState<StatusFillingServiceSpec>()

  useEffect(() => {
    if (props.status === undefined) {
      countingFillingServiceFieldsStatus(props.service)
        .then((result) => {
          setStatus(result)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      setStatus(props.status)
    }
  }, [props.service])

  return (
    <>
      {props.showAlert && status && (
        <Alert
          severity="info"
          style={{ marginBottom: '5px' }}
          onClose={() => {
            props.setShowAlert(false)
          }}
          closeText="Закрыть"
          classes={{
            message: 'statusAlertMessageServiceDescription',
          }}
        >
          Не все рекомендуемые поля сервиса заполнены
          <Tooltip
            arrow
            title={status.message}
            placement="bottom"
            classes={{
              popper: 'tooltipPopperServiceClass',
              tooltipPlacementBottom: 'tooltipPopperServiceClassInfo',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '5px',
              }}
            >
              <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
            </div>
          </Tooltip>
        </Alert>
      )}
    </>
  )
}

export default FillingStatusAlert
