import { ContentCopy } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Chip,
  IconButton,
  Typography,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { SiDocker } from '@edp/core-common-frontend/dist/packages/react-icons/si'
import { DockerTag, ServiceMetadata } from '@edp/types'

interface ArtifactsTableProps {
  uuid: string
  tags: DockerTag[]
  serviceMetadata?: ServiceMetadata
}

export const ArtifactsTable = (props: ArtifactsTableProps) => {
  const handleClick = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      console.log('Text copied to clipboard')
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      width: 250,
      renderCell: (params) => {
        return (
          <div
            style={{
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Chip
              label={params.row.name}
              size="small"
              variant="filled"
              icon={<SiDocker style={{ color: '#2B5834' }} />}
              sx={{
                minWidth: '80px',
                backgroundColor: '#CAE5CF',
                color: '#2B5834',
                border: 'none',
                justifyContent: 'left',
              }}
            />
          </div>
        )
      },
    },
    {
      field: 'pullCommand',
      headerName: 'Команда для загрузки образа',
      minWidth: 500,
      renderCell: (params) => (
        <div>
          {navigator.clipboard && (
            <IconButton
              size={'small'}
              onClick={() => handleClick(params.row.pullCommand)}
              color="primary"
            >
              <ContentCopy />
            </IconButton>
          )}
          <Typography variant="caption">{params.row.pullCommand}</Typography>
        </div>
      ),
    },
  ]

  const rows = props.tags.map((tag: DockerTag) => {
    return {
      name: tag.name,
      pullCommand: `docker pull ${props.serviceMetadata?.dockerRegistryUrl}/${props.serviceMetadata?.dockerImageName}:${tag.name}`,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.name}
      columns={columns}
      rows={rows}
      pageSize={8}
      rowsPerPageOptions={[8]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
    />
  )
}
