import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import { handleResponse } from '@edp/core-common-frontend'

export async function getOpensearchUrl(this: ApiClient): Promise<any> {
  const url = `${this.serverUrl}/opensearch/url`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
