import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import ListCredentialsTable from './ListCredentialsTable'
import { CredentialsBaseSpec } from 'types/Credentials'
import { Grid } from '@mui/material'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'

export const ListCredentialsPanel = () => {
  const apiClient = new ApiClient()

  const [credentials, setCredentials] = useState<Array<CredentialsBaseSpec>>([])

  const [updateTable, setUpdateTable] = useState<boolean>(false)

  const [deleted, setDeleted] = useState<boolean>(false)
  const [deletionFailed, setDeletionFailed] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')
  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)

  const [currentCredentialsUUID, setCurrentCredentialsUUID] =
    useState<string>('')
  const [currentCredentialsName, setCurrentCredentialsName] =
    useState<string>('')

  useEffect(() => {
    const getCredentials = async () => {
      await apiClient.getCredentialsArray().then(async (data) => {
        setCredentials(data)
        setUpdateTable(false)
      })
    }

    getCredentials()
  }, [updateTable])

  return (
    <>
      <ListCredentialsTable
        credentials={credentials}
        setDeleted={setDeleted}
        setDeletionFailed={setDeletionFailed}
        setDeletionErrorMessage={setDeletionErrorMessage}
        updateTable={setUpdateTable}
        deletionWindowOpened={deletionWindowOpened}
        setDeletionWindowOpened={setDeletionWindowOpened}
        currentCredentialsUUID={currentCredentialsUUID}
        setCurrentCredentialsUUID={setCurrentCredentialsUUID}
        currentCredentialsName={currentCredentialsName}
        setCurrentCredentialsName={setCurrentCredentialsName}
      />
      <Grid item xs={12}>
        <CustomSnackbarComponent
          opened={deleted}
          setOpened={setDeleted}
          message={`Учетные данные ${currentCredentialsName} удалены!`}
          severity="success"
        />
        <CustomSnackbarComponent
          opened={deletionFailed}
          setOpened={setDeletionFailed}
          message={`При удалении учетных данных ${currentCredentialsName} произошла ошибка. ${deletionErrorMessage}`}
          severity="error"
        />
      </Grid>
    </>
  )
}
