import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import {
  ServiceUpdateLockSpec,
  ServiceUpdateLockStatus,
  ServiceUpdateTemplateStatusSpec,
} from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import {
  CustomButton,
  CustomSnackbarComponent,
} from '@edp/core-common-frontend'
import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'

type UpdateServiceDialogProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setServiceUpdated: React.Dispatch<React.SetStateAction<boolean>>
  uuid: string
}

export const UpdateServiceDialog = (props: UpdateServiceDialogProps) => {
  const apiClient = new ApiClient()

  const [startServiceUpdate, setStartServiceUpdate] = useState<boolean>(false)
  const [serviceUpdated, setServiceUpdated] = useState<boolean>(false)
  const [loadingServiceUpdate, setLoadingServiceUpdate] =
    useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failedServiceUpdate, setFailedServiceUpdate] = useState<boolean>(false)
  const [unlockedUpdate, setUnlockedUpdate] = useState<boolean>(false)
  const [failedUnlockUpdate, setFailedUnlockUpdate] = useState<boolean>(false)

  const [serviceUpdateLock, setServiceUpdateLock] =
    useState<ServiceUpdateLockSpec>()

  const handleUpdateCurrentVersion = async () => {
    try {
      setStartServiceUpdate(false)
      setErrorMessage('')
      setLoadingServiceUpdate(true)

      apiClient
        .serviceUpdateTemplate(props.uuid)
        .then((res: ServiceUpdateTemplateStatusSpec) => {
          if (res.status === 'success') {
            setStartServiceUpdate(false)
            setServiceUpdated(true)
            props.setServiceUpdated(true)
            setLoadingServiceUpdate(false)
          } else {
            setStartServiceUpdate(false)
            setLoadingServiceUpdate(false)

            if (res.error) {
              setErrorMessage(res.error)
            }

            setStartServiceUpdate(false)
            setFailedServiceUpdate(true)
          }
        })
        .catch((e) => {
          setLoadingServiceUpdate(false)
          setErrorMessage(e.message)
          setStartServiceUpdate(false)
          setFailedServiceUpdate(true)
        })
      setStartServiceUpdate(true)
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setStartServiceUpdate(false)
        setFailedServiceUpdate(true)
      }
    }
  }

  const handleUnlockUpdate = async () => {
    try {
      setErrorMessage('')

      apiClient
        .deleteServiceUpdateLock(props.uuid)
        .then((res: any) => {
          setUnlockedUpdate(true)
        })
        .catch((e) => {
          setErrorMessage(e)
          setFailedUnlockUpdate(true)
        })
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailedUnlockUpdate(true)
      }
    }
  }

  const handleButtonYes = async () => {
    handleUpdateCurrentVersion()
    props.setOpen(false)
  }

  const handleButtonNo = async () => {
    props.setOpen(false)
  }

  const handleButtonUnlockUpdate = async () => {
    await handleUnlockUpdate()
    await getServiceUpdateLock()
  }

  const generateTextInfoUpdateLock = (): string => {
    if (serviceUpdateLock) {
      if (serviceUpdateLock.status === ServiceUpdateLockStatus.Unlocked) {
        return 'Обновление не производится'
      }

      if (serviceUpdateLock.status === ServiceUpdateLockStatus.Updating) {
        return 'EDP в процессе формирования обновления для сервиса'
      }

      return 'Неизвестно'
    } else {
      return 'Неизвестно'
    }
  }

  const handleClose = () => props.setOpen(false)

  const getServiceUpdateLock = async () => {
    try {
      await apiClient
        .getServiceUpdateLock(props.uuid)
        .then((res: ServiceUpdateLockSpec) => {
          setServiceUpdateLock(res)
        })
        .catch((e) => {
          const result: ServiceUpdateLockSpec = {
            service: props.uuid,
            status: ServiceUpdateLockStatus.Unknown,
          }
          setServiceUpdateLock(result)
          console.log(e)
        })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (props.open) {
      getServiceUpdateLock()
    }
  }, [props.open])

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>
          {serviceUpdateLock?.status === ServiceUpdateLockStatus.Unlocked ? (
            <div className="dialogTitleServiceUpdate">
              Вы уверены, что хотите запустить обновление?
            </div>
          ) : (
            <div className="dialogTitleServiceUpdate">
              В данный момент невозможно запустить обновление
            </div>
          )}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </DialogTitle>

        <DialogContent>
          <Typography component="div" variant="body1">
            Текущий статус: {generateTextInfoUpdateLock()}
          </Typography>
        </DialogContent>

        <DialogActions>
          {serviceUpdateLock?.status === ServiceUpdateLockStatus.Unlocked ? (
            <>
              <CustomButton onClick={handleButtonNo}>Отмена</CustomButton>
              <CustomButton onClick={handleButtonYes}>Да</CustomButton>
            </>
          ) : (
            <>
              {serviceUpdateLock?.status === ServiceUpdateLockStatus.Unknown ? (
                <>
                  <CustomButton onClick={handleButtonNo}>Закрыть</CustomButton>
                </>
              ) : (
                <>
                  <CustomButton onClick={handleButtonUnlockUpdate}>
                    Снять блокировку обновления
                  </CustomButton>
                  <CustomButton onClick={handleButtonNo}>Закрыть</CustomButton>
                </>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
      <Grid>
        <CustomSnackbarComponent
          opened={startServiceUpdate}
          setOpened={setStartServiceUpdate}
          message={`Процесс обновления сервиса успешно запущен`}
          severity="success"
        />
        <CustomSnackbarComponent
          opened={serviceUpdated}
          setOpened={setServiceUpdated}
          message={`Обновление для сервиса сформировано`}
          severity="success"
        />
        <CustomSnackbarComponent
          opened={failedServiceUpdate}
          setOpened={setFailedServiceUpdate}
          message={`Во время формирования обновления произошла ошибка: ${errorMessage}`}
          severity="error"
        />
        <CustomSnackbarComponent
          opened={failedUnlockUpdate}
          setOpened={setFailedUnlockUpdate}
          message={`Во время снятия блокировки на обновления произошла ошибка: ${errorMessage}`}
          severity="error"
        />
        <CustomSnackbarComponent
          opened={unlockedUpdate}
          setOpened={setUnlockedUpdate}
          message={`Блокировка на обновления успешно снята`}
          severity="success"
        />
      </Grid>
    </>
  )
}

export default UpdateServiceDialog
