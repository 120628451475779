import { useState } from 'react'
import { Card, CardMedia, IconButton, LinearProgress } from '@mui/material'
import { MdOutlineLink } from '@edp/core-common-frontend/dist/packages/react-icons/md'

export type TechRadarIframeProps = {
  url: string
}

export const TechRadarIframe = (props: TechRadarIframeProps) => {
  const [loading, setLoading] = useState(true)

  const handleLoad = () => {
    setLoading(false)
  }

  return (
    <Card
      sx={{
        height: '100%',

        border: 'none',
        boxShadow: 'none',
      }}
    >
      {loading && <LinearProgress />}
      <IconButton
        href={props.url}
        target="_blank"
        sx={{
          float: 'right',
          marginTop: '5px',
        }}
      >
        <MdOutlineLink />
      </IconButton>
      <CardMedia
        component="iframe"
        height="100%"
        src={props.url}
        sx={{
          visibility: loading ? 'hidden' : 'visible',
          border: 'none',
          boxShadow: 'none',
        }}
        onLoad={handleLoad}
      />
    </Card>
  )
}

export default TechRadarIframe
