import { Box, Grid } from '@mui/material'
import { ClusterSpec } from '@edp/types'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { TeamSelect } from 'components/TeamSelect'
import { CustomButton } from '@edp/core-common-frontend'
import { ComponentCard } from 'components/ComponentCard'
import { Link } from 'react-router-dom'
import { DeletionWindow } from 'components/DeletionWindow'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import { NothingFoundPage } from '@edp/core-common-frontend'
import useFetchCluster from 'hooks/useFetchCluster'

export const ClustersPage = () => {
  const [clusters, setClusters] = useState<ClusterSpec[]>()
  const [filteredClusters, setFilteredClusters] = useState<ClusterSpec[]>()
  const [owner, setOwner] = useState('')

  const [deletionWindowOpened, setDeletionWindowOpened] =
    useState<boolean>(false)
  const [clusterToDelete, setClusterToDelete] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [deleted, setDeleted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [deletionErrorMessage, setDeletionErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const getClusters = async () => {
    setLoading(true)
    try {
      const data = await apiClient.getClusters()
      setClusters(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getClusters()
  }, [])

  useEffect(() => {
    const c: ClusterSpec[] = []

    if (clusters && owner !== '') {
      for (const cluster of clusters) {
        if (cluster.owner === owner) {
          c.push(cluster)
        }
      }
      setFilteredClusters(c)
    }

    if (owner === '') {
      setFilteredClusters(clusters)
    }
  }, [clusters, owner])

  const handleOpenDeletionWindow = async (uuid: string) => {
    setClusterToDelete(uuid)
    setDeletionWindowOpened(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await apiClient.deleteCluster(clusterToDelete).then(() => {
        setDeleted(true)
        getClusters()
        setDeletionWindowOpened(false)
      })
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setDeletionErrorMessage(e.message)
      } else {
        setDeletionErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const clusterToDeleteSpec = useFetchCluster(clusterToDelete)

  return (
    <>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <TeamSelect
            title="Владелец"
            selected={owner}
            setSelected={setOwner}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            paddingBottom: '10px',
            height: '60px',
          }}
        >
          <CustomButton component={Link} to={'/kubernetes/clusters/register'}>
            Зарегистрировать
          </CustomButton>
        </Box>
      </Grid>
      {filteredClusters?.length === 0 && !loading && (
        <NothingFoundPage text="Попробуйте зарегистрировать новый Kubernetes кластер, изменить фильтр или проверить настройки ролевой модели." />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {' '}
        {filteredClusters?.map((cluster, index) => (
          <ComponentCard
            key={index}
            name={cluster.name}
            uuid={cluster.uuid}
            description={cluster.description}
            options={[
              {
                name: 'Идентификатор',
                value: `${cluster.slug}`,
              },
              {
                name: 'Владелец',
                value: cluster.ownerRef?.name,
              },
              {
                name: 'URL',
                value: cluster.address,
              },
            ]}
            linkOpen={`/kubernetes/clusters/${cluster.uuid}`}
            linkUpdate={`/kubernetes/clusters/update/${cluster.uuid}`}
            handleDelete={handleOpenDeletionWindow}
          />
        ))}
        <DeletionWindow
          title={'Удаление кластера'}
          text={`Кластер ${clusterToDeleteSpec?.name} будет удален из EDP.`}
          additionalText={`Кластер в Argo CD будет удален в том случае, если он регистрировался на этапе создания кластера в EDP.`}
          spec={clusterToDeleteSpec}
          opened={deletionWindowOpened}
          setOpened={setDeletionWindowOpened}
          handleDelete={handleDelete}
          loading={loading}
        />
        <Grid item xs={12}>
          <CustomSnackbarComponent
            opened={deleted}
            setOpened={setDeleted}
            message={`Кластер ${clusterToDeleteSpec?.name} удален.`}
            severity="success"
          />
          <CustomSnackbarComponent
            opened={failed}
            setOpened={setFailed}
            message={`При удалении кластера ${clusterToDeleteSpec?.name} произошла ошибка. ${deletionErrorMessage}`}
            severity="error"
          />
        </Grid>
      </Box>
    </>
  )
}
export default ClustersPage
