import {
  CustomCardHeader,
  CustomSnackbarComponent,
} from '@edp/core-common-frontend'
import { Card, CardContent, Grid } from '@mui/material'
import { CustomButton } from '@edp/core-common-frontend'
import { useState } from 'react'
import EditPermissions from './EditPermissions'
import { PermissionsTable } from './PermissionsTable'

export const PermissionsPanel = () => {
  const [opened, setOpened] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleOpen = () => setOpened(true)

  return (
    <>
      <Grid className="RolePanel-CreateButton">
        <CustomButton onClick={handleOpen}>Создать</CustomButton>
      </Grid>

      <EditPermissions
        opened={opened}
        setOpened={setOpened}
        created={created}
        setCreated={setCreated}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <Card sx={{ boxShadow: '1' }}>
        <CustomCardHeader
          height="80px"
          title="Политики доступа"
          description="Политики доступа разрешают производить действия с ресурсами EDP"
          fullPage={false}
        />
        <CardContent>
          <PermissionsTable created={created} />
        </CardContent>
      </Card>
      <CustomSnackbarComponent
        opened={created}
        setOpened={setCreated}
        message="Политики доступа созданы"
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
}

export default PermissionsPanel
