import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import { ClusterSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import BasicInfo from './EditClusterPage/BasicInfo'
import Certificates from './EditClusterPage/Certificates'
import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import './styles.css'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import { TbPlugConnected } from '@edp/core-common-frontend/dist/packages/react-icons/tb'
import { Integrations } from './EditClusterPage/Integrations'
import { useNavigate } from 'react-router-dom'

export const CreateClusterPage = () => {
  const navigate = useNavigate()

  const [cluster, setCluster] = useState<ClusterSpec>({
    uuid: '',
    name: '',
    slug: '',
    description: '',
    owner: '',
    address: '',
    token: '',
    tlsConfig: {
      selfSigned: false,
      insecureTLS: false,
      caData: '',
      certData: '',
      keyData: '',
    },
    integrations: {
      argocd: {
        enabled: true,
        cluster: '',
      },
    },
  })

  const [connected, setConnected] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [created, setCreated] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)

  const [opened, setOpened] = React.useState(false)

  const apiClient = new ApiClient()

  const checkConnect = async (): Promise<string> => {
    setConnected(false)
    if (!cluster.name || !cluster.owner || !cluster.address || !cluster.token) {
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return 'error'
    }
    setErrorMessage('')

    try {
      const healthy = (await apiClient.checkClusterHealth(
        cluster.address
      )) as boolean

      if (!healthy) {
        setConnected(false)
        setErrorMessage('Не удалось подключиться к Kubernetes кластеру')
        setFailed(true)
        return 'error'
      }

      setConnected(true)
      return 'success'
    } catch (e) {
      setConnected(false)
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('unknown')
      }
      return 'error'
    }
  }

  const handleCreate = async () => {
    setCreated(false)

    if (!cluster.name || !cluster.owner || !cluster.address || !cluster.token) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }
    setErrorMessage('')
    setLoading(true)

    try {
      const spec: ClusterSpec = {
        uuid: cluster.uuid,
        name: cluster.name,
        slug: cluster.slug,
        description: cluster.description,
        owner: cluster.owner,
        address: cluster.address,
        token: cluster.token,
        tlsConfig: {
          selfSigned: cluster.tlsConfig.selfSigned,
          insecureTLS: cluster.tlsConfig.insecureTLS,
          caData: cluster.tlsConfig.caData,
          certData: cluster.tlsConfig.certData,
          keyData: cluster.tlsConfig.keyData,
        },
        integrations: {
          argocd: {
            enabled: cluster.integrations.argocd?.enabled,
            cluster: cluster.integrations.argocd?.enabled
              ? cluster.name
              : undefined,
          },
        },
      }

      await apiClient.createCluster(spec)
      setCreated(true)
      navigate('/kubernetes/clusters')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createButtonText="Зарегистрировать"
      createdMessage={`Кластер ${cluster.name} зарегистрирован`}
    >
      <>
        <BasicInfo cluster={cluster} setCluster={setCluster} disabled={false} />
        <Integrations
          cluster={cluster}
          setCluster={setCluster}
          disabled={false}
        />
        <Certificates
          cluster={cluster}
          setCluster={setCluster}
          disabled={false}
        />
        <UserInteractionCustom
          name="check"
          description="Проверить подключение"
          helperText="Проверить подключение к Kubernetes кластеру."
        >
          <IconButton onClick={checkConnect} sx={{ marginLeft: '-6px' }}>
            <TbPlugConnected style={{ color: '#808080' }} />
          </IconButton>
        </UserInteractionCustom>
        <CustomSnackbarComponent
          opened={failed}
          setOpened={setFailed}
          message={errorMessage}
          severity="error"
        />
        <CustomSnackbarComponent
          opened={opened}
          setOpened={setOpened}
          message="Kubernetes кластер зарегистрирован"
          severity="success"
        />
        <CustomSnackbarComponent
          opened={connected}
          setOpened={setConnected}
          message="Подключение к Kubernetes кластеру установлено"
          severity="success"
        />
      </>
    </CreateComponentPage>
  )
}

export default CreateClusterPage
