import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import colorConfigs from 'configs/colorConfigs'
import { RouteType } from 'routes/config'
import { MdOutlineExpandLess } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { MdOutlineExpandMore } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import SidebarItem from './SidebarItem'

type Props = {
  item: RouteType
}

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false)

  const { appState } = useSelector((state: RootState) => state.appState)

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true)
    }
  }, [appState, item])

  return item.sidebarProps ? (
    <>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={{
          '&: hover': {
            backgroundColor: colorConfigs.sidebar.hoverBg,
          },
          borderRadius: '5px',
          paddingLeft: '0px',
          height: '50px',
          marginTop: '1px',
        }}
      >
        <ListItemIcon
          sx={{
            color: colorConfigs.sidebar.color,
            paddingLeft: '20px',
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{ fontWeight: 'medium', fontSize: '0.875rem' }}
              component={'span'}
            >
              {item.sidebarProps.displayText}
            </Typography>
          }
        />
        {open ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List
          sx={{
            paddingX: '10px',
          }}
        >
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
      </Collapse>
    </>
  ) : null
}

export default SidebarItemCollapse
