import useLoadPluggableComponents from '../PluginsPanel/useLoadPluggableComponents'

export const PluginsPanelExt1 = () => {
  const extPoint = 'ext_1'

  const pluggableComponents = useLoadPluggableComponents(extPoint)

  return (
    <div>
      {pluggableComponents.map((component) => {
        const Component = component
        return <Component />
      })}
    </div>
  )
}

export default PluginsPanelExt1
