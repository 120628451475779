import React, { useEffect, useState } from 'react'
import PluginsStore from './store'
import PluginsLoader from './PluginsLoader'
import { LoadedPluginsList } from './LoadedPluginsList'
import { RouteType } from 'routes/config'
import useLoadPluggableComponents from './useLoadPluggableComponents'

interface EdpPluginComponent {
  component: React.ComponentType
  extensionPoint: string
}

export interface EdpPlugin {
  name?: string
  id: number
  version?: string
  enabled?: boolean
  components: EdpPluginComponent[]
  routes?: RouteType[]
}

declare global {
  interface Window {
    registerPlugin: () => EdpPlugin
  }
}

export const PluginsPanel = () => {
  const [pluginNameInputValue, setPluginNameInputValue] = useState('')
  const [pluginName, setPluginName] = useState<string>('')

  function loadPlugin() {
    return new Promise<void>((resolve, reject) => {
      try {
        const script = document.createElement('script')
        script.id = 'plugin'
        script.type = 'text/javascript'
        script.src = `http://edp.e42dev:3000/static/plugins/${pluginName}.js`
        script.onload = () => {
          const plugin = window.registerPlugin()
          plugin.enabled = false
          let plugins = PluginsStore.$('plugins').value
          plugins = [...plugins, plugin]
          PluginsStore.$('plugins').set(plugins)
        }

        document.getElementsByTagName('head')[0].appendChild(script)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  useEffect(() => {
    if (pluginName) {
      loadPlugin()
    }
  }, [pluginName])

  const handlePluginNameInputChange = (event: any) => {
    setPluginNameInputValue(event.target.value)
  }

  const handlePluginNameInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setPluginName(pluginNameInputValue)
    }
  }

  return (
    <div>
      <PluginsLoader />
      <div>Название плагина: </div>
      <input
        type="text"
        value={pluginNameInputValue}
        onChange={handlePluginNameInputChange}
        onKeyDown={handlePluginNameInputKeyDown}
      />
      <br />
      <LoadedPluginsList />
    </div>
  )
}

export default PluginsPanel
