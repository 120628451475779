import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import { FaDocker } from '@edp/core-common-frontend/dist/packages/react-icons/fa'
import { MdSettingsInputComponent } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { CustomButton } from '@edp/core-common-frontend'
import { ApiClient } from 'api/ApiClient'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import UserInteractionEnvironmentSelect from 'components/UserInteraction/UserInteractionEnvironmentSelect'
import UserInteractionServiceBranchesSelect from 'components/UserInteraction/UserInteractionServiceBranchesSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GitlabPipeline } from 'types/Controllers/Gitlab'
import PipelineVariables from '../Deploy/PipelineVariables'
import BuildStore from './store'

export const Build = observer(() => {
  const { uuid } = useParams()

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)
  const [variablesOpened, setVariablesOpened] = useState<boolean>(false)
  const [environment, setEnvironment] = useState<string>('')

  const apiClient = new ApiClient()

  const handleOpen = () => {
    BuildStore.reset()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  useEffect(() => {
    const getEnvironment = async () => {
      if (BuildStore.$('environment').value) {
        const data = await apiClient.getEnvironment(
          BuildStore.$('environment').value
        )
        setEnvironment(data.slug)
      } else {
        setEnvironment('')
      }
    }

    getEnvironment()
  }, [BuildStore.$('environment').value])

  useEffect(() => {
    const setVariables = async () => {
      try {
        BuildStore.$('variables').set([
          { key: 'ACTION', value: 'build' },
          { key: 'ENVIRONMENT', value: environment },
          { key: 'BRANCH_NAME', value: BuildStore.$('ref').value },
          { key: 'DOCKER_IMAGE_TAG', value: BuildStore.$('tag').value },
        ])
      } catch (e) {
        console.log(e)
      }
    }

    setVariables()
  }, [
    environment,
    BuildStore.$('ref').value,
    BuildStore.$('tag').value,
    BuildStore.$('environment').value,
  ])

  const build = async () => {
    setLoading(true)
    setErrorMessage('')

    if (uuid) {
      try {
        await BuildStore.validate().then(({ isValid }) => {
          if (!isValid) {
            setFailed(true)
            const e = new Error('Все обязательные поля должны быть заполнены')
            return Promise.reject(e)
          }
        })

        const spec: GitlabPipeline = {
          ref: BuildStore.$('ref').value,
          variables: BuildStore.$('variables').value,
        }

        await apiClient.startPipeline(uuid, spec)

        setCreated(true)
        setOpen(false)
      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.message)
          setFailed(true)
        } else {
          setErrorMessage('unknown')
          setFailed(true)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <CustomButton
        startIcon={<FaDocker />}
        onClick={handleOpen}
        sx={{
          width: '145px',
        }}
      >
        Собрать
      </CustomButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: '750px' } }}
        aria-labelledby="build-dialog"
        aria-describedby="build-dialog-description"
      >
        <DialogTitle>
          Собрать артефакт
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Grid
            sx={{
              display: 'grid',
              paddingTop: '10px',
            }}
          >
            <UserInteractionServiceBranchesSelect
              name="ref"
              description="Ветка репозитория"
              helperText="Ветка репозитория из которой будет производиться сборка."
              error={!BuildStore.$('ref').isValid}
              selected={BuildStore.$('ref').value}
              setSelected={(value) => BuildStore.$('ref').set(value)}
            />
            <UserInteractionEnvironmentSelect
              name="environment"
              description="Окружение"
              helperText="Окружение для которого будет производиться сборка."
              error={!BuildStore.$('environment').isValid}
              selected={BuildStore.$('environment').value}
              setSelected={(value) => BuildStore.$('environment').set(value)}
            />
            <UserInteractionTextField
              name="tag"
              description="Тег Docker образа"
              helperText="По умолчанию будет использоваться хеш коммита."
              {...BuildStore.$('tag').bind()}
            />
            <UserInteractionCustom
              name="check"
              description="Переменные pipeline"
              helperText="Переменные окружения, которые будут переданы в pipeline развертывания."
            >
              <IconButton
                onClick={() => setVariablesOpened(true)}
                sx={{ marginLeft: '-6px' }}
              >
                <MdSettingsInputComponent style={{ color: '#757575' }} />
              </IconButton>
              <PipelineVariables
                variables={BuildStore.$('variables').value}
                opened={variablesOpened}
                setOpened={setVariablesOpened}
              />
            </UserInteractionCustom>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton loading={loading} onClick={build}>
            Собрать
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomSnackbarComponent
        opened={created}
        setOpened={setCreated}
        message={'Сборка запущена'}
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={errorMessage}
        severity="error"
      />
    </>
  )
})

export default Build
