import { useState } from 'react'

import { Resources } from './EditEnvironmentPage/Resources'
import { BasicInfo } from './EditEnvironmentPage/BasicInfo'

import { EnvironmentSpec } from 'types/Managers/Environments'
import { ApiClient } from 'api/ApiClient'
import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import { Integrations } from './EditEnvironmentPage/Integrations'
import { useNavigate } from 'react-router-dom'

export const CreateEnvironmentPage = () => {
  const navigate = useNavigate()
  const [environment, setEnvironment] = useState<EnvironmentSpec>({
    name: '',
    slug: '',
    owner: '',
    cluster: '',
    description: '',
    quotas: {
      kubernetes: {
        requestsCpu: 0,
        requestsMemory: 0,
        limitsCpu: 0,
        limitsMemory: 0,
      },
    },
    integrations: {
      opensearch: {
        enabled: false,
        datastream: {
          name: '',
          number_of_shards: 2,
          refresh_interval: '10s',
          rollover_min_size: '50gb',
          rollover_min_index_age: '5s',
          rollover_min_primary_shard_size: '25gb',
          delete_min_index_age: '10m',
        },
      },
      sigstore: {
        enabled: false,
      },
      kubernetes: {
        enabled: false,
        namespace: '',
        role: '',
        roleBinding: '',
        limitRange: '',
        clusterOutput: '',
        clusterFlow: '',
        serviceMonitor: '',
      },
      vault: {
        enabled: false,
        kubernetesRole: '',
      },
      grafana: {
        dashboard: '',
      },
    },
  })

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const apiClient = new ApiClient()

  const handleCreate = async () => {
    setLoading(true)
    setCreated(false)
    setFailed(false)
    setErrorMessage('')
    try {
      const environmentSpec: EnvironmentSpec = {
        name: environment.name,
        slug: environment.slug,
        owner: environment.owner,
        cluster: environment.cluster,
        description: environment.description,
        quotas: {
          kubernetes: {
            requestsCpu: environment.quotas.kubernetes.limitsCpu,
            requestsMemory: environment.quotas.kubernetes.limitsMemory,
            limitsCpu: environment.quotas.kubernetes.limitsCpu,
            limitsMemory: environment.quotas.kubernetes.limitsMemory,
          },
        },
        integrations: {
          opensearch: {
            enabled: environment.integrations.opensearch.enabled,
            datastream: {
              name: environment.integrations.opensearch.enabled
                ? `${environment.name}`
                : undefined,
              number_of_shards: environment.integrations.opensearch.enabled
                ? environment.integrations.opensearch.datastream
                    ?.number_of_shards
                : undefined,
              refresh_interval: environment.integrations.opensearch.enabled
                ? environment.integrations.opensearch.datastream
                    ?.refresh_interval
                : undefined,
              rollover_min_size: environment.integrations.opensearch.enabled
                ? environment.integrations.opensearch.datastream
                    ?.rollover_min_size
                : undefined,
              rollover_min_index_age: environment.integrations.opensearch
                .enabled
                ? environment.integrations.opensearch.datastream
                    ?.rollover_min_index_age
                : undefined,
              rollover_min_primary_shard_size: environment.integrations
                .opensearch.enabled
                ? environment.integrations.opensearch.datastream
                    ?.rollover_min_primary_shard_size
                : undefined,
              delete_min_index_age: environment.integrations.opensearch.enabled
                ? environment.integrations.opensearch.datastream
                    ?.delete_min_index_age
                : undefined,
            },
          },
          sigstore: {
            enabled: environment.integrations.sigstore.enabled,
          },
          vault: {
            enabled: environment.integrations.vault.enabled,
            kubernetesRole: environment.integrations.vault.enabled
              ? environment.name
              : undefined,
          },
          kubernetes: {
            enabled: environment.integrations.kubernetes.enabled,
            namespace: environment.integrations.kubernetes.enabled
              ? environment.name
              : undefined,
            role: environment.integrations.kubernetes.enabled
              ? environment.name
              : undefined,
            roleBinding: environment.integrations.kubernetes.enabled
              ? environment.name
              : undefined,
            limitRange: environment.integrations.kubernetes.enabled
              ? environment.name
              : undefined,
            clusterOutput: environment.integrations.kubernetes.enabled
              ? environment.name
              : undefined,
            clusterFlow: environment.integrations.kubernetes.enabled
              ? environment.name
              : undefined,
            serviceMonitor: environment.integrations.kubernetes.enabled
              ? environment.name
              : undefined,
          },
          grafana: {
            dashboard: environment.integrations.grafana?.dashboard,
          },
        },
      }

      await apiClient.createEnvironment(environmentSpec)
      setCreated(true)
      navigate('/components/environments')
    } catch (e) {
      console.log(e)
      if (e instanceof Error) {
        setFailed(true)
        setErrorMessage(e.message)
      } else {
        setFailed(true)
        setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createdMessage={`Окружение ${environment.name} создано`}
    >
      <>
        <BasicInfo
          environment={environment}
          setEnvironment={setEnvironment}
          disabled={false}
        />
        <Resources
          environment={environment}
          setEnvironment={setEnvironment}
          disabled={false}
        />
        <Integrations
          environment={environment}
          setEnvironment={setEnvironment}
          disabled={false}
        />
      </>
    </CreateComponentPage>
  )
}

export default CreateEnvironmentPage
