import { CustomButton } from '@edp/core-common-frontend'
import { Close } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { ApiClient } from 'api/ApiClient'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { useEffect, useState } from 'react'
import { Permission } from 'types/rbac/permission'
import { Role } from 'types/rbac/role'
import RolesTransferList from './RolesTransferList'
import './styles.css'

export enum RoleActions {
  Create,
  Update,
}

export interface EditRoleProps {
  opened: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  created: boolean
  setCreated: React.Dispatch<React.SetStateAction<boolean>>
  updated: boolean
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
  failed: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  action: RoleActions
  uuid?: string
}

export const EditRole = (props: EditRoleProps) => {
  const [name, setName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [rolePermissions, setRolePermissions] = useState<Permission[]>([])

  const apiClient = new ApiClient()

  const handleClose = () => props.setOpened(false)

  const getRole = async () => {
    try {
      if (props.action === RoleActions.Update && props.uuid && props.opened) {
        const data = await apiClient.getRole(props.uuid)
        setRolePermissions(data.permissions)
        setName(data.name)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRole()
  }, [props.opened])

  const handleCreate = async () => {
    setName('')
    setRolePermissions([])

    props.setCreated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    try {
      setLoading(true)

      const permissions =
        rolePermissions.map((permission) => permission.uuid || '') || []

      const spec: Role = {
        name: name,
        permissions: permissions,
      }

      await apiClient.createRole(spec)
      props.setCreated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleUpdate = async () => {
    props.setUpdated(false)
    props.setFailed(false)
    props.setErrorMessage('')

    try {
      setLoading(true)

      const permissions =
        rolePermissions.map((permission) => permission.uuid || '') || []

      const spec: Role = {
        uuid: props.uuid,
        name: name,
        permissions: permissions,
      }

      await apiClient.updateRole(spec)
      props.setUpdated(true)
      props.setOpened(false)
    } catch (e) {
      if (e instanceof Error) {
        props.setFailed(true)
        props.setErrorMessage(e.message)
      } else {
        props.setFailed(true)
        props.setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog
        PaperProps={{ sx: { maxWidth: '900px' } }}
        open={props.opened}
        onClose={handleClose}
      >
        <DialogTitle>
          {props.action === RoleActions.Create && 'Создание роли'}
          {props.action === RoleActions.Update && 'Изменение роли'}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Typography
            component={'span'}
            variant="caption"
            sx={{ paddingTop: '15px' }}
          >
            Роль - это набор политик доступа, разрешающих производить действия с
            ресурсами EDP. Укажите название роли и выберите политики, которые вы
            хотите включить в данную роль.
          </Typography>
          <div style={{ paddingTop: '15px' }}>
            <UserInteractionTextField
              name="name"
              description="Название"
              helperText="Название роли."
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>

          <div style={{ paddingTop: '25px' }}>
            <RolesTransferList
              rolePermissions={rolePermissions}
              setRolePermissions={setRolePermissions}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right', alignSelf: 'end' }}>
          {props.action === RoleActions.Create && (
            <CustomButton onClick={handleCreate} loading={loading}>
              Создать
            </CustomButton>
          )}
          {props.action === RoleActions.Update && (
            <CustomButton onClick={handleUpdate} loading={loading}>
              Обновить
            </CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditRole
