import React from 'react'

import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Typography, Tooltip } from '@mui/material'
import Editor from 'react-simple-code-editor'
import { Grammar, highlight, languages } from 'prismjs'

import 'prismjs/components/prism-yaml'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-markdown'
import 'prismjs/themes/prism.css'
import './styles.css'

export interface UserInteractionHighlightedInputProps {
  name: string
  description: string
  helperText: string
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  language: string
  minHeight?: string
  disabled?: boolean
}

const UserInteractionHighlightedInput = (
  props: UserInteractionHighlightedInputProps
) => {
  const { name, description, helperText, value, setValue, minHeight } = props

  let grammar: Grammar
  let language: string

  switch (props.language) {
    case 'yaml': {
      grammar = languages.yaml
      language = 'language-yaml'
      break
    }
    case 'json': {
      grammar = languages.json
      language = 'language-json'
      break
    }
    case 'markdown': {
      grammar = languages.markdown
      language = 'language-markdown'
      break
    }
  }

  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {description}
        </Typography>
        <Tooltip arrow title={helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <Editor
        disabled={props.disabled}
        name={name}
        className="UserInteraction-HighlightedInput-Editor"
        value={value}
        onValueChange={(value) => setValue(value)}
        highlight={(value) => highlight(value, grammar, language)}
        padding={10}
        style={{
          minHeight: `${minHeight || '40px'}`,
          fontSize: '14px',
        }}
      />
    </Grid>
  )
}

export default UserInteractionHighlightedInput
