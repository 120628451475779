import { Typography } from '@mui/material'
import UserInteractionCredentialsSelect from 'components/UserInteraction/UserInteractionCredentialsSelect'
import UserInteractionInfraServiceSelect from 'components/UserInteraction/UserInteractionInfraServiceSelect'
import UserInteractionSlider from 'components/UserInteraction/UserInteractionSlider'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import React from 'react'
import { INFRA_SERVICE_GITLAB } from 'types/Managers/InfraServices'
import { RepositorySpec } from 'types/Managers/Repositories'

export interface BasicInfoProps {
  repository: RepositorySpec
  setRepository: React.Dispatch<React.SetStateAction<RepositorySpec>>
  disabled: boolean
}

const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setRepository((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setOwner: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setRepository((prevState) => ({
      ...prevState,
      owner: value instanceof Function ? value(prevState.owner) : value,
    }))
  }

  const setCredentials: React.Dispatch<React.SetStateAction<string>> = (
    value
  ) => {
    props.setRepository({
      ...props.repository,
      RepositoryMirroring: {
        ...props.repository.RepositoryMirroring,
        credentials:
          value instanceof Function
            ? value(props.repository.RepositoryMirroring.credentials || '')
            : value,
      },
    })
  }

  const setSource: React.Dispatch<React.SetStateAction<string>> = (value) => {
    props.setRepository({
      ...props.repository,
      RepositoryMirroring: {
        ...props.repository.RepositoryMirroring,
        source:
          value instanceof Function
            ? value(props.repository.RepositoryMirroring.source || '')
            : value,
      },
    })
  }

  function valueLabelFormatSyncFrequency(value: number) {
    return `${value / 60} мин.`
  }

  const marksSyncFrequency = [
    {
      value: 60,
      label: '1 мин.',
    },
    {
      value: 3600,
      label: '60 мин.',
    },
  ]

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название репозитория."
        value={props.repository.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор репозитория в инфраструктурных сервисах. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        value={props.repository.slug || ''}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание репозитория."
        value={props.repository.description}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTeamSelect
        name="owner"
        description="Владелец"
        helperText="Команда владеющая репозиторием."
        selected={props.repository.owner}
        setSelected={setOwner}
      />
      <UserInteractionTextField
        name="url"
        description="URL"
        helperText="Ссылка на репозиторий. Должна задаваться в формате https://.../repo.git. Например, https://git.example.com/example.git."
        value={props.repository.url}
        onChange={handleChange}
      />
      <UserInteractionSwitch
        name="isTemplate"
        description="Шаблонный репозиторий"
        helperText="Шаблонные репозитории используются для создания новых сервисов."
        checked={props.repository.isTemplate}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setRepository({
            ...props.repository,
            isTemplate: event.target.checked,
          })
        }
      />
      <UserInteractionSwitch
        name="RepositoryMirroring"
        description="Зеркалирование"
        helperText="Включить зеркалирование репозитория."
        checked={props.repository.RepositoryMirroring.enabled}
        disabled={props.disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setRepository({
            ...props.repository,
            RepositoryMirroring: {
              ...props.repository.RepositoryMirroring,
              enabled: event.target.checked,
            },
          })
        }
      />
      {props.repository.RepositoryMirroring.enabled ? (
        <>
          <UserInteractionSlider
            name="syncFrequency"
            description="Частота обновления"
            disabled={props.disabled}
            helperText="Периодичность синхронизации репозитория."
            value={props.repository.RepositoryMirroring.syncFrequency || 60}
            step={60}
            min={60}
            max={3600}
            marks={marksSyncFrequency}
            valueLabelFormat={valueLabelFormatSyncFrequency}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setRepository({
                ...props.repository,
                RepositoryMirroring: {
                  ...props.repository.RepositoryMirroring,
                  syncFrequency: Number(event.target.value),
                },
              })
            }
          />
          <UserInteractionTextField
            name="remoteUrl"
            description="Зеркалируемый репозиторий"
            disabled={props.disabled}
            helperText="Ссылка на зеркалируемый репозиторий."
            value={props.repository.RepositoryMirroring.remoteUrl || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setRepository({
                ...props.repository,
                RepositoryMirroring: {
                  ...props.repository.RepositoryMirroring,
                  remoteUrl: event.target.value,
                },
              })
            }
          />
          <UserInteractionInfraServiceSelect
            name="source"
            description="Источник репозитория"
            disabled={props.disabled}
            helperText="Источник репозитория. Для зеркалирования из внешней системы (не имеющей отношения к EDP) выберите external и укажите учетные данные для авторизации в зеркалируемом репозитории (опционально)."
            type={INFRA_SERVICE_GITLAB}
            selected={props.repository.RepositoryMirroring.source || ''}
            setSelected={setSource}
          />
          {props.repository.RepositoryMirroring.source === 'external' && (
            <UserInteractionCredentialsSelect
              name="credentials"
              description="Учетные данные"
              disabled={props.disabled}
              helperText="Учетные данные для авторизации в зеркалируемом репозитории."
              selected={props.repository.RepositoryMirroring.credentials || ''}
              setSelected={setCredentials}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default BasicInfo
