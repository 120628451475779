import React, { useState, ChangeEvent } from 'react'
import pako from 'pako'
import tar from 'tar-stream'

function PluginsLoader(): JSX.Element {
  const [files, setFiles] = useState<string[]>([])

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0]
    if (!file) return

    try {
      const fileContent = await readFile(file)
      const unpackedFiles = await unpackTarGz(fileContent)
      setFiles(unpackedFiles)
    } catch (error) {
      console.error('Error handling file:', error)
    }
  }

  const readFile = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as ArrayBuffer)
      reader.onerror = () => reject(reader.error)
      reader.readAsArrayBuffer(file)
    })
  }

  const unpackTarGz = async (fileContent: ArrayBuffer): Promise<string[]> => {
    const gunzipped = pako.inflate(new Uint8Array(fileContent), {
      to: 'string',
    })
    const extract = tar.extract()

    return new Promise((resolve, reject) => {
      const files: string[] = []

      extract.on('entry', (header, stream, next) => {
        if (!header.name.endsWith('/')) {
          files.push(header.name)
        }
        stream.on('end', next)
        stream.resume()
      })

      extract.on('finish', () => {
        resolve(files)
      })

      extract.on('error', (error) => {
        reject(error)
      })

      extract.end(Buffer.from(gunzipped))
    })
  }

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        accept=".tar.gz application/gzip"
      />
      <ul>
        {files.map((file, index) => (
          <li key={index}>{file}</li>
        ))}
      </ul>
    </div>
  )
}

export default PluginsLoader
