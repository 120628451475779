import {
  NotInterested,
  Pending,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { IoGitMergeOutline } from '@edp/core-common-frontend/dist/packages/react-icons/io5'

export enum MRState {
  Opened = 'opened',
  Closed = 'closed',
  Merged = 'merged',
  Locked = 'locked',
}

export const MRStateColors = {
  [MRState.Opened]: {
    backgroundColor: '#ADD8E6',
    color: '#0000FF',
    icon: <Pending />,
  },
  [MRState.Locked]: {
    backgroundColor: '#ADD8E6',
    color: '#0000FF',
    icon: <IoGitMergeOutline />,
  },
  [MRState.Merged]: {
    backgroundColor: '#CAE5CF',
    color: '#2B5834',
    icon: <IoGitMergeOutline />,
  },
  [MRState.Closed]: {
    backgroundColor: '#D3D3D3',
    color: '#696969',
    icon: <NotInterested />,
  },
}
