import { CustomCardHeader } from '@edp/core-common-frontend'
import {
  Alert,
  Card,
  CardContent,
  LinearProgress,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { React } from '@edp/core-common-frontend/dist/packages/react'
import { ServiceMetadata } from '@edp/types'
import { DockerTag } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { ArtifactsTable } from './ArtifactsTable'

export interface ArtifactsCardProps {
  uuid: string
}

export const ArtifactsCard = (props: ArtifactsCardProps) => {
  const [tags, setTags] = React.useState<DockerTag[]>([])
  const [serviceMetadata, setServiceMetadata] =
    React.useState<ServiceMetadata>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [failed, setFailed] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const apiClient = new ApiClient()

  const getTags = async () => {
    try {
      setLoading(true)
      setErrorMessage('')
      setFailed(false)

      const data = await apiClient.getServiceTags(props.uuid)

      const metadata: ServiceMetadata = {
        dockerRegistryUrl: data.dockerRegistryUrl,
        dockerRegistryName: data.dockerRegistryName,
        dockerRegistryType: data.dockerRegistryType,
        dockerImageName: data.dockerImageName,
      }

      setTags(data.tags)
      setServiceMetadata(metadata)
    } catch (e) {
      console.error(e)
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('unknown')
      }
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getTags()
  }, [])

  return (
    <Card sx={{ minHeight: '400px', boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Артефакты сборки"
        description="Docker образы сервиса в хранилище артефактов"
        fullPage={false}
      />
      {loading && <LinearProgress />}
      {!loading && failed && <Alert severity="error">{errorMessage}</Alert>}
      {!loading && !failed && (
        <CardContent>
          <ArtifactsTable
            uuid={props.uuid}
            tags={tags}
            serviceMetadata={serviceMetadata}
          />
        </CardContent>
      )}
    </Card>
  )
}

export default ArtifactsCard
