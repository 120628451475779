import { CustomButton } from '@edp/core-common-frontend'
import { ServiceSpec } from '@edp/types'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import {
  StatusFillingServiceFields,
  StatusFillingServiceSpec,
} from 'utils/countingFillingServiceFields'
import { ServiceSummaryFiltered } from './utils'

export interface ServiceSummaryDownloadButtonProps {
  services: ServiceSpec[]
  selectedTeams: string[]
  selectedLifecycleStages: string[]
  statusService: Map<string, StatusFillingServiceSpec>
}

export const ServiceSummaryDownloadButton = (
  props: ServiceSummaryDownloadButtonProps
) => {
  const generateData = () => {
    const data = []

    for (const service of props.services) {
      if (
        !ServiceSummaryFiltered(
          props.selectedTeams,
          props.selectedLifecycleStages,
          service
        )
      ) {
        continue
      }

      if (service.uuid) {
        const status = props.statusService.get(service.uuid)
        if (status) {
          data.push({
            ['Сервис']: service.name,
            ['Команда']: service.ownerRef?.name,
            ['Текущая стадия']: service.lifecycleStageRef?.name,
            ['Namespaces']: status.filledFields.includes(
              StatusFillingServiceFields.Namespaces
            ),
            ['Helm релизы']: status.filledFields.includes(
              StatusFillingServiceFields.HelmReleases
            ),
            ['Swagger']: status.filledFields.includes(
              StatusFillingServiceFields.Swagger
            ),
            ['Стадия']: status.filledFields.includes(
              StatusFillingServiceFields.Lifecycle
            ),
            ['Grafana']: status.filledFields.includes(
              StatusFillingServiceFields.Grafana
            ),
            ['Registry']: status.filledFields.includes(
              StatusFillingServiceFields.Registry
            ),
          })
        }
      }
    }

    return data
  }

  const downloadCSVData = () => {
    const now = new Date()
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename: `ServicesSummary-${now.toLocaleDateString()}-${now.getHours()}-${now.getMinutes()}`,
    })

    const data = generateData()

    if (data.length !== 0) {
      const csv = generateCsv(csvConfig)(data)
      download(csvConfig)(csv)
    }
  }

  return (
    <CustomButton onClick={() => downloadCSVData()}>Скачать CSV</CustomButton>
  )
}

export default ServiceSummaryDownloadButton
