import { useEffect, useState } from 'react'
import PluginsStore from './store'
import { EdpPlugin } from './PluginsPanel'

const useLoadPluggableComponents = (extensionPoint: string) => {
  const [components, setComponents] = useState<React.ComponentType[]>([])

  useEffect(() => {
    const plugins = PluginsStore.$('plugins').value as EdpPlugin[]

    let loadedComponents: React.ComponentType[] = []

    for (const plugin of plugins) {
      if (plugin.enabled) {
        for (const component of plugin.components) {
          if (component.extensionPoint == extensionPoint) {
            loadedComponents = [...loadedComponents, component.component]
            setComponents(loadedComponents)
          }
        }
      }
    }
  }, [PluginsStore.$('plugins').value])

  return components
}

export default useLoadPluggableComponents
