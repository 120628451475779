import React, { forwardRef, useEffect, useState } from 'react'
import './styles.css'
import { ApiClient } from 'api/ApiClient'
import UserInteractionCustomSelect from './UserInteractionCustomSelect'

export interface UserInteractionServiceTemplateVersionSelectProps {
  templateUUID: string
  name: string
  description: string
  helperText: string
  error?: boolean
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  setOptions?: React.Dispatch<React.SetStateAction<Array<string>>>
}

const UserInteractionServiceTemplateVersionSelect = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: UserInteractionServiceTemplateVersionSelectProps, _ref) => {
    const [serviceTemplateVersions, setServiceTemplateVersions] = useState<
      string[]
    >([])

    useEffect(() => {
      const apiClient = new ApiClient()

      const getServiceTemplateVersions = async () => {
        try {
          const data = await apiClient.getServiceTemplateVersions(
            props.templateUUID
          )
          setServiceTemplateVersions(data)

          if (props.setOptions) {
            props.setOptions(data)
          }
        } catch (e) {
          console.log('При получении данных возникла ошибка:', e)
        }
      }

      getServiceTemplateVersions()
    }, [props.templateUUID])

    return (
      <UserInteractionCustomSelect
        name={props.name}
        description={props.description}
        key={''}
        helperText={props.helperText}
        options={serviceTemplateVersions}
        error={props.error}
        selected={props.selected}
        setSelected={props.setSelected}
      />
    )
  }
)

UserInteractionServiceTemplateVersionSelect.displayName =
  'UserInteractionServiceTemplateVersionSelect'

export default UserInteractionServiceTemplateVersionSelect
