import { CustomCardHeader } from '@edp/core-common-frontend'
import { Alert, Card, CardContent, LinearProgress } from '@mui/material'
import { MergeRequestsTable } from './MergeRequestsTable'
import { GitlabMergeRequest } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { useAsync } from 'react-use'

export interface MergeRequestsCardProps {
  uuid: string
}

export const MergeRequestsCard = (props: MergeRequestsCardProps) => {
  const apiClient = new ApiClient()

  const { value, loading, error } = useAsync(async (): Promise<
    GitlabMergeRequest[]
  > => {
    const gitlabObj = await apiClient.getMergeRequestsSummary(props.uuid)
    const data = gitlabObj?.getMergeRequestsData
    const renderData: any = { data }
    return renderData
  }, [])

  return (
    <Card sx={{ minHeight: '400px', boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Merge requests"
        description="Информация о MR сервиса"
        fullPage={false}
      />
      {loading && <LinearProgress />}
      {!loading && error && <Alert severity="error">{error.message}</Alert>}
      {!loading && !error && (
        <CardContent>
          <MergeRequestsTable mergeRequests={value || []} />
        </CardContent>
      )}
    </Card>
  )
}
