import { ServiceInfoValidateSpec } from '@edp/types'
import { Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

type ServiceTemplateValidateIconProps = {
  status?: ServiceInfoValidateSpec
  tooltipClass?: string
}

export const ServiceTemplateValidateIcon = (
  props: ServiceTemplateValidateIconProps
) => {
  if (props.status) {
    let text = `Шаблон сервиса имеет проблемы с конфигурацией`

    if (props.status.errors) {
      // Структура

      let messageSpec = ''

      for (const error of props.status.errors) {
        if (error.path) {
          messageSpec = `${messageSpec}\nФайл: ${error.filename}\nПуть: ${error.path}\nОшибка: ${error.message}`
        }
      }

      if (messageSpec !== '') {
        text = `${text}\n\nПроблемы со структурой конфигурационного файла: \n${messageSpec}`
      }

      // Другие ошибки:

      let messageOtherErrors = ''
      for (const error of props.status.errors) {
        if (
          typeof error.variable === 'undefined' &&
          typeof error.path === 'undefined'
        ) {
          messageOtherErrors = `${messageOtherErrors}\n${error.message}`
        }
      }

      if (messageOtherErrors !== '') {
        text = `${text}\n\nОшибки:\n${messageOtherErrors}`
      }
    }

    return (
      <Tooltip title={text} className={props.tooltipClass}>
        <InfoIcon />
      </Tooltip>
    )
  } else {
    return <></>
  }
}
