import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
  CustomSelectProps,
} from '@edp/core-common-frontend'
import { LifecycleStageSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'

export interface LifecycleStageSelectProps extends CustomSelectProps {}

export const LifecycleStageSelect = (props: LifecycleStageSelectProps) => {
  const [lifecycleStages, setLifecycleStages] = useState<LifecycleStageSpec[]>()

  useEffect(() => {
    const apiClient = new ApiClient()

    const getLifecycleStages = async () => {
      try {
        const data = await apiClient.getLifecycleStages()
        setLifecycleStages(data)
      } catch (e) {
        console.log(
          'Возникла ошибка при получении стадий жизненного цикла: ',
          e
        )
      }
    }

    getLifecycleStages()
  }, [])

  const options: CustomSelectComponentUUIDOption[] =
    lifecycleStages?.map((o) => ({
      label: o.name,
      uuid: o.uuid,
    })) || []

  return <CustomSelectComponentUUID options={options} {...props} />
}

export default LifecycleStageSelect
