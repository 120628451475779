import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Tooltip, Typography } from '@mui/material'
import CredentialsSelectComponent from '../CredentialsSelect/CredentialsSelect'

export interface UserInteractionCredentialsSelectProps {
  name: string
  description: string
  helperText: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
}

const UserInteractionCredentialsSelect = (
  props: UserInteractionCredentialsSelectProps
) => {
  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {props.description}
        </Typography>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <CredentialsSelectComponent
        selected={props.selected}
        setSelected={props.setSelected}
        disabled={props.disabled}
      />
    </Grid>
  )
}

export default UserInteractionCredentialsSelect
