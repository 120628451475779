import {
  DataGrid,
  GridColDef,
} from '@edp/core-common-frontend/dist/packages/mui/x-data-grid'
import { FaTag } from '@edp/core-common-frontend/dist/packages/react-icons/fa'
import { MdCalendarToday } from '@edp/core-common-frontend/dist/packages/react-icons/md'
import { RxAvatar } from '@edp/core-common-frontend/dist/packages/react-icons/rx'
import { Chip, Link, Typography } from '@mui/material'
import moment from 'moment'
import { GitlabTag } from 'types/index'

export interface TagsTableProps {
  tags: GitlabTag[]
}

export const TagsTable = (props: TagsTableProps) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Chip
              label={params.row.name}
              size="small"
              variant="filled"
              icon={<FaTag style={{ color: '#2B5834' }} size={'12px'} />}
              sx={{
                minWidth: '80px',
                backgroundColor: '#CAE5CF',
                color: '#2B5834',
                border: 'none',
                justifyContent: 'left',
              }}
            />
          </div>
        )
      },
    },
    {
      field: 'commit_short_id',
      headerName: 'Commit',
      minWidth: 200,
      renderCell: (params) => (
        <div>
          <div>
            <Link
              href={params.row.commit_web_url}
              target="_blank"
              rel="noreferrer"
            >
              {params.row.commit_short_id}
            </Link>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RxAvatar />
            <Typography sx={{ paddingLeft: '5px' }} variant="caption">
              {params.row.commit_author}
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MdCalendarToday />
            <Typography sx={{ paddingLeft: '5px' }} variant="caption">
              {moment(params.row.commit_date).format('DD.MM.YYYY HH:mm:ss')}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      field: 'commit_message',
      headerName: 'Commit message',
      width: 500,
      renderCell: (params) => {
        return (
          <Typography
            variant="caption"
            sx={{ paddingBottom: '5px', paddingTop: '5px' }}
          >
            {params.row.commit_message}
          </Typography>
        )
      },
    },
  ]

  const rows = props.tags.map((tag: GitlabTag) => {
    return {
      name: tag.name,
      message: tag.message,
      commit_short_id: tag.commit.short_id,
      commit_web_url: tag.commit.web_url,
      commit_author: tag.commit.author_name,
      commit_message: tag.commit.message,
      commit_date: tag.commit.created_at,
    }
  })

  return (
    <DataGrid
      getRowId={(row) => row.name}
      columns={columns}
      rows={rows}
      pageSize={5}
      rowsPerPageOptions={[5]}
      autoHeight={true}
      autoPageSize
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => 'auto'}
    />
  )
}
