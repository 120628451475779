import { Card, CardActions, CardContent, Grid, Link } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { CustomButton } from '@edp/core-common-frontend'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  RepositoryMirroringStatus,
  RepositorySpec,
} from 'types/Managers/Repositories'

import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import { CredentialsBaseSpec } from 'types/Credentials'
import './style.css'

export const GitMirrorPanel = () => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const [repository, setRepository] = useState<RepositorySpec>()
  const [repositoryCredentials, setRepositoryCredentials] =
    useState<CredentialsBaseSpec>()

  const [started, setStarted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)

  const getRepository = async () => {
    try {
      if (uuid) {
        const data = await apiClient.getRepository(uuid)
        setRepository(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getCredentialsBaseSpec = async () => {
    try {
      if (repository?.RepositoryMirroring.credentials) {
        const data = await apiClient.getCredentials(
          repository.RepositoryMirroring.credentials
        )
        setRepositoryCredentials(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getRepository()
  }, [])

  useEffect(() => {
    getCredentialsBaseSpec()
  }, [repository])

  const handleButtonSync = () => {
    try {
      if (uuid) {
        setStarted(false)
        setErrorMessage('')
        setLoading(true)

        apiClient
          .runRepositorySync(uuid)
          .then(() => {
            setStarted(true)
            setLoading(false)
          })
          .catch((e) => {
            setLoading(false)
            setErrorMessage(e.message)
            setFailed(true)
          })
      }
    } catch (e) {
      setLoading(false)
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Grid>
            <b>Оригинальный репозиторий:</b>{' '}
            <Link href={repository?.RepositoryMirroring.remoteUrl}>
              {repository?.RepositoryMirroring.remoteUrl}
            </Link>
          </Grid>
          <Grid>
            <b>Зеркало:</b>{' '}
            <Link href={repository?.url}>{repository?.url}</Link>
          </Grid>
          <Grid>
            <b>Время последней синхронизации: </b>{' '}
            {String(repository?.RepositoryMirroring.lastSync)}
          </Grid>
          <Grid>
            <b>Время следующей синхронизации:</b>{' '}
            {String(repository?.RepositoryMirroring.nextSync)}
          </Grid>
          <Grid>
            {repository?.RepositoryMirroring.credentials && (
              <>
                <b>Учетные данные для авторизации:</b>
                {String(repositoryCredentials?.name)}
              </>
            )}
          </Grid>
          <Grid>
            <b>Периодичность синхронизации:</b> раз в{' '}
            {String(Number(repository?.RepositoryMirroring.syncFrequency) / 60)}{' '}
            мин.
          </Grid>
          <Grid>
            <b>Статус последней синхронизации:</b>
            {repository?.RepositoryMirroring.status ===
              RepositoryMirroringStatus.UNKNOWN && ' неизвестно'}
            {repository?.RepositoryMirroring.status ===
              RepositoryMirroringStatus.ERROR && ' ошибка'}
            {repository?.RepositoryMirroring.status ===
              RepositoryMirroringStatus.SUCCESS && ' успешно'}
          </Grid>
        </CardContent>
        <CardActions>
          <CustomButton loading={loading} onClick={handleButtonSync}>
            Синхронизировать
          </CustomButton>
        </CardActions>
      </Card>
      <CustomSnackbarComponent
        opened={started}
        setOpened={setStarted}
        message={'Синхронизация успешно запущена'}
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={`При запуске синхронизации произошла ошибка. ${errorMessage}`}
        severity="error"
      />
    </>
  )
}
