import { ApiClient } from 'api/ApiClient'
import { InstanceSpec } from 'types/Managers/Instances'
import { KubernetesListObject, KubernetesObject } from '@kubernetes/client-node'
import { getAuthType, getJWT } from 'api/utils'
import { GrafanaDashboardPanelResponse } from 'common/grafana'
import { handleResponse } from '@edp/core-common-frontend'

export async function createInstance(
  this: ApiClient,
  instance: InstanceSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/instances`

  const body = JSON.stringify(instance)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })
  return await handleResponse(response)
}

export async function getInstances(this: ApiClient): Promise<InstanceSpec[]> {
  const url = `${this.serverUrl}/instances`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getInstancesBy(
  this: ApiClient,
  filterName: string,
  filterValue: string
): Promise<InstanceSpec[]> {
  const url = `${this.serverUrl}/instances?filterName=${filterName}&filterValue=${filterValue}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await handleResponse(response)
}

export async function getInstance(
  this: ApiClient,
  uuid: string
): Promise<InstanceSpec> {
  const url = `${this.serverUrl}/instances/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updateInstance(
  this: ApiClient,
  spec: InstanceSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/instances/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function deletePod(
  this: ApiClient,
  uuid: string,
  namespace: string,
  pod: string
) {
  const url = `${this.serverUrl}/instances/${uuid}/pods/${namespace}/${pod}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getInstanceResources(
  this: ApiClient,
  uuid: string
): Promise<KubernetesListObject<KubernetesObject>[]> {
  const url = `${this.serverUrl}/instances/${uuid}/resources`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getInstanceGrafanaPanels(
  this: ApiClient,
  uuid: string
): Promise<GrafanaDashboardPanelResponse> {
  const url = `${this.serverUrl}/instances/${uuid}/grafana/panels`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getInstanceSecretsUrl(
  this: ApiClient,
  uuid: string
): Promise<string> {
  const url = `${this.serverUrl}/instances/${uuid}/secrets/url`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function deleteInstance(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/instances/${uuid}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
