import { ApiClient } from 'api/ApiClient'
import CreateComponentPage from 'components/CreateComponentPage/CreateComponentPage'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  RepositoryMirroringStatus,
  RepositoryMirroringType,
  RepositorySpec,
} from 'types/Managers/Repositories'
import BasicInfo from './EditRepositoryPage/BasicInfo'

export const CreateRepositoryPage = () => {
  const navigate = useNavigate()

  const [repository, setRepository] = useState<RepositorySpec>({
    uuid: '',
    name: '',
    slug: '',
    owner: '',
    description: '',
    url: '',
    isTemplate: false,
    RepositoryMirroring: {
      enabled: false,
      type: RepositoryMirroringType.PULL,
      credentials: '',
      syncFrequency: 60,
      source: '',
      status: RepositoryMirroringStatus.UNKNOWN,
      remoteUrl: '',
    },
  })

  const [created, setCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const apiClient = new ApiClient()

  const handleCreate = async () => {
    setCreated(false)

    if (!repository.name || !repository.owner || !repository.url) {
      setLoading(false)
      setErrorMessage('Все поля должны быть заполнены')
      setFailed(true)
      return
    }

    setErrorMessage('')
    setLoading(true)

    try {
      const spec: RepositorySpec = {
        uuid: repository.uuid,
        name: repository.name,
        slug: repository.slug,
        description: repository.description,
        owner: repository.owner,
        isTemplate: repository.isTemplate,
        url: repository.url,
        RepositoryMirroring: {
          enabled: repository.RepositoryMirroring.enabled,
          type: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.type
            : undefined,
          credentials: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.credentials
            : undefined,
          syncFrequency: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.syncFrequency
            : undefined,
          source: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.source
            : undefined,
          remoteUrl: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.remoteUrl
            : undefined,
          status: repository.RepositoryMirroring.enabled
            ? repository.RepositoryMirroring.status
            : undefined,
        },
      }

      await apiClient.createRepository(spec)

      setCreated(true)
      navigate('/components/repositories')
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
        setFailed(true)
      } else {
        setErrorMessage('unknown')
        setFailed(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CreateComponentPage
      handleCreate={handleCreate}
      loading={loading}
      created={created}
      setCreated={setCreated}
      failed={failed}
      setFailed={setFailed}
      errorMessage={errorMessage}
      createdMessage={`Репозиторий ${repository.name} зарегистрирован.`}
      createButtonText="Зарегистрировать"
    >
      <BasicInfo
        repository={repository}
        setRepository={setRepository}
        disabled={false}
      />
    </CreateComponentPage>
  )
}
export default CreateRepositoryPage
