import { ServiceInfoValidateSpec } from '@edp/types'
import { Alert, AlertColor, Grid } from '@mui/material'
import './style.css'
import { ServiceTemplateValidateIcon } from 'components/ServiceTemplateValidateIcon'

type ServiceTemplateVersionErrorProps = {
  severity: AlertColor
  text: string
  status?: ServiceInfoValidateSpec
}

export const ServiceTemplateVersionError = (
  props: ServiceTemplateVersionErrorProps
) => {
  return (
    <Grid className="VersionAlertErrorGrid">
      <Alert
        className="VersionAlertError"
        severity={props.severity}
        classes={{
          message: 'VersionAlertErrorMessage',
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: props.text }} />
        <ServiceTemplateValidateIcon
          status={props.status}
          tooltipClass="VersionAlertErrorTooltip"
        />
      </Alert>
    </Grid>
  )
}
