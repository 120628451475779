import { TeamSpec } from '@edp/types'
import { Typography } from '@mui/material'
import UserInteractionTeamSelect from 'components/UserInteraction/UserInteractionTeamSelect'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import React from 'react'

export interface BasicInfoProps {
  disabled: boolean
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

const BasicInfo = (props: BasicInfoProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.setTeam((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const setParentTeam: React.Dispatch<React.SetStateAction<string>> = (
    value
  ) => {
    props.setTeam((prevState) => ({
      ...prevState,
      parentTeam:
        value instanceof Function ? value(prevState.parentTeam || '') : value,
    }))
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Базовая информация
      </Typography>
      <UserInteractionTextField
        name="name"
        description="Название"
        helperText="Название команды."
        value={props.team.name}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="slug"
        description="Идентификатор"
        helperText="Идентификатор команды в инфраструктурных сервисах. Должен быть уникальным в рамках EDP. Допустимая длина — от 1 до 63 символов. Может содержать строчные буквы латинского алфавита, цифры и дефисы."
        value={props.team.slug || ''}
        onChange={handleChange}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание команды."
        value={props.team.description}
        onChange={handleChange}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTextField
        name="responsible"
        description="Ответственный"
        helperText="Ответственный за команду."
        value={props.team.responsible || ''}
        onChange={handleChange}
      />
      <UserInteractionTeamSelect
        name="parentTeam"
        description="Родительская команда"
        helperText="Структура верхнего уровня в иерархии организации."
        selected={props.team.parentTeam || ''}
        setSelected={setParentTeam}
      />
    </>
  )
}

export default BasicInfo
