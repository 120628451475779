import { useEffect, useState } from 'react'

import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'api/ApiClient'
import { TbUserCircle } from '@edp/core-common-frontend/dist/packages/react-icons/tb'
import { KeycloakUser } from 'types/Keycloak'
import { NothingFoundPage } from '@edp/core-common-frontend'

export const TeamMembersPanel = () => {
  const { uuid } = useParams()
  const [teamMembers, setTeamMembers] = useState<KeycloakUser[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const apiClient = new ApiClient()

    const getTeams = async () => {
      try {
        setLoading(true)
        const data = await apiClient.getKeycloakGroupMembers(uuid || '')
        setTeamMembers(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getTeams()
  }, [uuid])

  return (
    <>
      {!loading && teamMembers.length === 0 && (
        <NothingFoundPage
          text={'Попробуйте добавить участников в группу в Keycloak.'}
        />
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
            gridAutoRows: '1fr',
            gridGap: 10,
          }}
        >
          {teamMembers?.map((member) => (
            <Card sx={{ textAlign: 'center', alignContent: 'center' }}>
              <CardContent>
                <TbUserCircle />
                <Typography variant="h5" component="div">
                  {member.username}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {member.email}
                </Typography>
                <Typography sx={{ paddingTop: '10px' }} variant="h6">
                  {member.firstName} {member.lastName}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </>
  )
}

export default TeamMembersPanel
