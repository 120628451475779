import { Button, Chip, CircularProgress, Grid, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useParams } from 'react-router-dom'
import { ComponentCard } from 'components/ComponentCard'
import { EnvironmentsStatusCard } from './components/EnvironmentStatusCard'
import { useFetchService } from '@edp/core-common-frontend'
import './style.css'
import { ApiClient } from 'api/ApiClient'
import {
  ServiceExistUpdateTemplateBranchSpec,
  ServiceInfoValidateSpec,
  StatusBoolean,
} from '@edp/types'
import { useEffect, useState } from 'react'
import { GrUpdate } from '@edp/core-common-frontend/dist/packages/react-icons/gr'
import UpdateServiceModal from './UpdateServiceModal'
import {
  countingFillingServiceFieldsStatus,
  StatusFillingServiceSpec,
} from 'utils/countingFillingServiceFields'
import FillingStatusComponent, {
  FillingStatusComponentType,
} from '../FillingStatus/FillingStatusComponent'
import { FillingStatusAlert } from '../FillingStatus/FillingStatusAlert'

export const DescriptionPanel = () => {
  const { uuid } = useParams()
  const service = useFetchService(uuid)
  const [validateStatus, setValidateStatus] = useState<ServiceInfoValidateSpec>(
    { status: 'unknown', branch: '' }
  )

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [statusFillingService, setStatusFillingService] =
    useState<StatusFillingServiceSpec>()

  const apiClient = new ApiClient()

  const [serviceUUID, setServiceUUID] = useState<string>('')

  const [existUpdateTemplateBranch, setExistUpdateTemplateBranch] =
    useState<boolean>(false)
  const [serviceUpdateBranchUrl, setServiceUpdateBranchUrl] =
    useState<string>('')
  const [existUpdateTemplate, setExistUpdateTemplate] = useState<boolean>(false)
  const [serviceUpdated, setServiceUpdated] = useState<boolean>(false)

  const [openUpdateServiceModal, setOpenUpdateServiceModal] =
    useState<boolean>(false)

  useEffect(() => {
    const validateService = async () => {
      const apiClient = new ApiClient()

      try {
        if (uuid) {
          const data: ServiceInfoValidateSpec =
            await apiClient.serviceConfigurationValidate(uuid)
          setValidateStatus(data)
        }
      } catch (e) {
        console.log(e)
      }
    }

    validateService()
  }, [])

  useEffect(() => {
    if (service?.template) {
      checkExistUpdateTemplateBranch()
      checkExistUpdateTemplate()
    }
  }, [service])

  useEffect(() => {
    if (service?.uuid) {
      setServiceUUID(service.uuid)
    }
  }, [service])

  useEffect(() => {
    if (service?.uuid) {
      countingFillingServiceFieldsStatus(service)
        .then((status) => {
          setStatusFillingService(status)
          if (status.allFields !== status.filledFields.length) {
            setShowAlert(true)
          }
        })
        .catch((e) => {
          console.log(e)
          setStatusFillingService({
            message: 'Ошибка получения данных',
            filledFields: [],
            allFields: 0,
          })
        })
    }
  }, [service])

  useEffect(() => {
    if (serviceUpdated) {
      setServiceUpdated(false)
      checkExistUpdateTemplateBranch()
      checkExistUpdateTemplate()
    }
  }, [serviceUpdated])

  const handleInfoStatus = () => {}

  const generateValidateChipIcon = () => {
    let text = `Сервис имеет проблемы с конфигурацией\n\nВетка: ${validateStatus.branch}`

    if (validateStatus.errors) {
      // Ветка

      for (const error of validateStatus.errors) {
        if (error.filename === 'branch') {
          text = `${text}\n\n${error.message}`
        }
      }

      // Структура

      let messageSpec = ''

      for (const error of validateStatus.errors) {
        if (error.path) {
          messageSpec = `${messageSpec}\nФайл: ${error.filename}\nПуть: ${error.path}\nОшибка: ${error.message}`
        }
      }

      if (messageSpec !== '') {
        text = `${text}\n\nПроблемы со структурой .edp.yaml:\n${messageSpec}`
      }

      // Переменные

      let messageVariables = ''

      for (const error of validateStatus.errors) {
        if (error.variable) {
          messageVariables = `${messageVariables}\nПеременная: ${error.variable}\nОшибка: ${error.message}`
        }
      }

      if (messageVariables !== '') {
        text = `${text}\n\nПроблемы с переменными шаблона:\n${messageVariables}`
      }

      // Другие ошибки:

      let messageOtherErrors = ''
      for (const error of validateStatus.errors) {
        if (
          typeof error.variable === 'undefined' &&
          typeof error.path === 'undefined'
        ) {
          messageOtherErrors = `${messageOtherErrors}\n${error.message}`
        }

        if (messageOtherErrors !== '') {
          text = `${text}\n\nОшибки:\n${messageOtherErrors}`
        }
      }
    }

    return (
      <Tooltip
        title={text}
        placement="bottom"
        classes={{
          popper: 'tooltipPopperServiceClass',
          tooltipPlacementBottom: 'tooltipPopperServiceClassInfo',
        }}
      >
        <InfoIcon />
      </Tooltip>
    )
  }

  const validateChip = () => {
    return (
      <>
        {service?.templated && (
          <>
            {validateStatus.status === 'success' && (
              <Chip label="Конфигурация корректна" color="success" />
            )}
            {validateStatus.status === 'error' && (
              <Chip
                label="Есть проблемы с конфигурацией"
                color="warning"
                deleteIcon={generateValidateChipIcon()}
                onDelete={handleInfoStatus} // Пустая функция, но она обязательна
                classes={{
                  deleteIcon: 'deleteIconClass',
                }}
              />
            )}
          </>
        )}
      </>
    )
  }

  const newVersionTemplateChip = () => {
    return (
      <>
        {service?.templated && (
          <>
            {existUpdateTemplate && !existUpdateTemplateBranch && (
              <Chip
                style={{ marginLeft: '5px' }}
                label={`Доступна новая версия шаблона`}
                color="info"
              />
            )}
          </>
        )}
      </>
    )
  }

  const existUpdateTemplateBranchChip = () => {
    return (
      <>
        {service?.templated && (
          <>
            {existUpdateTemplateBranch && (
              <Chip
                style={{ marginLeft: '5px' }}
                label="Доступна ветка с обновлением"
                color="info"
                clickable={true}
                onClick={() => {
                  window.open(serviceUpdateBranchUrl)
                }}
              />
            )}
          </>
        )}
      </>
    )
  }

  const headerChips = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {(validateStatus.status === 'unknown' ||
          statusFillingService === undefined) && (
          <CircularProgress
            size="25px"
            color="inherit"
            style={{ marginRight: '5px' }}
          />
        )}
        {service !== undefined && statusFillingService !== undefined && (
          <div
            style={{
              marginRight: '10px',
            }}
          >
            <FillingStatusComponent
              service={service}
              type={FillingStatusComponentType.Circular}
              enabledLoading={false}
              status={statusFillingService}
            />
          </div>
        )}
        {validateChip()}
        {newVersionTemplateChip()}
        {existUpdateTemplateBranchChip()}
      </div>
    )
  }

  const UpdateCurrentVersionButton = (): JSX.Element => {
    return (
      <>
        <Button
          size="small"
          color="inherit"
          variant="text"
          onClick={() => {
            setOpenUpdateServiceModal(true)
          }}
          startIcon={<GrUpdate />}
        >
          Запустить обновление
        </Button>
      </>
    )
  }

  const checkExistUpdateTemplateBranch = () => {
    try {
      if (service?.uuid) {
        apiClient
          .serviceUpdateTemplateBranchExist(service.uuid)
          .then((res: ServiceExistUpdateTemplateBranchSpec) => {
            setExistUpdateTemplateBranch(res.status)
            if (res.url) {
              setServiceUpdateBranchUrl(res.url)
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const checkExistUpdateTemplate = () => {
    try {
      if (service?.uuid) {
        apiClient
          .serviceUpdateTemplateExist(service.uuid)
          .then((res: StatusBoolean) => {
            setExistUpdateTemplate(res.status)
          })
          .catch((e) => {
            console.log(e)
          })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Grid container spacing={6} direction="row" alignItems="stretch">
        <Grid item sm={12}>
          {service !== undefined && statusFillingService !== undefined && (
            <FillingStatusAlert
              service={service}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              status={statusFillingService}
            />
          )}
          <ComponentCard
            name={service?.name}
            description={service?.description}
            headerAction={headerChips()}
            headerClassName="headerServiceClass"
            headerClasses={{
              content: 'headerContentServiceClass',
              action: 'headerActionServiceClass',
            }}
            fullPage
            options={[
              {
                name: 'Идентификатор',
                value: `${service?.slug}`,
              },
              {
                name: 'Владелец',
                value: service?.ownerRef?.name,
                isUrl: true,
                externalUrl: false,
                url: `/teams/${service?.ownerRef?.uuid}`,
              },
              {
                name: 'Репозиторий',
                isUrl: true,
                value: service?.repositoryRef?.name,
                url: `/components/repositories/${service?.repositoryRef?.uuid}`,
                externalUrl: false,
              },
              {
                name: 'Репозиторий в Gitlab',
                isUrl: true,
                value: service?.repositoryRef?.slug,
                url: service?.repositoryRef?.url,
                externalUrl: true,
              },
              {
                name: 'Стадия',
                isUrl: false,
                value: service?.lifecycleStageRef?.name
                  ? `${service.lifecycleStageRef.name}`
                  : 'неизвестно',
              },
              {
                name: 'Создан из шаблона',
                value: service?.templated ? 'да' : 'нет',
              },
              {
                name: 'Автообновление шаблона',
                value: service?.templateUpdate ? 'да' : 'нет',
                disabled: !service?.templated,
              },
              ...(service?.templated
                ? [
                    {
                      name: 'Шаблон',
                      isUrl: true,
                      value: service?.templateRef?.name,
                      url: `/components/service-templates/${service?.templateRef?.uuid}`,
                      externalUrl: false,
                    },
                  ]
                : []),
            ]}
            customCardActions={UpdateCurrentVersionButton()}
          />
        </Grid>
        <Grid item sm={12}>
          <EnvironmentsStatusCard />
        </Grid>
      </Grid>
      <UpdateServiceModal
        open={openUpdateServiceModal}
        setOpen={setOpenUpdateServiceModal}
        setServiceUpdated={setServiceUpdated}
        uuid={serviceUUID}
      />
    </>
  )
}

export default DescriptionPanel
