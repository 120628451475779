import {
  Cancel,
  CheckCircle,
  NotInterested,
  Pending,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { TerraformActionRecordStatus } from '@edp/types'

export const StatusColors = {
  [TerraformActionRecordStatus.Success]: {
    backgroundColor: '#CAE5CF',
    color: '#2B5834',
    icon: <CheckCircle />,
  },
  [TerraformActionRecordStatus.Failed]: {
    backgroundColor: '#FFB6C1',
    color: '#D40A0A',
    icon: <Cancel />,
  },
  [TerraformActionRecordStatus.Created]: {
    backgroundColor: '#FFFACD',
    color: '#DAA520',
    icon: <Pending />,
  },
  [TerraformActionRecordStatus.Running]: {
    backgroundColor: '#ADD8E6',
    color: '#0000FF',
    icon: <Pending />,
  },
  [TerraformActionRecordStatus.Skipped]: {
    backgroundColor: '#D3D3D3',
    color: '#696969',
    icon: <NotInterested />,
  },
}
