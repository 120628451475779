import { SiMattermost } from '@edp/core-common-frontend/dist/packages/react-icons/si'
import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'
import {
  ActionVariables,
  MattermostWebhookVariables,
} from 'types/Managers/Actions'

import './styles.css'
import MattermostWebhookAction from 'pages/components/Actions/MattermostWebhookAction/MattermostWebhookAction'
import { MattermostWebhookActionForm } from 'pages/components/Actions/MattermostWebhookAction/store'
import { reaction, runInAction } from 'mobx'
import ServiceStore from '../store'
import { useEffect } from 'react'

export interface MattermostWebhookProps {
  actionVariables: ActionVariables
  index: number
}

export const MattermostWebhookLogo = () => {
  return (
    <Tooltip
      arrow
      title={'Mattermost webhook action отправляет webhook в Mattermost.'}
      placement="top"
    >
      <div>
        <SiMattermost className="ActionTitle-Logo" />
      </div>
    </Tooltip>
  )
}

export const MattermostWebhook = observer((props: MattermostWebhookProps) => {
  const form = MattermostWebhookActionForm()
  form.set(props.actionVariables.variables)

  const setActionsVariables = (index: number) => {
    runInAction(() => {
      const actionsVariables: ActionVariables[] = [
        ...ServiceStore.$('actionsVariables').value,
      ]

      const webhook: MattermostWebhookVariables = {
        url: form.$('url').value,
        webhook: {
          channel: form.$('webhook.channel').value,
          text: form.$('webhook.text').value,
          username: form.$('webhook.username').value,
        },
      }

      actionsVariables[index].variables = webhook

      ServiceStore.$('actionsVariables').set(actionsVariables)
    })
  }

  useEffect(() => {
    const disposer = reaction(
      () => ({
        webhook: form.$changed,
      }),
      () => {
        setActionsVariables(props.index)
      }
    )

    return () => disposer()
  }, [form, props.index])

  return <MattermostWebhookAction form={form} />
})
