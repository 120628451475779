import { getAuthType, getJWT } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import {
  TerraformActionRecordSpec,
  TerraformActionSpec,
  TerraformVariable,
} from '@edp/types'
import { handleResponse } from '@edp/core-common-frontend'

export async function createTerraformAction(
  this: ApiClient,
  spec: TerraformActionSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/terraform-actions`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getTerraformActions(
  this: ApiClient
): Promise<TerraformActionSpec[]> {
  const url = `${this.serverUrl}/terraform-actions`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getTerraformActionsBy(
  this: ApiClient,
  filterName: string,
  filterValue: string
): Promise<TerraformActionSpec[]> {
  const url = `${this.serverUrl}/terraform-actions?filterName=${filterName}&filterValue=${filterValue}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    },
  })
  return await handleResponse(response)
}

export async function getTerraformAction(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/terraform-actions/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getTerraformActionVariables(
  this: ApiClient,
  uuid: string
): Promise<TerraformVariable[]> {
  const url = `${this.serverUrl}/terraform-actions/${uuid}/variables`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getTerraformActionRecords(
  this: ApiClient,
  uuid: string,
  entityKind?: string,
  entityUUID?: string
): Promise<TerraformActionRecordSpec[]> {
  const url =
    `${this.serverUrl}/terraform-actions/${uuid}/records?` +
    new URLSearchParams({
      entityUUID: entityUUID ? entityUUID : '',
      entityKind: entityKind ? entityKind : '',
    })
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updateTerraformAction(
  this: ApiClient,
  spec: TerraformActionSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/terraform-actions/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function deleteTerraformAction(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/terraform-actions/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function terraformActionInit(
  this: ApiClient,
  uuid: string
): Promise<JSON> {
  const url = `${this.serverUrl}/terraform-actions/${uuid}/init`

  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await handleResponse(response)
}

export async function terraformActionPlan(
  this: ApiClient,
  uuid: string
): Promise<JSON> {
  const url = `${this.serverUrl}/terraform-actions/${uuid}/plan`

  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await handleResponse(response)
}

export async function terraformActionApply(
  this: ApiClient,
  uuid: string
): Promise<JSON> {
  const url = `${this.serverUrl}/terraform-actions/${uuid}/apply`

  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await handleResponse(response)
}

export async function terraformActionRun(
  this: ApiClient,
  uuid: string,
  terraformActionRecord: TerraformActionRecordSpec
): Promise<TerraformActionRecordSpec> {
  const url = `${this.serverUrl}/terraform-actions/${uuid}/run`
  const body = JSON.stringify(terraformActionRecord)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })
  return await handleResponse(response)
}

export async function getTerraformActionRecordLogs(
  this: ApiClient,
  uuid: string,
  stage: string
): Promise<any> {
  const url = `${this.serverUrl}/terraform-action-records/${uuid}/${stage}/logs`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getTerraformActionRecord(
  this: ApiClient,
  uuid: string
): Promise<any> {
  const url = `${this.serverUrl}/terraform-action-records/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
