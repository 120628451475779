import { getJWT, getAuthType } from 'api/utils'
import { ApiClient } from 'api/ApiClient'
import { KeycloakUser } from 'types/Keycloak'
import { GitlabUser } from 'types/Controllers/Gitlab'
import { TeamSpec } from '@edp/types'
import { handleResponse } from '@edp/core-common-frontend'

export async function createTeam(
  this: ApiClient,
  spec: TeamSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/teams`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getTeams(this: ApiClient): Promise<TeamSpec[]> {
  const url = `${this.serverUrl}/teams`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getTeam(
  this: ApiClient,
  uuid: string
): Promise<TeamSpec> {
  const url = `${this.serverUrl}/teams/${uuid}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updateTeam(
  this: ApiClient,
  spec: TeamSpec
): Promise<JSON> {
  const url = `${this.serverUrl}/teams/${spec.uuid}`
  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PATCH',
    body: body,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWT()}`,
      'X-Auth-Type': `${getAuthType()}`,
    }),
  })

  return await handleResponse(response)
}

export async function getKeycloakGroupMembers(
  this: ApiClient,
  uuid: string
): Promise<KeycloakUser[]> {
  const url = `${this.serverUrl}/teams/${uuid}/members`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function getGitlabGroupMembers(
  this: ApiClient,
  uuid: string
): Promise<GitlabUser[]> {
  const url = `${this.serverUrl}/teams/${uuid}/gitlab/members`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function syncGitlabGroupMembers(
  this: ApiClient,
  uuid: string
): Promise<KeycloakUser[]> {
  const url = `${this.serverUrl}/teams/${uuid}/sync/gitlab/members`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function deleteTeam(this: ApiClient, uuid: string) {
  const url = `${this.serverUrl}/teams/${uuid}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}
