import { TeamSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { ComponentPage } from 'components/ComponentPage'
import { OperationsPanel } from 'components/OperationsPanel/OperationsPanel'
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { CosignPanel } from './CosignPanel/CosignPanel'
import DescriptionPanel from './DescriptionPanel/DescriptionPanel'
import MonitoringPanel from './MonitoringPanel/MonitoringPanel'
import { ResourcesPanel } from './ResourcesPanel/ResourcesPanel'
import ServicesPanel from './ServicesPanel/ServicesPanel'
import SyncPanel from './SyncPanel/SyncPanel'
import TeamMembersPanel from './TeamMembersPanel/TeamMembersPanel'

export const TeamPage = () => {
  const { uuid } = useParams()
  const [team, setTeam] = useState<TeamSpec>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const getTeam = async () => {
      const apiClient = new ApiClient()

      try {
        setLoading(true)
        const data = await apiClient.getTeam(uuid || '')
        setTeam(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    getTeam()
  }, [])

  const panels = [
    {
      name: 'Описание',
      index: 0,
      children: <DescriptionPanel />,
    },
    {
      name: 'Сервисы',
      index: 1,
      children: <ServicesPanel team={team} />,
    },
    {
      name: 'Участники',
      index: 2,
      children: <TeamMembersPanel />,
    },
    {
      name: 'Мониторинг',
      index: 3,
      children: <MonitoringPanel />,
    },
    {
      name: 'Ресурсы Kubernetes',
      index: 4,
      children: <ResourcesPanel />,
    },
    {
      name: 'Синхронизация',
      index: 5,
      children: <SyncPanel />,
    },
    {
      name: 'Подпись артефактов',
      index: 6,
      children: <CosignPanel />,
    },
    {
      name: 'Операции',
      index: 7,
      children: <OperationsPanel entity={uuid || ''} />,
    },
  ]

  return (
    <>
      {!loading && !team ? (
        <Navigate to="/404" />
      ) : (
        <ComponentPage panels={panels} />
      )}
    </>
  )
}
export default TeamPage
